import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./procurements.scss";
import { ProgressBarChart } from "../../components/ProgressBarComponent/ProgressBarComponent";
import { Icon } from "@chakra-ui/react";
import project_icon from "../../assets/icons/project-icon.svg";
import { BsThreeDots } from "react-icons/bs";
import { ProjectsContext } from "../../context/ProjectsContext";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";
import image from "../../assets/images/profile-card-cover-image.jpg";
import { MainDrawerComponent } from "../../components/AccountSetUpCard/DrawerComponent";
import axios from "axios";
import {
  get_procurement_requests,
  cancel_procurement_requests,
  change_procurement_requests_status,
} from "../../config/variables";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
// import avatar from '../../assets/images/avatar.png';
import { IoMdArrowRoundBack, IoMdCheckmark } from "react-icons/io";
import { FaUserEdit } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useContext } from "react";
import { TostMessageContext } from "../../context/TostMessage";
import { MdOutlineCancel } from "react-icons/md";
import { IoCheckmarkDone } from "react-icons/io5";
import { FaRegPenToSquare } from "react-icons/fa6";

export const Staffs = [
  {
    name: "John Smith",
    image: image,
    department: "Engineering",
    checkedin: true,
    phone: "0903902980383",
    date: "today",
  },
  {
    name: "Philips Roy",
    image: image,
    department: "Engineering",
    checkedin: false,
    phone: "0903902980383",
    date: "today",
  },
  {
    name: "Andri Beoy",
    image: image,
    department: "Engineering",
    checkedin: true,
    phone: "0903902980383",
    date: "yesterday",
  },
  {
    name: "Ray Bob",
    image: image,
    department: "Engineering",
    checkedin: false,
    phone: "0903902980383",
    date: "yesterday",
  },
  {
    name: "Joseph Evi",
    image: image,
    department: "Engineering",
    checkedin: true,
    phone: "0903902980383",
    date: "yesterday",
  },
];

function Procurements() {
  const [loading, setLoading] = React.useState(false);
  const [cancelling, setCancelling] = React.useState(false);
  const [input, setInput] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [staffDetails, setStaffDetails] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const { setTostMessage } = useContext(TostMessageContext);
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const navigate = useNavigate();

  const fetchStaffs = async () => {
    if (staffs?.length < 1) {
      setLoading(true);
    }

    try {
      const response = await axios.post(
        get_procurement_requests,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );

      setStaffs(response.data.response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      fetchStaffs();
    })();
  }, [selectedOption]);

  async function cancelRequest(id) {
    setCancelling(true);
    try {
      const response = await axios.post(
        cancel_procurement_requests,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            request_id: id,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      if (response.data.status) {
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
      fetchStaffs();
      setCancelling(false);
    } catch (error) {
      setTostMessage({
        messageType: "error",
        message: "Network error",
      });
      setCancelling(false);
    }
  }

  async function changeRequestStatus(type, id) {
    setCancelling(true);
    try {
      const response = await axios.post(
        change_procurement_requests_status,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            request_id: id,
            action: type,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      if (response.data.status) {
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
      fetchStaffs();
      setCancelling(false);
    } catch (error) {
      setTostMessage({
        messageType: "error",
        message: "Network error",
      });
      setCancelling(false);
    }
  }
  // console.log(userProfile);

  return (
    <div className='MyStaffs xl:px-10 max-md:px-6 md:px-10'>
      {staffDetails ? (
        <>

        </>
      ) : (
        <>
          <div className='py-3 flex justify-between items-center'>
            <h2 className='text-[1rem] sm:text-lg lg:text-2xl font-bold'>Requests</h2>
            {userProfile?.sub_account_overview?.allowed_permissions?.includes(
              "create_procurement_request"
            ) ||
            userProfile?.sub_account_overview?.allowed_permissions?.includes(
              "full_access"
            ) ? (
              <MainDrawerComponent
                open={29}
                size={"sm"}
                card={{ sub: "Create New Request" }}
                fetchRequests={fetchStaffs}
              >
                <button className='px-3 sm:px-6 flex items-center hover:opacity-[0.9] gap-2 py-2 rounded-lg text-white bg-native-blue border'>
                  <FaRegPenToSquare /> Create New Request
                </button>
              </MainDrawerComponent>
            ) : null}
          </div>
          <div className='mt-6 w-full overflow-auto'>
            {loading ? (
              <div>
                {[1, 2, 3, 4, 5, 6].map((i, index) => (
                  <div
                    key={index}
                    className='h-12 w-full bg-white animate-pulse py-3 my-2 mt-6'
                  ></div>
                ))}
              </div>
            ) : (
              <table className='min-w-full border rounded-xl border-gray-300 bg-white'>
                <thead className=''>
                  <tr className='bg-[#F9F9F9]'>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Requested By</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Department</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Project</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Request</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Date</th>
                    {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "handle_procurement_requests"
                    ) ||
                    userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "full_access"
                    ) ? (
                      <th className='py-3 px-2 border-b font-semibold text-[14px]'></th>
                    ) : null}
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'></th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'></th>
                  </tr>
                </thead>
                <tbody>
                  {staffs?.map((item, id) => (
                    <tr key={id} className='hover:bg-gray-50 border-b'>
                      <td className='py-3 px-2 text-[13px] font-semibold'>
                        <div>
                          <div className='font-semibold'>
                            {item.requested_by}
                          </div>
                        </div>
                      </td>
                      <td className='py-3 px-2 text-[13px] font-semibold'>
                        {item.requested_by_dept}
                      </td>
                      <td className='py-3 px-2 text-[13px] font-semibold'>
                        {item.project_title}
                      </td>
                      <td className='py-3 px-2 text-[13px] font-semibold'>{item.request}</td>
                      <td className='py-3 px-2 text-[13px] font-semibold'>{item.time}</td>
                      {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                        "handle_procurement_requests"
                      ) ||
                      userProfile?.sub_account_overview?.allowed_permissions?.includes(
                        "full_access"
                      ) ? (
                        <td className='py-3 px-2 text-[13px] font-semibold'>
                          {item.hr_status == 0 && (
                            <button
                              onClick={() =>
                                changeRequestStatus("approve", item.request_id)
                              }
                              className='p-1 text-sm my-1 mx-1 bg-[#0091ff] text-white rounded-lg'
                            >
                              <IoMdCheckmark color='white' />
                            </button>
                          )}
                          {item.hr_status == 1 && (
                            <button
                              onClick={() =>
                                changeRequestStatus("treated", item.request_id)
                              }
                              className='p-1 text-sm my-1 mx-1 bg-[#00b47a] text-white rounded-lg'
                            >
                              <IoCheckmarkDone color='white' />
                            </button>
                          )}
                          {item.hr_status == 1 && (
                            <button
                              onClick={() =>
                                changeRequestStatus("reject", item.request_id)
                              }
                              className='p-1 text-sm my-1 mx-1  bg-[#d40000] text-white rounded-lg'
                            >
                              <MdOutlineCancel color='white' />
                            </button>
                          )}
                          {item.hr_status == 0 && (
                            <button
                              onClick={() =>
                                changeRequestStatus("reject", item.request_id)
                              }
                              className='p-1 text-sm my-1 mx-1  bg-[#d40000] text-white rounded-lg'
                            >
                              <MdOutlineCancel color='white' />
                            </button>
                          )}
                        </td>
                      ) : null}
                      <td
                        className={`py-2 px-2 border-b ${
                          item.hr_status == 0 && "text-[#fcba03]"
                        } ${item.hr_status == 1 && "text-[#000aff]"} ${
                          item.hr_status == 2 && "text-[#00b47a]"
                        } ${item.hr_status == 3 && "text-[#d40000]"} `}
                      >
                        {item.hr_status == 0 && "PENDING"}
                        {item.hr_status == 1 && "APPROVED"}
                        {item.hr_status == 2 && "TREATED"}
                        {item.hr_status == 3 && "REJECTED"}
                      </td>
                      <td className='py-2 px-2 border-b'>
                        {item.requester_id == userProfile?.sub_account_id ? (
                          <button
                            onClick={() =>
                              cancelling ? null : cancelRequest(item.request_id)
                            }
                            className='p-1 text-sm my-1 bg-[#d40000] text-white rounded-lg'
                          >
                            {cancelling ? "Cancelling..." : "Cancel"}
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Procurements;
