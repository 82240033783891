import { useContext } from 'react';
import { FiCalendar, FiUserCheck,  } from 'react-icons/fi';
import { ImHome, ImCalendar,ImDatabase } from 'react-icons/im';
import { IoPersonAddOutline } from 'react-icons/io5';
import { LuGitPullRequest } from 'react-icons/lu';
import { MdOutlinePendingActions, MdOutlineImage } from 'react-icons/md';
import {
  RxDashboard,
  RxAvatar,
  RxMagnifyingGlass,
  RxBarChart,
  RxHelpCircle,RxComponent2
} from 'react-icons/rx';
import { TbHelp, TbPlus, TbSection, TbSubtask } from 'react-icons/tb';
import { v4 as uuidv4 } from 'uuid';
export const DashboardSideNavLinks = [
  {
    id: uuidv4(),
    icon: <RxDashboard />,
    name: 'Home',
    path: '/dashboard/home',
    accountType: 'both',
  },
  {
    id: uuidv4(),
    icon: <RxMagnifyingGlass />,
    name: 'Find Projects',
    path: '/dashboard/search-project',
    accountType: 'contractor',
  },
  {
    id: uuidv4(),
    icon: <TbPlus />,
    name: 'Add Project',
    path: '/dashboard/create-project',
    accountType: 'client',
  },
  // {
  //   id: uuidv4(),
  //   icon: <MdOutlinePendingActions />,
  //   name: "Ongoing Project",
  //   path: "/dashboard/ongoing-projects",
  //   accountType: "contractor",
  // },
  {
    id: uuidv4(),
    icon: <MdOutlinePendingActions />,
    name: 'My Projects',
    path: '/dashboard/ongoing-projects',
    accountType: 'both',
  },
  // {
  //  id: uuidv4(),
  //  icon: <MdOutlineImage />,
  //  name: 'Media Library',
  //  path: '/dashboard/projects-gallery',
  //  accountType: 'both',
  // },
  // {
  //   id: uuidv4(),
  //   icon: <ImCalendar />,
  //   name: "Project History",
  //   path: "/dashboard/past-projects",
  //   accountType: "both",
  // },
  {
    id: uuidv4(),
    icon: <ImCalendar />,
    name: 'Application',
    path: '/dashboard/applications',
    accountType: 'contractor',
  },
  // {
  //   id: uuidv4(),
  //   icon: <ImCalendar />,
  //   name: "Project Bids",
  //   path: "/dashboard/project-bids",
  //   accountType: "client",
  // },
  {
    id: uuidv4(),
    icon: <FiCalendar />,
    name: 'Schedular',
    path: '/dashboard/schedule',
    accountType: 'client',
  },
  {
    id: uuidv4(),
    icon: <LuGitPullRequest />,
    name: 'Procurements',
    path: '/dashboard/procurements',
    accountType: 'client',
  },
  {
    id: uuidv4(),
    icon: <FiUserCheck />,
    name: 'Attendance',
    path: '/dashboard/attendance',
    accountType: 'client',
  },
  // {
  //   id: uuidv4(),
  //   icon: <TbSubtask />,
  //   name: 'Tasks',
  //   path: '/dashboard/tasks',
  //   accountType: 'client',
  // },
  {
    id: uuidv4(),
    icon: <TbSection />,
    name: 'Roles & Levels',
    path: '/dashboard/departments',
    accountType: 'client',
  },
  // {
  //   id: uuidv4(),
  //   icon: <IoPersonAddOutline />,
  //   name: 'Add User',
  //   path: '/dashboard/add-staff',
  //   accountType: 'client',
  // },
  {
    id: uuidv4(),
    icon: <IoPersonAddOutline />,
    name: 'Manage Users',
    path: '/dashboard/register-staff',
    accountType: 'client',
  },
  {
    id: uuidv4(),
    icon: <RxBarChart />,
    name: 'Financials',
    path: '/dashboard/finance',
    accountType: 'both',
  },
  // {
  //   id: uuidv4(),
  //   icon: <ImDatabase />,
  //   name: 'Switch Apps.',
  //   path: '/dashboard/enterprise-applications',
  //   accountType: 'both',
  // },
  {
    id: uuidv4(),
    icon: <RxAvatar />,
    name: 'Profile',
    path: '/dashboard/profile',
    accountType: 'both',
  },
  // {
  //   id: uuidv4(),
  //   icon: <TbHelp />,
  //   name: 'Help',
  //   path: '/dashboard/faq',
  //   accountType: 'both',
  // },
];
