
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { login_to_facility_endpoint } from '../../config/variables';
import { useCookies } from 'react-cookie';
import { TostMessageContext } from '../../context/TostMessage';
import { AuthContext } from '../../context/AuthContext';

function FacilityEntry() {
    const [loading,setLoading] = useState(false)
    const cookies = useCookies()
    const { setTostMessage } = React.useContext(TostMessageContext);
    const { userProfile } = useContext(AuthContext);
    const login_to_facility = async () => {
        setLoading(true)
        let data = JSON.stringify({
            user: {
              account_id: userProfile?.account_id,
              sub_account_id: userProfile?.sub_account_id,
            },
            data: {
              email: userProfile?.email,
            },
          });
    
          const headers = {
            access: cookies.urbexEnterpriseUserToken,
            "Content-Type": "application/json",
          };
          axios
            .post(login_to_facility_endpoint, data, { headers })
            .then((response) => {
              if (!response.data.status) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                setLoading(false);
              }
              if (response.data.status) {
                setTostMessage({
                  messageType: "success",
                  message: response.data.response,
                });
                const redirect_url = response.data.redirect_url
                window.open(redirect_url,"_blank");
                setLoading(false)
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              setLoading(false)
            });
            
    }
  return (
    <div className='d-flex flex-column justify-content-center '>
        <div className='alert alert-warning'>
            You are about to enter into the facility dashboard.
            <button className='mx-3 btn btn-sm btn-info text-white' disabled={loading} onClick={()=>{
                login_to_facility()
            }}>{`${loading ? 'Processing':"Click here to proceed"}`}</button>
        </div>
    </div>
  )
}

export default FacilityEntry