import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
// import LandingPage from './pages/LandingPage/LandingPage';
import LoginPage from "./pages/AuthPage/LoginPage";
import RegisterPage from "./pages/AuthPage/RegisterPage";
import OtpVerificationPage from "./pages/AuthPage/OtpVerificationPage";
// import { ToastContainer } from 'react-toastify';
import PasswordResetPage from "./pages/AuthPage/PasswordResetPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import HomePage from "./pages/HomePage/HomePage";
import CreateProjectPage from "./pages/CreateProjectPage/CreateProjectPage";
import SearchProjectPage from "./pages/SearchProjectPage/SearchProjectPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import EmployeeAttendanceSection from "./pages/attendance/attendance";
import TostMessageComponent from "./components/TostMessageComponent/TostMessageComponent";
import OngoingProjectsPage from "./pages/OngoingProjectsPage/OngoingProjectsPage";
import PastProjectsPage from "./pages/PastProjectsPage/PastProjectsPage";
import FinancePage from "./pages/FinancePage/FinancePage";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import { VerificationProvider } from "./context/VerificationContext";
import ViewOngoingProjectsPage from "./pages/OngoingProjectsPage/ViewOngoingProjectsPage";
import { ProjectsProvider } from "./context/ProjectsContext";
import ApplicationPage from "./pages/ApplicationPage/ApplicationPage";
import Departments from "./pages/Departments/Departments";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import ChatPage from "./pages/ChatPage/ChatPage";
import RegisterStaff from "./pages/RegisterStaff/index";
import MyStaffs from "./pages/StaffList/AllStaff";
import AuditPage from "./pages/AuditPage/index";
import CallPage, { VideoCallCardHover } from "./pages/CallPage/CallPage.jsx";
import FAQPage from "./pages/FAQPage/FAQPage";
import ActivityTracker from "./pages/ActivityTracker/ActivityTracker.jsx";
import StaffProfile from "./pages/StaffProfile";
import Tasks from "./pages/tasks/tasks.jsx";
import {
  VideoCallContext,
  VideoCallProvider,
} from "./context/videoCallContext";
import { useContext, useEffect } from "react";
import { PushNotificationContext } from "./context/PushNotificationContext";
import ProjectsGallery from "./pages/Gallery/projects-gallery";
import EditProjectPage from "./pages/EditProject/EditProject.jsx";
import Index from "./pages/schedule/schedule.jsx";
import Procurements from "./pages/procurements/procurements";
import AddStaff from "./pages/add-staff/add-staff";
import DeleteData from "./pages/DeleteData/DeleteData.jsx";
import { AuthContext } from "./context/AuthContext.jsx";
import addNotification, { Notifications } from "react-push-notification";
import { useCookies } from "react-cookie";
import { onMessage } from "firebase/messaging";
import { generateToken, messaging } from "./notification/firebase.js";
import FacilityEntry from "./pages/facility/FacilityEntry.jsx";
import Applications from "./pages/Applications/Applications.jsx";
import StaffAnalytics from "./pages/StaffProfile/StaffAnalytics.jsx";
function App() {
  const { callButtonClicked, incomingCall, setIncomingCall } =
    useContext(VideoCallContext);
  const [cookies] = useCookies();

  const {
    isPushNotificationSupported,
    sendNotification,
    initializePushNotifications,
    registerServiceWorker,
  } = useContext(PushNotificationContext);
  const { getUserProfileFunction } = useContext(AuthContext);
  const { userProfile } = useContext(AuthContext);

  useEffect(() => {
    const pushNotificationSuported = isPushNotificationSupported();
    if (pushNotificationSuported) {
      registerServiceWorker();
      initializePushNotifications().then(function (consent) {
        if (consent === "granted") {
          // sendNotification();
        }
      });
    }
  }, []);

  useEffect(() => {
    generateToken(
      cookies?.urbexEnterpriseUserToken,
      cookies?.userEnterpriseAccountIds?.account_id,
      cookies?.userEnterpriseAccountIds?.sub_account_id
    );
    onMessage(messaging, (payload) => {
      addNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        theme: "darkblue",
        native: true,
        icon: payload.notification.image,
      });
    });
  }, [userProfile]);

  useEffect(() => {
    if (!userProfile?.account_id) {
      getUserProfileFunction();
    }
  }, []);

  return (
    <div>
      <Notifications />
      <TostMessageComponent />
      <Routes>
        <Route exact path='/' element={<Navigate replace to='/login' />} />
        <Route exact path='/login' element={<LoginPage />} />
        <Route exact path='/register' element={<RegisterPage />} />
        <Route exact path='/verify/:email' element={<OtpVerificationPage />} />
        <Route exact path='/password-reset' element={<PasswordResetPage />} />
        <Route
          exact
          path='/dashboard'
          element={<Navigate replace to='/dashboard/home' />}
        />
        <Route path='/delete' element={<DeleteData />} />
        <Route
          path='/dashboard'
          element={
            <VerificationProvider>
              <ProjectsProvider>
                <Dashboard />
              </ProjectsProvider>
            </VerificationProvider>
          }
        >
          <Route path='home' element={<HomePage />} />
          <Route
            path='search-project/:staff_id'
            element={
              <ProjectsProvider>
                <SearchProjectPage />
              </ProjectsProvider>
            }
          />
          <Route
            path='search-project/:id'
            element={
              <ProjectsProvider>
                <ProjectPage />
              </ProjectsProvider>
            }
          />
          <Route
            path='ongoing-projects'
            exact
            element={<OngoingProjectsPage />}
          />
          <Route
            path='facility-manager'
            exact
            element={<FacilityEntry />}
          />
          <Route path='projects-gallery' exact element={<ProjectsGallery />} />
          <Route path='activity-tracker' exact element={<ActivityTracker />} />
          <Route
            path='ongoing-projects/:id'
            element={<ViewOngoingProjectsPage />}
          />
          <Route path='edit-project/:id' element={<EditProjectPage />} />
          <Route path='staff-analytics/:staff_id' element={<StaffAnalytics />} />
          <Route path='subscriptions' exact element={<Subscriptions />} />
          <Route path='audit-page' exact element={<AuditPage />} />
          <Route path='past-projects' element={<PastProjectsPage />} />
          <Route path='create-project' element={<CreateProjectPage />} />
          <Route path='applications' element={<ApplicationPage />} />
          <Route path='enterprise-applications' element={<Applications />} />
          <Route
            path='past-projects/:id'
            element={<ViewOngoingProjectsPage />}
          />
          <Route path='ongoing-projects/:id/chat' element={<ChatPage />} />
          <Route path='finance' element={<FinancePage />} />
          <Route path='profile' element={<ProfilePage />} />
          <Route path='attendance' element={<EmployeeAttendanceSection />} />
          <Route path='schedule' element={<Index />} />
          <Route path='procurements' element={<Procurements />} />
          <Route path='departments' element={<Departments />} />
          <Route path='add-staff' element={<AddStaff />} />
          <Route path='faq' element={<FAQPage />} />
          <Route path='register-staff' element={<RegisterStaff />} />
          <Route path='register-staff/staff-list' element={<MyStaffs />} />
          <Route path='tasks' element={<Tasks />} />
          <Route
            path='register-staff/staff-list/staff-profile'
            element={<StaffProfile />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
