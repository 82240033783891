import { useContext, useState } from "react";
import ModalComponent from "../../components/Modal";
import Logo from "../../assets/images/logo2.png";
import Trash from "../../assets/images/trash-9203u92f8.png";
import { TostMessageContext } from "../../context/TostMessage";

const DeleteData = () => {
	const {setTostMessage} = useContext(TostMessageContext)
	const [isActiveModal, setIsModalActive] = useState(false);

	function doSomething() {
		setTostMessage({
			messageType: 'success',
			message: 'Request successful. You will receive a confirmation email from Urbex.'
		})
	}

	function closeCurrentModal() {
		return isActiveModal ? setIsModalActive(false) : null;
	}

	return (
		<div className="grid grid-cols-1 lg:grid-cols-[.8fr,1fr] min-h-[100vh] bg-gray-200/20 py-8 px-2 ">
			<div className="items-center justify-center hidden lg:flex">
				<img src={Trash} alt="" className="" />
			</div>
			<form action="" className="max-w-xl flex items-center w-full mx-auto">
				<div className="bg-white px-3 py-4 md:!px-10 shadow-sm flex flex-col gap-y-3 h-5/6 rounded-[8px] w-full">
					<div className="flex items-center justify-center mx-auto relative">
						<img src={Logo} alt="Urbex's Logo" className="max-w-full w-auto h-20" />
					</div>
					<div className="pt-3 pb-2 italic">
						<span>Enter your credentails to proceed to delete </span>
					</div>
					<div className="flex flex-col items-start w-full">
						<label htmlFor="email">Email</label>
						<input
							type="email"
							placeholder="Enter your email address"
							id="email"
							className="w-full rounded-[5px] border border-black/60 bg-gray-200/30 min-h-[3rem]"
						/>
					</div>
					<div className="flex flex-col items-start w-full">
						<label htmlFor="password">Password</label>
						<input
							type="password"
							placeholder="Enter your password"
							id="password"
							className="w-full rounded-[5px] border border-black/60 bg-gray-200/30 min-h-[3rem]"
						/>
					</div>
					<div className="mt-4 w-full">
						<button
							type="button"
							className="w-full bg-[#ff0000] text-white rounded-[5px] min-h-[2.8rem] py-3"
							onClick={() => setIsModalActive(true)}>
							Yes, Delete Account
						</button>
					</div>
					<div className="mt-2 text-red-500">
						<span>Kindly note that by deleting your account, all associated data will be deleted and you will not be able to access the Urbex Platform anymore.</span>
					</div>
				</div>
			</form>

			{isActiveModal && (
				<ModalComponent isActiveModal modalTitle={"Delete"} closeModal={() => setIsModalActive(false)}>
					<div className="min-h-[150px] pt-6">
						<h4 className="text-center">Are you sure you want to delete ?</h4>
						<div className="flex items-center justify-center gap-x-3 mt-4">
							<button
								type="button"
								className="bg-slate-200/30 border border-slate-200/40 rounded-md hover:bg-slate-200/50 transition duration-200 ease-in-out py-2 px-6"
								onClick={() => closeCurrentModal()}>
								No
							</button>
							<button
								type="button"
								onClick={doSomething}
								className="transition duration-200 ease-in-out py-2 px-6 bg-red-600 hover:bg-red-700 rounded-md text-white/80">
								Yes
							</button>
						</div>
					</div>
				</ModalComponent>
			)}
		</div>
	);
};

export default DeleteData;
