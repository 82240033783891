import React, { createRef, useCallback } from "react";
import {
  BidProfileCard,
  DocumentsCard,
  MilestoneCard,
  NotificationCard,
  TabPanelComponent,
  TransferCard,
} from "./DrawerComponent";
import { IoIosArrowDown, IoMdArrowRoundBack, IoMdCheckmarkCircleOutline } from "react-icons/io";
import { Timeline, Event } from "react-timeline-scribble";
import AnimatedProgressBar from "react-bootstrap/ProgressBar";
// for document signature
import { useRef, useState } from "react";
import ReactLoading from "react-loading";
import { Tooltip } from 'react-tooltip'
import { blobToURL } from "./signature/Utils";
import FileViewer from 'react-file-viewer';
import { FcCancel } from "react-icons/fc";
import { GrDocumentTime } from "react-icons/gr";
// end of document signature

import check_done_icon from "../../assets/icons/check-done.svg";
import transfer_in from "../../assets/icons/transer_in.svg";
import transfer_out from "../../assets/icons/transfer_out.svg";
import notification_ball from "../../assets/icons/notification-ball.svg";
import MultipleFileUploadComponent from "../MultipleFileUploadComponent/MultipuleFileUploadComponent";
import { TostMessageContext } from "../../context/TostMessage";
import { AuthContext } from "../../context/AuthContext";
import { downloadFile, formatNumber } from "../../lib/index";
import Collapsible from 'react-collapsible';
import Marquee from "react-fast-marquee";
import { ProgressBarChart } from "./../../components/ProgressBarComponent/ProgressBarComponent";
import {
  add_milestones_end_point,
  add_to_portfolio_end_point,
  base_url,
  bid_for_project_end_point,
  complete_milestone_end_point,
  download_milestone_report_end_point,
  fetch_bid_details_end_point,
  start_milestone_end_point,
  submit_withdraw_request,
  upload_milestone_csv_endpoint,
  pay_with_wallet_endpoint,
  weekly_report,
  get_weekly_report,
  Add_documents,
  upload_project_csv_endpoint,
  create_procurement_request,
  create_event,
  create_announcement,
  pay_online_endpoint,
  change_password_end_point,
  delete_bulk_milestones,
  delete_milestones,
  logged_in_password_change,
  update_notifications_endpoint,
  fetch_notifications_endpoint,
  delete_main_document,
  fetch_messages_endpoint,
  approve_report,
  add_addons_endpoint,
  upload_weekly_report_file,
  fetch_user_projects_endpoint,
  add_user_to_projects,
  remove_user_from_projects_endpoint,
  fetch_remaining_permissions,
  add_user_permissions,
  revoke_user_permissions,
  get_budget_summary,
  add_project_funds,
  save_daily_checklist_endpoint,
  edit_milestone_cost,
  remove_project_funds
} from "../../config/variables";
import { useCookies } from "react-cookie";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { Link, useParams, useLocation } from "react-router-dom";
import { ProjectsContext } from "../../context/ProjectsContext";
import { saveAs } from "file-saver";
import greenTick from "../../assets/icons/green-tick.svg";
import csv_image from "../../assets/images/CSV_TEMP.png";
import csv_proj from "../../assets/images/CSV_PROJ.png";
import { BsArrowLeftCircle, BsTrash, BsCheck2Circle, BsInfoSquare, BsInfoLg, BsCheckCircle } from "react-icons/bs";
import { AiOutlinePlus, AiOutlineMinus, AiOutlineDelete } from "react-icons/ai";
import project_icon from "../../assets/icons/project-icon.svg";
import { VscAdd, VscDiffRemoved } from "react-icons/vsc";
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { useContext, useEffect } from "react";
import axios from "axios";
import { LuDownload, LuImagePlus } from "react-icons/lu";
import { IoAddSharp, IoArrowBack, IoDocumentAttachOutline, IoEyeSharp } from "react-icons/io5";
import {
  FaFileImage,
  FaFilePdf,
  FaFileArchive,
  FaFileAudio,
  FaFile,
} from "react-icons/fa";
import './ViewFile.scss';
 
// PDF
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import "../../pages/ChatPage/ChatPage.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import pin_icon from "../../assets/icons/pin-icon.svg";
import send_icon from "../../assets/icons/send-icon.svg";
import { MdSend, MdCloudDownload, MdEditNote, MdOutlineAvTimer, MdOutlineFileDownload, MdOutlineSupervisedUserCircle, MdCandlestickChart } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import io from "socket.io-client";
import { file_upload_endpoint } from "../../config/variables";
import { VideoCallContext } from "../../context/videoCallContext";
import { BsEmojiSmile, BsCardImage } from "react-icons/bs";
import { BiDotsVertical, BiPhone } from "react-icons/bi";
import { FiVideo } from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";
import Chart from 'react-apexcharts'

import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Icon,
  Box,
  FormLabel,
  Input,
  Select,
  Switch,
  InputGroup,
  InputRightElement,
  Avatar,
  Divider,
  useBoolean,
  Textarea,
} from "@chakra-ui/react";
import ReactSelect from "react-select";
import { WithdrawTabFormComponent } from "../FormsComponent/FormsComponent";
import { useDropzone } from "react-dropzone";
import { render } from "react-dom";
import { ReactGrid, Column, Row } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { TfiWrite } from "react-icons/tfi";
import { FaCheck, FaRegFlag, FaRegPenToSquare } from "react-icons/fa6";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TbEdit, TbEyeShare, TbHelpCircle, TbHelpSquareRounded } from "react-icons/tb";
import { GoDotFill } from "react-icons/go";

export function PasswordSecuritySettingsTab({ closeTabFunction }) {
  const [show, setShow] = React.useState(false);
  const [updatePassword, setUpdatePassword] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleUpdatePasswordClick = () => setUpdatePassword(!updatePassword);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const [cookies] = useCookies();
  const { setTostMessage } = useContext(TostMessageContext);

  async function updatePasswordFunc() {
    if (oldPass == "" || newPass == "" || confirmPass == "") {
      setTostMessage({
        messageType: "warning",
        message: "Please fill all fields.",
      });
      return;
    }

    if (newPass !== confirmPass) {
      setTostMessage({
        messageType: "warning",
        message: "Password mismatch.",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        logged_in_password_change,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            oldPass: oldPass,
            newPass: newPass,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const resData = await response.data;
      if (resData.status) {
        setTostMessage({
          messageType: "success",
          message: "Password changed successfully",
        });
        setUpdatePassword(false);
        closeTabFunction();
      } else {
        setTostMessage({
          messageType: "error",
          message: resData.response,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTostMessage({
        messageType: "error",
        message: "Could not change password now, please try again later.",
      });
    }
  }
  return (
    <div className='PasswordSecuritySettingsTab'>
      {updatePassword ? (
        <div className='update-password'>
          <div className='icon-container'>
            <FaArrowAltCircleLeft onClick={() => handleUpdatePasswordClick()} />
          </div>
          {/* <h2>Update Password</h2> */}
          <div>
            <b> Current Password</b>
            <p>
              <InputGroup size='md'>
                <Input
                  pr='4.5rem'
                  autoComplete='off'
                  type={show ? "text" : "password"}
                  placeholder='Enter password'
                  value={oldPass}
                  onChange={(e) => setOldPass(e.target.value)}
                />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </p>
          </div>
          <div>
            <b> New Password</b>
            <p>
              <InputGroup size='md'>
                <Input
                  pr='4.5rem'
                  autoComplete='off'
                  type={show ? "text" : "password"}
                  placeholder='Enter password'
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </p>
          </div>
          <div>
            <b> Confirm New Password</b>
            <p>
              <InputGroup size='md'>
                <Input
                  pr='4.5rem'
                  autoComplete='off'
                  type={show ? "text" : "password"}
                  placeholder='Enter password'
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </p>
          </div>
          <div className='mt-auto'>
            <button
              className='submit-button'
              onClick={() => (loading ? null : updatePasswordFunc())}
            >
              {loading ? "Updating..." : "Update Password"}
            </button>
          </div>
        </div>
      ) : (
        <>
          <p className=''>
            <sub>
              To change your password, you would be required to enter both your
              old and new password to enable us confirm its you.
            </sub>
          </p>
          <b className='d-flex gap-2 align-items-center'>
            <button
              className='icon_container p-2 text-white rounded-lg bg-[#000aff]'
              onClick={() => handleUpdatePasswordClick()}
            >
              Change Password
            </button>
          </b>
          {/* <div>
            <b>Biometrics</b>
            <div className="g-card">
              <p>Face or fingerprint</p> <Switch />
            </div>
            <sub>
              Use face recognition or fingerprint ID instead of a password to
              authenticate in the mobile app
            </sub>
          </div> */}
          {/* <div>
            {' '}
            <b>Security PIN</b>
            <div className="g-card">
              <p>
                Receive a six digit code by text message to confirm it’s you
              </p>{' '}
              <Switch />
            </div>
          </div> */}
        </>
      )}
    </div>
  );
}
export function StatsSettingsTab() {
  return (
    <div className='StatsSettingsTab'>
      <b> Client Satisfaction</b>
      <p></p>
      <b> Project Execution Rate</b>
      <p>98%</p>
      <b> Projects Executed</b>
      <p>15</p>
      <b>Ongoing Projects</b>
      <p>2</p>
      <b> Amount Earned</b>
      <p>N19,000,0000</p>
    </div>
  );
}
export function PrivacyPolicySettingsTab() {
  return (
    <div className='PrivacyPolicySettingsTab'>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You. We use Your Personal data to provide and improve the Service. By
        using the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <b>Interpretation</b>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <b>Definitions</b>
      <p className='mb-0 py-2'>For the purposes of this Privacy Policy:</p>

      <p className='mb-0 py-2'>
        Account means a unique account created for You to access our Service or
        parts of our Service.
      </p>

      <p className='mb-0 py-2'>
        Affiliate means an entity that controls, is controlled by or is under
        common control with a party, where "control" means ownership of 50% or
        more of the shares, equity interest or other securities entitled to vote
        for election of directors or other managing authority.
      </p>

      <p className='mb-0 py-2'>
        Application refers to Urbex.africa, the software program provided by the
        Company.
      </p>

      <p className='mb-0 py-2'>
        Company (referred to as either "the Company", "We", "Us" or "Our" in
        this Agreement) refers to Urbex.africa.
      </p>

      <p className='mb-0 py-2'>Country refers to: Nigeria</p>

      <p className='mb-0 py-2'>
        Device means any device that can access the Service such as a computer,
        a cellphone or a digital tablet.
      </p>

      <p className='mb-0 py-2'>
        Personal Data is any information that relates to an identified or
        identifiable individual.
      </p>

      <p className='mb-0 py-2'>Service refers to the Application.</p>

      <p className='mb-0 py-2'>
        Service Provider means any natural or legal person who processes the
        data on behalf of the Company. It refers to third-party companies or
        individuals employed by the Company to facilitate the Service, to
        provide the Service on behalf of the Company, to perform services
        related to the Service or to assist the Company in analyzing how the
        Service is used.
      </p>

      <p className='mb-0 py-2'>
        Usage Data refers to data collected automatically, either generated by
        the use of the Service or from the Service infrastructure itself (for
        example, the duration of a page visit).
      </p>

      <p className='mb-0 py-2'>
        You means the individual accessing or using the Service, or the company,
        or other legal entity on behalf of which such individual is accessing or
        using the Service, as applicable.
      </p>

      <b>Personal Data</b>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>

      <p className='mb-0 py-2'>Email address</p>

      <p className='mb-0 py-2'>First name and last name</p>

      <p className='mb-0 py-2'>Phone number</p>

      <p className='mb-0 py-2'>
        Address, State, Province, ZIP/Postal code, City
      </p>

      <p className='mb-0 py-2'>Usage Data</p>
      <b>Usage Data</b>
      <p className='mb-0 py-2'>
        Usage Data is collected automatically when using the Service. Usage Data
        may include information such as Your Device's Internet Protocol address
        (e.g. IP address), browser type, browser version, the pages of our
        Service that You visit, the time and date of Your visit, the time spent
        on those pages, unique device identifiers and other diagnostic data.
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data. We may also collect information that Your browser sends
        whenever You visit our Service or when You access the Service by or
        through a mobile device.
      </p>
      <b>Information Collected while Using the Application</b>
      <p className='mb-0 py-2'>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:
      </p>
      <p className='mb-0 py-2'>Information regarding your location</p>
      <p className='mb-0 py-2'>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company's servers and/or a Service Provider's server or it may be simply
        stored on Your device. You can enable or disable access to this
        information at any time, through Your Device settings.
      </p>
    </div>
  );
}
export function LanguageSettingsTab() {
  return (
    <div className='LanguageSettingsTab'>
      <b>Suggested</b>
      <label className='radio_container '>
        English (US) <input type='radio' name='language' size='md' />{" "}
        <span className='checkmark'></span>
      </label>
      <label className='radio_container '>
        English (UK) <input type='radio' name='language' size='md' />{" "}
        <span className='checkmark'></span>
      </label>
      <b className='d-block my-3'>Others</b>
      <label className='radio_container '>
        Hausa <input type='radio' name='language' size='md' />{" "}
        <span className='checkmark'></span>
      </label>{" "}
      {/* <div className="mt-3"> */}
      <label className='radio_container '>
        Igbo <input type='radio' name='language' size='md' />{" "}
        <span className='checkmark'></span>
      </label>
      <label className='radio_container '>
        Yoruba <input type='radio' name='language' size='md' />{" "}
        <span className='checkmark'></span>
      </label>
      {/* </div> */}
    </div>
  );
}
export function NotificationSettingsTab() {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const [cookies] = useCookies();
  const { setTostMessage } = useContext(TostMessageContext);
  const [milestoneReminder, setMilestoneReminder] = useState(true);
  const [projectStartReminder, setProjectStartReminder] = useState(true);
  const [documentReminder, setDocumentReminder] = useState(true);
  const [payoutReminder, setPayoutReminder] = useState(true);
  const [loginReminder, setLoginReminder] = useState(true);

  async function getNotifications() {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {},
    };
    try {
      const response = await axios.post(fetch_notifications_endpoint, data, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      });

      if (response.data.status) {
        if (
          response.data.disabled_notifications.includes("milestone_reminder")
        ) {
          setMilestoneReminder(false);
        }
        if (
          response.data.disabled_notifications.includes(
            "project_start_reminder"
          )
        ) {
          setProjectStartReminder(false);
        }
        if (
          response.data.disabled_notifications.includes("document_reminder")
        ) {
          setDocumentReminder(false);
        }
        if (response.data.disabled_notifications.includes("payout_reminder")) {
          setPayoutReminder(false);
        }
        if (response.data.disabled_notifications.includes("login_reminder")) {
          setLoginReminder(false);
        }
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setTostMessage({
        messageType: "error",
        message: "Network error",
      });
    }
  }

  useEffect(() => {
    getNotifications();
  }, []);

  async function updateNotification(type, value) {
    if (type == "milestone_reminder") {
      setMilestoneReminder(value);
    }
    if (type == "project_start_reminder") {
      setProjectStartReminder(value);
    }
    if (type == "document_reminder") {
      setDocumentReminder(value);
    }
    if (type == "payout_reminder") {
      setPayoutReminder(value);
    }
    if (type == "login_reminder") {
      setLoginReminder(value);
    }

    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        notif_type: type,
        value: value,
      },
    };
    try {
      const response = await axios.post(update_notifications_endpoint, data, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      });

      if (response.data.status) {
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setTostMessage({
        messageType: "error",
        message: "Network error",
      });
    }
  }

  return (
    <div className='NotificationSettingsTab'>
      <b>Project Notifications</b>
      <div className='d-flex justify-content-between my-3'>
        Milestone Reminders{" "}
        <Switch
          isChecked={milestoneReminder}
          onChange={(e) =>
            updateNotification("milestone_reminder", e.target.checked)
          }
          size='md'
        />
      </div>
      <div className='d-flex justify-content-between my-3'>
        Project Start Reminders{" "}
        <Switch
          isChecked={projectStartReminder}
          onChange={(e) =>
            updateNotification("project_start_reminder", e.target.checked)
          }
          size='md'
        />
      </div>
      <div className='d-flex justify-content-between my-3'>
        Document Reminders{" "}
        <Switch
          isChecked={documentReminder}
          onChange={(e) =>
            updateNotification("document_reminder", e.target.checked)
          }
          size='md'
        />
      </div>
      <div className='d-flex justify-content-between my-3'>
        Payout Reminders{" "}
        <Switch
          isChecked={payoutReminder}
          onChange={(e) =>
            updateNotification("payout_reminder", e.target.checked)
          }
          size='md'
        />
      </div>{" "}
      {/* <div className="mt-3"> */}
      <b className='d-block my-3'>System & services update</b>
      <div className='d-flex justify-content-between my-3'>
        Daily Login Reminders{" "}
        <Switch
          isChecked={loginReminder}
          onChange={(e) =>
            updateNotification("login_reminder", e.target.checked)
          }
          size='md'
        />
      </div>
      {/* <div className="d-flex justify-content-between my-3">
        Create Project Reminders <Switch size="md" />
      </div> */}
      {/* </div> */}
    </div>
  );
}
export function PortfolioDetailesTab({ project }) {
  return (
    <div className='PortfolioDetailesTab'>
      <b>Project Description</b>
      <p>{project?.project_description}</p>
      <div className='portfolio-image-container'>
        {project?.files?.map((file, i) => (
          // // i pass each file has an array (files: [file])
          // <PortfolioCard key={i} portfolio={{ files: [file] }} />{}
          <div>
            <img src={file?.file_name} alt='Project ' />
            <sup>uploaded: {file?.time_added} </sup>
          </div>
        ))}
      </div>

      <b>Posted: {project?.time_added}</b>
    </div>
  );
}
export function Livestream({ project, closeTabFunction }) {
  return (
    <div className=''>
      <a className='font-bold text-[#000aff]'>Not available</a>
    </div>
  );
}
export function NotificationTab({ notifications }) {
  return (
    <div className=''>
      {!notifications.length && <div>Your notifications will appear here</div>}
      {notifications?.map((notification) => {
        return <NotificationCard data={notification} />;
      })}
    </div>
  );
}

export function ViewContractorApplication({ bid_id, setBidId }) {
  const { id } = useParams();
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();
  const [project, setProject] = React.useState({
    loading: true,
    data: {},
  });
  async function getContractorBidFunction() {
    // return console.log(sData);
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        bid_id: bid_id,
        project_id: id,
      },
    };
    try {
      const response = await axios.post(fetch_bid_details_end_point, data, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      });

      // console.log(response);
      setProject({ loading: false, data: response.data });
    } catch (error) {
      // console.log(error);
    }
  }
  useEffect(() => {
    if (bid_id === null) return;
    getContractorBidFunction();
  }, [bid_id]);
  // useEffect(() => {
  //   console.log(project);
  // }, [project]);

  return (
    <div className='ViewContractorApplication'>
      <button onClick={() => setBidId(null)}>
        {" "}
        <BsArrowLeftCircle className='close-button' />
      </button>
      <div className='container'>
        {project.loading ? (
          <div className='Loading_screen'>Loading...</div>
        ) : (
          <div>
            <div className='profile-card'>
              {/* <div className="hero-image-container">
                <img src={coverImag} alt="" />
              </div> */}
              <div className='profile-image-container'>
                <Avatar src={project.data?.contractor_display_picture} />
              </div>
              <div className='info-side'>
                <h2>{project?.data?.contractor_name}</h2>
                <Button> {project?.data?.kyc_stage}</Button>
                <h3>About the contractor</h3>
                <p>
                  {project.data?.state}, {project.data?.country}.
                </p>
                <p>
                  <b>Applied</b> {project?.data?.date}
                </p>
              </div>
            </div>

            <div className='bid_proposal'>
              <h2>Proposal</h2>
              <p className=''>{project.data?.bid_proposal}</p>
            </div>
            <div className='project-info-card'>
              <Divider className='my-3' />

              <div className='project-info-card-body'>
                <h2>Project Milestones</h2>
                <div className='project-milestones-container'>
                  {/* {console.log(project?.data?.project_milestones)} */}
                  {project?.data?.bid_milestones?.length <= 0 ? (
                    <>No project milestones available</>
                  ) : (
                    <>
                      {project?.data?.bid_milestones?.map((milestone) => (
                        <div
                          className='milestone d-flex gap-3'
                          key={milestone?.milestone_id}
                        >
                          <div className='icon'>
                            <img src={greenTick} alt='tick icon' />
                          </div>
                          <div className='text'>
                            {milestone?.milestone_name}
                            <b className='ms-auto me-2'>
                              {" "}
                              {milestone?.duration}
                            </b>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <h2 className='mt-5'>Gallery</h2>
                <div className='gallery-container container'>
                  <div className='row'>
                    {project?.data?.bid_files?.length <= 0 ? (
                      <>No Image for this project</>
                    ) : (
                      <>
                        {project?.data?.bid_files?.map((image, i) => (
                          <div className='col-4 col-md-3' key={i}>
                            <div className='image-container'>
                              <img src={image} alt='' />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function DocumentsTab({ documents }) {
  const [viewDocument, setViewDocument] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [addingDocument, setAddingDocument] = useState(false);
  const [cookies] = useCookies();
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setPercentage] = useState(0);
  const { setTostMessage } = useContext(TostMessageContext);
  const { userProfile } = useContext(AuthContext);
  const { getProjectDetailes } = useContext(ProjectsContext);
  const [signing, setSigning] = useState(false);
  const [documentSignUrl, setDocumentSignURL] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleting, setDeleting] = useState(false);

  const { id } = useParams();

  const uploadProgress = (progressEvent) => {
    var Percentage = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setPercentage(Percentage);
    console.log(progressEvent.loaded, progressEvent.total);
  };

  // useEffect(() => {
  //   console.log(viewDocument);
  // }, [viewDocument])
  async function uploadSignedPDF(file) {
    let formData = new FormData();
    setUploading(true);
    formData.append("account_id", userProfile?.account_id);
    formData.append("sub_account_id", userProfile?.sub_account_id);
    formData.append("project_id", id);
    formData.append("document_id", viewDocument?.document_id);
    formData.append("document_name", viewDocument?.document_name);
    formData.append("isSigning", "no");
    formData.append("files", file);

    // return;
    try {
      const response = await axios.post(
        `${base_url}/projects/share_documents.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            access: cookies.urbexEnterpriseUserToken,
          },
          onUploadProgress: uploadProgress,
        }
      );
      const resData = await response.data;
      if (resData.statusCode === 401) {
        setTostMessage({
          messageType: "error",
          message: "Could not save document! Please try again",
        });
        setPercentage(0);
        setUploading(false);
      }
      if (resData.status) {
        setTostMessage({
          messageType: "success",
          message: `${viewDocument?.document_name} updated successfully!`,
        });
        getProjectDetailes();
        setPercentage(0);
        setUploading(false);
      } else {
        setTostMessage({
          messageType: "error",
          message: response.response,
        });
        setPercentage(0);
        setUploading(false);
      }
    } catch (e) {
      setTostMessage({
        messageType: "error",
        message: 'Network error, please try again',
      });
      setPercentage(0);
      setUploading(false);
    }
  }

  function downloadPDF(uri, name) {
    const downloadLink = document.createElement("a");
    downloadLink.href = uri;
    downloadLink.download = name;
    downloadLink.target = "_blank"; // Open in a new tab/window

    // Trigger a click event on the link to initiate the download
    downloadLink.click();

    // Remove the link element after the download is initiated
    downloadLink.remove();
  }

  async function deleteFile(doc_id, version_id, document_name, version) {
    if (window.confirm(
      `Are you sure you want to delete ${document_name} revision ${version}`
    )) {

      try {
        const response = await axios.post(
          `${base_url}/projects/delete_document.php`,
          {
            user: {
              account_id: userProfile?.account_id,
              sub_account_id: userProfile?.sub_account_id,
            },
            data: {
              document_id: doc_id,
              version_id: version_id,
            },
          },
          {
            headers: {
              access: cookies.urbexEnterpriseUserToken,
            },
          }
        );
        const resData = await response.data;

        if (resData.statusCode === 401) {
          setTostMessage({
            messageType: "error",
            message: "Could not delete document! Please try again",
          });
        }
        if (resData.status) {
          setTostMessage({
            messageType: "success",
            message: `${document_name} revision ${version}, deleted successfully!`,
          });
          getProjectDetailes();
        }
        setUploading(false);
      } catch (e) {
        setTostMessage({
          messageType: "error",
          message: `Could not delete document! Please try again`,
        });
      }
  }
  }

  async function flagFile(doc_id, version_id, document_name, version) {
    console.log(viewDocument);
    try {
      const response = await axios.post(
        `${base_url}/projects/flag_document.php`,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            document_id: doc_id,
            version_id: version_id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const resData = await response.data;

      if (resData.statusCode === 401) {
        setTostMessage({
          messageType: "error",
          message: "Could not flag document! Please try again",
        });
      }
      if (resData.status) {
        setTostMessage({
          messageType: "success",
          message: resData.response,
        });
        // console.log(resData)
        setViewDocument(resData.viewDocument);
        getProjectDetailes();
      }
      setUploading(false);
    } catch (e) {
      setTostMessage({
        messageType: "error",
        message: `Could not flag document! Please try again`,
      });
    }
  }

  async function deleteDocument(id) {
    if (deleting) {
      return;
    }

    if (window.confirm("Are you sure you want to delete this document?")) {
      setDeleting(true);
      let data = JSON.stringify({
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          document_id: id,
        },
      });

      const headers = {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      };

      axios
        .post(delete_main_document, data, { headers })
        .then((response) => {
          if (!response.data.status) {
            setTostMessage({
              messageType: "error",
              message: response.data.response,
            });
          }
          if (response.data.status) {
            getProjectDetailes();
            setTostMessage({
              messageType: "success",
              message: response.data.response,
            });
          }
          setDeleting(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setDeleting(false);
        });
    } else {
      setDeleting(false);
    }
  }

  // useEffect(() => {
  //   console.log(viewDocument);
  // }, [viewDocument]);

  const onDrop = useCallback(async (acceptedFiles) => {
    // const URL = await blobToURL(acceptedFiles[0]);
    // const URL = await blobToURL(acceptedFiles);
    console.log(viewDocument);
    uploadSignedPDF(acceptedFiles[0], viewDocument);
    // console.log(viewDocument)
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "*",
  });

  const handleFileChange = (file) => {
    uploadSignedPDF(file);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const getPdf = (url) => {
    fetch({
      method: "GET",
      url: url,
      responseType: "arraybuffer",
    }).then((response) => {
      // console.log(response.data);
      return response.data;
    });
  };

  var show_date = "";
  var category = false;
  return (
    <>
      {addingDocument ? (
        <div className=''>
          <div className="w-full flex flex-row justify-between items-center mb-2">
            <button
              className='px-3 py-1 bg-native-blue text-white rounded-full w-50'
              onClick={() => {
                setAddingDocument(false);
              }}
            >
              View Documents
            </button>
            <b className="text-sm text-[#000aff]">{`${userProfile?.account_presets?.total_size_used}/${userProfile?.account_presets?.total_storage_limit} used`}</b>
          </div>
          <div className='w-full h-auto p-3 bg-[#c4f1ff] rounded-[10px] flex items-center justify-center'>
            <a className='text-sm text-center'>
              You can request documents from team members by selecting them in
              "to be shared by" option and leaving the document field empty.
            </a>
          </div>
          <AddDocuments />
        </div>
      ) : (
        <div className=''>
          <div className="w-full mt-2 flex flex-row justify-between items-center mb-2">
            {userProfile?.sub_account_overview?.allowed_permissions?.includes(
              "add_new_document"
            ) ||
            userProfile?.sub_account_overview?.allowed_permissions?.includes(
              "full_access"
            ) ? (
              <button
                className='px-3 py-1 bg-native-blue text-white rounded-full w-50'
                onClick={() => {
                  setAddingDocument(true);
                }}
              >
                Add or Request New Document
              </button>
            ) : null}
            <b className="text-sm text-[#000aff]">{`${userProfile?.account_presets?.total_size_used}/${userProfile?.account_presets?.total_storage_limit} used`}</b>
          </div>
          {viewDocument ? (
            <>
              {signing ? (
                <div>
                  <nav className='flex items-center justify-between w-full'>
                    <p className='mb-0'>
                      Page {pageNumber} of {numPages}
                    </p>
                    <div>
                      <button
                        className='p-2 mx-2 border rounded-sm font-bold'
                        onClick={goToPrevPage}
                      >
                        Prev
                      </button>
                      <button
                        className='p-2 mx-2 border rounded-sm font-bold'
                        onClick={goToNextPage}
                      >
                        Next
                      </button>
                    </div>
                  </nav>
                  <Document
                    className='h-[75%]'
                    file={`https://corsproxy.io/?${encodeURIComponent(
                      documentSignUrl
                    )}`}
                    // file={getPdf(documentSignUrl)}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                </div>
              ) : (
                <div className=''>
                  <div className='flex mt-2 items-center'>
                    <BsArrowLeftCircle
                      onClick={() => {
                        setViewDocument(null);
                      }}
                      size={24}
                      className='close-button mr-2 hover:opacity-60'
                    />
                    <p className='font-bold mb-0 text-[20px] text-black text-start'>
                      {viewDocument.document_name}
                    </p>
                  </div>
                  <div className='mt-2 bg-gray-100 p-4 rounded-3xl'>
                    <>
                      {viewDocument.is_document_shared ? (
                        <div className='flex flex-column'>
                          <a>You can share more versions of this document</a>
                          {/* <div {...getRootProps()} className="relative">
                            <input
                              {...getInputProps()}
                              className="absolute top-0 right-0 left-0 bottom-0"
                            />
                            <button className="px-10 mt-2 rounded-full border text-black hover:bg-slate-400 py-2">
                              Upload {viewDocument.document_name}
                            </button>
                          </div> */}
                          <div className='flex items-center w-full mt-2'>
                            <label className='cursor-pointer w-full h-8 border bg-white border-gray-300 rounded-md py-2 px-4 inline-flex items-center'>
                              <span className='mr-2'>
                                Click to upload {viewDocument.document_name}
                              </span>
                              <input
                                type='file'
                                className='hidden'
                                onChange={(e) =>
                                  handleFileChange(e.target.files[0])
                                }
                              />
                            </label>
                          </div>
                          {uploading && (
                            <AnimatedProgressBar
                              animated
                              className="mt-2"
                              now={uploadPercentage}
                            />
                          )}
                        </div>
                      ) : (
                        <>
                          {viewDocument.to_be_shared_by ==
                          userProfile?.sub_account_id ? (
                            <div className='flex flex-column'>
                              <a>
                                You have been requested to share{" "}
                                {viewDocument.document_name}
                              </a>
                              {/* <div {...getRootProps()} className="relative">
                                <input
                                  {...getInputProps()}
                                  className="absolute top-0 right-0 left-0 bottom-0"
                                />
                                <button className="px-10 mt-2 rounded-full border text-black hover:bg-slate-400 py-2">
                                  Upload {viewDocument.document_name}
                                </button>
                              </div> */}
                              <div className='flex items-center w-full'>
                                <label className='cursor-pointer w-full h-8 border bg-white border-gray-300 rounded-md py-2 px-4 inline-flex items-center'>
                                  <span className='mr-2'>Pick File</span>
                                  <input
                                    type='file'
                                    className='hidden'
                                    onChange={(e) =>
                                      handleFileChange(e.target.files[0])
                                    }
                                  />
                                </label>
                              </div>
                              {uploading && (
                                <AnimatedProgressBar
                                  animated
                                  now={uploadPercentage}
                                />
                              )}
                            </div>
                          ) : (
                            <div className='flex flex-column'>
                              <a>
                                This document was requested from{" "}
                                {viewDocument.to_be_shared_by_words}, but you
                                can still share it anyway.
                              </a>
                              {/* <div {...getRootProps()} className="relative">
                                <input
                                  {...getInputProps()}
                                  className="absolute top-0 right-0 left-0 bottom-0"
                                />
                                <button className="px-10 mt-2 mb-2 rounded-full border  text-black hover:bg-slate-400 py-2">
                                  Upload {viewDocument.document_name}
                                </button>
                              </div> */}
                              <div className='flex items-center w-full'>
                                <label className='cursor-pointer w-full h-8 border bg-white border-gray-300 rounded-md py-2 px-4 inline-flex items-center'>
                                  <span className='mr-2'>
                                    Upload {viewDocument.document_name}
                                  </span>
                                  <input
                                    type='file'
                                    className='hidden'
                                    onChange={(e) =>
                                      handleFileChange(e.target.files[0])
                                    }
                                  />
                                </label>
                              </div>
                              {uploading && (
                                <AnimatedProgressBar
                                  className="mt-2"
                                  animated
                                  now={uploadPercentage}
                                />
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </div>
                  <div className='flex flex-column items-start mt-4'>
                    <p className='font-bold mb-0 text-lg text-black text-start'>
                      Versions Uploaded
                    </p>
                    {viewDocument?.is_document_shared && (
                      <div className='flex justify-between w-full mt-1 mb-2'>
                        <div className='flex flex-column'>
                          <a className='font-bold text-[14px]'>
                            Newest version
                          </a>
                          <a className='text-[14px]'>
                            Shared by {viewDocument?.shared_by}
                          </a>
                          <a className='text-sm'>{viewDocument?.time}</a>
                        </div>
                        <div className='flex flex-row gap-2'>
                          {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                            "delete_document"
                          ) ||
                          userProfile?.sub_account_overview?.allowed_permissions?.includes(
                            "full_access"
                          ) ? (
                            <button
                              onClick={() => {
                                deleteFile(
                                  viewDocument?.document_id,
                                  viewDocument?.version_id,
                                  viewDocument?.document_name,
                                  viewDocument?.version
                                );
                              }}
                              className='font-bold h-10 text-[11px] flex flex-row items-center justify-center text-[#ffffff] bg-[#ff0000] py-1 px-3 rounded-full'
                            >
                              <AiOutlineDelete className='' size={18} />
                            </button>
                          ) : null}
                          <button
                            onClick={() => {
                              flagFile(
                                viewDocument?.document_id,
                                viewDocument?.version_id,
                                viewDocument?.document_name,
                                viewDocument?.version
                              );
                            }}
                            className={`font-bold h-10 text-[11px] flex flex-row items-center justify-center text-[#000] py-1 px-3 rounded-full ${
                              viewDocument?.version_flagged
                                ? "bg-[#000aff]"
                                : "bg-gray-200"
                            }`}
                          >
                            <FaRegFlag
                              className=''
                              color={
                                viewDocument?.version_flagged ? "#fff" : "#000"
                              }
                              size={18}
                            />
                          </button>
                          <button
                            onClick={() => {
                              downloadPDF(
                                viewDocument?.document_uri,
                                viewDocument?.document_name
                              );
                            }}
                            className='font-bold h-10 text-[12px] flex flex-row items-center justify-center text-[#000aff] bg-gray-200 py-1 px-3 rounded-full'
                          >
                            View
                          </button>
                          {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                            "sign_documents"
                          ) ||
                          userProfile?.sub_account_overview?.allowed_permissions?.includes(
                            "full_access"
                          ) ? (
                            <button
                              onClick={() => {
                                setSigning(true);
                                setDocumentSignURL(viewDocument?.document_uri);
                              }}
                              className='font-bold h-10 text-[12px] flex flex-row items-center justify-center text-[#000aff] bg-gray-200 py-1 px-3 rounded-full'
                            >
                              Sign <TfiWrite className='ml-1' size={18} />
                            </button>
                          ) : null}
                        </div>
                      </div>
                    )}
                    {viewDocument?.versions_sent?.map((item, index) => (
                      <div
                        key={index}
                        className='flex justify-between w-full mt-1'
                      >
                        <div className='flex flex-column'>
                          <a className='font-bold text-[13px]'>
                            Version {item.version}
                          </a>
                          <a className='text-[13px]'>
                            Shared by {item.sent_by}
                          </a>
                          <a className='text-xs'>{item.time}</a>
                        </div>
                        <div className='flex flex-row gap-2'>
                          {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                            "delete_document"
                          ) ||
                          userProfile?.sub_account_overview?.allowed_permissions?.includes(
                            "full_access"
                          ) ? (
                            <button
                              onClick={() => {
                                deleteFile(
                                  viewDocument?.document_id,
                                  item.version_id,
                                  viewDocument?.document_name,
                                  item.version
                                );
                              }}
                              className='font-bold h-10 text-[11px] flex flex-row items-center justify-center text-[#ffffff] bg-[#ff0000] py-1 px-3 rounded-full'
                            >
                              <AiOutlineDelete className='' size={18} />
                            </button>
                          ) : null}
                          <button
                            onClick={() => {
                              flagFile(
                                viewDocument?.document_id,
                                item.version_id,
                                viewDocument?.document_name,
                                item.version
                              );
                            }}
                            className={`font-bold h-10 text-[11px] flex flex-row items-center justify-center text-[#000] py-1 px-3 rounded-full ${
                              item.version_flagged
                                ? "bg-[#000aff]"
                                : "bg-gray-200"
                            }`}
                          >
                            <FaRegFlag
                              className=''
                              color={item.version_flagged ? "#fff" : "#000"}
                              size={18}
                            />
                          </button>
                          <button
                            onClick={() => {
                              downloadPDF(
                                item.file_uri,
                                viewDocument?.document_name
                              );
                            }}
                            className='font-bold h-10 text-[11px] flex flex-row items-center justify-center text-[#000aff] bg-gray-200 py-1 px-3 rounded-full'
                          >
                            View
                          </button>
                          {/* {userProfile?.sub_account_overview?.allowed_permissions?.includes("sign_documents") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access") ? (
                          <button
                            onClick={() => {
                              setSigning(true);
                              setDocumentSignURL(viewDocument?.document_uri);
                            }}
                            className="font-bold h-10 text-[12px] flex flex-row items-center justify-center text-[#000aff] bg-gray-200 py-1 px-3 rounded-full"
                          >
                            Sign <TfiWrite className="ml-1" size={18} />
                          </button>
                          ):null} */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              {documents?.documents?.length === 0 ? (
                <>No documents added to this project</>
              ) : (
                <>
                  {documents?.flagged?.map((document, id) => {
                    if (category == "") {
                      category = document.document_department;
                      show_date = true;
                    } else if (
                      category != "" &&
                      category != document.document_department
                    ) {
                      category = document.document_department;
                      show_date = true;
                    } else if (
                      category != "" &&
                      category == document.document_department
                    ) {
                      category = document.document_department;
                      show_date = false;
                    }

                    return (
                      <>
                        {show_date && (
                          <a className='pt-2 font-bold text-[14px] text-[#000aff]'>
                            {document.document_department}
                          </a>
                        )}
                        <div key={id}>
                          {/* <DocumentsCard
                            document={document}
                            setViewDocument={setViewDocument}
                          /> */}
                          <div className='p-2 px-3 cursor-pointer rounded-xl bg-zinc-100 transition-all ease-in-out my-2 flex items-center gap-4 pt-10'>
                            <div className='flex-1'>
                              <div>
                                <h5 className='text-[14px] mb-0 font-bold'>
                                  {document.document_name}
                                </h5>
                                {document.to_be_shared_by ==
                                userProfile?.sub_account_id && !document.is_document_shared ? (
                                  <h6 className='text-sm'>
                                    You have been requested to share this
                                    document
                                  </h6>
                                ) : null}
                              </div>
                              <div className='text-sm'>{document.time}</div>
                            </div>
                            <div className='mt-0 flex gap-4 cursor-pointer'>
                              <button
                                onClick={() => {
                                  setViewDocument(document);
                                }}
                                className={`font-bold text-[14px] text-[#000aff] bg-gray-200 py-1 px-3 rounded-full ${
                                  document.is_document_shared
                                    ? "text-black"
                                    : "text-[#000aff]"
                                }`}
                              >
                                {document.is_document_shared
                                  ? "View"
                                  : "Share"}
                              </button>
                              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                                "delete_document"
                              ) ||
                              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                                "full_access"
                              ) ? (
                                <button
                                  onClick={() =>
                                    deleteDocument(document.document_id)
                                  }
                                  className={`font-bold text-[#000aff] bg-gray-200 py-1 px-3  rounded-full text-[#ff0000]`}
                                >
                                  {!deleting ? (
                                    <RiDeleteBin5Line
                                      size={17}
                                      color='#ff0000'
                                    />
                                  ) : (
                                    <ReactLoading
                                      type={"cylon"}
                                      height={20}
                                      width={20}
                                      color='#ff0000'
                                    />
                                  )}
                                </button>
                              ) : null}
                              {/* {userProfile?.sub_account_overview?.allowed_permissions?.includes("sign_documents") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access") ? (
                              <button
                                className={`font-bold text-[#000aff] bg-gray-200 py-2 px-4  rounded-full ${
                                  document.is_document_signed ? 'text-black' : 'text-[#000aff]'
                                }`}
                              >
                                {document.is_document_signed ? 'Signed' : 'Sign'}
                              </button>
                              ):null} */}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {documents?.documents?.map((document, id) => {
                    if (category == "") {
                      category = document.document_department;
                      show_date = true;
                    } else if (
                      category != "" &&
                      category != document.document_department
                    ) {
                      category = document.document_department;
                      show_date = true;
                    } else if (
                      category != "" &&
                      category == document.document_department
                    ) {
                      category = document.document_department;
                      show_date = false;
                    }

                    return (
                      <>
                        {show_date && (
                          <a className='pt-2 font-bold text-[14px]'>
                            {document.document_department}
                          </a>
                        )}
                        <div key={id}>
                          {/* <DocumentsCard
                            document={document}
                            setViewDocument={setViewDocument}
                          /> */}
                          <div className='p-2 px-3 cursor-pointer rounded-xl bg-zinc-100 transition-all ease-in-out my-2 flex items-center gap-4 pt-10'>
                            <div className='flex-1'>
                              <div>
                                <h5 className='text-[14px] mb-0 font-bold'>
                                  {document.document_name}
                                </h5>
                                {document.to_be_shared_by ==
                                userProfile?.sub_account_id && !document.is_document_shared ? (
                                  <h6 className='text-sm'>
                                    You have been requested to share this
                                    document
                                  </h6>
                                ) : null}
                              </div>
                              <div className='text-sm'>{document.time}</div>
                            </div>
                            <div className='mt-0 flex gap-4 cursor-pointer'>
                              <button
                                onClick={() => {
                                  setViewDocument(document);
                                }}
                                className={`font-bold text-[14px] text-[#000aff] bg-gray-200 py-1 px-3 rounded-full ${
                                  document.is_document_shared
                                    ? "text-black"
                                    : "text-[#000aff]"
                                }`}
                              >
                                {document.is_document_shared
                                  ? "View"
                                  : "Share"}
                              </button>
                              {/* {userProfile?.sub_account_overview?.allowed_permissions?.includes("sign_documents") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access") ? (
                              <button
                                className={`font-bold text-[#000aff] bg-gray-200 py-2 px-4  rounded-full ${
                                  document.is_document_signed ? 'text-black' : 'text-[#000aff]'
                                }`}
                              >
                                {document.is_document_signed ? 'Signed' : 'Sign'}
                              </button>
                              ):null} */}
                              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                                "delete_document"
                              ) ||
                              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                                "full_access"
                              ) ? (
                                <button
                                  onClick={() =>
                                    deleteDocument(document.document_id)
                                  }
                                  className={`font-bold text-[#000aff] bg-gray-200 py-1 px-3  rounded-full text-[#ff0000]`}
                                >
                                  {!deleting ? (
                                    <RiDeleteBin5Line
                                      size={17}
                                      color='#ff0000'
                                    />
                                  ) : (
                                    <ReactLoading
                                      type={"cylon"}
                                      height={20}
                                      width={20}
                                      color='#ff0000'
                                    />
                                  )}
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export function ApplyForProjectTab({ project, closeTabFunction }) {
  const { setTostMessage } = React.useContext(TostMessageContext);
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [applicationForm, setApplicationForm] = React.useState({
    proposal: "",
    amount: "",
    duration: [],
  });
  const handelChanges = (e) => {
    setApplicationForm((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handelDurationChanges = (e, index) => {
    let newArray = [...applicationForm.duration];
    if (e.target.name === "duration") {
      newArray[index].duration = e.target.value;
    }
    if (e.target.name === "metric") {
      newArray[index].metric = e.target.value;
    }
  };

  async function handelSubmit() {
    if (applicationForm.proposal === "" || applicationForm.amount === 0) {
      setTostMessage({
        messageType: "error",
        message: "Please Fill Form Correctly ",
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();

    formData.append("project_id", project?.data.project_id);
    formData.append("account_id", userProfile?.account_id);
    formData.append("message", applicationForm.proposal);
    formData.append(
      "milestones",
      JSON.stringify({ milestones: applicationForm.duration })
    );
    formData.append("proposal_files", files);

    try {
      const response = await axios.post(bid_for_project_end_point, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      if (response.data.status) {
        // console.log(response.data);
        setLoading(false);
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        closeTabFunction();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  React.useEffect(() => {
    // console.log("project_milestones", project?.data);
    setApplicationForm((prev) => {
      return {
        ...prev,
        duration: project?.data?.project_milestones,
      };
    });
  }, [project]);
  return (
    <div className='ApplyForProjectTab'>
      <h1>{project?.data?.project_title}</h1>
      <sup>
        {project?.data?.site_address}, {project?.data?.state}
      </sup>
      <p className='my-3'>{project?.data?.project_desc}</p>
      <div className='my-4'>
        {" "}
        <b>Teams</b> <br />
        Client Budget : {project?.data?.budget}
      </div>
      <div className='my-4'>
        <b>Proposal</b>
        <textarea
          className='form-control'
          id=''
          cols='10'
          rows='5'
          name='proposal'
          onChange={(e) => handelChanges(e)}
        ></textarea>
      </div>
      <br /> <b>Bid amount</b>
      <Box>
        <FormLabel style={{ fontSize: 13 }} htmlFor='amount'>
          Amount
        </FormLabel>
        <Input
          id='amount'
          className='w-50'
          name='amount'
          onChange={(e) => handelChanges(e)}
        />
      </Box>
      <br /> <b>Duration</b>
      <div className='row'>
        {applicationForm?.duration?.map((milestone, i) => {
          return (
            <MilestonesDurationDateInput
              milestone={milestone}
              index={i}
              key={i}
              handelDurationChanges={handelDurationChanges}
            />
          );
        })}
      </div>
      <div className='upload-file-container my-3'>
        <MultipleFileUploadComponent files={files} setFiles={setFiles} />
      </div>
      <Button
        isLoading={loading}
        className='submit-button'
        onClick={() => handelSubmit()}
      >
        Submit
      </Button>
    </div>
  );
}
export function CreatePortfolioTab({ project, closeTabFunction }) {
  const { setTostMessage } = React.useContext(TostMessageContext);
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [portfolioForm, setPortfolioForm] = React.useState({
    project_title: "",
    project_description: "",
    project_duration: "",
  });

  const handelChanges = (e) => {
    setPortfolioForm((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedImage = event.target.files[0];
      if (selectedImage.type.startsWith("image/")) {
        setImages((prevImages) => [
          ...prevImages,
          URL.createObjectURL(selectedImage),
        ]);
      } else {
        alert("Please select an image file.");
      }
    }
  };

  async function handelSubmit() {
    if (portfolioForm.proposal === "" || portfolioForm.amount === 0) {
      setTostMessage({
        messageType: "error",
        message: "Please Fill Form Correctly ",
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();

    // formData.append("project_id", project?.data.project_id);
    formData.append("account_id", userProfile?.account_id);
    formData.append("project_title", portfolioForm.project_title);
    formData.append(
      "project_description",
      JSON.stringify({ milestones: portfolioForm.project_description })
    );
    formData.append("project_duration", portfolioForm.project_duration);
    formData.append("proposal_files", files);
    formData.append("isUpdate", true);
    try {
      const response = await axios.post(add_to_portfolio_end_point, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      if (response.data.status) {
        // console.log(response.data);
        setLoading(false);
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        closeTabFunction();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <div className='px-14'>
      <Box>
        <FormLabel htmlFor='project_title'>
          <b>Project Title</b>
        </FormLabel>
        <input
          id='project_title'
          name='project_title'
          onChange={(e) => handelChanges(e)}
          className='withdraw-input h-12'
          placeholder='Project title'
        />
      </Box>

      <div className='my-4'>
        <b>Project Description</b>
        <textarea
          className='withdraw-input mt-2 text-xs'
          id=''
          cols='10'
          rows='5'
          name='project_description'
          placeholder={`Good descriptions include the following:
            1. Your clients goal
            2. Details about your expertise to the project
            3. A summary of the project’s success`}
          onChange={(e) => handelChanges(e)}
        ></textarea>
      </div>
      {/* <br /> <b>Duration</b> */}
      <Box>
        <FormLabel htmlFor='project_duration'>
          <b>Duration</b>
        </FormLabel>
        <input
          id='project_duration'
          className='withdraw-input mt-2 h-12'
          name='project_duration'
          onChange={(e) => handelChanges(e)}
        />
      </Box>
      {/* <div className="d-flex w-100 gap-3">
        <Box className="w-100">
          <FormLabel style={{ fontSize: 13 }} htmlFor="sDate">
            Start Date
          </FormLabel>
          <Input
            id="sDate"
            type="date"
            name="sDate"
            onChange={(e) => handelChanges(e)}
          />
        </Box>{" "}
        <Box className="w-100">
          <FormLabel style={{ fontSize: 13 }} htmlFor="eDate">
            End Date
          </FormLabel>
          <Input
            id="eDate"
            type="date"
            name="eDate"
            onChange={(e) => handelChanges(e)}
          />
        </Box>
      </div> */}
      <div className='mb-10 mt-3'>
        <b className='mt-4 flex items-center gap-1'>
          Project Images <LuImagePlus size={20} className='rounded-lg ' />
        </b>
        <div className='gallery-container container p-0'>
          <div className='grid grid-cols-3 grid-flow-row gap-4 mt-4'>
            {images.map((image, idx) => (
              <div key={idx} className='h-36 relative'>
                <img
                  src={image}
                  alt=''
                  className='h-full w-full rounded-lg transition-all object-cover'
                />
              </div>
            ))}
            <div className='h-36 relative bg-gray-100 rounded-lg hover:scale-95 transition-all flex items-center justify-center'>
              <input
                type='file'
                onChange={onImageChange}
                className='h-full w-full absolute bg-transparent opacity-0'
                accept='image/*'
              />
              <LuImagePlus size={60} className='rounded-lg ' />
            </div>
          </div>
        </div>
      </div>

      <div className='upload-file-container my-3'>
        <b className='mt-4 d-block'>
          Other files <Icon />
        </b>
        <MultipleFileUploadComponent files={files} setFiles={setFiles} />
      </div>
      <div className='text-xs'>
        Project files may include
        <ul>
          <li> Images (.jpg, .gif, .png, up to 10 MB, not more than 400 MB)</li>
          <li>
            {" "}
            Videos (.mp4, .mov, .webm, .ogm, .ogv, up to 100 MB, 2 video
            maximum, maximum 60 seconds)
          </li>

          <li> Documents (.pdf, .docx, .txt up to 10 MB)</li>
        </ul>
      </div>
      <button
        isLoading={loading}
        className='px-6 py-2 hover:bg-secondary text-white bg-[#766d89] rounded-full my-10'
        onClick={() => handelSubmit()}
      >
        Submit
      </button>
    </div>
  );
}

export function MilestonesDurationDateInput({
  milestone,
  index,
  handelDurationChanges,
}) {
  // console.log(`milestone ${index}`, milestone);
  return (
    <div className='col-md-6'>
      <Box>
        <FormLabel
          style={{ fontSize: 13 }}
          htmlFor='corporate_id_number'
          className='mt-3'
        >
          How long will this milestone take (Estimated)?
        </FormLabel>
        <Input
          // ref={firstField}
          id='corporate_id_number'
          name='corporate_id_number'
          value={milestone?.milestone_name}
          disabled={true}
          // onChange={(e) => handleChange(e)}
          // {/* // placeholder="Please enter user name" */}
        />
      </Box>
      <div className='d-flex gap-3 justify-content-between'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='metric' className='mt-2'>
            Metric
          </FormLabel>
          <Select
            id='metric'
            defaultValue=' Select Metric'
            name='metric'
            className='w-100'
            onChange={(e) => handelDurationChanges(e, index)}
          >
            <option value=''>Select one...</option>
            <option value='Day'>Daily</option>
            <option value='Month'>Monthly</option>
            <option value='Year'>Yearly</option>
          </Select>
        </Box>
        <Box>
          <FormLabel
            style={{ fontSize: 13 }}
            htmlFor='duration'
            className='mt-2'
          >
            How Long?
          </FormLabel>
          <Input
            id='duration'
            name='duration'
            className='w-100'
            onChange={(e) => handelDurationChanges(e, index)}
          />
        </Box>
      </div>
    </div>
  );
}

export function TransferTab({ transfers }) {
  const [selected, setSelected] = React.useState(null);
  const [withdrawals, setWithdrawals] = React.useState([]);
  const [credits, setCredits] = React.useState([]);
  const [cookies] = useCookies();
  const {userProfile} = useContext(AuthContext)
  const [submittingReq, setSubmittingReq] = React.useState(false);
  const { projectDetailes, getProjectDetailes } = React.useContext(ProjectsContext);
  const { setTostMessage } = React.useContext(TostMessageContext);

  React.useEffect(() => {
    // console.log("transfers", transfers);
    let withdrawalsArray = [];
    let creditsArray = [];
    transfers.withdrawals.map((transfer) => {
      withdrawalsArray.push({
        ...transfer,
        type: "Withdrawals",
      });
    });
    transfers.credits.map((transfer) => {
      creditsArray.push({
        ...transfer,
        type: "Credits",
      });
    });

    setWithdrawals(withdrawalsArray);
    setCredits(creditsArray);
  }, [transfers]);

  // for transaction

  async function approvePayout() {
    // console.log(projectDetailes.data)
    setSubmittingReq(true);
    const response = await axios.post(
      `${base_url}/financials/approve_payout.php`,

      {
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          project_id: projectDetailes?.data?.project_id,
          request_id: selected?.request_id,
          action: "APPROVE",
        },
      },
      {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      }
    );
    const resData = await response.data;

    if (resData.statusCode === 401) {
      setSubmittingReq(false);
      return setTostMessage({
        messageType: "error",
        message: resData.response,
      });
    }

    if (resData.status) {
      getProjectDetailes();
      setTostMessage({
        messageType: "success",
        message: resData.response,
      });
      setSubmittingReq(false);
    } else {
      setTostMessage({
        messageType: "error",
        message: resData.response,
      });
      setSubmittingReq(false);
    }
  }

  async function declinePayout() {
    setSubmittingReq(true);
    const response = await axios.post(
      `${base_url}/financials/approve_payout.php`,

      {
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          project_id: projectDetailes?.data?.project_id,
          request_id: selected?.request_id,
          action: "DECLINE",
        },
      },
      {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      }
    );
    const resData = await response.data;

    if (resData.statusCode === 401) {
      setSubmittingReq(false);
      return setTostMessage({
        messageType: "error",
        message: resData.response,
      });
    }

    if (resData.status) {
      getProjectDetailes();
      setTostMessage({
        messageType: "success",
        message: resData.response,
      });
      setSubmittingReq(false);
    } else {
      setTostMessage({
        messageType: "error",
        message: resData.response,
      });
      setSubmittingReq(false);
    }
  }

  return (
    <>
      {/* {console.log(selected)} */}
      {selected !== null ? (
        <div className='selected-screen w-full'>
          <div className='flex flex-row items-center gap-x-2 md:gap-x-4'>
            <BsArrowLeftCircle
              onClick={() => setSelected(null)}
              className='close-button'
            />
            <span className='text-[30px] md:text-[50px] lg:text-[70px] font-bold'>
              ₦{formatNumber(selected?.amount)}
            </span>
          </div>
          {/* <br /> */}
          <br />
          {selected?.status == "APPROVED" && (
            <span className='bg-[#00b47a] w-auto text-center p-2 rounded-md text-white font-bold'>
              {selected?.status}
            </span>
          )}
          {selected?.status == "PENDING" && (
            <span className='bg-[#fcba03] w-auto text-center p-2 rounded-md text-white font-bold'>
              {selected?.status}
            </span>
          )}
          {selected?.status == "DECLINED" && (
            <span className='bg-[#FF0000] w-auto text-center p-2 rounded-md text-white font-bold'>
              {selected?.status}
            </span>
          )}
          <p className='bg-[#ebebeb] p-3 mt-3 rounded-lg w-full'>
            <b>Recipient Info.</b><br />
            {selected?.account_name}<br />
            {selected?.account_number}<br /><br />
            <b>Milestone Info.</b>
            <br />
            Milestone: <b>{selected?.expense_activity_name}</b><br />
            Planned Cost: <b>{selected?.expense_activity_cost}</b><br />
            Actual Cost (So Far): <b>{selected?.total_spent_on_activity}</b><br />
            <br />
            <b>Expense Category.</b><br/>
            {selected?.expense_category}
            <br /><br />
            <b>Narration.</b>
            <br />
            {selected?.narration === ""
              ? "No narration provided"
              : selected?.narration}
          </p>

          {selected?.status === "PENDING" && (userProfile?.sub_account_overview?.allowed_permissions?.includes("approve_payouts") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) ? (
            <div className='flex flex-row items-center justify-center gap-x-2 md:gap-x-4 mt-10'>
              <button
                disabled={submittingReq}
                onClick={() => submittingReq ? null : declinePayout()}
                className='border-0 px-8 md:px-16 font-bold py-2 md:py-4 rounded-full hover:border-red-400 bg-[#ff0000] text-white'
              >
                {!submittingReq ? 'Decline' : <ReactLoading height={25} width={25} type={'cylon'} color="#ffffff" />}
              </button>
              <button
                disabled={submittingReq}
                onClick={() => submittingReq ? null : approvePayout()}
                className='border-0 px-8 md:px-16 font-bold py-2 md:py-4 rounded-full hover:border-green-400 bg-[#00b47a] text-white'
              >
                {!submittingReq ? 'Approve' : <ReactLoading height={25} width={25} type={'cylon'} color="#ffffff" />}
              </button>
            </div>
          ) : null}
        </div>
      ) : (
        <TabPanelComponent
          btn1Text={`Credits ${transfers.credits.length}`}
          btn2Text={`Withdrawals ${transfers.withdrawals.length} `}
          tab1Content={
            <>
              {credits?.map((transfer, i) => {
                return (
                  <TransferCard
                    key={i}
                    index={i}
                    data={transfer}
                    setSelected={setSelected}
                    icon={transfer_in}
                  />
                );
              })}
            </>
          }
          tab2Content={
            <>
              {" "}
              {withdrawals?.map((transfer, i) => {
                return (
                  <TransferCard
                    key={i}
                    index={i}
                    data={transfer}
                    setSelected={setSelected}
                    icon={transfer_out}
                  />
                );
              })}
            </>
          }
        />
      )}
    </>
  );
}
export function MilestoneTab({ milestones, closeTabFunction }) {
  const [selected, setSelected] = React.useState(null);
  const { projectDetailes, setProjectDetailes } =
    React.useContext(ProjectsContext);
  const [completedMilestone, setCompletedMilestone] = React.useState([]);
  const [nonCompletedMilestone, setNonCompletedMilestone] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [cookies] = useCookies();
  const { userProfile } = useContext(AuthContext);
  const { setTostMessage } = React.useContext(TostMessageContext);
  const [latitude, setLatitude] = React.useState(null);
  const [longitude, setLongitude] = React.useState(null);
  const [milestoneRemark, setMilestoneRemark] = React.useState("");
  const [error, setErr] = useState("");
  const [gantView, setGantView] = useState(false);
  const [evaView, setEvaView] = useState(false);
  const [gantViewType, setGantViewType] = useState("Day");
  const [tasks, setTasks] = React.useState([]);
  const [downloadingReport, setDownloadingReport] = React.useState(false);
  const [milestoneStage, setMilestoneStage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setPercentage] = useState(0);

  // let tasks: Task[] = [
  //     {
  //       start: new Date(1698447600),
  //       end: new Date(1699570800),
  //       name: 'Idea',
  //       id: 'Task 0',
  //       type:'task',
  //       progress: 45,
  //       isDisabled: true,
  //       styles: { progressColor: '#000aff', progressSelectedColor: '#ff9e0d' },
  //     },
  // ];

  React.useEffect(() => {
    // console.log("milestones", milestones);
    let completedArray = [];
    let nonCompletedArray = [];
    let tasks_arr = [];
    milestones?.map((milestone, i) => {
      var task_color = "#bbb";
      if (milestone.milestone_started && !milestone.complete) {
        task_color = "#e1effe";
      }
      if (milestone.milestone_started && milestone.complete) {
        task_color = "#00b47a";
      }
      if (
        !milestone.milestone_started &&
        !milestone.complete &&
        milestone.delayed_start == "Expected to start today"
      ) {
        task_color = "#ffd561";
      }
      if (
        !milestone.milestone_started &&
        !milestone.complete &&
        milestone.delayed_start != "" &&
        milestone.delayed_start != "Expected to start today"
      ) {
        task_color = "#f79c9c";
      }
      if (milestone.milestone_started && !milestone.complete) {
        task_color = "#e1effe";
      }

      tasks_arr.push({
        start: new Date(milestone.start),
        end: new Date(milestone.end),
        name: milestone.name,
        id: milestone.milestone_id,
        type: "task",
        progress: milestone.progress,
        isDisabled: true,
        dependencies: milestone.dependencies,
        styles: {
          progressColor: "#000aff",
          progressSelectedColor: "#ff9e0d",
          backgroundColor: task_color,
        },
      });
      if (milestone?.complete) {
        completedArray.push(milestone);
      } else {
        nonCompletedArray.push(milestone);
      }
    });
    // // console.log(completedArray, nonCompletedArray);
    setCompletedMilestone(completedArray);
    setNonCompletedMilestone(nonCompletedArray);
    setTasks(tasks_arr);
    // return <MilestoneCard key={i} data={milestone} />;
  }, [milestones]);

  const { id } = useParams();
  const { getProjectDetailes } = React.useContext(ProjectsContext);

  const startMilestoneFunction = async () => {
    setLoading(true);
    const body = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
        milestone_id: selected.milestone_id,
      },
    };

    // return console.log(data);
    try {
      const response = await axios.post(start_milestone_end_point, body, {
        headers: {
          "Content-Type": "application/json",
          access: cookies.urbexEnterpriseUserToken,
        },
      });

      if (response.data.status) {
        setSelected(null);
        setLoading(false);
        getProjectDetailes();
        return setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
      }
      setLoading(false);
      setTostMessage({
        messageType: "error",
        message: "Starting milestone not successful",
      });
    } catch (error) {
      setLoading();
      console.log(error);
      setTostMessage({
        messageType: "error",
        message: "Error Starting Milestone",
      });
    }
  };

  React.useEffect(() => {
    console.log(projectDetailes);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const uploadProgress = (progressEvent) => {
    var Percentage = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setPercentage(Percentage);
    console.log(progressEvent.loaded, progressEvent.total);
  };

  const submitMilestoneRemarkFunction = async (action) => {
    setLoading(true);
    setUploading(true);
    if (!milestoneRemark) {
      setErr("Please provide milestone remark");
      setTostMessage({
        messageType: "warning",
        message: "Please enter a comment for this activity",
      });
      setLoading(false);
      setUploading(false);
      return;
    }
    if (Number(milestoneStage) > Number(selected.milestone_metric_target)) {
      setTostMessage({
        messageType: "warning",
        message: `Target done (${selected.milestone_metric_target_covered} ${selected.milestone_metric_system}) cannot be more than target set (${selected.milestone_metric_target}  ${selected.milestone_metric_system}). Please update the milestone target.`,
      });
      setLoading(false);
      setUploading(false);
      return;
    }
    if (action == "finish" && (Number(milestoneStage) < Number(selected.milestone_metric_target)) && (Number(selected.milestone_metric_target_covered) < Number(selected.milestone_metric_target))) {
      setTostMessage({
        messageType: "warning",
        message: "Cannot complete this activity without meeting target. Please edit the milestone target to continue",
      });
      setLoading(false);
      setUploading(false);
      return;
    }

    const currentPosition = {
      latitude,
      longitude,
    };
    const formData = new FormData();
    formData.append("account_id", userProfile?.account_id);
    formData.append("sub_account_id", userProfile?.sub_account_id);
    formData.append("project_id", id);
    formData.append("milestone_id", selected.milestone_id);
    formData.append("remarks", milestoneRemark);
    formData.append("currentPosition", currentPosition);
    formData.append("action", action);
    formData.append("reportMetricDone", milestoneStage);

    // console.log(files);
    // return;
    files?.map((item) => {
      return formData.append("files[]", item);
    });

    try {
      const response = await axios.post(
        complete_milestone_end_point,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            access: cookies.urbexEnterpriseUserToken,
          },
          onUploadProgress: uploadProgress,
        }
      );
      // console.log(response.data);
      if (response.data.status) {
        setSelected(null);
        getProjectDetailes();
        setLoading(false);
        setUploading(false);
        setMilestoneRemark("");
        setFiles([]);
        return setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
      } else {
        setLoading(false);
        setUploading(false);
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setLoading(false);
      setUploading(false);
      console.log(error);
      setTostMessage({
        messageType: "error",
        message: "Could not complete milestone, Network error.",
      });
    }
  };

  const downloadMilestoneReportFunction = async () => {
    // console.log({ download_milestone_report_end_point });
    // console.log({ selected });
    setDownloadingReport(true);
    window.open(`${download_milestone_report_end_point}?project_id=${id}&milestone_id=${selected.milestone_id}`, "_blank");
    setDownloadingReport(false);
    setTostMessage({
      messageType: "success",
      message: "Report downloaded",
    });
    // console.log(
    //   `${download_milestone_report_end_point}?project_id=${id}&milestone_id=${selected.milestone_id}`
    // );
    // try {
    //   const fileName = `${selected.milestone
    //     .replace(/\s/g, "_")
    //     .toUpperCase()}_REPORT`;

    //   const response = await fetch(
    //     `${download_milestone_report_end_point}?project_id=${id}&milestone_id=${selected.milestone_id}`,
    //     {
    //       method: "GET",
    //     }
    //   );

    //   if (response.ok) {
    //     const blob = await response.blob();
    //     saveAs(blob, fileName);
    //     setSelected(null);
    //   } else {
    //     setLoading(false);
    //     setTostMessage({
    //       messageType: "error",
    //       message: "Failed to download report",
    //     });
    //     throw new Error("Failed to Download Report");
    //   }
    //   setDownloadingReport(false);
    // } catch (error) {
    //   setLoading(false);
    //   setDownloadingReport(false);
    //   console.log(error);
    //   setTostMessage({
    //     messageType: "error",
    //     message: "Error Downloading Milestone Report",
    //   });
    // }
  };

  var date_category = "";
  var show_date = false;
  return (
    <div className=''>
      {evaView ? (
        <div className='flex flex-col'>
          {!selected && (
            <div className='flex items-start w-full justify-between mt-2'>
              <button
                className='p-10 py-1 rounded-full border border-native-blue transition-all active:scale-95'
                onClick={() => {
                  setEvaView(false);
                  setGantView(false);
                }}
              >
                Back to List
              </button>
            </div>
          )}
          <div
            className={`flex px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
          >
            <div className='flex flex-column items-start'>
              <div className='font-bold text-[13px]'>Budget At Completion</div>
              <div className='text-xs'>
                <a className='font-bold text-[23px]'>
                  {projectDetailes.data.budget.toLocaleString()}
                </a>
              </div>
              {/* <div className="text-xs">As set duration project creation</div> */}
            </div>
          </div>
          <div
            className={`flex px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
          >
            <div className='flex flex-column items-start'>
              <div className='font-bold text-[13px]'>Planned Value</div>
              <div className='text-xs mt-2'>
                <a className='font-bold text-[23px]'>
                  {projectDetailes.data.planned_value}
                </a>
              </div>
              <div className='text-xs font-bold'>
                {projectDetailes.data.planned_value_comment}
              </div>
              <div className='text-xs mt-2'>Based on realtime analysis</div>
            </div>
          </div>
          <div
            className={`flex px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
          >
            <div className='flex flex-column items-start'>
              <div className='font-bold text-[13px]'>Earned Value</div>
              <div className='text-xs mt-2'>
                <a className='font-bold text-[23px]'>
                  {projectDetailes.data.earned_value}
                </a>
              </div>
              <div className='text-xs font-bold'>
                {projectDetailes.data.earned_value_comment}
              </div>
              <div className='text-xs mt-2'>Based on realtime analysis</div>
            </div>
          </div>
          <div
            className={`flex px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
          >
            <div className='flex flex-column items-start'>
              <div className='font-bold text-[13px]'>Cost Variance</div>
              <div className='text-xs mt-2'>
                <a className='font-bold text-[23px]'>
                  {projectDetailes.data.cost_variance}
                </a>
              </div>
              {/* <div className="text-xs font-bold">{projectDetailes.data.earned_value_comment}</div> */}
              <div className='text-xs mt-2'>
                Based on realtime analysis of BCWP & ACWP
              </div>
            </div>
          </div>
          <div
            className={`flex px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
          >
            <div className='flex flex-column items-start'>
              <div className='font-bold text-[13px]'>
                Cost Performance Index (CPI)
              </div>
              <div className='text-xs mt-2'>
                <a className='font-bold text-[23px]'>
                  {projectDetailes.data.cost_performance_index}
                </a>
              </div>
              {/* <div className="text-xs font-bold">{projectDetailes.data.earned_value_comment}</div> */}
              <div className='text-xs mt-2'>
                Based on realtime analysis of earned value & ACWP
              </div>
            </div>
          </div>
        </div>
      ) : gantView ? (
        <>
          <div className='w-100 flex justify-between'>
            {!selected && (
              <div className='flex items-start w-full justify-between mt-2'>
                <button
                  className='text-sm md:!text-lg px-2 md:!px-10 py-1 bg-native-blue text-white rounded-full w-50 mb-2'
                  onClick={() => {
                    setGantView(false);
                    setEvaView(false);
                  }}
                >
                  Show List View
                </button>
              </div>
            )}
            <div className='w-40'>
              <Select
                onChange={(res) => {
                  console.log(res.target.value);
                  setGantViewType(res.target.value);
                }}
                variant='filled'
                size='sm'
                className='pt-0 pl-2 rounded-[10px]'
                placeholder='Change viewing'
              >
                <option value='Day'>Show Daily</option>
                <option value='Week'>Show Weekly</option>
                <option value='Month'>Show Monthly</option>
              </Select>
            </div>
          </div>
          <div className='w-100 h-100 mt-2'>
            {tasks.length > 0 && (
              <Gantt
                todayColor='#bff5e4'
                ganttWidth={"100%"}
                listCellWidth={""}
                viewMode={gantViewType}
                tasks={tasks}
              />
            )}
          </div>
        </>
      ) : (
        <div className='flex flex-col'>
          {!selected && (
            <div className='flex items-start w-full justify-between mt-2'>
              <button
                className='text-sm sm:!text-lg px-2 sm:!px-10 py-1 bg-native-blue text-white rounded-full max-w-[260px] mb-6'
                onClick={() => {
                  setGantView(true);
                  setEvaView(false);
                }}
              >
                Show Gantt View
              </button>
              <button
                className='text-sm sm:!text-lg px-2 sm:!px-10 py-1 rounded-full border border-native-blue transition-all active:scale-95'
                onClick={() => {
                  setEvaView(true);
                  setGantView(false);
                }}
              >
                Earned Value Analysis
              </button>
            </div>
          )}
          {selected ? (
            <div className='selected-screen'>
              <BsArrowLeftCircle
                onClick={() => setSelected(null)}
                className='close-button'
              />
              <br />
              {/* <b className="b">Milestone Name</b> <br /> */}
              <span className='span'>{selected?.milestone}</span>
              <br />
              <p className='p'>
                <b className='b'>Milestone Description: </b>
                {selected?.milestone_description === ""
                  ? "No Description provided"
                  : selected?.milestone_description}
              </p>
              {selected?.milestone_started & !selected.complete ? (
                <>
                  <b className='mb-2'>
                    Enter Target Done{" "}
                    {`(so far, ${selected.milestone_metric_target_covered} of ${selected.milestone_metric_target} ${selected.milestone_metric_system})`}
                  </b>
                  <input
                    name=''
                    id=''
                    min={0}
                    className='form-control'
                    placeholder={`Leave empty to use current ${selected.milestone_metric_system} covered`}
                    type={"number"}
                    value={milestoneStage.toLocaleString("en-US")}
                    onChange={(e) => setMilestoneStage(e.target.value)}
                  />
                  <b className='mt-2 mb-2'>Enter milestone remarks</b>
                  <textarea
                    name=''
                    id=''
                    rows='4'
                    className='form-control'
                    value={milestoneRemark}
                    onChange={(e) => setMilestoneRemark(e.target.value)}
                  ></textarea>
                  <div className='upload-file-container my-3'>
                    <MultipleFileUploadComponent
                      files={files}
                      setFiles={setFiles}
                      supportedFiles={`Image files only`}
                      accept={"image/*"}
                    />
                  </div>
                </>
              ) : null}
              {uploading && <AnimatedProgressBar animated now={uploadPercentage} />}
              <div className='mt-2'>
                {/* <button className="reject">Reject</button> */}
                <div className='flex items-start w-[100%] md:!w-[80%] ml-0 md:!ml-[10%] justify-between mt-4'>
                  {selected?.milestone_started && !selected?.complete ? (
                    userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "complete_milestones"
                    ) ||
                    userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "full_access"
                    ) ? (
                      <button
                        className='text-xs md:!text-lg p-3 md:!p-10 py-2 md:!py-3 rounded-full border border-native-blue transition-all active:scale-95 font-bold'
                        onClick={() => submitMilestoneRemarkFunction("finish")}
                        disabled={loading}
                      >
                        {!loading ? "Mark as Completed" : "Loading..."}
                      </button>
                    ) : null
                  ) : null}
                  {selected?.milestone_started ? (
                    userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "comment_on_milestones"
                    ) ||
                    userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "full_access"
                    ) ? (
                      <button
                        className='text-xs md:!text-lg p-3 md:!px-10 text-white py-2 md:!py-3 rounded-full bg-[#32a852] font-bold'
                        onClick={() => submitMilestoneRemarkFunction("comment")}
                        disabled={loading}
                      >
                        {!loading ? "Comment only" : "Loading..."}
                      </button>
                    ) : null
                  ) : null}
                  {selected?.milestone_started && selected.complete ? (
                    userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "download_site_acitivty_report"
                    ) ||
                    userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "full_access"
                    ) ? (
                      <button
                        className='text-xs md:!text-lg p-3 md:!p-10 py-2 md:!py-3 hover:bg-native-blue hover:text-white font-bold rounded-full transition-all first-letter:bg-transparent border-native-blue border'
                        onClick={() => downloadMilestoneReportFunction()}
                        disabled={loading}
                      >
                        {downloadingReport
                          ? "Downloading..."
                          : "Download Report"}
                      </button>
                    ) : null
                  ) : null}
                  {!selected?.milestone_started ? (
                    userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "start_milestones"
                    ) ||
                    userProfile?.sub_account_overview?.allowed_permissions?.includes(
                      "full_access"
                    ) ? (
                      <button
                        className='hover:bg-native-blue hover:text-white font-bold py-3 px-5 rounded-full transition-all first-letter:bg-transparent border-native-blue border'
                        onClick={() => startMilestoneFunction()}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Start Milestone"}
                      </button>
                    ) : null
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <TabPanelComponent
              btn2Text={`${completedMilestone.length} Completed`}
              btn1Text={`${nonCompletedMilestone.length} Pending`}
              tab2Content={
                <>
                  {completedMilestone.length < 0 ? (
                    <>No Record</>
                  ) : (
                    <>
                      {completedMilestone?.map((milestone, i) => {
                        return (
                          <MilestoneCard
                            setSelected={setSelected}
                            key={i}
                            data={milestone}
                          />
                        );
                      })}
                    </>
                  )}
                </>
              }
              tab1Content={
                <>
                  {nonCompletedMilestone.length < 0 ? (
                    <>No Record</>
                  ) : (
                    <>
                      {nonCompletedMilestone?.map((milestone, i) => {
                        if (date_category == "") {
                          date_category = milestone.milestone_start_date;
                          show_date = true;
                        } else if (
                          date_category != "" &&
                          date_category != milestone.milestone_start_date
                        ) {
                          date_category = milestone.milestone_start_date;
                          show_date = true;
                        } else if (
                          date_category != "" &&
                          date_category == milestone.milestone_start_date
                        ) {
                          date_category = milestone.milestone_start_date;
                          show_date = false;
                        }

                        return (
                          <>
                            {show_date && (
                              <a className='pt-3 font-bold text-[13px]'>
                                {"Activities for " +
                                  milestone.milestone_start_date}
                              </a>
                            )}
                            <MilestoneCard
                              setSelected={setSelected}
                              key={i}
                              data={milestone}
                            />
                          </>
                        );
                      })}
                    </>
                  )}
                </>
              }
            />
          )}
        </div>
      )}
    </div>
  );
}

export function WithdrawTab({ closeTabFunction }) {
  // const [addCard, setAddCard] = React.useState(false);
  const { id } = useParams();
  const { userProfile } = useContext(AuthContext);
  const [cookies] = useCookies();
  const { setTostMessage } = useContext(TostMessageContext);
  const [submittingReq, setSubmittingReq] = useState(false);
  const {projectDetailes, getProjectDetailes} = useContext(ProjectsContext);

  function requestWithdrawalFunction(e) {
    e.preventDefault();

    const formElement = e.target;
    if (
      formElement[0].value === "" ||
      formElement[4].value === "" ||
      formElement[5].value === "" ||
      formElement[6].value === "" ||
      formElement[1].value === "" ||
      formElement[2].value === "" ||
      formElement[3].value === ""
    ) {
      return setTostMessage({
        messageType: "error",
        message: "Fill Form before submission",
      });
    }
    // return console.log({ cookies });
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
        amount: formElement[0].value,
        expense_activity: formElement[4].value,
        expense_category: formElement[5].value,
        narration: formElement[6].value,
        d_account_number: formElement[1].value,
        d_account_name: formElement[3].value,
        d_bank_code: formElement[2].value,
        is_mock_balance: formElement[7].value
      },
    });
    // return console.log(JSON.parse(data));

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: submit_withdraw_request,
      headers: {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setSubmittingReq(true);
    axios
      .request(config)
      .then((response) => {
        const res = response.data;
        // console.log(response.data);
        closeTabFunction();
        if (res.status) {
          setTostMessage({
            messageType: "success",
            message: res.response,
          });
          getProjectDetailes();
        }
        setSubmittingReq(false);
      })
      .catch((error) => {
        setTostMessage({
          messageType: "error",
          message: "Error Making Request",
        });
        console.log(error);
        setSubmittingReq(false);
      });
  }
  return (
    <>
      {/* {addCard ? (
        <div className="selected-screen">
          <BsArrowLeftCircle
            onClick={() => setAddCard(false)}
            className="close-button"
          />
          {/* <AddBankCardFormComponent /> * /}
        </div>
      ) : ( */}
      <div className='mt-0 px-0'>
        <WithdrawTabFormComponent
          // setAddCard={setAddCard}
          milestones={projectDetailes?.data.project_milestones}
          submittingReq={submittingReq}
          requestWithdrawalFunction={submittingReq ? null : requestWithdrawalFunction}
        />
      </div>
      {/* )} */}
    </>
  );
}

export function DepositTab({ closeTabFunction, project_id }) {
  const [showFundDetails, setShowFundDetails] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [showAddCash, setShowAddCash] = useState(false);
  const [showForecast, setShowForecast] = useState(false);
  const [showEstimation, setShowEstimation] = useState(false);
  const [summaries, setSummaries] = useState("");
  const [newFund, setNewFund] = useState("");
  const { projectDetailes, setProjectDetailes } = React.useContext(ProjectsContext);
  const [cookies] = useCookies();
  const {userProfile} = useContext(AuthContext)
  const {setTostMessage} = useContext(TostMessageContext);
  const [completedMilestones, setCompletedMilestones] = useState([]);
  const [pendingMilestones, setPendingMilestones] = useState([]);
  const [fundingArray, setFundingArray] = useState([]);
  const [saving, setSaving] = useState(false);
  const [gaugeChartSeries, setGaugeChartSeries] = useState([0]);
  const [editCost, setEditCost] = useState(false);
  const [costEditDetails, setCostEditDetails] = useState({milestone_id: '', activity_name: ''});
  const [removing, setRemoving] = useState(false);

  const gaugeChartOptions = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '50%', // Ensure this matches the fill strokeWidth
          margin: 1,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
          },
        },
        hollow: {
          size: '50%', // Adjust as needed
        },
      },
    },
    fill: {
      type: 'solid',
      colors: ['rgba(23, 35, 55, 1)'],
    },
    labels: ['Amount Withdrawn'],
  };

  // const gaugeChartSeries = [80];

  const getSummary = async () => {
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: project_id,
        simulate: null,
        simulate_percentage: null,
      },
    });
    // return console.log(JSON.parse(data));

    let config = {
      method: "post",
      url: get_budget_summary,
      headers: {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const res = response.data;
        setCompletedMilestones(res.completed_milestones)
        setPendingMilestones(res.pending_milestones)
        setSummaries(res.summaries)
        setFundingArray(res.funding_array)
        console.log(res.summaries.total_planned_cost_remaining);
        setGaugeChartSeries([(res.summaries.total_planned_cost_remaining / res.summaries.bank_balance) * 100])
      })
      .catch((error) => {
        setTostMessage({
          messageType: "error",
          message: "Network error, please try again.",
        });
      });
  }

  const addFunds = async () => {
    setSaving(true);
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: project_id,
        amount: newFund,
      },
    });
    // return console.log(JSON.parse(data));

    let config = {
      method: "post",
      url: add_project_funds,
      headers: {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const res = response.data;
        if (res.status) {
          getSummary();
          setNewFund("");
          setSaving(false);
          setTostMessage({
            messageType: "success",
            message: res.response,
          });
        } else {
          setSaving(false);
          setTostMessage({
            messageType: "error",
            message: res.response,
          });
        }
      })
      .catch((error) => {
        setSaving(false);
        setTostMessage({
          messageType: "error",
          message: "Network error, please try again.",
        });
      });
  }

  const removeFunding = (id) => {
    if (removing) {
      return setTostMessage({
        messageType: 'warning',
        message: 'A process is already running, please wait.'
      });
    }
    if (!window.confirm(
      `Are you sure you want to delete this funding?`
    )) {
      return;
    }
    setRemoving(true);
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: project_id,
        fund_id: id,
      },
    });
    // return console.log(JSON.parse(data));

    let config = {
      method: "post",
      url: remove_project_funds,
      headers: {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const res = response.data;
        if (res.status) {
          getSummary();
          setRemoving(false);
          setTostMessage({
            messageType: "success",
            message: res.response,
          });
        } else {
          setRemoving(false);
          setTostMessage({
            messageType: "error",
            message: res.response,
          });
        }
      })
      .catch((error) => {
        setSaving(false);
        setTostMessage({
          messageType: "error",
          message: "Network error, please try again.",
        });
      });
  }

  useEffect(() => {
    getSummary();
  }, [])

  return (
    <div className="h-full pt-3">
      {editCost && (
        <EditMilestoneCost
          setEditCost={setEditCost}
          milestone_id={costEditDetails.milestone_id}
          editCost={editCost}
          activity_name={costEditDetails.activity_name}
          project_id={project_id}
          getSummary={getSummary}
        />
      )}
      {!showSummary && !showAddCash && !showForecast && !showEstimation ? (
        <div className="flex flex-row items-center justify-evenly mt-5 h-auto w-full h-full mt-2 px-3">
          <div onClick={() => {setShowAddCash(true); }} className="gap-y-2 bg-[#EDEDED] rounded-lg h-[10rem] w-[40%] hover:bg-gray-200 cursor-pointer flex flex-column items-center justify-center">
            <IoAddSharp size={40} />
            <b>Add Cash</b>
          </div>
          <div onClick={() => setShowSummary(true)} className="gap-y-2 bg-[#EDEDED] rounded-lg h-[10rem] w-[40%] hover:bg-gray-200 cursor-pointer flex flex-column items-center justify-center">
            <BsInfoLg size={40} />
            <b>Project Insights</b>
          </div>
          {/* <div onClick={() => setShowEstimation(true)} className="gap-y-2 bg-[#EDEDED] rounded-lg h-[10rem] w-[30%] hover:bg-gray-200 cursor-pointer flex flex-column items-center justify-center">
            <MdCandlestickChart size={40} />
            <b>Cost Estimation</b>
          </div> */}
          {/* <div onClick={() => setShowForecast(true)} className="gap-y-2 bg-[#EDEDED] rounded-lg h-[10rem] w-[30%] hover:bg-gray-200 cursor-pointer flex flex-column items-center justify-center">
            <MdCandlestickChart size={40} />
            <b>Forecast</b>
          </div> */}
        </div>
      ) : (
        <button onClick={() => {setShowAddCash(false);setShowSummary(false);setShowForecast(false);setShowEstimation(false)}}>
          <IoMdArrowRoundBack
            className='p-2 rounded-full bg-gray-100'
            size={44}
          />
        </button>
      )}
      {showForecast && (
        <div className="flex flex-column justify-between items-start w-full mb-2 mt-2">
          <div className="flex flex-row flex-wrap justify-between mb-2 w-full">
            <div
              className={`flex w-[24%] px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-bold text-[12px]'>Bank Balance</div>
                <div className='text-xs'>
                  <a className='font-bold text-[17px]'>
                    {summaries?.bank_balance}
                  </a>
                </div>
                {/* <div className="text-xs">As set duration project creation</div> */}
              </div>
            </div>
            <div
              className={`flex w-[24%] px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-bold text-[12px]'>Planned Cost</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    {summaries?.total_planned_cost_so_far}
                  </a>
                </div>
                <div className='text-[11px] font-bold'>
                  So far
                </div>
                {/* <div className='text-xs mt-2'>Based on realtime analysis</div> */}
              </div>
            </div>
            <div
              className={`flex w-[24%] px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-bold text-[12px]'>Actual Cost</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    {summaries?.total_actual_cost_so_far}
                  </a>
                </div>
                <div className='text-[11px] font-bold'>
                  So far
                </div>
                {/* <div className='text-xs mt-2'>Based on realtime analysis</div> */}
              </div>
            </div>
            <div
              className={`flex w-[24%] px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-bold text-[12px]'>Planned Cost</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    {summaries?.total_planned_cost_remaining}
                  </a>
                </div>
                <div className='text-[11px] font-bold'>
                  Remaining tasks
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 px-3 w-full">
            {/* <div className="donut">
            <Chart
              options={gaugeChartOptions}
              series={gaugeChartSeries}
              type="radialBar"
              height="300"
            />
            </div> */}
          </div>
        </div>
      )}
      {showAddCash && (
        <div className="flex flex-column justify-between items-start w-full mb-2 mt-2">
          <div className="flex flex-row flex-wrap justify-between mb-2 w-full">
            <div
              className={`flex w-[24%] px-3 py-3 items-center justify-between my-2 rounded-md cursor-pointer bg-[#EDEDED]`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-semibold text-[12px]'>Budget</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    {summaries?.project_budget}
                  </a>
                </div>
                {/* <div className="text-xs">As set duration project creation</div> */}
              </div>
            </div>
            <div
              className={`flex w-[24%] px-3 py-3 items-center justify-between my-2 rounded-md cursor-pointer bg-[#EDEDED]`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-semibold text-[12px]'>Total Funded</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    {summaries?.total_credited}
                  </a>
                </div>
                {/* <div className='text-xs mt-2'>Based on realtime analysis</div> */}
              </div>
            </div>
            <div
              className={`flex w-[24%] px-3 py-3 items-center justify-between my-2 rounded-md cursor-pointer bg-[#EDEDED]`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-semibold text-[12px]'>Total Spent</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    {summaries?.total_debited}
                  </a>
                </div>
                {/* <div className='text-xs mt-2'>Based on realtime analysis</div> */}
              </div>
            </div>
            <div
              className={`flex w-[24%] px-3 py-3 items-center justify-between my-2 rounded-md cursor-pointer bg-[#EDEDED]`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-semibold text-[12px]'>Bank Balance</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    {summaries?.bank_balance}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 px-3 w-full">
            <b>Funding History</b>
            {fundingArray?.map((item, index) => 
              <div className="flex flex-row items-end w-full mb-1">
                <div className="flex flex-column items-start w-[70%]">
                  <p className="font-semibold text-sm">{item.date}</p>
                  <input className='py-2 form-control mt-2 bg-[#f1f1f1]' value={item.amount} readOnly/>
                </div>
                <button onClick={() => removeFunding(item.id)} className="mx-2 p-3 bg-[#ff0000] rounded-md">
                  <VscDiffRemoved size={12} color="#ffffff" />
                </button>
              </div>
            )}
            <div className="flex flex-row items-end w-full mt-3">
              <div className="flex flex-column items-start w-[70%]">
                <p className="font-semibold text-sm">New Record</p>
                <input value={newFund} placeholder="Enter amount here..." type={'number'} onChange={(e) => setNewFund(e.target.value)} className='py-2 form-control mt-2 bg-[#ffffff]' />
              </div>
              <button onClick={() => !saving ? addFunds() : null} className="mx-2 px-3 py-2 bg-native-blue rounded-md flex flex-row items-center">
                <IoMdCheckmarkCircleOutline className="mr-2" size={15} color="#ffffff" />
                <b className="font-bold text-white">{saving ? 'Saving...' : 'Add'}</b>
              </button>
            </div>
          </div>
        </div>
      )}
      {showSummary && (
        <div className="flex flex-row flex-wrap justify-between">
            <div
              className={`flex w-[24%] px-3 py-3 items-start justify-between my-2 rounded-md cursor-pointer bg-[#EDEDED]`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-semibold text-[12px]'>Planned Cost (so far)</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    N{summaries?.total_planned_cost_so_far}
                  </a>
                </div>
                <div className='text-[11px] font-bold'>
                  {projectDetailes.data.planned_value_comment}
                </div>
                {/* <div className='text-xs mt-2'>Based on realtime analysis</div> */}
              </div>
            </div>
            <div
              className={`flex w-[24%] px-3 py-3 items-start justify-between my-2 rounded-md cursor-pointer bg-[#EDEDED]`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-semibold text-[12px]'>Actual Cost (so far)</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    N{summaries?.total_actual_cost_so_far}
                  </a>
                </div>
                <div className='text-[11px] font-bold'>
                  {projectDetailes.data.earned_value_comment}
                </div>
                {/* <div className='text-xs mt-2'>Based on realtime analysis</div> */}
              </div>
            </div>
            <div
              className={`flex w-[24%] px-3 py-3 items-start justify-between my-2 rounded-md cursor-pointer bg-[#EDEDED]`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-semibold text-[12px]'>Planned Cost (remaining)</div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    N{summaries?.total_planned_cost_remaining}
                  </a>
                </div>
                {/* <div className="text-xs font-bold">{projectDetailes.data.earned_value_comment}</div> */}
                {/* <div className='text-xs mt-2'>
                  Based on realtime analysis of BCWP & ACWP
                </div> */}
              </div>
            </div>
            <div
              className={`flex w-[24%] px-3 py-3 items-start justify-between my-2 rounded-md cursor-pointer bg-[#EDEDED]`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-semibold text-[12px]'>Bank Balance</div>
                <div className='text-xs mt-2'>
                  <a className={`font-bold text-[17px] ${summaries?.bank_balance >= summaries?.total_planned_cost_remaining ? 'text-[#00b47a]' : 'text-[#EA4335]'}`}>
                    N{summaries?.bank_balance}
                  </a>
                </div>
              </div>
            </div>
            {/* <div
              className={`flex w-[24%] px-3 py-3 items-center justify-between my-2 rounded-xl cursor-pointer bg-blue-100`}
            >
              <div className='flex flex-column items-start'>
                <div className='font-bold text-[12px]'>
                  Cost Performance Index (CPI)
                </div>
                <div className='text-xs mt-2'>
                  <a className='font-bold text-[17px]'>
                    {projectDetailes.data.cost_performance_index}
                  </a>
                </div>
                <div className='text-xs mt-2'>
                  Based on realtime analysis of earned value & ACWP
                </div>
              </div>
            </div> */}
            <b className="mt-2">Completed Tasks</b>
            <div className="flex flex-row flex-wrap justify-between w-full mb-2 mt-1">
              <table className='min-w-full rounded-xl border-none bg-white'>
                <thead className=''>
                  <tr className='bg-[#F9F9F9]'>
                    <th className='py-2 px-2 border-b font-semibold text-[14px]'>Activity</th>
                    <th className='py-2 px-2 border-b font-semibold text-[14px]'>Planned Cost</th>
                    <th className='py-2 px-2 border-b font-semibold text-[14px]'>Actual Cost</th>
                    <th className='py-2 px-2 border-b font-semibold text-[14px]'></th>
                    <th className='py-2 px-2 border-b font-semibold text-[14px]'>Start Date</th>
                  </tr>
                </thead>
                <tbody>
                {completedMilestones?.map((item, index) => 
                  <tr
                    key={index}
                    className='hover:bg-gray-50 border-b'
                  >
                    <td className='py-2 px-2 flex'>
                        <span className="text-[12px] font-semibold">{item.milestone_name}</span>
                    </td>
                    <td className='py-2 px-2'>
                      <span className="text-[12px] font-semibold">{item.planned_cost}</span>
                    </td>
                    <td className='py-2 px-2'>
                        <span className="text-[12px] font-semibold">{item.actual_cost}</span>
                    </td>
                    <td className='py-2 px-2'>
                        {parseFloat(item.actual_cost.replace(/,/g, '')) <= parseFloat(item.planned_cost.replace(/,/g, '')) && <span className="text-[11px] text-white p-2 px-3 rounded-lg bg-[#00b47a] font-semibold">{'ON BUDGET'}</span>}
                        {parseFloat(item.actual_cost.replace(/,/g, '')) > parseFloat(item.planned_cost.replace(/,/g, '')) && <span className="text-[11px] text-white p-2 px-3 rounded-lg bg-[#EA4335] font-semibold">{'OVERRUN'}</span>}
                    </td>
                    <td className='py-2 px-2'>
                        <span className="text-[12px] font-semibold">{item.start_date}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            </div>
            <b className="mt-2">Pending & Tasks in Progress</b>
            <div className="flex flex-row flex-wrap justify-between items-start w-full mb-2 mt-1">
              <table className='min-w-full rounded-xl border-none bg-white'>
                  <thead className=''>
                    <tr className='bg-[#F9F9F9]'>
                      <th className='py-2 px-2 border-b font-semibold text-[14px]'>Activity</th>
                      <th className='py-2 px-2 border-b font-semibold text-[14px]'>Planned Cost</th>
                      <th className='py-2 px-2 border-b font-semibold text-[14px]'>Actual Cost</th>
                      <th className='py-2 px-2 border-b font-semibold text-[14px]'>Status</th>
                      <th className='py-2 px-2 border-b font-semibold text-[14px]'>Start Date</th>
                      <th className='py-2 px-2 border-b font-semibold'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingMilestones?.map((item, index) => 
                      <tr
                        key={index}
                        className='hover:bg-gray-50 border-b'
                      >
                        <td className='py-2 px-2 flex'>
                            <span className="text-[12px] font-semibold">{item.milestone_name}</span>
                        </td>
                        <td className='py-2 px-2'>
                          <span className="text-[12px] font-semibold">N{item.planned_cost}</span>
                        </td>
                        <td className='py-2 px-2'>
                            {parseFloat(item.actual_cost.replace(/,/g, '')) <= parseFloat(item.planned_cost.replace(/,/g, '')) && <span className="text-[#00b47a] font-semibold text-[12px]">N{item.actual_cost}</span>}
                            {parseFloat(item.actual_cost.replace(/,/g, '')) > parseFloat(item.planned_cost.replace(/,/g, '')) && <span className="text-[#EA4335] font-semibold text-[12px]">N{item.actual_cost}</span>}
                        </td>
                        <td className='py-2 px-2'>
                            <span className="text-[12px] font-semibold">{(item.milestone_target_done/item.milestone_target) * 100}%</span>
                        </td>
                        <td className='py-2 px-2'>
                            <span className="text-[12px] cursor-pointer font-bold flex flex-row text-underline">{item.start_date}</span>
                        </td>
                        <td className='py-2 px-2'>
                          <span onClick={() => {setEditCost(true); setCostEditDetails({milestone_id: item.milestone_id, activity_name: item.milestone_name})}} className="text-[14px] cursor-pointer font-bold flex flex-row text-underline">Edit <TbEdit size={20} /></span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
            </div>
        </div>
      )}
      {showFundDetails && (
        <div className='px-10 py-20'>
          <b>
            You can deposit to your project account using the details bellow and it
            will reflect in your project financials
          </b>

          <div className='mt-7'>
            <FormLabel style={{ fontSize: 15 }} htmlFor='id_file'>
              <b>BANK NAME:</b>
            </FormLabel>
            <input
              placeholder='Enter a Reason'
              className='withdraw-input max-w-sm'
              value={projectDetailes.account_bank}
              disabled
            />
          </div>
          <div className='mt-4'>
            <FormLabel style={{ fontSize: 15 }} htmlFor='id_file'>
              <b>ACCOUNT NAME:</b>
            </FormLabel>
            <input
              placeholder='Enter a Reason'
              className='withdraw-input max-w-sm'
              disabled
              value={projectDetailes.account_name}
            />
          </div>
          <div className='mt-4'>
            <FormLabel style={{ fontSize: 15 }} htmlFor='id_file'>
              <b>ACCOUNT NUMBER:</b>
            </FormLabel>
            <input
              placeholder='Enter a Reason'
              className='withdraw-input max-w-sm'
              disabled
              value={projectDetailes.account_number}
            />
          </div>
        </div>
        )}
    </div>
  );
}

export function EditMilestoneCost({ setEditCost, editCost, project_id, milestone_id, activity_name, getSummary }) {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [cookies] = useCookies();

  const handleSubmit = async () => {
      setLoading(true);
      let data = JSON.stringify({
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          new_amount: amount,
          project_id: project_id,
          milestone_id: milestone_id
        },
      });
  
      const headers = {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      };
  
      axios
        .post(edit_milestone_cost, data, { headers })
        .then((response) => {
          if (!response.data.status) {
            setLoading(false);
            return setTostMessage({
              messageType: 'error',
              message: response.data.response
            })
          }
          if (response.data.status) {
            setEditCost(false)
            setAmount("");
            setLoading(false);
            getSummary();
            return setTostMessage({
              messageType: 'success',
              message: response.data.response
            })
          }
        }).catch((error) => {
          setLoading(false);
          return setTostMessage({
            messageType: 'error',
            message: 'Network error, please check your network and try again.'
          })
        });
    }

  return (
    <div className="backdropFile w-[100%] h-[100%] z-[999] position-fixed top-[0] flex flex-column items-end p-2">
      <button onClick={() => setEditCost(false) } className="p-3 py-2 rounded-md bg-[#ffffff] text-[#ff0000] font-bold">Close</button>
      <div className={`w-full h-full mt-2 flex items-center justify-center`}>
        <div className="h-auto w-[60%] bg-white min-h-[30%] rounded-lg p-4 flex flex-column justify-center">
        <div className='w-full h-auto p-3 mb-2 bg-[#c4f1ff] rounded-[10px] flex items-center justify-center'>
          <b className='text-sm text-center'>
            {activity_name}
          </b>
        </div>
          <Box>
            <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
              New Planned Cost
            </FormLabel>
            <input className="bg-slate-100 h-10 px-3 border-none rounded-md text-[.9rem] w-full" 
                placeholder="Enter new amount"
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
            />
          </Box>
          {(amount == "") ? 
            (<button className="continue w-50 mt-3 mx-auto !rounded-full disabled" type="button" role="disabled">
                Update
            </button>):
            (<button
                className={`continue w-50 mx-auto mt-3 bg-[#000aff] !rounded-full ${loading ? 'loading' : ''}`}
                onClick={handleSubmit}
                type="button"
                disabled={loading}
            >
                {loading ? 'Please Wait...' : `Update`}
            </button>) }
        </div>
      </div>
    </div>
  )
}

export function GalleryTab() {
  const [cookies] = useCookies();
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userProfile } = React.useContext(AuthContext);
  const [viewingFile, setViewingFile] = useState(false);
  const [viewingFileUrl, setViewingFileUrl] = useState('');
  const [viewingFileType, setViewingFileType] = useState('');
  // console.log({ projectDetailes });

  const { id } = useParams();

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const getProjectGallery = async () => {
    try {
      const response = await axios.post(
        `${base_url}/projects/project_gallery.php`,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            project_id: id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const data = await response.data;
      if (data.status) {
        setImages(data.gallery);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getProjectGallery();
  }, []);

  const viewFile = (fileUrl, fileType) => {
    setViewingFile(true);
    setViewingFileUrl(fileUrl);
    setViewingFileType(fileType);
  }

  return (
    <div className='px-7 mt-4'>
      {viewingFile && (
        <ViewFile 
          filePath={viewingFileUrl}
          fileType={viewingFileType}
          setViewingFile={setViewingFile}
        />
      )}
      <div className='mx-auto'>
        <div className='grid grid-cols-2 gap-4'>
          {loading ? (
            <>
              {[1, 2, 3, 4, 5, 6, 6, 7].map((i, idx) => (
                <div
                  key={idx}
                  className='h-[200px] w-full rounded-2xl animate-pulse bg-gray-100'
                ></div>
              ))}
            </>
          ) : (
            <>
              {images.map((image, index) => {
                return (
                  <div
                    key={index}
                    className='relative'
                    onClick={() => viewFile(image?.file_uri, image?.file_uri.split('.').pop())}
                  >
                    <div className='hover:bg-black rounded-2xl hover:bg-opacity-25 absolute top-0 right-0 left-0 bottom-0 flex'>
                      <p className='m-auto px-6 py-1 rounded-lg font-semibold bg-opacity-50 cursor-pointer bg-gray-200'>
                        View
                      </p>
                    </div>
                    <img
                      src={image?.file_uri}
                      alt={`Image ${index + 1}`}
                      className='cursor-pointer h-[200px] object-cover w-full rounded-2xl'
                    />
                  </div>
                );
              })}
            </>
          )}

          {selectedImage && (
            <div
              className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75'
              onClick={handleClose}
            >
              <div className='rounded-xl p-3 pt-4 bg-white shadow-lg'>
                <button
                  className='text-black text-4xl rounded-xl px-5py-2'
                  onClick={handleClose}
                >
                  x
                </button>
                <img
                  src={selectedImage?.file_uri}
                  alt='Selected Image'
                  className='max-h-[700px] rounded-xl mx-4'
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function DownloadReportTab({ reports }) {
  const filterReports = () => {
    const filteredReports = reports.filter((item) => item.milestone_started);
    return filteredReports;
  };
  const [selected, setSelected] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { setTostMessage } = React.useContext(TostMessageContext);
  const [reportFromDate, setReportFromDate] = useState("");
  const [reportToDate, setReportToDate] = useState("");
  const [includeChecklist, setIncludeCheckList] = useState('no');
  const [fillCheckList, setFillCheckList] = useState(false);
  const { userProfile } = React.useContext(AuthContext);
  const [checklistResponse, setCheckListResponse] = useState([{}]);
  const [cookies] = useCookies();
  const {getProjectDetailes} = useContext(ProjectsContext);

  const { id } = useParams();

  const data = filterReports();
  useEffect(() => {
    setSelected(data);
    return () => null;
  }, []);

  const downloadMilestoneReportFunction = async (item, action) => {
    try {
      window.open(`${download_milestone_report_end_point}?project_id=${id}&milestone_id=${item.milestone_id}&action=${action}&includeChecklist=${includeChecklist}`, '_blank')
    } catch (error) {
      setLoading(false);
      console.log(error);
      setTostMessage({
        messageType: "error",
        message: "Error Downloading Milestone Report",
      });
    }
  };

  const handleChange = (e, index, header) => {
    const { name, value } = e.target;
    const new_array = checklistResponse;
    new_array[index] = name + 'URBEX_ANSWER' + value + 'URBEX_ANSWER' + header;
    setCheckListResponse(new_array);
    // console.log(checklistResponse);
  }

  const SaveChecklistFunction = async () => {
    // console.log(checklistResponse);

    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
        checklistResponse: checklistResponse,
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: save_daily_checklist_endpoint,
      headers: {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    setLoading(true);
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
          setFillCheckList(false);
          setCheckListResponse([]);
        } else {
          setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setTostMessage({
          messageType: "error",
          message: 'Network error, could not save.',
        });
        console.log(error);
      });

  }

  var show_date = false;
  var category_group = "";
  return (
    <>
    {fillCheckList ? (
      <div className='flex flex-col'>
        <div className="w-full flex flex-row justify-start mt-2 mb-2">
          <button
              onClick={() => setFillCheckList(false)}
              className='px-6 flex items-center gap-2 py-2 rounded-lg text-white bg-native-blue border'
          >
              <IoArrowBack /> Back
          </button>
        </div>
        {userProfile?.site_checklist?.length < 1 && (
          <div className="w-full mt-[40%] flex items-center justify-center">
            <b className="font-bold text-md">Organization checklist not set, contact admin.</b>
          </div>
        )}
        {userProfile?.site_checklist?.map((item, index) => {
          if (category_group == "") {
            category_group = item.item_header;
            show_date = true;
          } else if (
            category_group != "" &&
            category_group != item.item_header
          ) {
            category_group = item.item_header;
            show_date = true;
          } else if (
            category_group != "" &&
            category_group == item.item_header
          ) {
            category_group = item.item_header;
            show_date = false;
          }
          return (
            <>
              {show_date && 
                <b className='text-[13px]'>
                  {item.item_header}
                </b>
              }
              <div className='AddMilestonesInputs w-full border-b bg-gray-100 rounded-xl p-3 m-0 flex justify-between items-center mb-3'>
                <div className='w-full'>
                  <div className='d-flex justify-content-between m-w-[100%]'>
                    <Box className="w-full">
                      <FormLabel
                        style={{ fontSize: 11, fontWeight: "bold" }}
                        htmlFor='milestone_name'
                        className='mt-0 font-bold'
                      >
                        {item.item_question}
                      </FormLabel>
                      {item.input_type == "ShortText" && 
                        <Input
                          placeholder={'Enter response here...'}
                          className='text-10 h-auto py-1 bg-white w-full'
                          name={item.item_question}
                          onChange={(e) => handleChange(e, index, item.item_header)}
                        />
                      }
                      {item.input_type == "LongText" && 
                        <Textarea
                          placeholder={'Enter response here...'}
                          className='text-10 h-auto py-1 bg-white w-full'
                          name={item.item_question}
                          onChange={(e) => handleChange(e, index, item.item_header)}
                        ></Textarea>
                      }
                      {item.input_type == "YesNo" && 
                        <Select
                          className='text-10 h-auto py-1 bg-white w-full'
                          name={item.item_question}
                          onChange={(e) => handleChange(e, index, item.item_header)}
                        >
                          <option value={''}>Select an Option</option>
                          <option value={'YES'}>YES</option>
                          <option value={'NO'}>NO</option>
                          <option value={'N/A'}>N/A</option>
                        </Select>
                      }
                    </Box>
                  </div>
                </div>
              </div>  
            </>
          )
        })}
        {userProfile?.site_checklist?.length > 0 && (
        <button
          className='bg-emerald-500 p-10 py-3 text-white rounded-full'
          onClick={() => SaveChecklistFunction()}
          disabled={loading}
        >
          {loading
            ? "Loading..."
            : `Save Checklist`}
        </button>
        )}
      </div>
    ) : (
      <div className='flex flex-col'>
        <div className="w-full flex flex-row justify-end mt-2">
          <button
              onClick={() => setFillCheckList(true)}
              className='px-6 flex items-center gap-2 py-2 rounded-lg text-white bg-native-blue border'
          >
              <FaRegPenToSquare /> Daily Checklist
          </button>
        </div>
        <div className="mt-2 w-full p-2 bg-gray-100 rounded-[5px] flex flex-column sm:!flex-row justify-between items-center gap-y-2 sm:gap-y-0">
          <Tooltip id="daily-report-tooltip" />
          <a data-tooltip-id="daily-report-tooltip" data-tooltip-content="Auto-generate daily report for variable date range. Data in this report is sourced from your daily milestone updates.">
            <TbHelpCircle size={25} />
          </a>
          <div className="flex flex-row items-center justify-center">
            <div className="mx-1 flex flex-column sm:!flex-row  items-center gap-x-1">
              <label className="text-xs sm:text-sm font-bold">From</label>
              <input 
              onChange={(e) => {
                setReportFromDate(e.target.value)
              }}
              className="h-[2rem] border-none rounded-[5px] w-[100px] sm:w-[min-content] bg-white" type="date" />
            </div>
            <div className="mx-1 flex flex-column sm:!flex-row items-center gap-x-1">
              <label className="text-xs sm:text-sm font-bold">to</label>
              <input
              onChange={(e) => {
                setReportToDate(e.target.value)
              }}
              className="h-[2rem] border-none rounded-[5px] w-[100px] sm:w-[min-content] bg-white" type="date" />
            </div>
            {/* <div className="mx-1 flex flex-column sm:!flex-row items-center gap-x-1">
              <select
              onChange={(e) => {
                setIncludeCheckList(e.target.value)
              }}
              className="h-[2rem] border-none rounded-[5px] w-[100px] sm:w-[min-content] bg-white"
              >
                <option value={'NO'}>Without Checklist</option>
                <option value={'YES'}>With Checklist</option>
              </select>
            </div> */}
          </div>
          <div className="flex flex-row gap-x-1">
            <button 
            onClick={() => window.open(`https://api.urbex.africa/mgt-enterprise/reports/daily.php?project_id=${id}&from=${reportFromDate}&to=${reportToDate}&action=view`)}
            className="bg-[#000aff] text-sm text-white h-[min-content] p-2 rounded-[5px]">
              <b>View</b>
            </button>
            <button 
            onClick={() => window.open(`https://api.urbex.africa/mgt-enterprise/reports/daily.php?project_id=${id}&from=${reportFromDate}&to=${reportToDate}&action=download`)}
            className="bg-native-blue flex flex-row text-sm text-white h-[min-content] p-2 rounded-[5px]">
              <b className="block sm:hidden">Download</b>
              <MdOutlineFileDownload size={20} />
            </button>
          </div>
        </div>
        {loading ? (
          <>
            {[1, 2, 3, 4, 5, 6, 7].map((i, idx) => (
              <div
                key={idx}
                className='animate-pulse py-10 transition-all ease-in-out cursor-pointer bg-gray-200 my-2 rounded-xl'
              ></div>
            ))}
          </>
        ) : (
          <div>
            <div className='gap-4 overflow-auto p-3'>
              {selected.map((item, i) => {
                const milestone_metric_target_covered = Number(
                  item.milestone_metric_target_covered
                );
                const milestone_metric_target = Number(
                  item.milestone_metric_target
                );
                const percentageNum =
                  (milestone_metric_target_covered / milestone_metric_target) *
                  100;
                const percentage = isNaN(percentageNum)
                  ? 1
                  : Math.ceil(percentageNum);

                return (
                  <div
                    key={i}
                    className='transition-all ease-in-out cursor-pointer my-1 p-2 rounded-xl'
                  >
                    <div className='flex items-center justify-between'>
                      <div className='w-[60%]'>{item.milestone}</div>
                      <div className='flex gap-2 items-center justify-between'>
                        <div
                          className={`hidden sm:!block text-sm w-[max-content] ${
                            item.milestone_started &&
                            !item.complete &&
                            "bg-blue-700 text-white rounded-full px-2 py-1"
                          }
                          ${
                            item.complete &&
                            "bg-emerald-600 text-white rounded-full px-2 py-1"
                          }
                          `}
                        >
                          {item.milestone_started && !item.complete
                            ? "In Progress"
                            : item.milestone_started && item.complete
                            ? "Completed"
                            : ""}
                          {` (${item.progress}%)`}
                        </div>
                        {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                          "download_site_acitivty_report"
                        ) ||
                        userProfile?.sub_account_overview?.allowed_permissions?.includes(
                          "full_access"
                        ) ? (
                          <button
                            className={`active:scale-95 transition-all ease-in-out text-sm ${
                              item.milestone_started && !item.complete
                                ? "text-blue-600 rounded-full px-2 py-1"
                                : item.milestone_started && item.complete
                                ? "text-emerald-600 rounded-full px-2 py-1"
                                : ""
                            }`}
                            onClick={() => downloadMilestoneReportFunction(item, 'view')}
                          >
                            View
                          </button>
                        ) : null}
                        {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                          "download_site_acitivty_report"
                        ) ||
                        userProfile?.sub_account_overview?.allowed_permissions?.includes(
                          "full_access"
                        ) ? (
                          <button
                            className={`active:scale-95 transition-all ease-in-out text-sm ${
                              item.milestone_started && !item.complete
                                ? "text-blue-600 rounded-full px-2 py-1"
                                : item.milestone_started && item.complete
                                ? "text-emerald-600 rounded-full px-2 py-1"
                                : ""
                            }`}
                            onClick={() => downloadMilestoneReportFunction(item, 'download')}
                          >
                            Download
                          </button>
                        ) : null}
                      </div>
                    </div>
                    {item.complete && item.milestone_started && (
                      <div className='text-xs'>Time taken: {item.duration}</div>
                    )}
                    {!item.milestone_started && (
                      <div className='text-xs'>Duration: Not started</div>
                    )}
                    {item.milestone_started && !item.complete && (
                      <>
                        <div className='text-xs'>
                          Duration set: {item.duration}
                        </div>
                        <div className='text-xs'>
                          Duration elapsed: {item.duration_elapsed}
                        </div>
                      </>
                    )}
                    <div className='flex justify-between mb-1 mt-2'>
                      <span
                        className={`text-xs font-medium  dark:text-white  ${
                          item.milestone_started && !item.complete
                            ? "text-blue-600"
                            : item.milestone_started && item.complete
                            ? "text-emerald-600"
                            : ""
                        }`}
                      >
                        {item.milestone_metric_target_covered} of{" "}
                        {item.milestone_metric_target}{" "}
                        {item.milestone_metric_system}
                      </span>
                    </div>
                    {item.milestone_metric_system != "" && (
                      <div className='w-full bg-gray-100 rounded-full h-2'>
                        <div
                          className={`h-2 rounded-full ${
                            item.milestone_started && !item.complete
                              ? "bg-blue-700"
                              : item.milestone_started && item.complete
                              ? "bg-emerald-600"
                              : ""
                          }`}
                          style={{ width: `${percentage}%` }}
                        ></div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {!selected.length && (
          <div className='h-full mx-auto mt-56 items-center'>
            <p>Reports submitted for this project will be available here</p>
          </div>
        )}
      </div>
    )}
    </>
  );
}
// {selected.map((item, index) => (
//   <div key={index} className="flex flex-col w-full px-20 my-4">
//     {/* <b className="b">Milestone Name</b> <br /> */}
//     <span className="font-bold">{item?.milestone}</span>
//     <br />
//     <h2></h2>
//     <p className="p-3 bg-gray-100 my-4 rounded-xl w-full">
//       <b className="b">Milestone Description: </b>
//       {item?.milestone_description === ''
//         ? 'No Description provided'
//         : item?.milestone_description}
//     </p>
//     {item?.milestone_started & item.complete ? (
//       <button
//         className="px-10 py-3 rounded-full border hover:bg-native-blue hover:text-white self-end"
//         onClick={() => downloadMilestoneReportFunction(item)}
//         disabled={loading}
//       >
//         Download Report
//       </button>
//     ) : null}{' '}
//     <hr />
//   </div>
// ))}

export function BidsTab({ project }) {
  const [bidId, setBidId] = React.useState(null);
  console.log({ project });
  React.useEffect(() => {
    console.log("project", project);
  }, [project]);
  // React.useEffect(() => {
  //   console.log("bidId:", bidId);
  // }, [bidId]);
  return (
    <div className='BidsTab'>
      {!bidId ? (
        <>
          {project?.project_bids?.map((bid) => {
            return (
              <BidProfileCard key={bid.bid_id} bid={bid} setBidId={setBidId} />
            );
          })}
        </>
      ) : (
        <ViewContractorApplication bid_id={bidId} setBidId={setBidId} />
      )}

      {!project?.project_bids.length && (
        <div className='mt-10 text-lg'>Project Bids will appear here</div>
      )}
      {/* <BidProfileCard />
      <BidProfileCard />
      <BidProfileCard />
      <BidProfileCard /> */}
    </div>
  );
}
  
export function SubscriptionPayCard({ closeTabFunction, plan, price,details,is_addon,active_subscription }) {
  const [siteVisits, setSiteVisits] = useState(0);
  const [extraStorage,setExtraStorage] = useState(0)
  const [addOns, setAddOns] = useState(0);
  var [details,setDetails]  = useState(details);
  const { projectDetailes, getProjectDetailes } = React.useContext(ProjectsContext);
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();
  const [newloading, setNewLoading] = useState(false);
  const { setTostMessage } = React.useContext(TostMessageContext);
  const [loading, setLoading] = useState(false);
  const [livestream, setLivestream] = useBoolean();
  const [siteVisitsCount, setSiteVisitsCount] = useState(0);
  const [subscriptionMonths, setSubscriptionMonths] = useState(1);
  // getProjectDetailes();

  function SiteVisit(type, addon_cost) {
    if (type === "add") {
      setSiteVisits(siteVisits + 1);
      setAddOns(addOns + addon_cost);
      setSiteVisitsCount(siteVisitsCount + 1);
    } else {
      if (siteVisits > 0) {
        setSiteVisits(siteVisits - 1);
        setAddOns(addOns - addon_cost);
        setSiteVisitsCount(siteVisitsCount - 1);
      }
    }
  }
  async function payOnline() {
    setNewLoading(true);
    if(is_addon){
      const data = {
        user: {
          account_id: userProfile?.account_id,
        },
        data: {
          amount: addOns,
          extra_user: siteVisits,
          active_plan_id:active_subscription.id,
          extra_storage:(extraStorage / 1024).toFixed(2)
        },
      };
      try {
        const response = await axios.post(add_addons_endpoint, data, {
          headers: {
            access: cookies.userToken,
          },
        });
        setNewLoading(false);
        if (response?.data?.status) {
          setTostMessage({
            messageType: "success",
            message: "redirecting to " + response.data.response,
          });
          window.location.href = response.data.response;
          closeTabFunction();
        } else {
          setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }else{
      var total = addOns + (details.price * subscriptionMonths);

    var items = "";
    if (siteVisitsCount > 0) {
      items = items + `${siteVisitsCount} Site Visits,`;
    }
    if (livestream) {
      items = items + ` Livestream setup`;
    }
    const data = {
      user: {
        account_id: userProfile?.account_id,
      },
      data: {
        amount: total,
        plan: plan,
        extra_user: siteVisits,
        years: subscriptionMonths,
        planId:details.id,
        extra_storage:(extraStorage / 1024).toFixed(2)
      },
    };
    try {
      const response = await axios.post(pay_online_endpoint, data, {
        headers: {
          access: cookies.userToken,
        },
      });
      setNewLoading(false);
      if (response?.data?.status) {
        setTostMessage({
          messageType: "success",
          message: "redirecting to " + response.data.response,
        });
        window.location.href = response.data.response;
        closeTabFunction();
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      console.log(error);
    }
    }
  }
  function extraStorageSub(type,addon_cost){
    if (type === "add") {
      setExtraStorage(((extraStorage + 500)));
      setAddOns(addOns + addon_cost);
    } else {
      if (extraStorage > 0) {
        setExtraStorage(((extraStorage - 500)));
        setAddOns(addOns - addon_cost);
        
      }
    }
  }
  function SubscriptionMonths(type) {
    if (type === "add") {
      setSubscriptionMonths(subscriptionMonths + 1);
    } else {
      if (siteVisits > 0) {
        setSubscriptionMonths(subscriptionMonths - 1);
      }
    }
    setDetails({...details,price:price * subscriptionMonths })
  }
  
  return (
    <div className=''>
      <div className='mt-3 flex items-center'>
        {plan !== "Add-ons" && (
          <div>
            <h2 className='text-[#32a852] text-[50px] font-bold mb-0'>{`$${(
              details?.price * subscriptionMonths
            ).toLocaleString("en-us")}`}</h2>
            <span className='text-[15px]'>per year</span>
          </div>
        )}
        {plan !== "Add-ons" && <a className='text-[30px] font-bold mx-2'>+</a>}
        <div>
          <h2 className='text-[#32a852] text-[50px] font-bold mb-0'>{`$${addOns.toLocaleString(
            "en-us"
          )}`}</h2>
          <span className='text-[15px]'>add-ons</span>
        </div>
      </div>
      <div className='mt-3 mb-3'>
        <a className='font-bold'>{!is_addon ? 'Access more with the urbex app!' : "With Urbex add-ons, you can;"}</a>
        {
          is_addon ? 
          <>
        <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Add more users </a></span>
        <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Increase storage limit </a></span>
          </>:
          <>
            <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>{details?.no_of_projects} projects</a></span>
        <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>{details?.no_of_users} users </a></span>
        <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>{details?.storage_limit} GB storage limit </a></span>
          </>
        }
        {/* <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Training.</a></span>
        <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>24/7 support</a></span>
        <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Free updates</a></span> */}
        {(
          <>
            {/* <div className='w-[90%] h-30 bg-white py-2 hover:border-1 hover:border-native-blue mt-2 rounded-[5px] flex justify-between items-center'>
              <a className='font-bold'>{subscriptionMonths} year(s)</a>
              <div className='flex items-center'>
                <button
                  onClick={() => SubscriptionMonths("minus")}
                  className='border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue'
                >
                  <AiOutlineMinus size={15} />
                </button>
                <a className='font-bold text-[20px] mx-2'>
                  {subscriptionMonths}
                </a>
                <button
                  onClick={() => SubscriptionMonths("add")}
                  className='border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue'
                >
                  <AiOutlinePlus size={15} />
                </button>
              </div>
            </div> */}
            <div className='w-[90%] h-30 bg-white py-2 hover:border-1 hover:border-native-blue mt-2 rounded-[5px] flex justify-between items-center'>
              <a className='font-bold'>Extra user ($10.00)</a>
              <div className='flex items-center'>
                <button
                  onClick={() => SiteVisit("minus", 10)}
                  className='border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue'
                >
                  <AiOutlineMinus size={15} />
                </button>
                <a className='font-bold text-[20px] mx-2'>{siteVisits}</a>
                <button
                  onClick={() => SiteVisit("add", 10)}
                  className='border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue'
                >
                  <AiOutlinePlus size={15} />
                </button>
              </div>
              {/* <Switch size="md" /> */}
            </div>
            <div className='w-[90%] h-30 bg-white py-2 hover:border-1 hover:border-native-blue mt-2 rounded-[5px] flex justify-between items-center'>
              <a className='font-bold'>Extra storage ($10.00)<br></br>
              <small>Per 500mb</small>
              </a>
              <div className='flex items-center'>
                <button
                  onClick={() => extraStorageSub("minus", 10)}
                  className='border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue'
                >
                  <AiOutlineMinus size={15} />
                </button>
                <a className='font-bold text-[20px] mx-2'><small>{(extraStorage / 1024).toFixed(2)} GB</small></a>
                <button
                  onClick={() => extraStorageSub("add", 10)}
                  className='border-native-blue border-2 rounded-[3px] p-1 bg-white text-native-blue'
                >
                  <AiOutlinePlus size={15} />
                </button>
              </div>
              {/* <Switch size="md" /> */}
            </div>
          </>
        )}
      </div>
      <hr className='mt-3'></hr>
      <div className='mt-3 flex items-center justify-center flex-column'>
        <a className='font-bold d-none'>Choose Payment Method</a>
        <div className='flex flex-column items-center'>
          <button
          disabled = {newloading}
            onClick={() => {
              payOnline();
            }}
            className='bg-[#000AFF] border-[#000AFF] border-2 text-white text-[20px]  px-4 py-2 font-bold mt-0 h-100 w-100 rounded-[10px]'
          >
            {!newloading ? "Pay online" : "Processing..."}
          </button>
        </div>
      </div>
    </div>
  );
}

export function AddMilestones({ closeTabFunction, project_milestones }) {
  const { userProfile } = useContext(AuthContext);
  const { projectDetailes, getProjectDetailes } =
  React.useContext(ProjectsContext);
  const { id } = useParams();
  const [cookies] = useCookies();
  const { setTostMessage } = useContext(TostMessageContext);
  const [loading, setLoading] = useState(false);
  const [import_file, setImport] = useState(false);
  const [updateMilestonesView, setUpdateMilestones] = useState(false);
  const [files, setFiles] = React.useState([]);
  const [end_dates, setEndDates] = React.useState([]);
  const [selectAll, setSelectAll] = useState(false); // State to handle the select all checkbox
  const [deletingMilestones, setDeletingMilestones] = useState(false);
  

  const [milestones, setMilestones] = useState([
    {
      milestone_name: "",
      duration: 1,
      metric: "Select metric",
      description: "",
      milestone_metric_target: 1,
      milestone_metric_system: "Select metric",
      start_date: "",
      end_date: "",
      dependencies: "",
      milestone_cost: 0,
      checked:false
    },
  ]);

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setMilestones(milestones.map(milestone => ({ ...milestone, checked })));
  };

  const handleCheckboxChange = (index) => {
     const newMilestones = [...milestones];
     newMilestones[index].checked = !newMilestones[index].checked;
     setMilestones(newMilestones);
     setSelectAll(newMilestones.every(milestone => milestone.checked));
  };
  async function handleDeleteAll() {
    setLoading(true);
    setDeletingMilestones(true);

    const formData = new FormData();
    formData.append('project_id',id);

    //fetching all milestone id to be deleted
    const checkedMilestones = milestones.filter(m => m.checked).map(m => m.milestone_id);
    checkedMilestones.forEach(id => {
      formData.append('to_be_deleted[]', id);
    });
    
    const config = {
      headers: {
        access: cookies.urbexEnterpriseUserToken,
        'Content-Type': 'multipart/form-data'
      }
    };
    
    try {
      const response = await axios.post(delete_bulk_milestones,formData, config);
      setLoading(false);
      if (response?.data?.status) {
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        setMilestones(milestones.filter(milestone => !milestone.checked));
        getProjectDetailes();
        closeTabFunction();
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    setDeletingMilestones(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setDeletingMilestones(false);
    }
  }
  

  const isAnySelected = milestones.some(milestone => milestone.checked);

  function arrayToString(arr) {
    return arr.join(",");
  }

  const editableMilestones = project_milestones?.map((item, index) => {
    const [number, unit] = item.duration.split(" ");
    return {
      milestone_name: item.milestone,
      milestone_id: item.milestone_id,
      duration: parseInt(number, 10),
      metric: unit,
      description: "",
      milestone_metric_target: item.milestone_metric_target,
      milestone_metric_system: item.milestone_metric_system,
      start_date: item.start,
      end_date: item.milestone_end_date,
      dependencies: arrayToString(item.dependencies),
      milestone_cost: item.milestone_cost,
    };
  });

  const handelChanges = (index, property, value) => {
    // console.log('Handling changes:', index, property, value);

    setMilestones((prevMilestones) => {
      if (property === "delete") {
        return prevMilestones.filter((_, i) => i !== index);
      }

      const updatedMilestones = prevMilestones.map((milestone, i) => {
        if (i === index) {
          return {
            ...milestone,
            [property]: value,
          };
        }
        return milestone;
      });

      return updatedMilestones;
    });
  };

  async function handelSubmit() {
    setLoading(true);
    const formData = new FormData();

    formData.append("project_id", projectDetailes?.data.project_id);
    formData.append("account_id", userProfile?.account_id);
    formData.append("sub_account_id", userProfile?.sub_account_id);
    formData.append("milestone_file", files[0]);

    try {
      const response = await axios.post(
        upload_milestone_csv_endpoint,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      if (response.data.status) {
        // console.log(response.data);
        setLoading(false);
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        closeTabFunction();
        getProjectDetailes();
      } else {
        setLoading(false);
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setLoading(false);
      setTostMessage({
        messageType: "error",
        message: error,
      });
      console.log(error);
    }
  }

  async function AddMilestonesFunction() {
    let error = false;
    milestones.forEach((milestone, i) => {
      if (!milestone.milestone_metric_system || milestone.milestone_metric_system === "" || milestone.milestone_metric_system === "Select metric") {
        error = true;
        return setTostMessage({
          messageType: "error",
          message: `Select milestone metric system in form ${i + 1}`,
        });
      }
      if (!milestone.milestone_name || milestone.milestone_name === "") {
        error = true;
        return setTostMessage({
          messageType: "error",
          message: `Fill milestone name in form ${i + 1}`,
        });
      }
      if (!milestone.duration || milestone.duration === "") {
        error = true;
        return setTostMessage({
          messageType: "error",
          message: `Fill duration in form ${i + 1}`,
        });
      }
      if (
        !milestone.metric ||
        milestone.metric === "" ||
        milestone.metric == "Select metric"
      ) {
        error = true;
        return setTostMessage({
          messageType: "error",
          message: `Fill metric in form ${i + 1}`,
        });
      }
      
    });

    if (error) {
      return;
    }

    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
        milestones,
      },
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: add_milestones_end_point,
      headers: {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setLoading(true);
    // return console.log(config);
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          getProjectDetailes();
          setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
          setMilestones([]);
          closeTabFunction();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  

  return (
    <>
      {updateMilestonesView ? (
        <div className='flex flex-col px-2'>
          <div className="flex space-between gap-x-4">
          <button
            className='px-1 py-1 bg-transparent border text-native-blue rounded-full w-50 mb-3'
            onClick={() => {
              setUpdateMilestones(false);
              setMilestones([
                {
                  milestone_name: "",
                  duration: 1,
                  metric: "Select metric",
                  description: "",
                  milestone_metric_target: 1,
                  milestone_metric_system: "Select Metric",
                  start_date: "",
                  end_date: "",
                  dependencies: "",
                  milestone_cost: 0,
                  checked:false
                },
              ]);
            }}
          >
            Go back
          </button>

          <button
            className={`px-1 py-1  border rounded-full w-50 mb-3 ${isAnySelected ? 'text-white bg-danger' : 'text-gray-500 bg-gray-300 bg-transparent'}`}
            disabled={!isAnySelected}
            onClick={deletingMilestones ? null : handleDeleteAll}
          >
            {deletingMilestones ? 'Deleting...' : 'Delete All'}
          </button>
          </div>
          
          <div className='mb-2'>
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              style={{marginBottom:5}}
            /> Select All
          </div>
          
          {milestones?.map((milestone, i) => {
            return (
              <>
                <b className='text-[13px] mb-2'>
                <input
                  type="checkbox"
                  style={{marginRight:5}}
                  checked={milestone.checked}
                  onChange={() => handleCheckboxChange(i)}
                />
                  Activity ID #{milestone.milestone_id} (
                  <a className='text-[#333]'>
                    Use this ID for linking dependencies
                  </a>
                  )
                </b>
                <AddMilestonesInputs
                  end_dates={end_dates}
                  setEndDates={setEndDates}
                  setTostMessage={setTostMessage}
                  index={i}
                  key={i}
                  milestones={milestone}
                  handelChanges={handelChanges}
                />
              </>
            );
          })}
          <button
            className='bg-emerald-500 p-10 py-3 text-white rounded-full'
            onClick={() => AddMilestonesFunction()}
            disabled={loading}
          >
            {loading
              ? "Loading..."
              : `Save ${milestones.length} Milestones Changes`}
          </button>
        </div>
      ) : (
        <>
          {import_file ? (
            <div className='flex flex-column align-end items-end justify-end'>
              <div className='flex w-full justify-between'>
                <button
                  className='px-8 py-1 bg-native-blue text-white rounded-full mb-6'
                  onClick={() => {
                    setImport(false);
                  }}
                >
                  Enter Manually
                </button>
                <button
                  className='px-1 py-1 bg-transparent border text-native-blue rounded-full w-50 mb-6'
                  onClick={() => {
                    setUpdateMilestones(true);
                    setMilestones(editableMilestones);
                  }}
                >
                  Edit Milestones
                </button>
              </div>
              <Box>
                <div className='grid grid-cols-1'>
                  <div className='p-3 rounded-xl bg-gray-50 transition-all ease-in-out my-3 flex items-start gap-4'>
                    <div className=''>
                      <div>
                        <h5 className='text-native-blue'>NOTE:</h5>
                        <p className='text-sm text-gray-500'>
                          You can import project activities as a CSV file to
                          ease up the data entry process. The CSV file should be
                          in the following format:
                        </p>
                        <h5 className='text-native-blue'>STEPS</h5>
                        <Link
                          to={`https://youtu.be/PGOWzwT05sE?si=1ZxfR6EbJRhMA0X0`}
                          target='_blank'
                        >
                          <p className='text-sm text-[#000aff] underline decoration-solid'>
                            1. Hide all summary tasks in Microsoft projects
                            [WATCH VIDEO]
                          </p>
                        </Link>
                        <Link
                          to={`https://youtu.be/1mdh5xDHE1k?si=Hi36S7VUlMQdOEtM`}
                          target='_blank'
                        >
                          <p className='text-sm text-[#000aff] underline decoration-solid'>
                            2. Export to CSV or Excel from Microsoft projects
                            [WATCH VIDEO]
                          </p>
                        </Link>
                        <p className='text-sm text-[#000aff] decoration-solid'>
                          3. Re-arrange table columns to match format below
                        </p>
                      </div>
                    </div>
                  </div>
                  <center>
                    <img
                      src={csv_image}
                      className='w-[80%] flex self-center h-auto'
                    />
                  </center>
                  <div className='upload-file-container my-3'>
                    <MultipleFileUploadComponent
                      files={files}
                      setFiles={setFiles}
                      supportedFiles={"CSV Files only"}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </div>
                  <button
                    onClick={() => handelSubmit()}
                    className='bg-[#32a852] p-10 py-3 text-white mx-auto rounded-full w-[40%]'
                  >
                    {loading ? "Uploading..." : "Import Activities"}
                  </button>
                </div>
              </Box>
            </div>
          ) : (
            <div className='flex flex-column align-end items-end justify-end'>
              <div className='flex w-full justify-between'>
                <button
                  className='px-8 py-1 bg-native-blue text-white rounded-full mb-6'
                  onClick={() => {
                    setImport(true);
                  }}
                >
                  Import CSV file
                </button>
                <button
                  className='px-1 py-1 bg-transparent border text-native-blue rounded-full w-50 mb-6'
                  onClick={() => {
                    setUpdateMilestones(true);
                    setMilestones(editableMilestones);
                  }}
                >
                  Edit Milestones
                </button>
              </div>
              {milestones.length === 0 ? null : (
                <>
                  <Box>
                    <div className='grid grid-cols-1'>
                      {projectDetailes?.data?.project_milestones_set == "0"
                        ? projectDetailes?.data?.project_milestones_template?.map(
                            (item, i) => {
                              return (
                                <div className='flex flex-column'>
                                  <b className='text-[13px]'>
                                    Activity ID #{i} (
                                    <a className='text-[#333]'>
                                      Use this ID for linking dependencies
                                    </a>
                                    )
                                  </b>
                                  <AddMilestonesInputs
                                    end_dates={end_dates}
                                    setEndDates={setEndDates}
                                    setTostMessage={setTostMessage}
                                    index={i}
                                    key={i}
                                    milestones={item}
                                    handelChanges={handelChanges}
                                  />
                                </div>
                              );
                            }
                          )
                        : null}
                      {milestones?.map((milestone, i) => {
                        return (
                          <div className='flex flex-column'>
                            <b className='text-[13px]'>
                              Activity ID #{i} (
                              <a className='text-[#333]'>
                                Use this ID for linking dependencies
                              </a>
                              )
                            </b>
                            <AddMilestonesInputs
                              end_dates={end_dates}
                              setEndDates={setEndDates}
                              setTostMessage={setTostMessage}
                              index={i}
                              key={i}
                              milestones={milestone}
                              handelChanges={handelChanges}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </Box>
                  <div className='flex items-start w-full justify-between mt-4'>
                    {milestones.length > 0 && (
                      <button
                        className='p-10 py-3 rounded-full border border-native-blue transition-all active:scale-95'
                        onClick={() => {
                          setMilestones([
                            ...milestones,
                            {
                              milestone_name: "",
                              duration: 1,
                              metric: "Select metric",
                              description: "",
                              milestone_metric_target: 1,
                              milestone_metric_system: "Select Metric",
                              start_date: "",
                              end_date: "",
                              dependencies: "",
                              milestone_cost: 0,
                            },
                          ]);
                        }}
                      >
                        <a className='font-bold'>Add New Field</a>
                      </button>
                    )}
                    <button
                      className='bg-[#32a852] p-10 py-3 text-white rounded-full'
                      onClick={() => AddMilestonesFunction()}
                      disabled={loading}
                    >
                      {loading
                        ? "Loading..."
                        : `Save ${milestones.length} Milestones`}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

function AddMilestonesInputs({
  index,
  handelChanges,
  milestones,
  end_dates,
  setEndDates,
  setTostMessage,
}) {
  const handleChange = (e) => {
    const { name, value } = e.target;

    handelChanges(index, name, value);

    if (name === "start_date") {
      const date = new Date(value);

      if (milestones.duration === "" || milestones.duration < 1) {
        setTostMessage({
          messageType: "error",
          message: "Please select a duration first",
        });
        return;
      }

      let days_to_add;
      switch (milestones.metric) {
        case "Day":
          days_to_add = milestones.duration;
          break;
        case "Week":
          days_to_add = milestones.duration * 7;
          break;
        case "Month":
          days_to_add = milestones.duration * 30;
          break;
        case "Year":
          days_to_add = milestones.duration * 365;
          break;
        default:
          days_to_add = 0;
      }

      const computedEndDate = new Date(
        date.getTime() + days_to_add * 24 * 60 * 60 * 1000
      );

      const newEndDate = `${computedEndDate.getFullYear()}-${(
        computedEndDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${computedEndDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      handelChanges(index, "end_date", newEndDate);
      setEndDates((prevEndDates) => {
        const updatedEndDates = [...prevEndDates];
        updatedEndDates[index] = {
          id: index,
          end_date: newEndDate,
        };
        return updatedEndDates;
      });
    }
  };

  const handleDelete = () => {
    handelChanges(index, "delete");
  };

  return (
    <div className='AddMilestonesInputs border-b bg-gray-100 rounded-xl p-3 m-0 flex justify-between items-center mb-3'>
      <div className='w-auto'>
        <div className='d-flex justify-content-between m-w-[100%]'>
          <Box>
            <FormLabel
              style={{ fontSize: 11, fontWeight: "bold" }}
              htmlFor='milestone_name'
              className='mt-0 font-bold'
            >
              {`Activity Name`}
            </FormLabel>
            <Input
              // ref={firstField}
              id='milestone_name'
              placeholder='Enter activity name'
              value={milestones.milestone_name}
              disabled={false}
              className='text-10 h-auto py-1 bg-white w-70'
              name='milestone_name'
              onChange={(e) => handleChange(e)}
              // {/* // placeholder="Please enter user name" */}
            />
          </Box>
          <Box>
            <FormLabel
              style={{ fontSize: 11, fontWeight: "bold" }}
              htmlFor='milestone_cost'
              className='mt-0 font-bold'
            >
              {`Cost`}
            </FormLabel>
            <Input
              // ref={firstField}
              id='cost'
              placeholder='Enter total planned cost'
              // value={""}
              disabled={false}
              type='number'
              className='text-10 h-auto py-1 bg-white'
              name='milestone_cost'
              value={milestones.milestone_cost}
              onChange={(e) => handleChange(e)}
              // {/* // placeholder="Please enter user name" */}
            />
          </Box>
        </div>
        <div className='d-flex gap-3 justify-content-between m-w-[100%]'>
          <Box>
            <FormLabel
              style={{ fontSize: 11 }}
              htmlFor='metric'
              className='mt-2'
            >
              Metric
            </FormLabel>
            <Select
              id='metric'
              className='w-auto text-10 h-auto py-1 bg-white'
              name='metric'
              onChange={(e) => handleChange(e)}
            >
              <option value=''>{milestones.metric}</option>
              <option value='Day'>Day</option>
              <option value='Week'>Week</option>
              <option value='Month'>Month</option>
              <option value='Year'>Year</option>
            </Select>
          </Box>
          <Box>
            <FormLabel
              style={{ fontSize: 11 }}
              htmlFor='duration'
              className='mt-2'
            >
              Duration
            </FormLabel>
            <Input
              id='duration'
              name='duration'
              type='number'
              autoComplete='off'
              min={1}
              className='w-100 text-10 h-auto py-1 bg-white'
              value={milestones.duration}
              onChange={(e) => handleChange(e)}
            />
          </Box>
          <Box>
            <FormLabel
              style={{ fontSize: 11 }}
              htmlFor='metric'
              className='mt-2'
            >
              Target Metric
            </FormLabel>
            <Select
              id='metric'
              className='w-auto text-10 h-auto py-1 bg-white'
              name='milestone_metric_system'
              onChange={(e) => handleChange(e)}
            >
              <option value="">{milestones.milestone_metric_system}</option>
              <option value='Units'>Units</option>
              <option value='Inches'>Inches</option>
              <option value='Metres'>Metres</option>
              <option value='Square Metres'>Square Metres</option>
              <option value='Cubic Metres'>Cubic Metres</option>
              <option value='Kilometres'>Kilometres</option>
              <option value='Percent'>Percent</option>
            </Select>
          </Box>
          <Box>
            <FormLabel
              style={{ fontSize: 11 }}
              htmlFor='duration'
              className='mt-2'
            >
              Target
            </FormLabel>
            <Input
              id='duration'
              name='milestone_metric_target'
              type='number'
              autoComplete='off'
              min={1}
              className='w-100 text-10 h-auto py-1 bg-white'
              value={milestones.milestone_metric_target}
              onChange={(e) => handleChange(e)}
            />
          </Box>
        </div>
        <div className='d-flex gap-3 justify-content-between m-w-[100%]'>
          <Box>
            <FormLabel
              style={{ fontSize: 11 }}
              htmlFor='duration'
              className='mt-2'
            >
              Start Date
            </FormLabel>
            <Input
              id='start_date'
              type={"date"}
              name='start_date'
              className='w-100 text-10 h-auto py-1 bg-white'
              value={milestones.start_date}
              onChange={(e) => handleChange(e)}
            />
          </Box>
          <Box>
            <FormLabel
              style={{ fontSize: 11 }}
              htmlFor='duration'
              className='mt-2'
            >
              End Date
            </FormLabel>
            <Input
              id='duration'
              type={"date"}
              // value={end_dates[index]?.end_date}
              value={milestones.end_date}
              name='end_date'
              className='w-100 text-10 h-auto py-1 bg-white'
              onChange={(e) => handleChange(e)}
            />
          </Box>
          <Box>
            <FormLabel
              style={{ fontSize: 11 }}
              htmlFor='duration'
              className='mt-2'
            >
              Dependencies (e.g 3,4)
            </FormLabel>
            <Input
              id='dependencies'
              placeholder='Enter dependencies'
              value={milestones.dependencies}
              name='dependencies'
              className='w-100 text-10 h-auto py-1 bg-white'
              onChange={(e) => handleChange(e)}
            />
          </Box>
        </div>
        
      </div>
      <button
        onClick={() => handleDelete()}
        className='delete-button bg-transparent  text-red rounded-full px-3 py-1 h-[50px]'
      >
        <BsTrash className='text-[#ff0000] text-[15px]' />
      </button>
    </div>
  );
}

export function AddDocuments() {
  const { id } = useParams();
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setPercentage] = useState(0);
  const { setTostMessage } = useContext(TostMessageContext);
  const { getProjectDetailes } = useContext(ProjectsContext);

  const initialSection = {
    name: "",
    confidentiality: "",
    document_category: "",
    file: null,
    to_be_shared_by: "",
  };

  const [formSections, setFormSections] = useState([initialSection]);
  const [loading, setLoading] = useState(false);

  const handleAddSection = () => {
    setFormSections([...formSections, initialSection]);
  };

  const uploadProgress = (progressEvent) => {
    var Percentage = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setPercentage(Percentage);
    console.log(progressEvent.loaded, progressEvent.total);
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedSections = [...formSections];
    updatedSections[index][fieldName] = value;
    setFormSections(updatedSections);
  };

  const handleFileChange = (index, file) => {
    const updatedSections = [...formSections];
    updatedSections[index]["file"] = file;
    setFormSections(updatedSections);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setUploading(true);
      // Create a new FormData object
      let formData = new FormData();

      // Append user information
      formData.append("account_id", userProfile?.account_id);
      formData.append("project_id", id);
      formData.append("sub_account_id", userProfile?.sub_account_id);
      // Loop through each section and append its data to formData
      formSections.forEach((item, index) => {
        formData.append("document_name[]", item.name);
        formData.append("document_category[]", item.document_category);
        formData.append("confidentiality_level[]", item.confidentiality);
        formData.append("document_file[]", item.file);
        formData.append("to_be_shared_by[]", item.to_be_shared_by);
      });

      // Post the form data
      const responseData = await axios.post(Add_documents, formData, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
        onUploadProgress: uploadProgress,
      });

      const data = await responseData.data;
      // console.log(data);
      if (data.status) {
        setTostMessage({
          messageType: "success",
          message: data.response,
        });
        getProjectDetailes();
        setUploading(false);
      } else {
        setTostMessage({
          messageType: "error",
          message: data.response,
        });
        setUploading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setUploading(false);
    }
  };

  return (
    <>
      <div>
        {formSections.map((section, index) => (
          <div
            className='AddMilestoneInputs bg-gray-100 border-b my-3 rounded-xl p-4'
            key={index}
          >
            <div className='flex justify-between w-full mb-2'>
              <div className='flex items-start flex-column w-[49%]'>
                <p className='font-bold text-xs mb-1'>Name</p>
                <input
                  type='text'
                  value={section.name}
                  className='rounded-lg border-gray-300 h-8 w-full'
                  placeholder='Enter document name'
                  onChange={(e) =>
                    handleInputChange(index, "name", e.target.value)
                  }
                />
              </div>
              <div className='flex items-start flex-column w-[49%]'>
                <p className='font-bold text-xs mb-1'>Choose Category</p>
                <select
                  className='rounded-lg h-8 py-0 border-gray-300 w-full'
                  // value={section.document_category}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "document_category",
                      e.target.value
                    )
                  }
                >
                  <option selected disabled>
                    Select Document Category
                  </option>
                  <option value='CONTRACTS'>CONTRACTS</option>
                  <option value='GENERAL'>GENERAL</option>
                  <option value='PROJECT REPORTS'>PROJECT REPORTS</option>
                  <option value='DRAWINGS'>DRAWINGS</option>
                  <option value='REGULATORY'>REGULATORY</option>
                </select>
              </div>
            </div>
            <div className='flex justify-between mb-2'>
              <div className='flex flex-column items-start w-[49%]'>
                <p className='font-bold text-xs mb-1'>To Be Shared By</p>
                <select
                  className='rounded-lg h-8 border-gray-300 py-0 w-full'
                  // value={section.document_category}
                  onChange={(e) =>
                    handleInputChange(index, "to_be_shared_by", e.target.value)
                  }
                >
                  <option selected disabled>
                    Select Team Member
                  </option>
                  {userProfile?.sub_accounts?.map((item, index) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
              <div className='flex items-start flex-column w-[49%]'>
                <p className='font-bold text-xs mb-1'>Confidential</p>
                <select
                  // value={section.confidentiality}
                  onChange={(e) =>
                    handleInputChange(index, "confidentiality", e.target.value)
                  }
                  className='rounded-lg w-full border-gray-300 h-8 py-0'
                >
                  <option selected disabled>
                    Select
                  </option>
                  <option value='YES'>YES</option>
                  <option value='NO'>NO</option>
                </select>
              </div>
            </div>
            <div className='flex flex-column justify-center items-start mb-2'>
              <p className='font-bold text-xs mb-1'>
                {formSections[index].file
                  ? "Document Selected"
                  : "Select Document"}
              </p>

              <div className='flex items-center w-full'>
                <label className='cursor-pointer w-full h-8 border bg-white border-gray-300 rounded-md py-2 px-4 inline-flex items-center'>
                  <span className='mr-2'>{formSections[index].file ? 'Selected' : 'Pick File'}</span>
                  <input
                    type='file'
                    className='hidden'
                    onChange={(e) => handleFileChange(index, e.target.files[0])}
                  />
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>{" "}
      {uploading && <AnimatedProgressBar animated now={uploadPercentage} />}
      <div className='flex items-start w-full justify-between mt-4'>
        <button
          className='text-sm sm:!text-md px-3 py-2 sm:!px-10 sm:!py-3 rounded-full border border-native-blue transition-all active:scale-95'
          onClick={handleAddSection}
        >
          Add Section
        </button>{" "}
        <button
          className='bg-[#32a852] text-sm sm:!text-md px-3 py-2 sm:!px-10 sm:!py-3 text-white rounded-full'
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Saving Document(s)..." : `Save Document(s)`}
        </button>
      </div>
    </>
  );
}

export function WriteProjectReport({ project, closeTabFunction }) {
  const [newReport, setNewReport] = useState(true);
  const [weekNo, setWeekNo] = useState(0);
  const [weekStartDate, setWeekStartDate] = useState(new Date());
  const [weekEndDate, setWeekEndDate] = useState(new Date());
  const [weekStartDateRaw, setWeekStartDateRaw] = useState("");
  const [weekEndDateRaw, setWeekEndDateRaw] = useState("");
  const [dayInputs, setDayInputs] = useState(Array(7).fill(""));
  const { id } = useParams();
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState(null);
  const [report, setReport] = useState(null);
  const [approvingReport, setApproving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setPercentage] = useState(0);
  const [attachedFiles, setAttachedFiles] = useState([]);

  const { setTostMessage } = useContext(TostMessageContext);

  const handleWeekStartDateChangeOnce = (e) => {
    var d = new Date();
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    console.log(weekNo);
    setWeekNo(weekNo)
    const weekYear = d.getFullYear() + '-W' + weekNo;
    const [y, w] = weekYear.split('-');

    let date = new Date(y, 0, (1 + (w.replace('W','') - 1) * 7)); // Elle's method
    date.setDate(date.getDate() + (0 - date.getDay())); // 0 - Sunday, 1 - Monday etc

    const week_start_string = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    // setDisplayWeekStartDate(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());
    const selectedDate = new Date(week_start_string).addHours(4);
    setWeekStartDate(selectedDate);
    setWeekStartDateRaw(week_start_string);
    const endDate = new Date(week_start_string).addHours(4);
    endDate.setDate(selectedDate.getDate() + 6);
    setWeekEndDate(endDate);
    setWeekEndDateRaw(endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate());
  };

  function getDayName(dateStr, locale) {
      var date = new Date(dateStr);
      return date.toLocaleDateString(locale, { weekday: 'long' });        
  }

  Date.prototype.addHours= function(h){
      this.setHours(this.getHours()+h);
      return this;
  }

  const handleWeekStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value).addHours(4);
    var dateStr = selectedDate;
    var day = getDayName(dateStr, "en-US"); // Gives back 'Sunday'
    if (day !== "Sunday") {
      return setTostMessage({
        messageType: 'error',
        message: 'Week start date must be a Sunday'
      })
    }
    var d = new Date(e.target.value);
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    console.log(weekNo + 1);
    setWeekNo(weekNo + 1)

    setWeekStartDate(selectedDate);
    const endDate = new Date(selectedDate).addHours(4);
    endDate.setDate(selectedDate.getDate() + 6);
    setWeekEndDate(endDate);
    // var result = getWeekNumber(selectedDate);
    // setWeekNo(result[1]);
  };

  useEffect(() => {
    handleWeekStartDateChangeOnce();
  }, []);

  const getDayLabel = (dayIndex) => {
    const dayDate = new Date(weekStartDate);
    dayDate.setDate(weekStartDate.getDate() + dayIndex);
    return `${dayName(dayDate.getDay())} (${
      dayDate.toISOString().split("T")[0]
    })`;
  };

  const dayName = (dayIndex) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[dayIndex];
  };

  const handleDayInputChange = (index, value) => {
    const newDayInputs = [...dayInputs];
    newDayInputs[index] = value;
    setDayInputs(newDayInputs);
  };

  const getWeeklyReport = async () => {
    try {
      const responseData = await axios.post(
        get_weekly_report,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            project_id: id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );

      const { response } = responseData.data; // Assuming response structure
      setReports(response); // Update reports state with fetched data
      //console.log(response); // Log fetched reports
    } catch (error) {
      console.error('Error fetching weekly reports:', error);
    }
  };
  useEffect(() => {
    getWeeklyReport();
    // var result = getWeekNumber(new Date());
    // setWeekNo(result[1]);
  }, [report]);

  const approveReport = async () => {
    try {
      setApproving(true);
      const response = await axios.post(
        approve_report,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            project_id: id,
            week_no: Number(report?.week_no)
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      if (response.data.status) {
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        getWeeklyReport();
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
      setApproving(false);
    } catch (error) {
      setApproving(false);
    }
  }

  const handleSubmit = async () => {
    var dateStr = weekStartDate;
    var day = getDayName(dateStr, "en-US"); // Gives back 'Sunday'
    if (day !== "Sunday") {
      return setTostMessage({
        messageType: 'error',
        message: 'Week start date must be a Sunday'
      })
    }

    console.log(attachedFiles);
    // return;
    try {
      setLoading(true);
      const response = await axios.post(
        weekly_report,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            project_id: id,
            week_no: Number(weekNo),
            start_date: weekStartDate.toISOString().split("T")[0],
            end_date: weekEndDate.toISOString().split("T")[0],
            reports: dayInputs
              .map((input, index) => {
                const dayDate = new Date(weekStartDate);
                dayDate.setDate(weekStartDate.getDate() + index);
                const day = dayName(dayDate?.getDay());
                const date = dayDate?.toISOString().split("T")[0];
                return (
                  day &&
                  date &&
                  input && {
                    day,
                    date,
                    activities: input,
                  }
                );
              })
              .filter(Boolean),
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      if (response.data.status) {
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        if (attachedFiles.length > 0) {
          setUploading(true);
          let formData = new FormData();
          formData.append("account_id", userProfile?.account_id);
          formData.append("project_id", id);
          formData.append("sub_account_id", userProfile?.sub_account_id);
          formData.append("week_no", Number(weekNo));
          attachedFiles.forEach((item, index) => {
            console.log(item)
            formData.append('attachments[]', item)
          })
          formData.append('document_number', response.data.doc_number)
          
          
          const responseData = await axios.post(upload_weekly_report_file, formData, {
            headers: {
              access: cookies.urbexEnterpriseUserToken,
            },
            onUploadProgress: uploadProgress,
          });
      
          const data = await responseData.data;
          if (data.status) {
            setTostMessage({
              messageType: "success",
              message: data.response,
            });
            setUploading(false);
          } else {
            setTostMessage({
              messageType: "error",
              message: data.response,
            });
            setUploading(false);
          }
        }
        getWeeklyReport();
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const uploadProgress = (progressEvent) => {
    var Percentage = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setPercentage(Percentage);
    console.log(progressEvent.loaded, progressEvent.total);
  };

  const handleFileChange = (file) => {
    const chosenFiles = Array.prototype.slice.call(file)
    const uploaded = [...attachedFiles];
    chosenFiles.some((file) => {
      console.log(file)
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    })
    setAttachedFiles(uploaded)
    // console.log(attachedFiles)
  };

  function getWeekNumber(d) {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
      // Return array of year and week number
      return [d.getUTCFullYear(), weekNo];
  }
  return (
    <>
      <div className='grid grid-cols-2'>
        <button
          onClick={() => {
            setNewReport(true);
            setReport(null);
          }}
          className={`bg-gray-100 w-full py-2 rounded-tl-full text-xs sm:text-sm font-semibold rounded-bl-full mt-3 ${
            newReport && "bg-native-blue text-white"
          }`}
        >
          Submit New Report
        </button>
        <button
          onClick={() => {
            getWeeklyReport();
            setNewReport(false);
          }}
          className={`bg-gray-100 w-full py-2 mt-3 text-xs sm:text-sm font-semibold rounded-tr-full rounded-br-full ${
            !newReport && "bg-native-blue text-white"
          }`}
        >
          View Previous Reports
        </button>
      </div>
      {newReport ? (
        <div>
          <div className='px-0 grid grid-cols-2 justify-between space-x-4 mt-4 p-3 pb-7 border-b'>
            {/* <div className='flex flex-col'>
              <label className='text-sm font-semibold'>Week {weekNo}</label>
              <input
                type='number'
                className='p-2 border rounded'
                placeholder='Week No.'
                value={weekNo}
                onChange={(e) => setWeekNo(e.target.value)}
              />
            </div> */}
            <div className='flex flex-col'>
              <label className='text-sm font-semibold'>Week {weekNo} Start Date:</label>
              <input
                type='date'
                value={weekStartDate.toISOString().split("T")[0]}
                onChange={handleWeekStartDateChange}
                className='p-2 border rounded'
              />
            </div>

            <div className='flex flex-col'>
              <label className='text-sm font-semibold'>Week End Date:</label>
              <input
                type='date'
                value={weekEndDate.toISOString().split("T")[0]}
                readOnly
                className='p-2 border rounded'
              />
            </div>
          </div>
          <div className='py-3 grid grid-cols-1 gap-4'>
            {[...Array(7)].map((_, index) => (
              <div className='flex flex-col mb-1' key={index}>
                <label className='text-[1rem] sm:text-lg font-semibold'>
                  {getDayLabel(index)}: 
                </label>
                <textarea
                  type='text'
                  rows={4}
                  className='p-2 border rounded'
                  placeholder='Add report'
                  value={dayInputs[index]}
                  onChange={(e) => handleDayInputChange(index, e.target.value)}
                />
              </div>
            ))}
          </div>
          <div className='p-3 px-0 grid grid-cols-1 gap-4'>
            <div className='flex flex-col mb-1'>
                <label className='text-sm sm:text-lg font-semibold'>
                    ADD ATTACHMENTS <label className="text-[#ff0000]">(.XLSX or IMAGE FILES ONLY)</label>
                </label>
                <div className='flex items-center w-full'>
                  <label className='cursor-pointer w-full h-10 border bg-white border-gray-300 rounded-md py-2 px-4 inline-flex items-center'>
                    <span className='mr-2'>
                      {attachedFiles.length > 0 ? attachedFiles?.length + ' attachments selected' : 'Select attachments'} 
                    </span>
                    <input
                      type='file'
                      accept="application/vnd.ms-excel,image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      className='hidden'
                      multiple={true}
                      onChange={(e) =>
                        handleFileChange(e.target.files)
                      }
                    />
                  </label>
                </div>
                {uploading && (
                  <AnimatedProgressBar
                    className="mt-2"
                    animated
                    now={uploadPercentage}
                  />
                )}
            </div>
          </div>
        </div>
      ) : (
        <>
          {report ? (
            <>
              <div className="w-full pt-2 flex flex-row justify-between items-center">
                <button onClick={() => setReport(null)}>
                  <IoMdArrowRoundBack
                    className='p-2 rounded-full bg-gray-100'
                    size={44}
                  />
                </button>
                <label className="text-sm font-semibold hidden sm:!block">Last Edited: <label className="text-[#000aff]">{report?.last_edited_by} ({report?.last_edited_time})</label></label>
                <label className="text-sm font-semibold block sm:!hidden flex flex-row items-center gap-x-2"><GrDocumentTime /><label className="text-[#000aff]">{report?.last_edited_by}</label></label>
                {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                  "approve_reports"
                ) ||
                userProfile?.sub_account_overview?.allowed_permissions?.includes(
                  "full_access"
                ) ? (
                  <>
                    {report?.revision_approved ? (
                      <button className='bg-[#bbbbbb] px-2 sm:px-3 h-auto md:h-12 text-white rounded-full' onClick={() => setTostMessage({messageType: 'warning', message: 'This revision is already approved'})}>
                        {approvingReport ? <b className="text-[#000000] text-xs sm:text-[1rem]">Approving Revision...</b> : <b className="text-[#000000] text-xs sm:text-[1rem]">Approve ({report?.revision_number})</b>}
                      </button>
                    ) : (
                      <button className='bg-[#00b47a] px-2 sm:px-3 h-auto md:h-12 text-white rounded-full' onClick={() => approveReport()}>
                        {approvingReport ? <b className="text-white text-xs sm:text-[1rem">Approving Revision...</b> : <b className="text-white text-xs sm:text-[1rem]">Approve ({report?.revision_number})</b>}
                      </button>
                    )}
                  </>
                ) : (
                  <button className='p-2 bg-[#bbbbbb] px-2 sm:px-3 h-auto md:h-12 text-white rounded-full' onClick={() => setTostMessage({messageType: 'warning', message: 'You are not allowed to carry out this action'})}>
                    <b className="text-[#000000] text-xs sm:text-[1rem">{report?.revision_approved ? 'Approved' : 'Not Approved'}</b>
                  </button>
                )}
              </div>
              <div>{report && <DetailReport getWeeklyReport={getWeeklyReport} setReport={setReport} setTostMessage={setTostMessage} initialData={report} />}</div>
            </>
          ) : (
            <div>
              {reports?.map((item, i) => (
                <div
                  className='bg-gray-100 w-full p-2 rounded-xl my-6'
                >
                  <div className='flex items-center justify-between px-2'>
                    <div className="flex flex-column">
                      <p className='m-0 mb-0 font-bold text-[14px] sm:text-[16px]'>Week: {item.week_no} (Rev. {item.revision_number})</p>
                      {item.revision_approved ? (
                        <p className='text-[#00b47a] text-[12px]'>{item.revision_approved ? 'Approved' : 'Not Approved'}</p>
                      ) : (
                        <p className='text-[#ffa53d] text-[12px]'>{item.revision_approved ? 'Approved' : 'Not Approved'}</p>
                      )}
                    </div>
                    <div className='hidden sm:flex flex-row gap-x-1 sm:gap-x-3'>
                      <p className='m-0 text-[12px] sm:text-[14px] text-[#000aff]'>
                        <a className="font-bold text-black">Doc. No:</a> {item.doc_number}
                      </p>
                      <p className='m-0 text-[12px] sm:text-[14px] text-[#000aff]'>
                        <a className="font-bold text-black">From:</a> {item.start_date}
                      </p>
                      <p className='m-0 text-[12px] sm:text-[14px] text-[#000aff]'>
                        <a className="font-bold text-black">To:</a> {item.end_date}
                      </p>
                    </div>
                    <div className="flex-row">
                      <button
                        onClick={() => setReport(item)}
                        className='p-2 sm:p-2 bg-none sm:bg-[#ebebeb] mx-1 px-2 sm:px-3 text-center text-white rounded-full'
                      >
                        <MdEditNote color="black" size={20} />
                      </button>
                      <button
                        onClick={() => {
                          window.open(
                            `https://api.urbex.africa/mgt-enterprise/reports/weekly_report.php?account_id=${cookies.urbexEnterpriseUserToken}&doc_reference=${item.doc_number}&project_id=${id}&revision_no=${item.revision_number}&action=view`,
                            "_blank"
                          );
                        }}
                        className='p-2 sm:p-2 bg-native-blue mx-1 px-2 sm:px-2 text-center text-white rounded-full'
                      >
                        <TbEyeShare color="white" size={20} />
                      </button>
                      <button
                        onClick={() => {
                          window.open(
                            `https://api.urbex.africa/mgt-enterprise/reports/weekly_report.php?account_id=${cookies.urbexEnterpriseUserToken}&doc_reference=${item.doc_number}&project_id=${id}&revision_no=${item.revision_number}&action=download`,
                            "_blank"
                          );
                        }}
                        className='p-2 sm:p-2 bg-[#000aff] mx-0 sm:mx-1 px-2 sm:px-2 text-center text-white rounded-full'
                      >
                        <LuDownload color="white" size={20} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {newReport && (
        <button
          onClick={handleSubmit}
          className='p-2 bg-native-blue h-12 w-auto text-white rounded-full font-bold'
        >
          {loading ? "Saving..." : "Save Report"}
        </button>
      )}
    </>
  );
}

const DetailReport = ({ initialData, setTostMessage, setReport, getWeeklyReport }) => {
  const [weekStartDate, setWeekStartDate] = useState(new Date());
  const [weekEndDate, setWeekEndDate] = useState(new Date());
  const [dayTextInputs, setDayTextInputs] = useState(Array(7).fill(""));
  const [loading, setLoading] = useState(false);
  const [weekNo, setWeekNo] = useState(initialData?.week_no);
  const { id } = useParams();
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setPercentage] = useState(0);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [viewingFile, setViewingFile] = useState(false);
  const [viewingFileUrl, setViewingFileUrl] = useState('');
  const [viewingFileType, setViewingFileType] = useState('');
  // const {setTostMessage} = useState(TostMessageContext)

  useEffect(() => {
    // Set initial values from the provided data
    if (initialData) {
      console.log(initialData)
      setWeekStartDate(new Date(initialData.start_date));
      setWeekEndDate(new Date(initialData.end_date));

      const initialTextInputs = Array(7).fill("");

      initialData.reports.forEach((report) => {
        const dayIndex = getDayIndex(report.day); // Adjust index to start from 0
        if (dayIndex >= 0 && dayIndex < 7) { // Ensure the index is within range
          initialTextInputs[dayIndex] = report.activities;
        }
      });

      setDayTextInputs(initialTextInputs);
    }
  }, [initialData]);

  function getDayName(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: 'long' });        
}

  Date.prototype.addHours= function(h){
    this.setHours(this.getHours()+h);
    return this;
  }

  const handleWeekStartDateChange = (e) => {
  const selectedDate = new Date(e.target.value).addHours(4);
  var dateStr = selectedDate;
  var day = getDayName(dateStr, "en-US"); // Gives back 'Sunday'
  if (day !== "Sunday") {
    return setTostMessage({
      messageType: 'error',
      message: 'Week start date must be a Sunday'
    })
  }
  var d = new Date(e.target.value);
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  console.log(weekNo + 1);
  setWeekNo(weekNo + 1)

  setWeekStartDate(selectedDate);
  const endDate = new Date(selectedDate).addHours(4);
  endDate.setDate(selectedDate.getDate() + 6);
  setWeekEndDate(endDate);
  // var result = getWeekNumber(selectedDate);
  // setWeekNo(result[1]);
  };

  const handleDayTextInputChange = (index, value) => {
    const newInputs = [...dayTextInputs];
    newInputs[index] = value;
    setDayTextInputs(newInputs);
  };

  const handleSubmit = async () => {
    var dateStr = weekStartDate;
    var day = getDayName(dateStr, "en-US"); // Gives back 'Sunday'
    if (day !== "Sunday") {
      return setTostMessage({
        messageType: 'error',
        message: 'Week start date must be a Sunday'
      })
    }

    try {
      setLoading(true);

      const response = await axios.post(
        weekly_report,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            project_id: id,
            week_no: Number(weekNo),
            start_date: weekStartDate.toISOString().split("T")[0],
            end_date: weekEndDate.toISOString().split("T")[0],
            reports: dayTextInputs
              .map((input, index) => {
                const dayDate = new Date(weekStartDate);
                dayDate.setDate(weekStartDate.getDate() + index);
                const day = dayName(dayDate.getDay());
                const date = dayDate.toISOString().split("T")[0];
                return (
                  day &&
                  date &&
                  input && {
                    day,
                    date,
                    activities: input,
                  }
                );
              })
              .filter(Boolean),
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );

      // console.log({ data: response.data });
      if (response.data.status) {
        setTostMessage({
          messageType: 'success',
          message: response.data.response
        })
        if (attachedFiles.length > 0) {
          setUploading(true);
          let formData = new FormData();
          formData.append("account_id", userProfile?.account_id);
          formData.append("project_id", id);
          formData.append("sub_account_id", userProfile?.sub_account_id);
          formData.append("week_no", Number(weekNo));
          attachedFiles.forEach((item, index) => {
            console.log(item)
            formData.append('attachments[]', item)
          })
          formData.append('document_number', response.data.doc_number)
          
          
          const responseData = await axios.post(upload_weekly_report_file, formData, {
            headers: {
              access: cookies.urbexEnterpriseUserToken,
            },
            onUploadProgress: uploadProgress,
          });
      
          const data = await responseData.data;
          if (data.status) {
            setTostMessage({
              messageType: "success",
              message: data.response,
            });
            setUploading(false);
          } else {
            setTostMessage({
              messageType: "error",
              message: data.response,
            });
            setUploading(false);
          }
        }
        getWeeklyReport();
        setReport(null);
      } else {
        setTostMessage({
          messageType: 'error',
          message: response.data.response
        })
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getDayLabel = (dayIndex) => {
    const dayDate = new Date(weekStartDate);
    dayDate.setDate(weekStartDate.getDate() + dayIndex);
    return `${dayName(dayDate.getDay())} (${
      dayDate.toISOString().split("T")[0]
    })`;
  };

  const dayName = (dayIndex) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[dayIndex];
  };

  const getDayIndex = (dayName) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days.indexOf(dayName);
  };

  const uploadProgress = (progressEvent) => {
    var Percentage = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setPercentage(Percentage);
    console.log(progressEvent.loaded, progressEvent.total);
  };

  const handleFileChange = (file) => {
    const chosenFiles = Array.prototype.slice.call(file)
    const uploaded = [...attachedFiles];
    chosenFiles.some((file) => {
      console.log(file)
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    })
    setAttachedFiles(uploaded)
    console.log(attachedFiles)
  };

  const viewFile = (fileUrl, fileType) => {
    setViewingFile(true);
    setViewingFileUrl(fileUrl);
    setViewingFileType(fileType);
  }

  return (
    <div className='px-0 flex flex-col space-y-4 pt-2'>
      {viewingFile && (
        <ViewFile 
          filePath={viewingFileUrl}
          fileType={viewingFileType}
          setViewingFile={setViewingFile}
        />
      )}
      <div className='grid grid-cols-2 space-x-4 py-3 pb-7 border-b'>
        {/* <div className='flex flex-col'>
          <label className='text-sm font-semibold'>Week:</label>
          <input
            type='number'
            className='p-2 border rounded'
            placeholder='Week No.'
            value={weekNo}
            onChange={(e) => setWeekNo(e.target.value)}
          />
        </div> */}
        <div className='flex flex-col'>
          <label className='text-sm font-semibold'>Week {weekNo} Start Date:</label>
          <input
            type='date'
            value={weekStartDate.toISOString().split("T")[0]}
            onChange={handleWeekStartDateChange}
            className='p-2 border rounded'
          />
        </div>

        <div className='flex flex-col'>
          <label className='text-sm font-semibold'>End Date:</label>
          <input
            type='date'
            value={weekEndDate.toISOString().split("T")[0]}
            readOnly
            className='p-2 border rounded'
          />
        </div>
      </div>

      <div className='grid grid-cols-1 gap-4 px-0'>
        {dayTextInputs.map((input, index) => (
          <React.Fragment key={index}>
            <label className='text-[1rem] sm:text-lg font-semibold'>
              {getDayLabel(index)}:
            </label>
            <textarea
              type='text'
              value={input}
              placeholder={'Enter report here...'}
              rows={3}
              onChange={(e) => handleDayTextInputChange(index, e.target.value)}
              className='p-2 border rounded'
            />
          </React.Fragment>
        ))}
      </div>

      <div className='p-3 px-0 grid grid-cols-1 gap-4'>
        <div className='flex flex-col mb-1'>
            <label className='text-lg font-semibold'>
                ADD ATTACHMENTS <label className="text-[#ff0000]">(.XLSX or IMAGE FILES ONLY)</label>
            </label>
            <div className='flex items-center w-full'>
              <label className='cursor-pointer w-full h-10 border bg-white border-gray-300 rounded-md py-2 px-4 inline-flex items-center'>
                <span className='mr-2'>
                  {attachedFiles.length > 0 ? attachedFiles?.length + ' attachments selected' : 'Select attachments'} 
                </span>
                <input
                  type='file'
                  accept="application/vnd.ms-excel,image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  className='hidden'
                  multiple={true}
                  onChange={(e) =>
                    handleFileChange(e.target.files)
                  }
                />
              </label>
            </div>
            {uploading && (
              <AnimatedProgressBar
                className="mt-2"
                animated
                now={uploadPercentage}
              />
            )}
        </div>
      </div>

      <button
        onClick={handleSubmit}
        className='font-bold p-2 bg-native-blue mt-3 px-10 h-12 w-28 text-white rounded-full'
      >
        {loading ? "Saving..." : "Save Edit"}
      </button>
      <hr/>
      <Collapsible openedClassName="w-full bg-white py-2 px-3 text-native-blue font-bold" className="w-full bg-white py-2 px-3 text-native-blue font-bold" trigger={
        <div className="w-full flex justify-between">
          <b className="font-bold text-md">VIEW ATTACHMENTS</b>
          <IoIosArrowDown color="black" />
        </div>
      }>
        <div className="flex flex-row flex-wrap gap-x-1 gap-y-2 mt-2">
          {initialData?.appendix?.map((item, index) => {
            return(
              <div onClick={() => viewFile(item.file_url, item.file_url.split('.').pop())} className="flex flex-row w-[32%] py-2 px-2 bg-native-blue rounded-[10px] text-white cursor-pointer">
                {item.file_type == "image" ? (
                  <img className="h-[50px] w-[50px] rounded-[5px] mr-2 object-cover" src={item.file_url} />
                ) : (
                  <embed
                    src={item.file_url} // Use uri property for immediate display
                    type={item.file_type} // Use type property for the embed type
                    className='h-[50px] w-[50px] rounded-[5px] mr-2 transition-all object-cover'
                  />
                )}
                <div className="flex flex-column">
                  <b className="text-[13px] text-[#00b47a]">{item.file_size}</b>
                  <a className="text-[13px] font-normal">{item.time}</a>
                </div>
              </div>
            )
          })}
        </div>
      </Collapsible>
    </div>
  );
};

const ViewFile = ({ filePath, fileType, setViewingFile }) => {
  useEffect(() => {
    // console.log(fileType)
  }, [])
  return(
    <div className="backdropFile w-[100%] h-[100%] z-[999] position-fixed top-[0] flex flex-column items-end p-2">
      <button onClick={() => setViewingFile(false) } className="p-3 py-2 rounded-md bg-[#ffffff] text-[#ff0000] font-bold">Close</button>
      <div className={`w-full h-[90%] mt-2 flex justify-center items-center`}>
        <FileViewer
          fileType={fileType}
          filePath={filePath}
        />
      </div>
    </div>
  )
}  

export function ProjectChatRoom({ project, closeTabFunction }) {
  const currentUrl = useLocation();

  const chatServerUrl = "https://urbex-chat-8c2746135712.herokuapp.com/";
  const [cookies] = useCookies();

  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);
  const { userProfile } = useContext(AuthContext);
  // const [refresh, setRefresh] = useState(false);
  // const [state, setState] = useState([]);
  const [chatObject, setChatObject] = useState(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileUploadingStage, setFileUploadingStage] = useState(0);
  const [viewChatSettings, setViewChatSettings] = useState(false);
  // fileCard States
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [icon, setIcon] = React.useState(null);
  const [generatedURL, setGeneratedURL] = React.useState(null);
  const [imageSelected, setImageSelected] = React.useState(false);
  const [videoMemeberInvites, setVideoMemberInvites] = useState([]);
  const [viewingFile, setViewingFile] = useState(false);
  const [viewingFileUrl, setViewingFileUrl] = useState('');
  const [viewingFileType, setViewingFileType] = useState('');

  React.useEffect(() => {
    let PMPChatObject = JSON.parse(sessionStorage.getItem("PMPChatObject"));
    setChatObject(PMPChatObject);
    // console.log("PMPChatObject", PMPChatObject);
  }, [currentUrl]);

  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  // const [textInput, setTextInput] = useState({});

  var textInput = {};

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);
  async function getPojectChat() {
    joinChatRoom();

    if (socket === null) return;

    socket.on("messages", (messages) => {
      setMessages(messages.reverse());
      // setMessages(messages);
      setLoading(false);
      messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
      scrollToBottom();
      // console.log("messages", messages);
    });

    socket.on("new_message", (message) => {
      console.log("New message", message);
      setMessages((curr) => [...curr, ...message]);
      scrollToBottom();
    });
  }

  function joinChatRoom() {
    // console.log("user_profile", userProfile);
    const token = cookies.urbexEnterpriseUserToken || null;
    socket?.emit("join-chat-room", {
      access: token,
      account_id: userProfile?.account_id,
      sub_account_id: userProfile?.sub_account_id,
      client_id: chatObject?.client_id,
      contractor_id: chatObject?.contractor_id,
      project_id: chatObject?.project_id,
      // pm_to: chatObject?.pm_to
    });
  }

  useEffect(() => {
    getPojectChat();
  }, [socket]);

  useEffect(() => {
    setLoading(true);
    const newSocket = io(chatServerUrl, {
      forceNew: true,
      reconnectionAttempts: 15,
    });
    // console.log("newSocket", newSocket);
    setSocket(newSocket);

    getPojectChat();
    return () => {
      newSocket.disconnect();
      console.log("chat disconnected");
    };
  }, [currentUrl]);
  // const onEndReached = ({ distanceFromEnd }) => {
  //   console.log("distanceFromEnd", distanceFromEnd);x
  //   if (distanceFromEnd < 0) return;
  //   //   fetchData();
  // };

  // const openEmojiKeyboard = () => {
  //   TextInput.State.currentlyFocusedInput()?.setNativeProps({
  //     openEmojiKeyboard,
  //   });
  // };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        fetch_messages_endpoint,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            project_id: chatObject?.project_id,
            chat_type: null,
            client_id: chatObject?.client_id,
            contractor_id: chatObject?.contractor_id,
            offset: messages?.length,
            records: 50,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      // console.log(response.data)
      if (response.data.status) {
        var new_arr = messages;
        new_arr = response.data.messages.reverse().concat(messages);
        setMessages(new_arr);
        // console.log(messages);
      } else {
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(() => e.target.files);
      // console.log(file);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files) {
      setImage(() => e.target.files);
      // console.log(image);
    }
  };

  const uploadProgress = (progressEvent) => {
    var Percentage = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setFileUploadingStage(Percentage);
    // console.log(progressEvent.loaded, progressEvent.total);
    // console.log(
    //   "Upload progress: " +
    //     Math.round((progressEvent.loaded / progressEvent.total) * 100) +
    //     "%"
    // );
  };

  const sendMessage = async (message) => {
    // console.log(document.getElementById("select-file").files);
    if (file?.length > 0) {
      const formData = new FormData();
      var media_name = "";
      formData.append("account_id", userProfile?.account_id);
      formData.append("sub_account_id", userProfile?.sub_account_id);
      formData.append("project_id", chatObject?.project_id);
      formData.append("type", "document");
      formData.append(
        "fileToUpload[]",
        document.getElementById("select-file").files[0]
      );

      try {
        setFileUploading(true);
        const response = await axios.post(file_upload_endpoint, formData, {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: uploadProgress,
        });
        const resData = await response.data;
        console.log("resData", resData);
        if (!resData.status) {
          // setError(resData.response);
          return;
        }
        setFileUploading(false);
        setFileUploadingStage(0);
        closeFileCardPreview();
        media_name = resData.media_name;
      } catch (error) {
        console.log(error);
        setFileUploading(false);
        setFileUploadingStage(0);
      }

      // file?.map((item) => {
      //   console.log(item.uri)
      //   return formData.append("fileToUpload[]", {
      //     uri: item.uri,
      //     name: item.name,
      //     type: item.type,
      //     size: item.size,
      //   })
      // })

      // const userData = await AsyncStorage.getItem("@urbexEnterpriseUserToken");
      // console.log("message", message);
      const token = cookies.urbexEnterpriseUserToken || null;

      socket?.emit("new_message", {
        access: token,
        sender_id: userProfile?.account_id,
        client_id: chatObject?.client_id,
        contractor_id: chatObject?.contractor_id,
        project_manager_id: chatObject?.project_manager_id,
        project_id: chatObject?.project_id,
        message: message,
        image: "",
        audio: "",
        video: "",
        message_type: "chat",
        document: media_name,
        recipient_id: [],
      });
    } else if (image?.length > 0) {
      const formData = new FormData();
      var media_name = [];
      formData.append("account_id", userProfile?.account_id);
      formData.append("project_id", chatObject?.project_id);
      formData.append("type", "image");
      formData.append(
        "fileToUpload[]",
        document.getElementById("select-image").files[0]
      );

      try {
        setFileUploading(true);
        const response = await axios.post(file_upload_endpoint, formData, {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: uploadProgress,
        });
        const resData = await response.data;
        // console.log("resData", resData);
        if (!resData.status) {
          return;
        }

        setFileUploading(false);
        setFileUploadingStage(0);
        // close fileCard on upload done
        closeFileCardPreview();

        media_name = resData.media_name;
      } catch (error) {
        setFileUploading(false);
        setFileUploadingStage(0);
        console.log(error);
      }

      const token = cookies.urbexEnterpriseUserToken || null;

      socket?.emit("new_message", {
        access: token,
        sender_id: userProfile?.account_id,
        client_id: chatObject?.client_id,
        contractor_id: chatObject?.contractor_id,
        project_manager_id: chatObject?.project_manager_id,
        project_id: chatObject?.project_id,
        message: message,
        image: media_name[0],
        audio: "",
        video: "",
        message_type: "chat",
        document: [],
        recipient_id: [],
      });
    } else {
      const token = cookies.urbexEnterpriseUserToken || null;

      socket?.emit("new_message", {
        access: token,
        sender_id: userProfile?.sub_account_id,
        client_id: chatObject?.client_id,
        contractor_id: chatObject?.contractor_id,
        project_manager_id: chatObject?.project_manager_id,
        project_id: chatObject?.project_id,
        message: message,
        image: "",
        audio: "",
        video: "",
        message_type: "chat",
        document: [],
        recipient_id: [],
      });
    }
  };
  function closeFileCardPreview() {
    setSelectedFiles(null);
    setFile(null);
    setImage(null);
    setIcon(null);
    setGeneratedURL(null);
    setImageSelected(false);
    setFileUploading(false);
    setFileUploadingStage(0);
  }
  const viewFile = (fileUrl, fileType) => {
    setViewingFile(true);
    setViewingFileUrl(fileUrl);
    setViewingFileType(fileType);
  }

  const { setCallButtonClicked, setDialing } = useContext(VideoCallContext);
  return (
    <>
      {viewChatSettings ? (
        <div className='h-full w-full'>
          <div className='w-full h-auto'>
            <a className='text-[18px] font-bold mt-2'>Create Video Meeting</a>
            <div className='h-[300px] w-full bg-[#ebebeb] rounded-[10px] flex flex-row'>
              <div className='h-full w-[50%] bg-[#f9f9f9] py-2 overflow-scroll'>
                <div
                  onClick={() => {
                    if (
                      videoMemeberInvites.indexOf(
                        userProfile?.sub_account_id
                      ) === -1
                    ) {
                      setVideoMemberInvites([
                        ...videoMemeberInvites,
                        userProfile?.sub_account_id,
                      ]);
                    } else {
                      let value = userProfile?.sub_account_id;
                      setVideoMemberInvites((oldValues) => {
                        return oldValues.filter(
                          (videoMemeberInvites) => videoMemeberInvites !== value
                        );
                      });
                    }
                  }}
                  className={`flex ${
                    videoMemeberInvites.indexOf(userProfile?.sub_account_id) !==
                    -1
                      ? `bg-[#ebebeb]`
                      : ``
                  } hover:bg-[#ebebeb] flex-row py-2 px-3 items-center cursor-pointer`}
                >
                  <img
                    className='mr-3'
                    src={
                      "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                    alt='image'
                    width={"35px"}
                  />
                  <b>Me</b>
                </div>
                {chatObject?.project_members.map((item, index) => {
                  if (videoMemeberInvites.indexOf(item.sub_account_id) == -1) {
                    var selected = false;
                  } else {
                    var selected = true;
                  }
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        if (
                          videoMemeberInvites.indexOf(item.sub_account_id) ===
                          -1
                        ) {
                          setVideoMemberInvites([
                            ...videoMemeberInvites,
                            item.sub_account_id,
                          ]);
                        } else {
                          let value = item.sub_account_id;
                          setVideoMemberInvites((oldValues) => {
                            return oldValues.filter(
                              (videoMemeberInvites) =>
                                videoMemeberInvites !== value
                            );
                          });
                        }
                      }}
                      className={`flex ${
                        selected ? `bg-[#ebebeb]` : ``
                      } hover:bg-[#ebebeb] flex-row py-2 px-3 items-center cursor-pointer`}
                    >
                      <img
                        className='mr-3'
                        src={
                          "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        alt='image'
                        width={"35px"}
                      />
                      <b>{item.sub_account_name}</b>
                    </div>
                  );
                })}
              </div>
              <div className='flex flex-column items-center w-[50%] px-5 justify-center'>
                <div className='flex flex-column my-2 items-start w-full justify-center'>
                  <label>Meeting Name</label>
                  <input
                    className='p-2 border rounded w-[100%]'
                    type='text'
                    defaultValue={`${chatObject?.project_title}'s meeting`}
                  />
                </div>
                <div className='flex flex-column my-2 items-start w-full justify-center'>
                  <label>Meeting Time</label>
                  <input
                    className='p-2 border rounded w-[100%]'
                    type='datetime-local'
                    defaultValue={`${chatObject?.project_title}'s meeting`}
                  />
                </div>
                <div className='flex flex-column my-2 items-start w-full justify-center'>
                  <button className='py-2 bg-native-blue mt-3 px-10 h-12 w-auto text-white rounded-full'>
                    {loading ? "Creating meeting" : "Create Meeting"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='ChatPage h-full'>
          {viewingFile && (
              <ViewFile 
                filePath={viewingFileUrl}
                fileType={viewingFileType}
                setViewingFile={setViewingFile}
              />
          )}
          <div className='top-section'>
            <div className='profile-container'>
              {chatObject?.project_members.map((item, index) => {
                return <b key={index}>{item.sub_account_name + ","}</b>;
              })}
            </div>
            <div className='icon-container'>
              {/* <BiPhone /> */}
              {/* <Link to="./call">
              <FiVideo
                onClick={() => {
                  setCallButtonClicked(true);
                  const token = cookies.urbexEnterpriseUserToken || null;
                  socket?.emit("new_message", {
                    access: token,
                    sender_id: userProfile?.sub_account_id,
                    client_id: chatObject?.client_id,
                    contractor_id: chatObject?.contractor_id,
                    project_manager_id: chatObject?.project_manager_id,
                    project_id: chatObject?.project_id,
                    message: "",
                    image: "",
                    audio: "",
                    video: "",
                    message_type: "call_request",
                    document: [],
                  });
                }}
              /> 
            </Link>*/}
              <BiDotsVertical
                onClick={() => {
                  setViewChatSettings(true);
                }}
              />
            </div>
          </div>
          <hr />
          <div className='chat-section'>
            {loading ? (
              <div className='text-center mt-3 fs-[15px] fw-bold'>
                Loading Chats ....
              </div>
            ) : (
              <>
                {messages.length <= 0 ? (
                  <div className='text-center mt-3 fs-[15px] fw-bold'>
                    No messages available
                  </div>
                ) : (
                  <>
                    <InfiniteScroll
                      className='chat-section'
                      dataLength={messages?.length} //This is important field to render the next data
                      next={fetchData()}
                      scrollThreshold={0.5}
                      hasMore={true}
                      inverse={true}
                      loader={<h4>Loading...</h4>}
                    >
                      {/* {console.log(userProfile?.account_id)} */}
                      {messages?.map((chat, i) => (
                        <>
                          {chat.message_type === "chat" ? (
                            <ChatCard
                              chatClass={
                                chat.sender === userProfile?.sub_account_id
                                  ? "me"
                                  : "you"
                              }
                              sub_account_id={userProfile?.sub_account_id}
                              message={chat}
                              setViewingFile={setViewingFile}
                              viewFile={viewFile}
                              key={i}
                            />
                          ) : null}
                        </>
                      ))}
                      <div
                        style={{ height: 0, width: 0, backgroundColor: "red" }}
                        ref={messagesEndRef}
                      />
                    </InfiniteScroll>
                  </>
                )}
              </>
            )}
          </div>

          <div className='file-selection'>
            <FileCard
              file={file || image}
              setFile={setFile}
              setImage={setImage}
              sendMessage={sendMessage}
              fileUploading={fileUploading}
              fileUploadingStage={fileUploadingStage}
              setFileUploadingStage={setFileUploadingStage}
              FileState={{
                selectedFiles,
                setSelectedFiles,
                icon,
                setIcon,
                generatedURL,
                setGeneratedURL,
                imageSelected,
                setImageSelected,
                closeFileCardPreview,
              }}
            />
          </div>
          <div className='bottom-section'>
            <div className='icon-container'>
              {/* <Icon /> */}
              {/* <Icon /> */}
              <label htmlFor='select-file'>
                {/* <img src={pin_icon} alt="send" width={"35px"} /> */}
                <IoDocumentAttachOutline style={{ width: "28px" }} />
              </label>
              <input
                type='file'
                id='select-file'
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <label htmlFor='select-image'>
                <LuImagePlus style={{ width: "28px" }} />
              </label>
              <input
                type='file'
                id='select-image'
                onChange={handleImageChange}
                style={{ display: "none" }}
                accept='image/*'
              />
              {/* <BsEmojiSmile /> */}
            </div>
            <input
              type='text'
              className='form-control'
              placeholder={`${
                selectedFiles ? "Caption (Optional)" : "Enter message here..."
              }`}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />{" "}
            <div className='icon-container'>
              <button
                className='send-button'
                onClick={() => {
                  if (
                    inputValue === "" &&
                    file?.length < 1 &&
                    image?.length < 1
                  )
                    return;
                  sendMessage(inputValue);
                  setInputValue("");
                }}
              >
                <MdSend />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function ChatCard({ chatClass, message, sub_account_id, viewFile }) {
  return (
    <div className={`ChatCard ${chatClass}`}>
      {message.sender !== sub_account_id && (
        <p className='mb-1' style={{ color: "#000aff" }}>
          {message.sender_name}
        </p>
      )}
      {message.image != "" && message.image != null ? (
        <img
          onClick={() => viewFile(message.image, message.image.split('.').pop())}
          style={{ width: "100%", height: 200, borderRadius: 5 }}
          src={message.image}
        />
      ) : null}
      {message.document?.length > 0 ? (
        <div
          style={{
            width: "100%",
            padding: 5,
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          onClick={() => viewFile(message.document[0], message.document[0].split('.').pop())}
        >
          <Icon as={MdCloudDownload} />
          <a style={{ fontWeight: "bold", fontSize: "10px", marginLeft: 5 }}>
            {"Download " + message.document?.length + " attachment(s)"}
          </a>
        </div>
      ) : null}
      <div className='my-1 text-[11px]' style={{ width: "100%" }}>
        {message.message}
      </div>
      <p className='mb-0'>{message.time}</p>
    </div>
  );
}
function FileCard({ file, fileUploading, fileUploadingStage, FileState }) {
  const {
    selectedFiles,
    setSelectedFiles,
    icon,
    setIcon,
    generatedURL,
    setGeneratedURL,
    imageSelected,
    setImageSelected,
    closeFileCardPreview,
  } = FileState;
  useEffect(() => {
    if (imageSelected) {
      setGeneratedURL(URL.createObjectURL(selectedFiles));
      return () => {
        if (selectedFiles) {
          URL.revokeObjectURL(URL.createObjectURL(selectedFiles));
        }
      };
    }
  }, [imageSelected]);
  useEffect(() => {
    setImageSelected(() => false);
    if (selectedFiles?.type.split("/")[0] === "image") {
      return setImageSelected(true);
    }
    if (selectedFiles?.type.split("/")[0] === "audio") {
      return setIcon(<FaFileAudio />);
    }
    if (selectedFiles?.type.split("/")[0] === "application") {
      if (selectedFiles.type.split("/")[1] === "pdf") {
        return setIcon(<FaFilePdf />);
      }
      if (selectedFiles?.type.split("/")[1] === "zip") {
        return setIcon(<FaFileArchive />);
      }
    }
    return setIcon(<FaFile />);
  }, [selectedFiles]);
  useEffect(() => {
    if (file) {
      setSelectedFiles(() => file[0]);
      // console.log("file: ", file[0]);
    }
  }, [file]);

  function calculateSelectedFileSize(file) {
    let fileSizeInBytes = file?.size;
    let fileSize;
    let unit;

    if (fileSizeInBytes < 1024) {
      fileSize = fileSizeInBytes;
      unit = "bytes";
    } else if (fileSizeInBytes < 1048576) {
      fileSize = (fileSizeInBytes / 1024).toFixed(2);
      unit = "KB";
    } else if (fileSizeInBytes < 1073741824) {
      fileSize = (fileSizeInBytes / 1048576).toFixed(2);
      unit = "MB";
    } else {
      fileSize = (fileSizeInBytes / 1073741824).toFixed(2);
      unit = "GB";
    }
    return {
      size: fileSize,
      unit: unit,
    };
  }
  return (
    <div
      className='FileCard'
      style={selectedFiles ? { display: "flex" } : { display: "none" }}
    >
      <div className='close-icon' onClick={() => closeFileCardPreview()}>
        <AiOutlineClose />
      </div>
      <div className='preview-container'>
        {imageSelected ? (
          <img src={generatedURL} alt='' />
        ) : (
          <div className='icon'> {icon}</div>
        )}

        <div>
          {selectedFiles?.name} {" - "}
          {calculateSelectedFileSize(selectedFiles).size}
          {calculateSelectedFileSize(selectedFiles).unit}
        </div>
      </div>
      {fileUploading ? (
        <div className='progressbar'>
          <ProgressBar
            bgColor='#000AFF'
            baseBgColor='#FFFFFF'
            height='10px'
            isLabelVisible={false}
            completed={fileUploadingStage}
          />
        </div>
      ) : null}
    </div>
  );
}

export function ImportProjects({ closeTabFunction }) {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [cookies] = useCookies();
  const [files, setFiles] = React.useState([]);

  async function handelSubmit() {
    setLoading(true);
    const formData = new FormData();

    formData.append("account_id", userProfile?.account_id);
    formData.append("sub_account_id", userProfile?.sub_account_id);
    formData.append("projects_file", files[0]);

    try {
      const response = await axios.post(upload_project_csv_endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      if (response.data.status) {
        // console.log(response.data);
        setLoading(false);
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        closeTabFunction();
      } else {
        setLoading(false);
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setLoading(false);
      setTostMessage({
        messageType: "error",
        message: error,
      });
      console.log(error);
    }
  }

  return (
    <div className='flex flex-column align-end items-end justify-end'>
      <Box>
        <div className='grid grid-cols-1'>
          <div className='p-3 rounded-xl bg-gray-50 transition-all ease-in-out my-3 flex items-start gap-4'>
            <div className=''>
              <div>
                <h5 className=''>NOTE:</h5>
                <p className='text-sm text-gray-500'>
                  You can import projects in a CSV file to ease the data entry
                  process. The CSV file should be in the following format:
                </p>
              </div>
            </div>
          </div>
          <center>
            <img src={csv_proj} className='w-[90%] flex self-center h-auto' />
          </center>
          <div className='upload-file-container my-3'>
            <MultipleFileUploadComponent
              files={files}
              setFiles={setFiles}
              supportedFiles={"CSV Files only"}
              accept={".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
            />
          </div>
          <button
            onClick={() => handelSubmit()}
            className='bg-native-blue p-10 py-3 text-white mx-auto rounded-full w-full'
          >
            {loading ? "Uploading..." : "Import Projects"}
          </button>
        </div>
      </Box>
    </div>
  );
}

export function CreateProcurementRequest({ closeTabFunction, fetchRequests }) {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [cookies] = useCookies();
  const [requestDetails, setRequestDetails] = useState("");
  const [requestProject, setRequestProject] = useState("");
  const [requestUrgency, setRequestUrgency] = useState("");

  // console.log(userProfile)
  
  async function handelSubmit() {
    if (requestDetails == "" || requestUrgency == "") {
      setTostMessage({
        messageType: "warning",
        message: "All compulsory fields must be filled before submitting.",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        create_procurement_request,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            project_id: requestProject,
            requestDetails: requestDetails,
            requestUrgency: requestUrgency,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      if (response.data.status) {
        // console.log(response.data);
        setLoading(false);
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        fetchRequests();
        closeTabFunction();
      } else {
        setLoading(false);
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setLoading(false);
      setTostMessage({
        messageType: "error",
        message: error,
      });
      console.log(error);
    }
  }

  return (
    <div>
      <div className='w-full h-auto p-3 bg-[#c4f1ff] rounded-[10px] flex items-center justify-center'>
        <a className='text-sm text-center'>
          You can either leave the "projects field" unselected to make general
          requests or select a project to make project specific requests.
        </a>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Select a project (This shows a list of your ongoing projects)
          </FormLabel>
          <Select
            id='project_id'
            name='project_id'
            onChange={(e) => setRequestProject(e.target.value)}
          >
            <option value=''>Select Project (Optional)</option>
            {userProfile?.ongoing_project?.map((item, index) => {
              return (
                <option value={item.project_id}>{item.project_title}</option>
              );
            })}
          </Select>
        </Box>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Select urgency level
          </FormLabel>
          <Select
            id='urgency'
            name='urgency'
            onChange={(e) => setRequestUrgency(e.target.value)}
          >
            <option value=''>Select an option</option>
            <option value='HIGH'>HIGH</option>
            <option value='MEDIUM'>MEDIUM</option>
            <option value='LOW'>LOW</option>
          </Select>
        </Box>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Enter request details
          </FormLabel>
          <Textarea
            type='text'
            id='request'
            name='request'
            onChange={(e) => setRequestDetails(e.target.value)}
            value={requestDetails}
          />
        </Box>
      </div>
      <button
        onClick={() => handelSubmit()}
        className='bg-native-blue p-10 py-3 my-5 text-white mx-auto rounded-full w-full'
      >
        {loading ? "Uploading..." : "Submit Request"}
      </button>
    </div>
  );
}

export function ViewClockIn({ closeTabFunction, selected ,attendance}) {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);

  const convertDateFormat = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toLocaleDateString();
    const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    return { formattedDate, time };
  };
  const { formattedDated, timed } = convertDateFormat(selected?.timestamp);

  var track_no = 0;
  var show_track = false;

  return (
    <>
      <div className='flex flex-row w-auto'>
        {/* <b className='text-[16px] font-bold py-2'>
          {`Location: ` + selected?.location}
        </b> */}
        <b className='text-[13px] font-bold p-2 bg-[#ebebeb] rounded-[10px] '>
          ATTENDANCE FOR {selected?.timestamp}
        </b>
      </div>
      <div className='w-100 mt-3'>
        {selected?.sub_attendance_info?.map((att, ind) => {
            // console.log(`https://maps.google.com/maps?q=${att?.position_lat},${att?.position_lon}&hl=en&z=14&amp;output=embed`);
            let { formattedDate, time } = convertDateFormat(att?.timestamp);
            if (att.type == "in") {
              track_no = track_no + 1;
              show_track = true;
            } else {
              show_track = false;
            }
            return (
              <>
                {show_track && (
                  <>
                    <b className="text-[#000aff]">TRACK {track_no} (Ver. {selected?.app_version == "" ? 'N/A' : selected?.app_version})</b>
                    <div className="flex items-center text-xs flex-row">
                      <div className={`flex flex-row items-center text-xs ${selected?.permissions?.backgroundLocationPermission ? 'text-[#00b47a]' : 'text-[#ff0000]'} font-semibold`}>
                        Background Perm.
                      </div>
                      <GoDotFill className="mx-1" size={13} color="#000" />
                      <div className={`flex flex-row items-center text-xs ${selected?.permissions?.fineLocationPermission ? 'text-[#00b47a]' : 'text-[#ff0000]'} font-semibold`}>
                        Precise Perm.
                      </div>
                    </div>
                  </>
                )}
                <div className={`${att.type == "out" ? 'mb-[2rem]' : ''} flex flex-row gap-x-2`}>
                    <MdOutlineAvTimer size={25} />
                    <div className="flex flex-column mb-3">
                      <div className="flex flex-row items-center gap-2">
                        <div className={`${att.type == "in" ? 'text-[#00b47a]' : ''} ${att.type == "out" ? 'text-[#ff7b00]' : ''}` + " flex flex-row items-center gap-1"}>
                          <b>{time}</b>
                        </div>
                        <p>({att.type == "in" || att.type == "out" ? 'Clocked ' + att.type : 'Auto'})</p>
                      </div>
                      <b className="underline cursor-pointer" onClick={() => window.open(`https://maps.google.com/maps?q=${att?.position_lat},${att?.position_lon}&hl=en&z=14&amp;output=embed`, '_blank')}>{att?.location == "" ? "Click to view location" : att?.location}</b>
                    </div>
                </div>
              </>
            )
        })}
        {/* <Timeline>
          {selected.sub_attendance_info.map((att, ind) => {
            let { formattedDate, time } = convertDateFormat(att.date_created);
            return (
              <Event
                key={ind}
                interval={
                  <a
                    href={`https://maps.google.com/maps?q=${att?.position_lat},${att?.position_lon}&hl=en&z=14&amp;output=embed`}
                    className="w-100 font-bold rounded-[10px]"
                    style={{ width: "max-content", fontWeight: 'bold' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`View Location (${time})`}
                  </a>
                }
              >
                <b className={`!font-bold ${att.type == "in" &&  'text-[#00b47a]' } ${att.type == "out" &&  'text-[#faa82d]' }`}>{att.type == "in" || att.type == "out" ? 'CLOCKED ' + att.type.toUpperCase() : ''}</b>
              </Event>
            );
          })}
        </Timeline> */}
      </div>
    </>
  );
}

export function ViewUserPermission({ closeTabFunction, employee, fetchStaffs}) {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const [showAddPermissions, setShowAddPermissions] = useState(false);
  const [cookies] = useCookies();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [editing, setEditing] = useState(false);
  const [addNewPermission, setAddNewPermission] = useState(false);
  const { setTostMessage } = useContext(TostMessageContext);
  const [revoking, setRevoking] = useState([45]);
  const permissionRef = useRef([]);

  //console.log(employee);
  if (!employee || employee.length === 0) {
    return <p>No employee data available.</p>;
  }

  function selectNewPermission (permission_id, isSelected) {
    const new_array = selectedPermissions;
    if (isSelected) {
      new_array.push(permission_id)
      setEditing(true);
    } else {
      new_array.splice(new_array.indexOf(permission_id), 1);
      if (new_array.length < 1) {
        setEditing(false)
      }
    }
    console.log(new_array);
    setSelectedPermissions(new_array)
  }

  const revokeAccess = async (permission_id) => {
    const new_array = revoking;
    new_array.push(permission_id);
    setRevoking(new_array);

    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        sub_account_id: employee.id,
        permission_id: permission_id
      },
    });

    const headers = {
      access: cookies.urbexEnterpriseUserToken,
      "Content-Type": "application/json",
    };

    axios
    .post(revoke_user_permissions, data, { headers })
    .then((response) => {
      const new_array2 = revoking;
      new_array2.splice(new_array2.indexOf(permission_id), 1);
      setRevoking(new_array2);
      if (response.data.status) {
        permissionRef[`extra${permission_id}`].style.backgroundColor = '#ffd4ea'
        permissionRef[`extra_button${permission_id}`].style.display = "none";
        return setTostMessage({
          messageType: 'success',
          message: response.data.response
        })
      } else {
        return setTostMessage({
          messageType: 'error',
          message: response.data.response
        })
      }
    }).catch((error) => {
      
    });
  }


  function formatDate(date) {
    if (date !== "Forever") {
      const d = new Date(date * 1000);
      return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    } else {
      return date;
    }
  }

  return (
    <>
      <div className='flex flex-column'>
        <b className='text-[16px] font-bold py-2'>
          {employee.department + ` (` + employee.department_level + `)`}
        </b>
      </div>
      {addNewPermission && (
        <AddUserPermissions
          employee={employee}
          setSelectedPermissions={setSelectedPermissions}
          selectedPermissions={selectedPermissions}
          setAddNewPermission={setAddNewPermission}
          permissionRef={permissionRef}
          fetchStaffs={fetchStaffs}
        />
      )}
      {employee.department_permissions && employee.department_permissions.length > 0 && (
          <div className='w-100 mt-3'>
            <table style={{ width: '100%' }}>
              <thead>
                <tr style={{ border: '1px solid #bbb', padding: '8px' }}>
                  <th></th>
                  <th style={{padding: '8px'}}>Name</th>
                  <th style={{padding: '8px'}}>Description</th>
                  <th style={{padding: '8px'}}>Revoking</th>
                </tr>
              </thead>
              <tbody>
                {employee?.department_permissions?.map((att, ind) => (
                  <tr key={ind}>
                    <td style={{ padding: '8px' }}>
                      <input style={{ color: '#bbb' }} type={'checkbox'} disabled checked/>
                    </td>
                    <td style={{ border: '1px solid #bbb', padding: '8px' }}>{att.name}</td>
                    <td style={{ border: '1px solid #bbb', padding: '8px' }}>{att.description}</td>
                    <td style={{ border: '1px solid #bbb', padding: '8px' }}>{`DEFAULT`}</td>
                  </tr>
                ))}
                {employee?.extra_permissions?.map((att, ind) => (
                  <tr ref={ref => { permissionRef[`extra${att.id}`] = ref }} key={ind}>
                    <td style={{ padding: '8px' }}>
                        <b ref={ref => { permissionRef[`extra_button${att.id}`] = ref }} onClick={() => revokeAccess(att.id)} className="text-[#ff0000] text-sm cursor-pointer"><FcCancel size={20} /></b>
                    </td>
                    <td style={{ border: '1px solid #bbb', padding: '8px' }}>{att.name}</td>
                    <td style={{ border: '1px solid #bbb', padding: '8px' }}>{att.description}</td>
                    <td style={{ border: '1px solid #bbb', padding: '8px' }}>{formatDate(att?.time_expire)}</td>
                  </tr>
                ))}
                {employee?.remaining_permissions?.map((att, ind) => (
                  <tr ref={ref => { permissionRef[`extra${att.id}`] = ref }} key={ind}>
                    <td style={{ padding: '8px' }}>
                      <input 
                      ref={ref => { permissionRef[`extra_button${att.id}`] = ref }}
                      onChange={(e) => {
                        // console.log(att.id, e.target.checked)
                        selectNewPermission(att.id, e.target.checked)
                      }}
                      type={'checkbox'} />
                    </td>
                    <td style={{ border: '1px solid #bbb', padding: '8px' }}>{att.name}</td>
                    <td style={{ border: '1px solid #bbb', padding: '8px' }}>{att.description}</td>
                    <td style={{ border: '1px solid #bbb', padding: '8px' }}></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      }
      {editing && (
        <button onClick={() => setAddNewPermission(true)} className="w-[90%] p-3 font-bold bg-native-blue text-white rounded-lg z-[50] bottom-[2rem] position-fixed">
          Save
        </button>
      )}
    </>
  );
}

export function CreateEvent({ closeTabFunction, refresh }) {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [cookies] = useCookies();

  const [eventDate, setEventDate] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventMembers, setCalendarMembers] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [members, setMembers] = useState([]);
  // console.log(userProfile)

  useEffect(() => {
    var new_array_of_members = [];
    userProfile?.sub_accounts.map((item, index) => {
      new_array_of_members.push({ value: item.id, label: item.name });
    });
    setMembers(new_array_of_members);
    console.log(members);
  }, []);

  async function handelSubmit() {
    setLoading(true);
    try {
      const response = await axios.post(
        create_event,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            event_name: eventName,
            event_date: eventDate,
            event_location: eventLocation,
            event_members: eventMembers,
            event_category: eventCategory,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      if (response.data.status) {
        setLoading(false);
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        refresh();
        closeTabFunction();
      } else {
        setLoading(false);
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setLoading(false);
      setTostMessage({
        messageType: "error",
        message: error,
      });
      console.log(error);
    }
  }

  return (
    <div>
      <div className='w-full h-auto p-3 bg-[#c4f1ff] rounded-[10px] flex items-center justify-center'>
        <a className='text-sm text-center'>
          Create events here and select all or some of your team to attend. Not
          only will automatic invites be sent to selected team members, it will
          also appear on their calendar.
        </a>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Event Name
          </FormLabel>
          <Input
            value={eventName}
            type={'text'}
            onChange={(e) => setEventName(e.target.value)}
          />
        </Box>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Location
          </FormLabel>
          <Input
            type='text'
            value={eventLocation}
            onChange={(e) => setEventLocation(e.target.value)}
          />
        </Box>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Event Category
          </FormLabel>
          <Select
            id='category'
            name='category'
            onChange={(e) => setEventCategory(e.target.value)}
          >
            <option value=''>Select Category</option>
            <option value='Meeting'>Meeting</option>
            <option value='Site Visit'>Site Visit</option>
            <option value='Inspection'>Inspection</option>
          </Select>
        </Box>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Select Members
          </FormLabel>
          <ReactSelect
            id='members'
            name='members'
            options={members}
            isMulti
            onChange={(e) => {
              setCalendarMembers(e);
            }}
          />
        </Box>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Choose date
          </FormLabel>
          <Input
            type='datetime-local'
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
          />
        </Box>
      </div>
      <button
        onClick={() => handelSubmit()}
        className='bg-native-blue p-10 py-3 my-5 text-white mx-auto rounded-full w-full'
      >
        {loading ? "Creating..." : "Create Event"}
      </button>
    </div>
  );
}

export function CreateAnnouncement({ closeTabFunction, refresh }) {
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [cookies] = useCookies();

  const [eventDate, setEventDate] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventMembers, setCalendarMembers] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [members, setMembers] = useState([]);
  // console.log(userProfile)

  useEffect(() => {
    var new_array_of_members = [];
    userProfile?.sub_accounts.map((item, index) => {
      new_array_of_members.push({ value: item.id, label: item.name });
    });
    setMembers(new_array_of_members);
    console.log(members);
  }, []);

  async function handelSubmit() {
    setLoading(true);
    try {
      const response = await axios.post(
        create_announcement,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            event_name: eventName,
            event_date: eventDate,
            event_location: eventLocation,
            event_members: eventMembers,
            event_category: eventCategory,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      if (response.data.status) {
        setLoading(false);
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        });
        refresh();
        closeTabFunction();
      } else {
        setLoading(false);
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      setLoading(false);
      setTostMessage({
        messageType: "error",
        message: error,
      });
      console.log(error);
    }
  }

  return (
    <div>
      <div className='w-full h-auto p-3 bg-[#c4f1ff] rounded-[10px] flex items-center justify-center'>
        <a className='text-sm text-center'>
          Create company wide announcements and updater your team on the go.
        </a>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Announcement Title
          </FormLabel>
          <Input
            value={eventName}
            type='text'
            onChange={(e) => setEventName(e.target.value)}
          />
        </Box>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Message
          </FormLabel>
          <Input
            type='text'
            multiple
            noOfLines={4}
            value={eventLocation}
            onChange={(e) => setEventLocation(e.target.value)}
          />
        </Box>
      </div>
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
            Select Members
          </FormLabel>
          <ReactSelect
            id='members'
            name='members'
            options={members}
            isMulti
            onChange={(e) => {
              setCalendarMembers(e);
            }}
          />
        </Box>
      </div>
      {/* <div className="col-12 mt-3">
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor="request">
            Schedule Time
          </FormLabel>
          <Input
            type="datetime-local"
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
          />
        </Box>
      </div> */}
      <button
        onClick={() => handelSubmit()}
        className='bg-native-blue p-10 py-3 my-5 text-white mx-auto rounded-full w-full'
      >
        {loading ? "Creating..." : "Create Announcement"}
      </button>
    </div>
  );
}


export function UserProjectsTabs({ closeTabFunction, refresh, employee }) {
  const [showAddProjects, setShowAddProjects] = useState(false);
  const [userProjects, setUserProjects] = useState([]);
  const [remainingProjects, setRemainingProjects] = useState([]);
  const { userProfile } = useContext(AuthContext);
  const [deleting, setDeleting] = useState(false);
  const { setTostMessage } = useContext(TostMessageContext);
  const [cookies] = useCookies();

  useEffect(() => {
    fetchProjects();
  }, [])
  const fetchProjects = async () => {
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        sub_account_id: employee,
      },
    });

    const headers = {
      access: cookies.urbexEnterpriseUserToken,
      "Content-Type": "application/json",
    };

    axios
      .post(fetch_user_projects_endpoint, data, { headers })
      .then((response) => {
        if (!response.data.status) {

        }
        if (response.data.status) {
          setUserProjects(response.data.ongoing_projects)
          setRemainingProjects(response.data.remaining_projects)
        }
      }).catch((error) => {

      });
  }

  function calculatePercentage(total, completed) {
    const percentage = (Number(completed) / Number(total)) * 100;

    if (isNaN(Number(percentage))) {
      return 0;
    }
    return Math.round(percentage);
  }

  const deleteProject = async (project) => {
    setDeleting(true);
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        sub_account_id: employee,
        project: project
      },
    });

    const headers = {
      access: cookies.urbexEnterpriseUserToken,
      "Content-Type": "application/json",
    };

    axios
      .post(remove_user_from_projects_endpoint, data, { headers })
      .then((response) => {
        if (!response.data.status) {
          setDeleting(false)
          return setTostMessage({
            messageType: 'error',
            message: response.data.response
          })
        }
        if (response.data.status) {
          fetchProjects();
          setDeleting(false)
          return setTostMessage({
            messageType: 'success',
            message: response.data.response
          })
        }
      }).catch((error) => {
        return setTostMessage({
          messageType: 'error',
          message: 'Network error, please check your network and try again.'
        })
      });
  }

  return(
    <div className="w-full h-full">
      <button onClick={() => setShowAddProjects(true)} className="bg-native-blue text-white p-2 rounded-md font-bold items-center flex flex-row position-relative r-0">
        <VscAdd className="mr-2" size={20} />
        Add to Project
      </button>
      {showAddProjects && (
        <AddUserToProject
          setShowAddProjects={setShowAddProjects}
          remainingProjects={remainingProjects}
          fetchProjects={fetchProjects}
          employee={employee}
        />
      )}
      <div>
        {userProjects?.map((project, index) => 
          <div className="flex flex-row items-center w-full">
          <Link
            to={`./${project?.project_id}`}
            className='projectCard flex p-3 md:p-4 lg:p-8 justify-between items-center h-auto md:h-28 lg:h-28 bg-gray-100 rounded-2xl my-3 w-full'
          >
            <div className="flex flex-row items-center gap-x-4 w-full">
              <div className='hidden md:block lg:block'>
                <img src={project_icon} />
              </div>
              <div className='w-[60%] md:w-full flex flex-column'>
                <h2 className='text-sm xl:text-md leading-tight'>
                  {project?.project_title}
                </h2>
                <span className='text-xs xl:text-sm leading-tight font-bold text-[#000aff]'>
                  {project?.project_status?.project_stage}
                </span>
                <span className='text-xs xl:text-sm leading-tight'>
                  {project?.site_address}
                </span>
                {/* <span className='flex flex-row items-center text-xs xl:text-sm leading-tight overflow-x-hidden'>
                  <MdOutlineSupervisedUserCircle className="mr-2" />
                  <Marquee gradient={true} pauseOnHover={true} gradientWidth={100} gradientColor={'transparent'}>
                    <a className="text-[#000]">{project?.created_by_name}</a>
                    {project?.project_members_scroll?.map((item, index) => {
                      return <a className="mr-1 text-[#000]">, {item.sub_account_name}</a>
                    })}
                  </Marquee>
                </span> */}
              </div>
            </div>
              <div>
                <ProgressBarChart
                  percentage={calculatePercentage(
                    project?.project_status?.project_milestones || 0,
                    project?.project_status?.project_milestones_completed || 0
                  )}
                />
              </div>
          </Link>
            <div
            className='p-1 md:p-3 text-danger font-bold ml-2 cursor-pointer'
            onClick={() => deleteProject(project?.project_id)}
          >
            {!deleting ? (
              'Remove'
            ) : (
              <ReactLoading type={"cylon"} color='#ff0000' />
            )}
          </div>
          </div>
        )}
      </div>
    </div>
  )
}

const AddUserToProject = ({ setShowAddProjects, remainingProjects, fetchProjects, employee }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setCalendarMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [cookies] = useCookies();
  const [memberRole, setMemberRole] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [roles, setRoles] = useState([
    { value: "QAQC HOD", label: "QAQC HOD" },
    { value: "HSE HOD", label: "HSE HOD" },
    { value: "ENGR HOD", label: "ENGR HOD" },
    { value: "DESIGN HOD", label: "DESIGN HOD" },
    { value: "SUPERVISOR HOD", label: "SUPERVISOR HOD" },
    { value: "ADMIN/HR HOD", label: "ADMIN/HR HOD" },
    { value: "CONSTRUCTION MANAGER", label: "CONSTRUCTION MANAGER" },
    { value: "GENERAL MANAGER", label: "GENERAL MANAGER" },
    { value: "PROJECT LEAD", label: "PROJECT LEAD" },
    { value: "SENIOR PROJECT LEAD", label: "SENIOR PROJECT LEAD" },
    { value: "JUNIOR PROJECT LEAD", label: "JUNIOR PROJECT LEAD" },
    { value: "HSE LEAD", label: "HSE LEAD" },
    { value: "JUNIOR SITE LEAD", label: "JUNIOR SITE LEAD" },
    {
      value: "SECTION LEAD (JUNIOR SITE LEAD)",
      label: "SECTION LEAD (JUNIOR SITE LEAD)",
    },
    { value: "PROJECT COORDINATOR", label: "PROJECT COORDINATOR" },
    { value: "SITE COORDINATOR", label: "SITE COORDINATOR" },
    { value: "HSE COORDINATOR", label: "HSE COORDINATOR" },
    { value: "ADMIN COORDINATOR", label: "ADMIN COORDINATOR" },
    {
      value: "APPOINTED RESIDENT QAQC/SITE ENGINEER",
      label: "APPOINTED RESIDENT QAQC/SITE ENGINEER",
    },
    { value: "QAQC/SITE ENGINEER", label: "QAQC/SITE ENGINEER" },
    { value: "JUNIOR QAQC/SITE ENGINEER", label: "JUNIOR QAQC/SITE ENGINEER" },
    {
      value: "TRAINEE QAQC/SITE ENGINEER",
      label: "TRAINEE QAQC/SITE ENGINEER",
    },
  ]);

  useEffect(() => {
    var new_array_of_members = [];
    remainingProjects?.map((item, index) => {
      new_array_of_members.push({ value: item.project_id, label: item.project_title });
    });
    setProjects(new_array_of_members);
  }, [])

  const handleSubmit = async () => {
    setLoading(true);
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        sub_account_id: employee,
        role: memberRole,
        selected_projects: selectedProjects
      },
    });

    const headers = {
      access: cookies.urbexEnterpriseUserToken,
      "Content-Type": "application/json",
    };

    axios
      .post(add_user_to_projects, data, { headers })
      .then((response) => {
        if (!response.data.status) {
          setLoading(false);
          return setTostMessage({
            messageType: 'error',
            message: response.data.response
          })
        }
        if (response.data.status) {
          setShowAddProjects(false)
          fetchProjects();
          setLoading(false);
          return setTostMessage({
            messageType: 'success',
            message: response.data.response
          })
        }
      }).catch((error) => {
        setLoading(false);
        return setTostMessage({
          messageType: 'error',
          message: 'Network error, please check your network and try again.'
        })
      });
  }
  return(
    <div className="backdropFile w-[100%] h-[100%] z-[999] position-fixed top-[0] flex flex-column items-end p-2">
      <button onClick={() => setShowAddProjects(false) } className="p-3 py-2 rounded-md bg-[#ffffff] text-[#ff0000] font-bold">Close</button>
      <div className={`w-full h-full mt-2 flex items-center justify-center`}>
        <div className="h-auto w-[80%] bg-white min-h-[30%] rounded-lg p-4 flex flex-column justify-center">
        <div className='w-full h-auto p-3 mb-2 bg-[#c4f1ff] rounded-[10px] flex items-center justify-center'>
          <b className='text-sm text-center'>
            Select the projects you want to add this user to
          </b>
        </div>
          <Box className="mb-2">
            <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
              Select Role
            </FormLabel>
            <ReactSelect
              id='role'
              name='role'
              placeholder='Role'
              options={roles}
              value={selectedOption}
              isMulti={false}
              onChange={(e) => {
                setMemberRole(e.value);
                setSelectedOption(e);
              }}
            />  
          </Box> 
          <Box>
            <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
              Select Project(s)
            </FormLabel>
            <ReactSelect
              id='projects'
              name='projects'
              options={projects}
              isMulti
              onChange={(e) => {
                setCalendarMembers(e);
              }}
            />
          </Box>
          {(selectedProjects?.length < 1) ? 
            (<button className="continue w-50 mt-3 mx-auto !rounded-full disabled" type="button" role="disabled">
                Add to {selectedProjects.length} project(s)
            </button>):
            (<button
                className={`continue w-50 mx-auto mt-3 bg-[#000aff] !rounded-full ${loading ? 'loading' : ''}`}
                onClick={handleSubmit}
                type="button"
                disabled={loading}
            >
                {loading ? 'Please Wait...' : `Add to ${selectedProjects.length} Project(s)`}
            </button>) }
        </div>
      </div>
    </div>
  )
} 


const AddUserPermissions = ({ setAddNewPermission, selectedPermissions, employee, setSelectedPermissions, fetchStaffs, permissionRef }) => {
  const [projects, setProjects] = useState([{value: '24 hours', label: '24 hours'},{value: '1 week', label: '1 week'},{value: '1 month', label: '1 month'},{value: 'Forever', label: 'Forever'}]);
  const [selectedProjects, setCalendarMembers] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState('');
  const [loading, setLoading] = useState(false);
  const { userProfile } = useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [cookies] = useCookies();
  const [memberRole, setMemberRole] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);

  const handleSubmit = async () => {
    setLoading(true);
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        sub_account_id: employee.id,
        selectedPermissions: selectedPermissions,
        selectedDuration: selectedDuration
      },
    });

    const headers = {
      access: cookies.urbexEnterpriseUserToken,
      "Content-Type": "application/json",
    };

    axios
      .post(add_user_permissions, data, { headers })
      .then((response) => {
        if (!response.data.status) {
          setLoading(false);
          return setTostMessage({
            messageType: 'error',
            message: response.data.response
          })
        }
        if (response.data.status) {
          setAddNewPermission(false)
          setSelectedPermissions([]);
          setLoading(false);
          for (var i=0;i<selectedPermissions.length;i++) {
            permissionRef[`extra${selectedPermissions[i]}`].style.backgroundColor = '#defff7'
            permissionRef[`extra_button${selectedPermissions[i]}`].style.display = "none";
          }
          return setTostMessage({
            messageType: 'success',
            message: response.data.response
          })
        }
      }).catch((error) => {
        setLoading(false);
        return setTostMessage({
          messageType: 'error',
          message: 'Network error, please check your network and try again.'
        })
      });
  }
  return(
    <div className="backdropFile w-[100%] h-[100%] z-[999] position-fixed top-[0] flex flex-column items-end p-2">
      <button onClick={() => setAddNewPermission(false) } className="p-3 py-2 rounded-md bg-[#ffffff] text-[#ff0000] font-bold">Close</button>
      <div className={`w-full h-full mt-2 flex items-center justify-center`}>
        <div className="h-auto w-[80%] bg-white min-h-[30%] rounded-lg p-4 flex flex-column justify-center">
        <div className='w-full h-auto p-3 mb-2 bg-[#c4f1ff] rounded-[10px] flex items-center justify-center'>
          <b className='text-sm text-center'>
            Choose how long before these rights are revoked
          </b>
        </div>
          <Box>
            <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
              Select Duration
            </FormLabel>
            <ReactSelect
              id='projects'
              name='projects'
              options={projects}
              isMulti={false}
              onChange={(e) => {
                setSelectedDuration(e.value);
              }}
            />
          </Box>
          {(selectedDuration == "") ? 
            (<button className="continue w-50 mt-3 mx-auto !rounded-full disabled" type="button" role="disabled">
                Add {selectedPermissions.length} permission(s)
            </button>):
            (<button
                className={`continue w-50 mx-auto mt-3 bg-[#000aff] !rounded-full ${loading ? 'loading' : ''}`}
                onClick={handleSubmit}
                type="button"
                disabled={loading}
            >
                {loading ? 'Please Wait...' : `Add ${selectedPermissions.length} permissions(s)`}
            </button>) }
        </div>
      </div>
    </div>
  )
} 