import React from "react";
import "./FAQPage.scss";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

function FAQPage() {
  // const [bids, setBids] = React.useState({
  //   loading: true,
  //   data: [],
  // });
  // // const [bidsFilter, setBidsFilter] = React.useState({
  // //   filter: false,
  // //   data: null,
  // // });
  // const { userProfile } = React.useContext(AuthContext);
  // const [cookies] = useCookies();

  // async function getBids() {
  //   const data = {
  //     user: {
  //       account_id: userProfile?.account_id,
  //     },
  //     data: {
  //       records: 10,
  //       offset: 0,
  //     },
  //   };
  //   try {
  //     const response = await axios.post(project_bids_end_point, data, {
  //       headers: {
  //         access: cookies.urbexEnterpriseUserToken,
  //       },
  //     });
  //     // return console.log(response.data);
  //     // return response.data;
  //     setBids({
  //       loading: false,
  //       data: response.data.bids,
  //     });
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // }
  // React.useEffect(() => {
  //   getBids();
  // }, []);
  const FAQ = [
    {
      question: "How to add disable push notifications?",
      answer:
        ".",
    },
    {
      question: "Requirements for contractors?",
      answer:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi labore saepe nisi nemo magnam sit laboriosam expedita adipisci repellat, vel aliquid, repellendus nobis, totam possimus ipsum amet blanditiis. Incidunt, est.Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi labore saepe nisi nemo magnam sit laboriosam expedita adipisci repellat, vel aliquid, repellendus nobis, totam possimus ipsum amet blanditiis. Incidunt, est.",
    },
  ];
  return (
    <div className='FAQPage px-4 lg:py-10 xl:px-20'>
      <h1>Frequently asked questions</h1>
      <Accordion allowToggle>
        {FAQ.map((item, i) => (
          <AccordionItem key={i}>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  {item.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}

export default FAQPage;
