import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./OngoingProjectsPage.scss";
import { ProgressBarChart } from "./../../components/ProgressBarComponent/ProgressBarComponent";
import { Button, Icon } from "@chakra-ui/react";
import { MainDrawerComponent } from "../../components/AccountSetUpCard/DrawerComponent";
import project_icon from "../../assets/icons/project-icon.svg";
import { BsThreeDots } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { ProjectsContext } from "./../../context/ProjectsContext";
import { AuthContext } from "../../context/AuthContext";
import { FaDeleteLeft, FaListOl } from "react-icons/fa6";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useState } from "react";
import { delete_project_end_point,fetch_my_projects_end_point,pin_project_end_point } from "../../config/variables";
import { useCookies } from "react-cookie";
import axios from "axios";
import { TostMessageContext } from "../../context/TostMessage";
import ReactLoading from "react-loading";
import { MdOutlineManageSearch, MdOutlineSupervisedUserCircle } from "react-icons/md";
import Marquee from "react-fast-marquee";
import { useEffect } from "react";
import { TiPin, TiPinOutline } from "react-icons/ti";
import { GoogleMap, InfoWindow, InfoWindowF, Marker, MarkerF, OverlayView, OverlayViewF, useJsApiLoader } from '@react-google-maps/api';
import { GrMapLocation } from "react-icons/gr";
import { TbMap2 } from "react-icons/tb";
import { LuConstruction } from "react-icons/lu";
// import MarketIcon from '../../assets/images/logo.png'

let MarketIcon = { url: require('../../assets/images/logo2.png'), scaledSize: { width: 32, height: 32 } };

const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

function OngoingProjectsPage() {
  const [loading, setLoading] = React.useState(true);
  const { userProfile } = useContext(AuthContext);
  const {setTostMessage} = useContext(TostMessageContext)
  const [userPosition, setUserPosition] = useState({lat: '000.00', lon: '00.00'});
  const [cookies] = useCookies();
  const [showMapView, setShowMapView] = useState(localStorage.getItem('showMapView') || false);
  const [projects, setProjects] = React.useState({
    loading: false,
    data: [],
  });
  const [mapZoom, setMapZoom] = useState(8);
  const [deleting, setDeleting] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectsFilter, setProjectsFilter] = React.useState({
    filter: false,
    data: null,
  });

  const navigate = useNavigate();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDYz3AVQ0lJmcUChpOPGeV-m4HwcNFCYWg"
  })

  const FLIGHT_PLAN_COORDS = [
    { lat: 37.772, lng: -122.214 },
    { lat: 21.291, lng: -157.821 },
    { lat: -18.142, lng: 178.431 },
    { lat: -27.467, lng: 153.027 },
  ]

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map)
    setTimeout(function () {
      setMapZoom(12);
    }, 1500)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const getPosition = async () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position)
        setUserPosition({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setTostMessage({
            messageType: 'error',
            message: 'Location access denied. Please enable location permissions to use map view.',
          });
        } else {
          setTostMessage({
            messageType: 'error',
            message: 'Location not allowed. Cannot use map view.',
          });
        }
      },
    );
  }
  const { userProjects, getUserProjects } = React.useContext(ProjectsContext);
  React.useEffect(() => {
    getUserProjects();
    getPosition();
  }, []);
  React.useEffect(() => {
    if (userProjects.loading) {
      return;
    } else {
      if (userProjects?.data) {
        setProjects({
          data: [
            ...userProjects?.data?.ongoing_projects,
            ...userProjects?.data?.past_projects,
          ],
        });
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }
  }, [userProjects]);

  function filterProjects(i) {
    // console.log("filterprojects", i);
    if (i === 0) {
      // console.log(projects);
      return setProjectsFilter({
        filter: false,
        data: null,
      });
    }
    let filterArray = [];
    if (i === 1) {
      // console.log(projects);
      projects.data.map((project) => {
        if (project.project_status.project_stage == "IN-REVIEW") {
          filterArray.push(project);
        }
      });
    }
    if (i === 2) {
      // console.log(projects);
      projects.data.map((project) => {
        if (project.project_status.project_stage == "ACTIVE") {
          filterArray.push(project);
        }
      });
    }
    if (i === 3) {
      // console.log(projects);
      projects.data.map((project) => {
        if (project.project_status.project_stage == "PAUSED") {
          filterArray.push(project);
        }
      });
    }
    if (i === 4) {
      // console.log(projects);
      projects.data.map((project) => {
        if (
          project.project_status.project_stage == "PROJECT FINISHED"
        ) {
          filterArray.push(project);
        }
      });
    }
    // if (i === 3) {
    //   // console.log(projects);
    //   projects.data.map((project) => {
    //     if (project.status === "Rejected") {
    //       filterArray.push(project);
    //     }
    //   });
    // }
    setProjectsFilter({
      filter: true,
      data: filterArray,
    });
    // console.log("projectsFilter", projectsFilter);
  }

  async function getProjects() {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        search: searchParam,
        offset: 0,
        records: 30,
      },
    };
    try {
      const response = await axios.post(fetch_my_projects_end_point, data, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      if (response.data.statusCode === 401) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        // navigate("/login");
        // forceLogOutFunction();
        return;
      }
      if (!response.data.status) {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
        return;
      }
      setProjects({
        data: [
          ...response?.data?.ongoing_projects,
          ...response?.data?.past_projects,
        ],
      });
      // // console.log("Projects", userProfile.account_id, response.data);
      // return response.data.projects;
    } catch (error) {
      // console.log(error);
    }
  }

  const search = async (value) => {
    setSearchParam(value);
    if (value.length > 1) {
      getProjects();
    } else {
      setProjects({
        data: [
          ...userProjects?.data?.ongoing_projects,
          ...userProjects?.data?.past_projects,
        ],
      });
      setProjectsFilter({
        filter: false,
        data: null,
      });
    }
  }

  const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) { 
          console.log("bottom")
      }
  }

  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
      return {
          x: offsetWidth + labelAnchor.x,
          y: offsetHeight + labelAnchor.y,
      };
  };

  function calculatePercentage(total, completed) {
    const percentage = (Number(completed) / Number(total)) * 100;

    if (isNaN(Number(percentage))) {
      return 0;
    }
    return Math.round(percentage);
  }

  async function pinProject(id) {
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
      },
    });

    const headers = {
      access: cookies.urbexEnterpriseUserToken,
      "Content-Type": "application/json",
    };

    axios
      .post(pin_project_end_point, data, { headers })
      .then((response) => {
        if (!response.data.status) {
          setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
        if (response.data.status) {
          getUserProjects();
          setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
        }
      })
      .catch((error) => {
        setTostMessage({
          messageType: "error",
          message: 'Network error, please try again.',
        });
      });
  }

  async function deleteProject(id) {
    if (deleting) {
      return;
    }

    if (window.confirm("Are you sure you want to delete this project?")) {
      setDeleting(true);
      let data = JSON.stringify({
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          project_id: id,
        },
      });

      const headers = {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      };

      axios
        .post(delete_project_end_point, data, { headers })
        .then((response) => {
          if (!response.data.status) {
            setTostMessage({
              messageType: "error",
              message: response.data.response,
            });
          }
          if (response.data.status) {
            getUserProjects();
            setTostMessage({
              messageType: "success",
              message: response.data.response,
            });
          }
          setDeleting(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setDeleting(false);
        });
    } else {
      setDeleting(false);
    }
  }

  return (
    <div onScroll={handleScroll} className='OngoingProjectsPage xl:px-20 max-md:px-6 md:px-10 '>
      <div className='flex justify-between items-center py-3'>
        <div className="flex flex-row gap-x-1">
          {showMapView ? (
            <button onClick={() => {setShowMapView(false);localStorage.removeItem('showMapView')}} className='hidden md:block md:text-sm w-auto lg:text-md hover:opacity-[0.8] bg-native-blue h-50 hover:text-white font-bold py-2 px-3 rounded-full transition-all first-letter:bg-transparent border-native-blue border'>
              <FaListOl size={18} color={'#fff'} />
            </button>
          ) : (
            <button onClick={() => {setShowMapView(true);localStorage.setItem('showMapView', true);setTostMessage({ messageType: 'warning', message: 'Only projects with Lat & Lng set will appear in the Map View.'})}} className='hidden md:block md:text-sm w-auto lg:text-md hover:opacity-[0.8] bg-native-blue h-50 hover:text-white font-bold py-2 px-3 rounded-full transition-all first-letter:bg-transparent border-native-blue border'>
              <TbMap2 size={18} color={'#fff'} />
            </button>
          )}
          <MainDrawerComponent
            open={28}
            size={"sm"}
            card={{ sub: "Import Projects" }}
          >
            <button className='hidden md:block md:text-sm w-auto lg:text-md hover:bg-native-blue h-50 hover:text-white font-bold py-2 px-3 rounded-full transition-all first-letter:bg-transparent border-native-blue border'>
              Import
            </button>
          </MainDrawerComponent>
        </div>
        <div className="flex overflow-auto flex-column sm:!flex-row items-start md:items-center md:gap-x-2">
          <div className='w-full overflow-auto md:w-auto md:overflow-hidden'>
            <FilterScrollComponent
              filtersArray={[
                "All",
                "In-Review",
                "Ongoing",
                "Paused",
                "Completed",
              ]}
              handleFilerFunction={(i) => filterProjects(i)}
            />
          </div>
          <div className="search-filter-container w-full sm:w-auto">
            <div className="search-box rounded-full !border-none !outline-none w-full sm:w-auto flex flex-row justify-between">
              <input type="text" onKeyUp={(e) => {
                if (e.target.value == "") { 
                  setProjects({
                    data: [
                      ...userProjects?.data?.ongoing_projects,
                      ...userProjects?.data?.past_projects,
                    ],
                  });
                  setProjectsFilter({
                    filter: false,
                    data: null,
                  });
                } 
                setSearchParam(e.target.value)
              }} placeholder="Search" className="search-input cursor-text focus:!outline-none focus:!border-none !outline-none !border-none" />
              <MdOutlineManageSearch className="cursor-pointer" color={searchParam == "" ? `#000` : `#000aff`} onClick={() => search(searchParam)} size={20} />
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div>
          {[1, 2, 3, 4, 5, 6].map((i, index) => (
            <div
              key={index}
              className='h-24 w-full bg-gray-200 animate-pulse rounded-lg py-3 my-7'
            ></div>
          ))}
        </div>
      ) : (
        <>
          {projects?.data?.length !== 0 ? (
            <>
              {projects.loading ? (
                <>Loading...</>
              ) : (
                <div className="w-full h-full">
                  {projectsFilter.filter === false ? (
                    <div className="w-full h-full">
                    {isLoaded && showMapView ? (
                      <div className="w-full h-full">
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={{
                            lat: Number(projects.data[0].project_lat) || userPosition.lat,
                            lng: Number(projects.data[0].project_lon) || userPosition.lon
                          }}
                          yesIWantToUseGoogleMapApiInternals
                          zoom={mapZoom}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                          options={{ 
                            // icon: MarketIcon,
                            styles: [{ elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off", }], }, {featureType: "poi.business", stylers: [{ visibility: "off", }]}],
                            fullscreenControl: false,
                          }}
                        >
                          { /* Child components, such as markers, info windows, etc. */ }
                          {projects.data?.map((project, i) => {
                            return (
                              <> 
                              {project.project_lat != "" && project.project_lon != "" && (
                                <>
                                  <MarkerF key={i} position={{
                                    lat: Number(project.project_lat),
                                    lng: Number(project.project_lon)
                                  }} 
                                  // onClick={() =>navigate(`./${project?.project_id}`)} 
                                  onClick={() => setSelectedProject(i)}
                                  icon={MarketIcon}
                                  >
                                    {selectedProject == i && (
                                      <InfoWindowF 
                                        headerContent={<h3>{project.project_title}</h3>}
                                        position={{
                                          lat: Number(project.project_lat),
                                          lng: Number(project.project_lon)
                                        }}
                                        className="InfoWindow"
                                        >
                                          <div className="flex flex-column h-[auto] w-[200px] min-h-[150px] items-center pr-[12px] pb-3">
                                            <img src={project.project_images[project.project_images.length - 1]} className="h-auto w-[80%]" />
                                            <div className="flex flex-column mt-1 w-[80%] justify-center items-center">
                                              <span className='text-sm xl:text-md font-semibold'>
                                                {project?.project_status.project_milestones_completed +
                                                  " of " +
                                                  project?.project_status.project_milestones}{" "}
                                              </span>
                                              <span className='text-xs xl:text-md'>
                                                milestones completed
                                              </span>
                                            </div>
                                            <button onClick={() =>navigate(`./${project?.project_id}`)} className="bg-native-blue p-2 px-4 rounded-lg text-white font-bold">
                                              View Project
                                            </button>
                                            <div className="flex flex-column sm:!flex-row items-center">
                                              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                                                "create_project"
                                              ) ||
                                              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                                                "full_access"
                                              ) ? (
                                                <Link
                                                  className='p-1 md:p-3'
                                                  to={`/dashboard/edit-project/${project?.project_id}`}
                                                >
                                                  {/* <Icon as={BsThreeDots} /> */}
                                                  <FaRegEdit size={20} />
                                                </Link>
                                              ) : null}
                                              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                                                "delete_project"
                                              ) ||
                                              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                                                "full_access"
                                              ) ? (
                                                <Link
                                                  className='p-1 md:p-3'
                                                  onClick={() => deleteProject(project?.project_id)}
                                                >
                                                  {!deleting ? (
                                                    <RiDeleteBin5Line size={20} color='#ff0000' />
                                                  ) : (
                                                    <ReactLoading type={"cylon"} color='#ff0000' />
                                                  )}
                                                </Link>
                                              ) : null}
                                              <Link
                                                className='p-1 md:p-3'
                                                onClick={() => pinProject(project?.project_id)}
                                              >
                                                {project?.project_pin ? (
                                                  <TiPin size={25} color="#000aff" />
                                                ) : (
                                                  <TiPinOutline size={25} color="#000aff" />
                                                )}
                                              </Link>
                                              </div>
                                              {project?.unapproved_reports > 0 && (
                                                <span className='text-xs font-semibold xl:text-sm leading-tight text-[#ff8a30]'>
                                                  {project?.unapproved_reports} unapproved report(s)
                                                </span>
                                              )}
                                          </div>              
                                      </InfoWindowF>
                                    )}
                                  </MarkerF>
                                  <OverlayViewF
                                    key={`OverL`+i}
                                    position={{
                                      lat: Number(project.project_lat),
                                      lng: Number(project.project_lon)
                                    }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y, {     x: -150, y: -40 })}>
                                    <div
                                        style={{
                                            background: `#203254`,
                                            width: 'auto',
                                            maxWidth: '100px',
                                            padding: `7px 12px`,
                                            fontSize: '11px',
                                            color: `white`,
                                            borderRadius: '4px',
                                        }}
                                    >
                                      {project.project_title} ({calculatePercentage(project?.project_status?.project_milestones || 0,project?.project_status?.project_milestones_completed || 0)}%)
                                    </div>
                                  </OverlayViewF>
                                  </>
                                )}
                              </>
                            )
                          })}

                        </GoogleMap>
                      </div>
                    ) : (
                      <>
                      {projects.data?.map((project, i) => {
                        return (
                          <div className='mx-auto' key={i}>
                            <OngoingProjectCard project={project} />
                          </div>
                        );
                      })}
                      </>
                    )}
                    </div>
                  ) : null}
                  {projectsFilter.filter === true ? (
                    <>
                      {projectsFilter.data === null ||
                      projectsFilter.data.length <= 0 ? (
                        <>Nothing to show...</>
                      ) : (
                        <>
                          {projectsFilter.data?.map((project, i) => {
                            return (
                              <div className='mx-auto' key={i}>
                                <OngoingProjectCard project={project} />
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              )}

              {/* <h1>Completed</h1>
              {projects.data?.map((project) => {
                return <>hi</>;
              })} */}
            </>
          ) : (
            <div className='no-project'>
              <h1>You have not created any projects</h1>
              <Link to='/dashboard/create-project'>Create New Project</Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OngoingProjectsPage;

export function OngoingProjectCard({ project }) {
  // const { project_milestones, project_milestones_completed } = project;
  const { userProfile } = useContext(AuthContext);
  const [deleting, setDeleting] = useState(false);
  const [cookies] = useCookies();
  const { setTostMessage } = React.useContext(TostMessageContext);
  const { userProjects, getUserProjects } = React.useContext(ProjectsContext);

  function calculatePercentage(total, completed) {
    const percentage = (Number(completed) / Number(total)) * 100;

    if (isNaN(Number(percentage))) {
      return 0;
    }
    return Math.round(percentage);
  }

  async function pinProject(id) {
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
      },
    });

    const headers = {
      access: cookies.urbexEnterpriseUserToken,
      "Content-Type": "application/json",
    };

    axios
      .post(pin_project_end_point, data, { headers })
      .then((response) => {
        if (!response.data.status) {
          setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
        if (response.data.status) {
          getUserProjects();
          setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
        }
      })
      .catch((error) => {
        setTostMessage({
          messageType: "error",
          message: 'Network error, please try again.',
        });
      });
  }

  async function deleteProject(id) {
    if (deleting) {
      return;
    }

    if (window.confirm("Are you sure you want to delete this project?")) {
      setDeleting(true);
      let data = JSON.stringify({
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          project_id: id,
        },
      });

      const headers = {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      };

      axios
        .post(delete_project_end_point, data, { headers })
        .then((response) => {
          if (!response.data.status) {
            setTostMessage({
              messageType: "error",
              message: response.data.response,
            });
          }
          if (response.data.status) {
            getUserProjects();
            setTostMessage({
              messageType: "success",
              message: response.data.response,
            });
          }
          setDeleting(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setDeleting(false);
        });
    } else {
      setDeleting(false);
    }
  }

  return (
    <Link
      to={`./${project?.project_id}`}
      className='projectCard flex p-3 md:p-4 lg:p-8 justify-between items-center h-auto md:h-28 lg:h-28 bg-white rounded-2xl my-3'
    >
      <div className='hidden md:block lg:block'>
        <img src={project_icon} />
      </div>
      <div className='w-[60%] md:w-[35%] flex flex-column'>
        <h2 className='text-sm xl:text-md leading-tight'>
          {project?.project_title}
        </h2>
        <span className='text-xs xl:text-sm leading-tight font-bold text-[#000aff]'>
          {project?.project_status?.project_stage}
        </span>
        <span className='text-xs xl:text-sm leading-tight'>
          {project?.site_address}
        </span>
        <span className='flex flex-row items-center text-xs xl:text-sm leading-tight'>
          <MdOutlineSupervisedUserCircle className="mr-2" />
          <Marquee gradient={true} pauseOnHover={true} gradientWidth={100}>
            <a className="text-[#000]">{project?.created_by_name}</a>
            {project?.project_members_scroll?.map((item, index) => {
              return <a className="mr-1 text-[#000]">, {item.sub_account_name}</a>
            })}
          </Marquee>
        </span>
        {project?.unapproved_reports > 0 && (
          <span className='text-xs font-semibold xl:text-sm leading-tight text-[#ff8a30]'>
            {project?.unapproved_reports} unapproved report(s)
          </span>
        )}
      </div>
      <div>
        <ProgressBarChart
          percentage={calculatePercentage(
            project?.project_status?.project_milestones || 0,
            project?.project_status?.project_milestones_completed || 0
          )}
        />
      </div>
      <div className='hidden md:flex flex-column'>
        <span className='text-sm xl:text-md'>Milestones</span>{" "}
        <h1 className='text-[20px]'>
          {project?.project_status.project_milestones}
        </h1>{" "}
        <span className='text-sm xl:text-md'>
          {project?.project_status.project_milestones_completed +
            " of " +
            project?.project_status.project_milestones}{" "}
          completed
        </span>
      </div>
      <div className="flex flex-column sm:!flex-row items-center">
      {userProfile?.sub_account_overview?.allowed_permissions?.includes(
        "create_project"
      ) ||
      userProfile?.sub_account_overview?.allowed_permissions?.includes(
        "full_access"
      ) ? (
        <Link
          className='p-1 md:p-3'
          to={`/dashboard/edit-project/${project?.project_id}`}
        >
          {/* <Icon as={BsThreeDots} /> */}
          <FaRegEdit size={20} />
        </Link>
      ) : null}
      {userProfile?.sub_account_overview?.allowed_permissions?.includes(
        "delete_project"
      ) ||
      userProfile?.sub_account_overview?.allowed_permissions?.includes(
        "full_access"
      ) ? (
        <Link
          className='p-1 md:p-3'
          onClick={() => deleteProject(project?.project_id)}
        >
          {!deleting ? (
            <RiDeleteBin5Line size={20} color='#ff0000' />
          ) : (
            <ReactLoading type={"cylon"} color='#ff0000' />
          )}
        </Link>
      ) : null}
      <Link
        className='p-1 md:p-3'
        onClick={() => pinProject(project?.project_id)}
      >
        {project?.project_pin ? (
          <TiPin size={25} color="#000aff" />
        ) : (
          <TiPinOutline size={25} color="#000aff" />
        )}
      </Link>
      </div>
    </Link>
  );
}

export function FilterScrollComponent({ filtersArray, handleFilerFunction }) {
  const [active, setActive] = React.useState(0);
  return (
    <div className='FilterScrollComponent flex-col'>
      {/* <div className='text-start w-full mt-4'>Filter</div> */}
      <div className='filter-container m-0'>
        {filtersArray?.map((filter, i) => (
          <button
            key={i}
            className={active === i ? "active text-sm text-white" : " text-sm"}
            onClick={() => {
              setActive(i);
              handleFilerFunction(i);
            }}
          >
            {filter}
          </button>
        ))}
      </div>
    </div>
  );
}
