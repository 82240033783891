import React from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlus } from '@fortawesome/free-solid-svg-icons'
import "./FileUpload.scss";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
// import axios from "axios";
// import { BsPlus } from "react-icons/bs";

const FileUpload = ({ files, setFiles, removeFile, supportedFiles, accept }) => {
  const {userProfile} = useContext(AuthContext)

  const uploadHandler = (event) => {
    const newFiles = event.target.files;
    // console.log("uploadHandler", files);
    if (!files) return;
    // file.isUploading = true;

    setFiles([...files, ...newFiles]);
    // upload file
    const formData = new FormData();
    // formData.append("newFile", file, file.name);
    // axios
    //   .post("http://localhost:8080/upload", formData)
    //   .then((res) => {
    //     file.isUploading = false;
    //     setFiles([...files, file]);
    //   })
    //   .catch((err) => {
    //     // inform the user
    //     console.error(err);
    //     removeFile(file.name);
    //   });
  };
  
  return (
    <div className="FileUpload">
      <div className="file-card hover:scale-95 transition-all">
        <div className="file-inputs">
          <input type="file" multiple accept={accept} onChange={(e) => uploadHandler(e)} />
          <button className="bg-secondary rounded-full">Upload Files</button>
        </div>

        <p className="main">Supported files</p>
        <p className="info">{supportedFiles  && supportedFiles != null ? supportedFiles : 'PDFs, Docs, Images'}</p>
        <b className="text-xs text-[#000aff]">{userProfile?.account_presets?.total_size_used} of {userProfile?.account_presets?.total_storage_limit} storage used</b>
      </div>
    </div>
  );
};

export default FileUpload;
