import React, { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useCookies } from "react-cookie";
import {
  fetch_user_profile_end_point,
  save_user_profile_end_point,
} from "../../config/variables";
import coverImag from "../../assets/images/profile-card-cover-image.jpg";
import call_icon from "../../assets/icons/call_icon.svg";
import location_icon from "../../assets/icons/location_icon.svg";
import email_icon from "../../assets/icons/email_icon.svg";
import bell_icon from "../../assets/icons/bell_icon.svg";
import language_icon from "../../assets/icons/language_icon.svg";
import lock_icon from "../../assets/icons/lock_icon.svg";
import password_icon from "../../assets/icons/password_icon.svg";
import stats_icon from "../../assets/icons/stats_icon.svg";
import "./ProfilePage.scss";
import { TbSection } from "react-icons/tb";
import { FaRegAddressCard } from "react-icons/fa6";
import { IoMailUnreadOutline } from "react-icons/io5";
import { TostMessageContext } from "../../context/TostMessage";
import {
  Avatar,
  Box,
  FormLabel,
  Input,
  Select,
  Icon,
  Button,
} from "@chakra-ui/react";
import { MainDrawerComponent } from "../../components/AccountSetUpCard/DrawerComponent";
import { useContext } from "react";

function ProfilePage() {
  const { userProfile, getUserProfileFunction } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies();
  const [userTitle, setTitle] = useState("");
  const [userFirstname, setFname] = useState("");
  const [userLastname, setLname] = useState("");
  const [userPhone, setPhone] = useState("");
  const [userGender, setGender] = useState("");
  const { setTostMessage } = useContext(TostMessageContext);
  const [typing, setTyping] = useState(false);
  const [saving, setSaving] = useState(false);
  const [profileForm, setProfileForm] = React.useState({
    // title: 'Loading...',
    // account_status: "Loading...",
    // account_type: "Loading...",
    // address: "Loading...",
    // country: "Loading...",
    // display_picture: "Loading...",
    // email: "Loading...",
    // gender: "Loading...",
    // name: "",
    // occupation: "Loading...",
    // portfolio: [],
    // state: "Loading...",
  });
  const [saved_profileForm, setSavedProfileForm] = useState({});

  async function getProfile() {
    setLoading(true);
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        profile_id: userProfile?.sub_account_id,
      },
    };
    try {
      const response = await axios.post(fetch_user_profile_end_point, data, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      const resData = await response.data;
      setProfileForm(resData.profile);
      setSavedProfileForm(resData.profile);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  }

  async function saveProfileChanges() {
    setLoading(true);
    setSaving(true);
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        userFirstname: profileForm?.first_name,
        userLastname: profileForm?.last_name,
        userGender: profileForm?.gender,
        userPhone: profileForm?.phone_number,
        userTitle: profileForm?.title,
      },
    };
    try {
      const response = await axios.post(save_user_profile_end_point, data, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      const resData = await response.data;
      if (resData.status) {
        setTostMessage({
          messageType: "success",
          message: "Profile updated.",
        });
        setTyping(false);
      } else {
        setTostMessage({
          messageType: "error",
          message: resData.response,
        });
      }
      setLoading(false);
      setSaving(false);
    } catch (error) {
      setLoading(false);
      setSaving(false);
      setTostMessage({
        messageType: "error",
        message: "Could not save changes, please try again.",
      });
    }
  }
  const handelChanges = (e) => {
    setTyping(true);
    setProfileForm((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  React.useEffect(() => {
    getProfile();
    // getUserProfileFunction();
  }, []);
  return (
    <div className='ProfilePage px-5'>
      <div className='top-container mt-5'>
        <b>Profile Details</b> / Edit Profile
      </div>

      <div className=''>
        <div className='flex flex-col-reverse xl:grid xl:grid-cols-3 gap-4'>
          <div className='col-span-2'>
            <div className='profile-form-container'>
              <div className='top-container'>
                {/* <h2>Edit Profile</h2> */}
                {typing ? (
                  <div className='button-container'>
                    <button
                      onClick={() => {
                        setProfileForm(saved_profileForm);
                        setTyping(false);
                      }}
                      className='bg-gray-300'
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => saveProfileChanges()}
                      className='bg-native-blue px-3 py-1 text-white rounded-full'
                    >
                      {!saving ? "Save Changes" : "Saving..."}
                    </button>
                  </div>
                ) : null}
              </div>
              <form action='' className='mt-4 row'>
                <div className='divider-container my-2'>
                  <b>General</b> <hr />
                </div>
                <div className='col-md-6'>
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='title'>
                      Title
                    </FormLabel>
                    <Input
                      id='title'
                      name='title'
                      defaultValue={profileForm?.title}
                      value={profileForm?.title}
                      // onChange={(e) => setTitle(e.target.value)}
                      onChange={(e) => handelChanges(e)}
                    />
                  </Box>
                </div>
                <div className='col-md-6'>
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='first_name'>
                      First name
                    </FormLabel>
                    <Input
                      name='first_name'
                      value={profileForm?.first_name}
                      onChange={(e) => handelChanges(e)}
                    />
                  </Box>
                </div>
                <div className='col-md-6'>
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='name'>
                      Last name
                    </FormLabel>
                    <Input
                      id='name'
                      name='last_name'
                      value={profileForm?.last_name}
                      onChange={(e) => handelChanges(e)}
                    />
                  </Box>
                </div>
                {/* <div className="col-md-6">
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor="gender">
                      Gender
                    </FormLabel>
                    <Select
                      id="gender"
                      name="gender"
                      onChange={(e) => handelChanges(e)}
                    >
                      <option value={profileForm?.gender}>
                        {' '}
                        {profileForm?.gender}
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Select>
                  </Box>
                </div> */}
                {/* <div className="col-md-6">
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor="Password">
                      Password
                    </FormLabel>
                    <Input
                      id="Password"
                      name="Password"
                      type={'password'}
                      defaultValue={'password'}
                    />
                  </Box>
                </div> */}
                {/* <div className="col-md-6">
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor="occupation">
                      Occupation
                    </FormLabel>
                    <Input
                      id="occupation"
                      name="occupation"
                      defaultValue={profileForm?.occupation}
                    />
                  </Box>
                </div> */}
                <div className='divider-container my-4'>
                  <b>Contacts</b> <hr />
                </div>
                <div className='col-md-6'>
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='Phone_number'>
                      Phone number
                    </FormLabel>
                    <Input
                      id='phone_number'
                      name='phone_number'
                      defaultValue={profileForm?.phone_number}
                      value={profileForm?.phone_number}
                      onChange={(e) => handelChanges(e)}
                      // onChange={(e) => handleChange(e)}
                    />
                  </Box>
                </div>
                <div className='col-md-6'>
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='email'>
                      Email
                    </FormLabel>
                    <Input
                      id='email'
                      name='email'
                      defaultValue={profileForm?.email}
                      readOnly
                      // onChange={(e) => handleChange(e)}
                      // {/* // placeholder="Please enter user name" */}
                    />
                  </Box>
                </div>
                <div className='col-md-6'>
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='state'>
                      Organization State
                    </FormLabel>
                    <Select
                      id='state'
                      defaultValue=' '
                      name='state'
                      // onChange={(e) => handleChange(e)}
                    >
                      <option value={profileForm?.state}>
                        {profileForm?.state}
                      </option>
                    </Select>
                  </Box>
                </div>
                <div className='col-md-6'>
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='country'>
                      Organization Country
                    </FormLabel>
                    {/* <Input
                      id="country"
                      name="country"
                      defaultValue={profileForm?.country}
                    /> */}
                    <Select
                      id='country'
                      name='country'
                      // onChange={(e) => handleChange(e)}
                    >
                      <option value={profileForm?.country}>
                        {profileForm?.country}
                      </option>
                    </Select>
                  </Box>
                </div>
                <div className='col-md-12'>
                  <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='address'>
                      Organization Address
                    </FormLabel>
                    <Input
                      id='address'
                      name='address'
                      defaultValue={profileForm?.address}
                      value={profileForm?.address}
                      // onChange={(e) => handleChange(e)}
                      // {/* // placeholder="Please enter user name" */}
                    />
                  </Box>
                </div>
              </form>
            </div>
            {/* <div className="">
              <div className="h2 d-flex gap-3">
                About 
                <div className="icon_container">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                className="form-control "
              ></textarea>
            </div> */}
          </div>
          <div className='col-span-1'>
            <div className='profile-card'>
              {/* <div className='w-full'>
                <img
                  className='w-full h-48 object-cover'
                  src={coverImag}
                  alt=''
                />
              </div> */}
              <div className='profile-image-container'>
                {/* <Avatar /> */}
                <Avatar src={profileForm?.display_picture} />
              </div>
              <h3> {profileForm?.name}</h3>
              <p> {profileForm?.occupation}</p>

              {/* <Button>Payment Verified</Button> */}

              <div className='more-info-container'>
                <div>
                  <div className='icon-container'>
                    <TbSection color='#000aff' size={20} />
                  </div>
                  {profileForm?.role}
                </div>
                <div>
                  <div className='icon-container'>
                    {/* <Icon /> */}
                    {/* <img src={location_icon} /> */}
                    <FaRegAddressCard color='#000aff' size={20} />
                  </div>
                  {profileForm?.state}, {profileForm?.country}
                </div>
                <div>
                  <div className='icon-container'>
                    {/* <Icon /> */}
                    {/* <img src={email_icon} /> */}
                    <IoMailUnreadOutline color='#000aff' size={20} />
                  </div>
                  {profileForm?.email}
                </div>
              </div>
              <div className='more-info-container w-full'>
                <OpenSettingsButton sub={"Password & Security"} open={11}>
                  <div className='w-full flex items-center gap-3 py-1 px-3'>
                    <div className='icon-container'>
                      {/* <Icon /> */}
                      <img src={lock_icon} />
                    </div>
                    <div className='d-flex justify-content-between w-100 ps-1'>
                      Password & Security
                    </div>
                  </div>
                </OpenSettingsButton>
                <OpenSettingsButton sub={"Notifications"} open={9}>
                  <div className='w-full flex items-center gap-3 py-1 px-3'>
                    <div className='icon-container'>
                      <img src={bell_icon} />
                    </div>
                    <div className='d-flex justify-content-between w-100 ps-1'>
                      Notifications <span className='text-[#000aff]'>ON</span>
                    </div>
                  </div>
                </OpenSettingsButton>
                <OpenSettingsButton sub={"Language"} open={10}>
                  <div className='w-full flex items-center gap-3 py-1 px-3'>
                    <div className='icon-container'>
                      {/* <Icon /> */}
                      <img src={language_icon} />
                    </div>
                    <div className='d-flex justify-content-between w-100 ps-1'>
                      Language <span className='text-[#000aff]'> English</span>
                    </div>
                  </div>
                </OpenSettingsButton>
                <OpenSettingsButton sub={" Privacy policy"} open={12}>
                  <div className='w-full flex items-center gap-3 py-1 px-3'>
                    <div className='icon-container'>
                      {/* <Icon /> */}
                      <img src={password_icon} />
                    </div>
                    <div className='d-flex justify-content-between w-100 ps-1'>
                      Privacy policy
                    </div>
                  </div>
                </OpenSettingsButton>
                {/* <OpenSettingsButton sub={'Stats'} open={13}>
                  {' '}
                  <div className="w-full flex items-center gap-3 py-1 px-3">
                    <div className="icon-container">
                      <img src={stats_icon} />
                    </div>
                    <div className="d-flex justify-content-between w-100 ps-1">
                      Stats
                    </div>
                  </div>
                </OpenSettingsButton> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;

function CreatePortfolioButton({ children }) {
  return (
    <MainDrawerComponent open={7} card={{ sub: "Add Portfolio" }}>
      {children}
    </MainDrawerComponent>
  );
}
function OpenSettingsButton({ children, sub, open }) {
  return (
    <div className='bg-gray-100 my-2 cursor-pointer rounded-lg'>
      <MainDrawerComponent open={open} card={{ sub }}>
        {children}
      </MainDrawerComponent>
    </div>
  );
}

export function PortfolioCard({ portfolio }) {
  const [images, setImages] = React.useState(portfolio?.files);
  const [currentImage, setCurrentImage] = React.useState(0);
  const min = 0;
  const max = images.length - 1;
  function nextImage() {
    if (currentImage + 1 > max) {
      return setCurrentImage(min);
    }

    setCurrentImage(currentImage + 1);
  }
  function prevImage() {
    if (currentImage - 1 < min) {
      return setCurrentImage(max);
    }
    setCurrentImage(currentImage - 1);
  }
  return (
    <div className='PortfolioCard'>
      <div className='image-container'>
        {max <= 0 ? null : (
          <button
            className='left'
            onClick={() => {
              prevImage();
            }}
          >
            {"<"}
          </button>
        )}
        <MainDrawerComponent
          open={8}
          card={{ sub: portfolio?.project_title }}
          project={portfolio}
        >
          <img src={images[currentImage]?.file_name} alt='' />
        </MainDrawerComponent>
        {max <= 0 ? null : (
          <button
            className='right'
            onClick={() => {
              nextImage();
            }}
          >
            {">"}
          </button>
        )}
      </div>
      <h3>{portfolio?.project_title}</h3>
    </div>
  );
}
