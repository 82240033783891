import { Avatar, Icon } from "@chakra-ui/react";
import React, {
  useState,
  useRef,
  ChangeEvent,
  useEffect,
  useContext,
} from "react";
import { BsEmojiSmile, BsCardImage } from "react-icons/bs";
import { BiDotsVertical, BiPhone } from "react-icons/bi";
import { FiVideo } from "react-icons/fi";
import axios from "axios";
import "./ChatPage.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import pin_icon from "../../assets/icons/pin-icon.svg";
import send_icon from "../../assets/icons/send-icon.svg";
import { MdSend, MdCloudDownload } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import io from "socket.io-client";
import { useCookies } from "react-cookie";
import { file_upload_endpoint } from "../../config/variables";
import { useLocation, Link } from "react-router-dom";
import { AuthContext } from "./../../context/AuthContext";
import { VideoCallContext } from "../../context/videoCallContext";
import {
  FaFileImage,
  FaFilePdf,
  FaFileArchive,
  FaFileAudio,
  FaFile,
} from "react-icons/fa";

function ChatPage() {
  const currentUrl = useLocation();

  const chatServerUrl = "https://mgtplatform.herokuapp.com/";
  const [cookies] = useCookies();

  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);
  const { userProfile } = useContext(AuthContext);
  // const [refresh, setRefresh] = useState(false);
  // const [state, setState] = useState([]);
  const [chatObject, setChatObject] = useState(null);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileUploadingStage, setFileUploadingStage] = useState(0);

  // fileCard States
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [icon, setIcon] = React.useState(null);
  const [generatedURL, setGeneratedURL] = React.useState(null);
  const [imageSelected, setImageSelected] = React.useState(false);

  React.useEffect(() => {
    let PMPChatObject = JSON.parse(sessionStorage.getItem("PMPChatObject"));
    setChatObject(PMPChatObject);
    // console.log("PMPChatObject", PMPChatObject);
  }, [currentUrl]);

  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  // const [textInput, setTextInput] = useState({});

  var textInput = {};

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  async function getPojectChat() {
    joinChatRoom();

    if (socket === null) return;

    socket.on("messages", (messages) => {
      setMessages(messages.reverse());
      setLoading(false);
      messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
      // console.log("messages", messages);
    });

    socket.on("new_message", (message) => {
      // console.log("New message", message);
      setMessages((curr) => [...curr, ...message]);
      scrollToBottom();
    });
  }

  function joinChatRoom() {
    // console.log("user_profile", userProfile);
    const token = cookies.urbexEnterpriseUserToken || null;
    socket?.emit("join-chat-room", {
      access: token,
      account_id: userProfile?.account_id,
      client_id: chatObject?.client_id,
      contractor_id: chatObject?.contractor_id,
      project_id: chatObject?.project_id,
      // pm_to: chatObject?.pm_to
    });
  }

  useEffect(() => {
    getPojectChat();
  }, [socket]);
  useEffect(() => {
    setLoading(true);
    const newSocket = io(chatServerUrl, {
      forceNew: true,
      reconnectionAttempts: 15,
    });
    // console.log("newSocket", newSocket);
    setSocket(newSocket);

    getPojectChat();
    return () => {
      newSocket.disconnect();
      console.log("chat disconnected");
    };
  }, [currentUrl]);
  // const onEndReached = ({ distanceFromEnd }) => {
  //   console.log("distanceFromEnd", distanceFromEnd);
  //   if (distanceFromEnd < 0) return;
  //   //   fetchData();
  // };

  // const openEmojiKeyboard = () => {
  //   TextInput.State.currentlyFocusedInput()?.setNativeProps({
  //     openEmojiKeyboard,
  //   });
  // };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(() => e.target.files);
      // console.log(file);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files) {
      setImage(() => e.target.files);
      // console.log(image);
    }
  };

  const uploadProgress = (progressEvent) => {
    var Percentage = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setFileUploadingStage(Percentage);
    // console.log(progressEvent.loaded, progressEvent.total);
    // console.log(
    //   "Upload progress: " +
    //     Math.round((progressEvent.loaded / progressEvent.total) * 100) +
    //     "%"
    // );
  };

  const sendMessage = async (message) => {
    // console.log(document.getElementById("select-file").files);
    if (file?.length > 0) {
      const formData = new FormData();
      var media_name = "";
      formData.append("account_id", userProfile?.account_id);
      formData.append("project_id", chatObject?.project_id);
      formData.append("type", "document");
      formData.append(
        "fileToUpload[]",
        document.getElementById("select-file").files[0]
      );

      try {
        setFileUploading(true);
        const response = await axios.post(file_upload_endpoint, formData, {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: uploadProgress,
        });
        const resData = await response.data;
        console.log("resData", resData);
        if (!resData.status) {
          // setError(resData.response);
          return;
        }
        setFileUploading(false);
        setFileUploadingStage(0);
        closeFileCardPreview();
        media_name = resData.media_name;
      } catch (error) {
        console.log(error);
        setFileUploading(false);
        setFileUploadingStage(0);
      }

      // file?.map((item) => {
      //   console.log(item.uri)
      //   return formData.append("fileToUpload[]", {
      //     uri: item.uri,
      //     name: item.name,
      //     type: item.type,
      //     size: item.size,
      //   })
      // })

      // const userData = await AsyncStorage.getItem("@urbexEnterpriseUserToken");
      // console.log("message", message);
      const token = cookies.urbexEnterpriseUserToken || null;

      socket?.emit("new_message", {
        access: token,
        sender_id: userProfile?.account_id,
        client_id: chatObject?.client_id,
        contractor_id: chatObject?.contractor_id,
        project_manager_id: chatObject?.project_manager_id,
        project_id: chatObject?.project_id,
        message: message,
        image: "",
        audio: "",
        video: "",
        message_type: "chat",
        document: media_name,
      });
    } else if (image?.length > 0) {
      const formData = new FormData();
      var media_name = [];
      formData.append("account_id", userProfile?.account_id);
      formData.append("project_id", chatObject?.project_id);
      formData.append("type", "image");
      formData.append(
        "fileToUpload[]",
        document.getElementById("select-image").files[0]
      );

      try {
        setFileUploading(true);
        const response = await axios.post(file_upload_endpoint, formData, {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: uploadProgress,
        });
        const resData = await response.data;
        // console.log("resData", resData);
        if (!resData.status) {
          return;
        }

        setFileUploading(false);
        setFileUploadingStage(0);
        // close fileCard on upload done
        closeFileCardPreview();

        media_name = resData.media_name;
      } catch (error) {
        setFileUploading(false);
        setFileUploadingStage(0);
        console.log(error);
      }

      const token = cookies.urbexEnterpriseUserToken || null;

      socket?.emit("new_message", {
        access: token,
        sender_id: userProfile?.account_id,
        client_id: chatObject?.client_id,
        contractor_id: chatObject?.contractor_id,
        project_manager_id: chatObject?.project_manager_id,
        project_id: chatObject?.project_id,
        message: message,
        image: media_name[0],
        audio: "",
        video: "",
        message_type: "chat",
        document: [],
      });
    } else {
      const token = cookies.urbexEnterpriseUserToken || null;

      socket?.emit("new_message", {
        access: token,
        sender_id: userProfile?.account_id,
        client_id: chatObject?.client_id,
        contractor_id: chatObject?.contractor_id,
        project_manager_id: chatObject?.project_manager_id,
        project_id: chatObject?.project_id,
        message: message,
        image: "",
        audio: "",
        video: "",
        message_type: "chat",
        document: [],
      });
    }
  };
  function closeFileCardPreview() {
    setSelectedFiles(null);
    setFile(null);
    setImage(null);
    setIcon(null);
    setGeneratedURL(null);
    setImageSelected(false);
    setFileUploading(false);
    setFileUploadingStage(0);
  }
  const { setCallButtonClicked, setDialing } = useContext(VideoCallContext);
  return (
    <div className='ChatPage'>
      <div className='top-section'>
        <div className='profile-container'>
          <Avatar />
          <b className="text-sm font-semibold sm:text-[1rem]">{chatObject?.project_title}</b>
        </div>
        <div className='icon-container'>
          <BiPhone />
          <Link to='./call'>
            <FiVideo
              onClick={() => {
                setCallButtonClicked(true);
                const token = cookies.urbexEnterpriseUserToken || null;
                socket?.emit("new_message", {
                  access: token,
                  sender_id: userProfile?.account_id,
                  client_id: chatObject?.client_id,
                  contractor_id: chatObject?.contractor_id,
                  project_manager_id: chatObject?.project_manager_id,
                  project_id: chatObject?.project_id,
                  message: "",
                  image: "",
                  audio: "",
                  video: "",
                  message_type: "call_request",
                  document: [],
                });
                // setDialing(true);
              }}
            />
          </Link>
          <BiDotsVertical />
        </div>
      </div>
      <hr />
      <div className='chat-section'>
        {loading ? (
          <div className='text-center mt-3 fs-3 fw-bold'>
            Loading Chats ....
          </div>
        ) : (
          <>
            {messages.length <= 0 ? (
              <div className='text-center mt-3 fs-3 fw-bold'>
                No messages available
              </div>
            ) : (
              <>
                {/* {console.log(userProfile?.account_id)} */}
                {messages?.map((chat, i) => (
                  <>
                    {chat.message_type === "chat" ? (
                      <ChatCard
                        chatClass={
                          chat.sender === userProfile?.account_id ? "me" : "you"
                        }
                        message={chat}
                        key={i}
                      />
                    ) : null}
                  </>
                ))}
                <div style={{ height: 10, width: 10 }} ref={messagesEndRef} />
              </>
            )}
          </>
        )}
      </div>

      <div className='file-selection'>
        <FileCard
          file={file || image}
          setFile={setFile}
          setImage={setImage}
          sendMessage={sendMessage}
          fileUploading={fileUploading}
          fileUploadingStage={fileUploadingStage}
          setFileUploadingStage={setFileUploadingStage}
          FileState={{
            selectedFiles,
            setSelectedFiles,
            icon,
            setIcon,
            generatedURL,
            setGeneratedURL,
            imageSelected,
            setImageSelected,
            closeFileCardPreview,
          }}
        />
      </div>
      <div className='bottom-section'>
        <div className='icon-container'>
          {/* <Icon /> */}
          {/* <Icon /> */}
          <label htmlFor='select-file'>
            <img src={pin_icon} alt='send' width={"35px"} />
          </label>
          <input
            type='file'
            id='select-file'
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <label htmlFor='select-image'>
            <BsCardImage style={{ width: "28px" }} />
          </label>
          <input
            type='file'
            id='select-image'
            onChange={handleImageChange}
            style={{ display: "none" }}
            accept='image/*'
          />
          <BsEmojiSmile />
        </div>
        <input
          type='text'
          className='form-control'
          placeholder={`${
            selectedFiles ? "Caption (Optional)" : "Message here..."
          }`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />{" "}
        <div className='icon-container'>
          <button
            className='send-button'
            onClick={() => {
              if (inputValue === "" && file?.length < 1 && image?.length < 1)
                return;
              sendMessage(inputValue);
              setInputValue("");
            }}
          >
            <MdSend />
            {/* <img src={send_icon} alt="send" /> */}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;

function ChatCard({ chatClass, message }) {
  return (
    <div className={`ChatCard ${chatClass}`}>
      {message.image != "" && message.image != null ? (
        <img
          style={{ width: "100%", height: 200, borderRadius: 5 }}
          src={message.image}
        />
      ) : null}
      {message.document?.length > 0 ? (
        <div
          style={{
            width: "100%",
            padding: 5,
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Icon as={MdCloudDownload} />
          <a style={{ fontWeight: "bold", fontSize: "10px", marginLeft: 5 }}>
            {"Download " + message.document?.length + " attachment(s)"}
          </a>
        </div>
      ) : null}
      <div style={{ width: "100%" }}>{message.message}</div>
      <p>{message.time}</p>
    </div>
  );
}
function FileCard({ file, fileUploading, fileUploadingStage, FileState }) {
  const {
    selectedFiles,
    setSelectedFiles,
    icon,
    setIcon,
    generatedURL,
    setGeneratedURL,
    imageSelected,
    setImageSelected,
    closeFileCardPreview,
  } = FileState;
  useEffect(() => {
    if (imageSelected) {
      setGeneratedURL(URL.createObjectURL(selectedFiles));
      return () => {
        if (selectedFiles) {
          URL.revokeObjectURL(URL.createObjectURL(selectedFiles));
        }
      };
    }
  }, [imageSelected]);
  useEffect(() => {
    setImageSelected(() => false);
    if (selectedFiles?.type.split("/")[0] === "image") {
      return setImageSelected(true);
    }
    if (selectedFiles?.type.split("/")[0] === "audio") {
      return setIcon(<FaFileAudio />);
    }
    if (selectedFiles?.type.split("/")[0] === "application") {
      if (selectedFiles.type.split("/")[1] === "pdf") {
        return setIcon(<FaFilePdf />);
      }
      if (selectedFiles?.type.split("/")[1] === "zip") {
        return setIcon(<FaFileArchive />);
      }
    }
    return setIcon(<FaFile />);
  }, [selectedFiles]);
  useEffect(() => {
    if (file) {
      setSelectedFiles(() => file[0]);
      // console.log("file: ", file[0]);
    }
  }, [file]);

  function calculateSelectedFileSize(file) {
    let fileSizeInBytes = file?.size;
    let fileSize;
    let unit;

    if (fileSizeInBytes < 1024) {
      fileSize = fileSizeInBytes;
      unit = "bytes";
    } else if (fileSizeInBytes < 1048576) {
      fileSize = (fileSizeInBytes / 1024).toFixed(2);
      unit = "KB";
    } else if (fileSizeInBytes < 1073741824) {
      fileSize = (fileSizeInBytes / 1048576).toFixed(2);
      unit = "MB";
    } else {
      fileSize = (fileSizeInBytes / 1073741824).toFixed(2);
      unit = "GB";
    }
    return {
      size: fileSize,
      unit: unit,
    };
  }
  return (
    <div
      className='FileCard'
      style={selectedFiles ? { display: "flex" } : { display: "none" }}
    >
      <div className='close-icon' onClick={() => closeFileCardPreview()}>
        <AiOutlineClose />
      </div>
      <div className='preview-container'>
        {imageSelected ? (
          <img src={generatedURL} alt='' />
        ) : (
          <div className='icon'> {icon}</div>
        )}

        <div>
          {selectedFiles?.name} {" - "}
          {calculateSelectedFileSize(selectedFiles).size}
          {calculateSelectedFileSize(selectedFiles).unit}
        </div>
      </div>
      {fileUploading ? (
        <div className='progressbar'>
          <ProgressBar
            bgColor='#000AFF'
            baseBgColor='#FFFFFF'
            height='10px'
            isLabelVisible={false}
            completed={fileUploadingStage}
          />
        </div>
      ) : null}
    </div>
  );
}
