import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { TostMessageContext } from '../../context/TostMessage';
import { AuthContext } from '../../context/AuthContext';
import { login_to_facility_endpoint } from '../../config/variables';
import axios from 'axios';

const Applications = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '80vh',
    // backgroundColor: '#f0f0f0',
  };

  const cardStyle = {
    width: '420px',
    margin: '20px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    textAlign: 'left',
  };

  const darkCardStyle = {
    ...cardStyle,
    backgroundColor: '#1e293b',
    color: '#fff',
  };

  const lightCardStyle = {
    ...cardStyle,
    backgroundColor: '#fff',
    color: '#1e293b',
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width:"60%"
  };

  const darkButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#fff',
    color: '#1e293b',
  };

  const lightButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#1e293b',
    color: '#fff',
  };

  const [loading,setLoading] = useState(false)
    const cookies = useCookies()
    const { setTostMessage } = React.useContext(TostMessageContext);
    const { userProfile } = useContext(AuthContext);
    const login_to_facility = async () => {
        setLoading(true)
        let data = JSON.stringify({
            user: {
              account_id: userProfile?.account_id,
              sub_account_id: userProfile?.sub_account_id,
            },
            data: {
              email: userProfile?.email,
            },
          });
    
          const headers = {
            access: cookies.urbexEnterpriseUserToken,
            "Content-Type": "application/json",
          };
          axios
            .post(login_to_facility_endpoint, data, { headers })
            .then((response) => {
              if (!response.data.status) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                setLoading(false);
              }
              if (response.data.status) {
                setTostMessage({
                  messageType: "success",
                  message: response.data.response,
                });
                const redirect_url = response.data.redirect_url
                window.open(redirect_url,"_blank");
                setLoading(false)
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              setLoading(false)
            });
            
    }

  return (
    <div style={containerStyle}>
      <div style={darkCardStyle}>
        <h4 className='mb-4 mt-1' style={{fontSize:20,fontWeight:"bolder"}}>Construction Management Suite</h4>
        <p className='text text-secondary'>
        All-in-one solution offering features such as; realtime reporting, task planning, milestone tracking, live broadcast from site, messaging & communication features, documents control, team location & attendance monitoring, funds disbursements and many more. 
        </p>
        <div className="container mt-4 flex justify-center" style={{alignItems:'center'}}>
        <button style={darkButtonStyle} disabled={loading} >{loading ? 'Proceeding to facility...' : 'Switch'}</button>
        </div>
      </div>
      <div style={lightCardStyle}>
        <h4 className='mb-4 mt-1' style={{fontSize:20,fontWeight:"bolder"}}>Facility Management Suite</h4>
        <p className='text text-secondary'>
          Manage assets & buildings in your portfolio seamlessly. Always be in-the-know and prevent asset breakdown.
        </p>
        <div className="container mt-4 flex justify-center" style={{alignItems:'center'}}>
        <button style={lightButtonStyle} disabled={loading} onClick={()=>{
            login_to_facility()
        }}>{loading ? 'Proceeding to facility...' : 'Switch'}</button>
        </div>
      </div>
    </div>
  );
}

export default Applications;