export function formatNumber(number) {
  const numberString = typeof number === "number" ? number.toString() : number;

  const [integerPart, decimalPart] = numberString.split(".");

  const formattedIntegerPart = integerPart
    .replace(/,/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const formattedNumber = decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;

  return formattedNumber;
}

export const getFileExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    // No dot found in the filename
    return "";
  }
  return filename.slice(lastDotIndex + 1);
};
