import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate} from "react-router-dom";
import "./audit.scss";
import axios from "axios";
import Chart from 'react-apexcharts';
import { fetch_staffs, staff_analytics } from "../../config/variables";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import badge from "../../assets/images/Badge.svg";
import verify from "../../assets/images/ID Verified.svg";
import status from "../../assets/images/Status.svg";
import { IoIosArrowDown } from "react-icons/io";
import { MainDrawerComponent } from "../../components/AccountSetUpCard/DrawerComponent";
import { IoGitPullRequestOutline } from "react-icons/io5";
import { FaProjectDiagram } from "react-icons/fa";

function AuditPage() {
  const [loading, setLoading] = React.useState(true);
  const [input, setInput] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [staffDetails, setStaffDetails] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const location = useLocation();
  const { staff_id } = location.state || {};
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [currentStaffId,setCurrentStaffId] = useState(staff_id)
  const [selectedName, setSelectedName] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [analytics,setAnalytics] = useState(null)
  
  const handleNameSelect = (name) => {
    setSelectedName(name);
    setIsDropdownOpen(false);
  };
  const navigate = useNavigate();
  const getStaffAnalytics = async (staff_id) => {
    setLoading(true);
    try {
      const response = await axios.post(
        staff_analytics,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data:{
            staff_id
          }
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const {data} =  response.data;
      setLoading(false);
      setSelectedName(data?.staff?.name);
      setAnalytics(data);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
  const fetchStaffs = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        fetch_staffs,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const { data } = await response.data;
      setStaffs(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    //getStaffAnalytics(currentStaffId)
    (async () => {
      getStaffAnalytics(currentStaffId)
      fetchStaffs();
    })();
    
  }, [currentStaffId]);

  const gaugeChartOptions = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: '#e7e7e7',
          strokeWidth: '50%', // Ensure this matches the fill strokeWidth
          margin: 1,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
          },
        },
        hollow: {
          size: '50%', // Adjust as needed
        },
      },
    },
    fill: {
      type: 'solid',
      colors: ['rgba(23, 35, 55, 1)'],
    },
    labels: ['Amount Withdrawn'],
  };

  const gaugeChartSeries = [80]; // Represents 70% of the gauge

  const lineChartOptions = {
    chart: {
      id: 'clocked-in-time',
    },
    xaxis: {
      categories: analytics?.chart_data?.label,
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 5,
    },
    tooltip: {
      enabled: true,
    },
    colors: ['#000aff']
  };

  const lineChartSeries = [
    {
      name: 'Clocked In',
      data: analytics?.chart_data?.value,
    },
  ];

  return (
    <div className='MyStaffs xl:px-10 max-md:px-6 md:px-10'>
      {staffDetails ? (
        navigate("/dashboard/register-staff/staff-list/staff-profile", {
          state: { staffDetails: staffDetails },
          replace: true,
        })
      ) : (
        <>
          {loading ? (
            <div className='mt-6 mx-auto w-full overflow-auto'>
              <div className="p-0 w-full">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                  {[1, 2, 3, 4, 5].map((i, index) => (
                    <div className="bg-gray-200 animate-pulse p-6 rounded-lg flex flex-col justify-between h-[180px]">
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className='mt-6 mx-auto w-full overflow-auto'>
               <div className="p-0 w-full">
                <div className="flex mb-4 justify-end w-full">
                    <div className="relative">
                    <button
                        className="bg-native-blue text-white px-4 py-2 rounded-md font-bold flex flex-row items-center"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                        {selectedName} <IoIosArrowDown className="ml-2" size={15} />
                    </button>
                    {isDropdownOpen && (
                        <div className="absolute right-0 mt-2 w-60 bg-white border border-gray-300 rounded-md shadow-lg z-9999 max-h-[15rem] overflow-y-auto">
                        {staffs?.map((staff,index) => (
                            <div
                            key={index}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              handleNameSelect(staff?.name)
                              setCurrentStaffId(staff?.id)
                            }}
                            >
                            {staff?.name}
                            </div>
                        ))}
                        </div>
                    )}
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                    <Card title="Access" value={analytics?.staff?.department_level} />
                    <Card title="Clocked in this month" value={analytics?.staff?.monthly_clockedin} subtext="" />
                    <Card title="Early Clock-In (before 9:00am)" value={analytics?.staff?.early_attendance} subtext="" />
                    <Card title="Reports Submitted" value={analytics?.staff?.reports_sent} />
                    <Card title="Reports Approved" value={analytics?.staff?.reports_approved} subtext="" />
                </div>
                </div>
                <div className="py-3 grid grid-cols-1 lg:grid-cols-12 gap-4">
                    {false && <>
                      <div className="bg-white p-6 rounded-lg shadow-md lg:col-span-4 ">
                        <h2 className="text-lg font-bold mb-2">Amount Withdrawn</h2>
                        <p className="text-sm text-gray-500 mb-4">Clocked / Reports</p>
                        <div className="flex justify-center items-center mb-4">
                        <div className="relative">
                            <Chart
                            options={gaugeChartOptions}
                            series={gaugeChartSeries}
                            type="radialBar"
                            height="300"
                            />
                            <div className="absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                                <div className="text-sm font-bold">₦290,000</div>
                                <div className="text-sm mt-2 bg-green-100 text-green-600">+10%</div>
                            </div>
                        </div>
                        </div>
                        <p className="text-sm text-gray-500 mb-4">Improvement on clocking in has been improved with reports</p>
                        <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <span className="bg-blue-100 text-blue-500 px-2 py-1 rounded-full">C</span>
                            <div className="ml-2">
                            <div className="text-gray-500 text-sm">Revenue</div>
                            <div className="text-red-500 font-bold">₦6,000 <span className="text-xs">▼</span></div>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="bg-blue-100 text-blue-500 px-2 py-1 rounded-full">C</span>
                            <div className="ml-2">
                            <div className="text-gray-500 text-sm">Today</div>
                            <div className="text-green-500 font-bold">₦15,000 <span className="text-xs">▲</span></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </>}

                    <div className="bg-white p-6 rounded-lg shadow-md lg:col-span-12">
                        <h2 className="text-lg font-bold mb-4">Attendance Analytics</h2>
                        <Chart
                        options={lineChartOptions}
                        series={lineChartSeries}
                        type="line"
                        height="350"
                        />
                    </div>
                    </div>
                

                <div className="py-3 grid grid-cols-1 lg:grid-cols-3 gap-4">
                    <div className="lg:col-span-2">
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white rounded-lg shadow-md">
                        <thead>
                            <tr className="border-b">
                            <th className="py-2 px-4 text-left">Date</th>
                            <th className="py-2 px-4 text-left">Clocked In</th>
                             <th className="py-2 px-4 text-left">Clocked Out</th> 
                            <th className="py-2 px-4 text-left">Location</th>
                            <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {analytics?.clock_records?.map((clock, idx) => {
                              if (clock.type == 'out') {
                                return (<tr key={idx} className="even:bg-gray-50">
                                <td className="py-3 px-4 border-b">{clock.date}</td>
                                <td className="py-3 px-4 border-b">
                                <span className="bg-green-100 text-green-600 px-2 py-1 rounded whitespace-nowrap">{analytics?.clock_records[idx + 1]?.clocked_in ?? 'Not checked in'}</span>
                                </td>
                                <td className="py-3 px-4 border-b">
                                <span className="bg-red-500 text-white px-2 py-1 rounded whitespace-nowrap">{clock.clocked_out ?? 'Not checked out'}</span>
                                </td>
                                <td className="py-3 px-4 border-b">
                                  {clock.location}
                                </td>
                                <td className="py-3 px-4 border-b">
                                  <button 
                                    onClick={() => navigate("/dashboard/attendance")} 
                                    className='bg-[#000] text-white font-bold text-sm px-2 py-1 rounded'>
                                    Full Data
                                  </button>
                                </td>
                            </tr>)
                              }
                            })}
                        </tbody>
                        </table>
                    </div>
                    </div>
                    
                    
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-lg font-bold mb-4">Personal Information</h2>
                        <div className="mb-4">
                        <div className="flex">
                            <div className="w-1/3">Name :</div>
                            <div className="w-2/3">{analytics?.staff?.name}</div>
                        </div>
                        <div className="flex">
                            <div className="w-1/3">Email :</div>
                            <div className="w-2/3">{analytics?.staff?.email}</div>
                        </div>
                        <div className="flex">
                            <div className="w-1/3">Phone No :</div>
                            <div className="w-2/3">{analytics?.staff?.phone ?? analytics?.staff?.phone_number}</div>
                        </div>
                        <div className="flex">
                            <div className="w-1/3">Location :</div>
                            <div className="w-2/3">{analytics?.staff?.home_address ?? 'Not available'}</div>
                        </div>
                        </div>
                        <h2 className="text-lg font-bold mb-4">Project Statistics</h2>
                        {/* <div className="mb-4">
                          <div className="flex">
                              <div className="text-sm">Ongoing Projects:</div>
                              <div className="text-sm ml-2 font-bold">{analytics?.staff?.num_of_ongoing_project ?? 0}</div>
                          </div>
                          <div className="flex">
                              <div className="text-sm">Procurement requests:</div>
                              <div className="text-sm ml-2 font-bold">{analytics?.staff?.number_of_procurement_request ?? 0}</div>
                          </div>
                        </div> */}
                        {/* <h2 className="text-lg font-bold mb-4">Ongoing Projects</h2> */}
                        <div className="flex items-center justify-between mb-2" style={{backgroundColor: '#ebe9e9',padding: '10px 10px',borderRadius: 10}}>
                          <div className="flex items-center" >
                              {/* <img src={verify} alt="icon" className="w-6 h-6 mr-2" /> */}
                              <FaProjectDiagram size={20} className="mr-2"/>
                              <div className="font-semibold">Projects {analytics?.staff?.num_of_ongoing_project ?? 0}</div>
                          </div>
                          <MainDrawerComponent 
                            open={34}
                            employee={analytics?.staff?.id}
                            card={{ sub: analytics?.staff?.name + "'s Projects" }}
                            className="px-3"
                          >
                            <b className="cursor-pointer text-[#000aff]">View All</b>
                          </MainDrawerComponent>
                        </div>
                        {/* <h2 className="text-lg font-bold mb-4">Procurement requests</h2> */}
                        <div className="flex items-center justify-between" style={{backgroundColor: '#ebe9e9',padding:'10px 10px',borderRadius: 10}}>
                          <div className="flex items-center" >
                              <IoGitPullRequestOutline size={20} className="mr-2"/>
                              <div className="font-semibold">Procurement Requests {analytics?.staff?.number_of_procurement_request ?? 0}</div>
                          </div>
                          {/* <img src={status} alt="icon" className="w-auto h-8" /> */}
                        </div>
                    </div>
                </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default AuditPage;

const Card = ({ title, value, subtext }) => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-md flex flex-col justify-between">
        <div className="flex justify-between items-center mb-4">
          <div className="bg-gray-100 p-0 rounded-full">
            <img src={badge} alt="icon" className="w-8 h-8" />
          </div>
          <button className="text-gray-400">•••</button>
        </div>
        <div className="text-gray-500 text-sm">{title}</div>
        <div className="text-2xl font-bold text-gray-800">{value}</div>
        {subtext && <div className="text-sm text-green-500 mt-2">{subtext}</div>}
      </div>
    );
};
