import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { get_all_attendance } from '../../config/variables';
import { AuthContext } from '../../context/AuthContext';
import Select from 'react-select';
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';
import { useMemo } from 'react';
import { MdOutlineTimer } from 'react-icons/md';
import { useCookies } from "react-cookie";

function getMonthNameFromDate(dateString) {
  const [day, month, year] = dateString.split('-');
  // console.log({ year });
  const dateObject = new Date(`${year}-${month}-${day}`);
  const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
    dateObject
  );
  return monthName;
}

function generateMonthObjects() {
  const currentYear = new Date().getFullYear();
  const monthObjects = [];

  for (let month = 0; month < 12; month++) {
    const monthIndex = month + 1;
    const monthString = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;

    const dateString = `01-${monthString}-${currentYear}`;

    const monthObject = {
      label: new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
        new Date(`${currentYear}-${monthString}-01`)
      ),
      value: dateString,
    };

    monthObjects.push(monthObject);
  }

  return monthObjects;
}

function getCurrentMonthDate() {
  const currentDate = new Date();

  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  const dayString = day < 10 ? `0${day}` : `${day}`;
  const monthString = month < 10 ? `0${month}` : `${month}`;

  return `${dayString}-${monthString}-${year}`;
}


const EmployeeAttendanceSection = () => {
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthDate());
  const [selectedWeek, setSelectedWeek] = useState(getCurrentMonthDate());
  const  currentDate = new Date(),currentYear = currentDate.getFullYear()
  const [selectedYear,setSelectedYear] = useState(currentYear)
  const [employeeData, setEmployeeData] = useState(null);
  const [monthObject, setMonthObject] = useState(null);
  const { userProfile } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showMonth, setShowMonth] = useState(false);
  const [weeklyHeaderArray, setWeeklyHeaderArray] = useState(null);
  const [cookies] = useCookies();
  const [displayWeekStart, setDisplayWeekStartDate] = useState('');
  const [displayWeekEnd, setDisplayWeekEndDate] = useState('');

  const monthObjects = useMemo(() => generateMonthObjects(), []);

  useEffect(() => {
    setMonthObject(monthObjects);
  }, [monthObjects]);
  
  function getCurrentWeek(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    // console.log(d.getUTCFullYear() + '-W' +  weekNo)
    return d.getUTCFullYear() + '-W' +  weekNo;
  }
  useEffect(() => {
    formatWeekDate(getCurrentWeek(new Date()));
  }, [])
  useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();
    if (showMonth) {
      (async () => {
        try {
          const response = await axios.post(
            get_all_attendance,
            {
              user: {
                account_id: userProfile?.account_id,
                sub_account_id: userProfile?.sub_account_id,
              },
              data: {
                month_of_attendance: selectedMonth,
                showWeekly: false
              }
            },
            { 
              cancelToken: source.token,
              headers: {
                access: cookies.urbexEnterpriseUserToken,
              }, 
            }
          );
          const data = response.data;
          setEmployeeData(data);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      })();
    } else {
      (async () => {
        try {
          const response = await axios.post(
            get_all_attendance,
            {
              user: {
                account_id: userProfile?.account_id,
                sub_account_id: userProfile?.sub_account_id,
              },
              data: {
                month_of_attendance: selectedWeek,
                showWeekly: true
              }
            },
            { 
              cancelToken: source.token,
              headers: {
                access: cookies.urbexEnterpriseUserToken,
              }, 
            }
          );
          const data = response.data;
          setEmployeeData(data);
          // console.log(displayWeekStart, displayWeekEnd)
        } catch (error) {
        } finally {
          setLoading(false);
        }
      })();
    }
    return () => {
      source.cancel('Request canceled');
    };
  }, [selectedMonth, userProfile, selectedWeek, showMonth]);

  const Tooltip = ({
    children,
    content,
    position = 'top',
    extraClasses = '',
  }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const positionClasses = {
      top: 'bottom-full mb-2',
      bottom: 'top-full mt-2',
      left: 'right-full mr-2',
      right: 'left-full ml-2',
    };

    return (
      <div className="relative flex items-center">
        <div
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onMouseOut={()=> setShowTooltip(false)}
        >
          {children}
        </div>
        {showTooltip && (
          <div
            className={`absolute z-10 ${
              positionClasses[position] || positionClasses.top
            } ${extraClasses} bg-black text-white text-xs rounded p-2 shadow-lg`}
          >
            {typeof content === 'string' ? (
              <span dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
              content
            )}
          </div>
        )}
      </div>
    );
  };

  function checkDates (dateCheck) {
    if (dateCheck == "" || dateCheck == null) {
      return;
    }

    var dateFrom = displayWeekStart;
    var dateTo = displayWeekEnd;

    var d1 = dateFrom.split("/");
    var d2 = dateTo.split("/");
    var c = dateCheck?.split("/");

    var from = new Date(d1[2], parseInt(d1[1])-1, d1[0]);  // -1 because months are from 0 to 11
    var to   = new Date(d2[2], parseInt(d2[1])-1, d2[0]);
    var check = new Date(c[2], parseInt(c[1])-1, c[0]);

    console.log(check > from && check < to)
  }

  const nth = (d) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  };

  // StatusCell Component
  const StatusCell = ({
    status,
    employee,
    day,
    location,
    device,
    clock_in_data,
    type
  }) => {
    const tooltipContent = (
      <div style={{ minWidth: 200 }}>
        <p>Name: {employee.name}</p>
        <p>Time Clocked: {day}</p>
        <p>Location: {location}</p>
        <p>Device: {device}</p>
        <p>
          Meta:{' '}
          {status === 'bad' ? 'Absent' : status === 'good' ? 'Present' : ''}
        </p>
      </div>
    );

    function timeIn (date) {
      if (date !== null) {
        const [dateString, time] = date.split(' ');
        // console.log(time);
        return time;
      } else {
        return 'N/A';
      }
    }

    return (
      <td
        className={`relative text-center border border-gray-300 p-2 py-40 ${
          status === 'bad'
            ? 'bg-red-50'
            : status === 'good'
            ? 'bg-emerald-100'
            : ''
        }`}
      >
        <div className="cursor-pointer flex items-center justify-center">
          <Tooltip
            content={tooltipContent}
            position="bottom"
            extraClasses="max-w-xs z-40"
          >
            <MainDrawerComponent
              open={30}
              card={{ sub: `Attendance - ${employee.name}` }}
              attendance={employee.attendance}
              selected={clock_in_data}
            >
              <div className='flex flex-row text-bold items-center text-xs gap-x-2 justify-center font-semibold'>
                {status === 'bad' ? '❌' : status === 'good' ? <MdOutlineTimer color='green' size={20} /> : ''}
                {type == "weekly" && day !== null ? ' (' + timeIn(day) + ')' : ''}
              </div>
            </MainDrawerComponent>
          </Tooltip>
        </div>
      </td>
    );
  };

  const renderTable = (data) => {
    return (
      <div className="overflow-x-auto pb-48">
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead>
            {showMonth ? (
              <tr>
                <th className="border border-gray-300 p-2 text-sm">
                  Name
                </th>
                  {
                    [...Array(31)].map((_, day) => (
                    <th key={day} className="border border-gray-300 p-2">
                      {day + 1 + nth(day+1)}
                    </th>
                  ))
                  }
              </tr>
            ) : (
              <tr>
                <th className="border border-gray-300 p-2 text-sm">
                  Name
                </th>
                  {
                    weeklyHeaderArray?.map((date, day) => (
                    <th key={day} className="text-sm sm:text-md border border-gray-300 p-2">
                      {date}
                    </th>
                  ))
                  }
              </tr>
            )}
          </thead>

          {userProfile?.sub_account_overview?.allowed_permissions?.includes("view_all_attendance") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access") ? (
          <tbody>
				    {data?.map((employee) => (
              <>
              {showMonth ? (
                  <tr key={employee?.id}>
                  <td className="border border-gray-300 p-2">{employee?.name}</td>
                  {employee?.attendance?.map((status, day) => {
                    return(
                      <StatusCell
                        status={status.status}
                        location={status.location}
                        device={status.device}
                        employee={employee}
                        day={status.timestamp}
                        clock_in_data={status}
                      />
                    )
                  })}
                </tr>
                ) : (
                  <tr key={employee?.id}>
                    <td className="border border-gray-300 p-2">{employee?.name}</td>
                    {employee?.attendance?.map((status, day) => {
                      return(
                        <StatusCell
                          status={status.status}
                          location={status.location}
                          device={status.device}
                          employee={employee}
                          day={status.timestamp}
                          clock_in_data={status}
                          type={'weekly'}
                        />
                      )
                    })}
                  </tr>
                )}
                </>
            ))}
          </tbody>
          ) : (
          <tbody>
				    {data?.map((employee) => {
              if (employee.id == userProfile?.sub_account_id) {
              return (
                <>
                {showMonth ? (
                    <tr key={employee?.id}>
                    <td className="border border-gray-300 p-2">{employee?.name}</td>
                    {employee?.attendance?.map((status, day) => {
                      return(
                        <StatusCell
                          status={status.status}
                          location={status.location}
                          device={status.device}
                          employee={employee}
                          day={status.timestamp}
                          clock_in_data={status}
                        />
                      )
                    })}
                  </tr>
                  ) : (
                    <tr key={employee?.id}>
                      <td className="border border-gray-300 p-2">{employee?.name}</td>
                      {employee?.attendance?.map((status, day) => {
                        return(
                          <StatusCell
                            status={status.status}
                            location={status.location}
                            device={status.device}
                            employee={employee}
                            day={status.timestamp}
                            clock_in_data={status}
                            type={'weekly'}
                          />
                        )
                      })}
                    </tr>
                  )}
                </>
                )
              }
            })}
          </tbody>
          )}
        </table>
      </div>
    );
  };
  const memoizedRenderTable = useMemo(
    () => renderTable(employeeData),
    [employeeData]
  );

  const [maxDate, setMaxDate] = useState('');
  useEffect(() => {
    // Get the current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Add 1 to get month in range 01-12 format
    // Calculate the maximum selectable month and year
    const maxMonthYear = `${currentYear}-${currentMonth}`;
    setMaxDate(maxMonthYear)

  }, []);
  const getMonthName = useMemo(
    () => getMonthNameFromDate(selectedMonth),
    [selectedMonth]
  );
  function formatDate(dateString) {
    const [year, month] = dateString.split('-');
    return `01-${month}-${year}`;
  }

  function formatWeekDate(dateString) {
    const [y, w] = dateString.split('-');

    let date = new Date(y, 0, (1 + (w.replace('W','') - 1) * 7)); // Elle's method
    date.setDate(date.getDate() + (1 - date.getDay())); // 0 - Sunday, 1 - Monday etc

    const weekday = ["Mon","Tue","Wed","Thur","Fri","Sat","Sun"];
    // CONSTRUCT ARRAY FOR TABLE HEADER
    const week_start_string = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    setDisplayWeekStartDate(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());
    let header_array = [];

    var today = date;
    const temp = {
      d: today.getDate(),
      m: today.getMonth(),
      y: today.getFullYear(),
    }
    const numDaysInMonth = new Date(temp.y, temp.m + 1, 0).getDate()
    let newDate;
    Array.from({length: 7}, _ => {
      if (temp.d > numDaysInMonth){
        temp.m +=1;
        temp.d = 1;
      }      

      newDate = new Date(temp.y, temp.m, temp.d++); //.toUTCString()
      header_array.push(newDate.toLocaleDateString('en-US', {weekday: 'short'}) + ', ' + newDate.getDate() + nth(newDate.getDate()));
      // return {
      //   day: newDate.toLocaleDateString('en-US', {weekday: 'short'}),
      //   num: newDate.getDate(),
      //   date: newDate.getDate() + "-" + newDate.getFullYear() + "-" + newDate.getMonth(),
      //   selected: false,
      // };
    });
    setDisplayWeekEndDate(newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear());

    setWeeklyHeaderArray(header_array);
    return week_start_string;
  }

  function filterProjects(i) {
    if (i === 1) {
      return setShowMonth(true);
    }
    if (i === 0) {
      return setShowMonth(false);
    }
  }
  
  return (
    <div className="p-4">
      {/* <label className="font-bold mb-2 block">Showing attendance for:</label> */}
      <div className='w-full overflow-auto md:w-auto md:overflow-hidden'>
        <FilterScrollComponent
          filtersArray={[
            "Weekly View",
            "Monthly View"
          ]}
          handleFilerFunction={(i) => filterProjects(i)}
        />
      </div>
      <div className="border-gray-300 mb-4 mt-2 w-auto flex flex-row items-center">
        {showMonth && (
          <input type="month" id="dateInput" onChange={(e)=>{
            // console.log(e.target.value)
          setSelectedMonth(formatDate(e.target.value))
          }} min="2023-01" max={maxDate} defaultValue={maxDate} />
        )}
        {!showMonth && (
          <input type="week" id="dateInput" onChange={(e)=>{
          setSelectedWeek(formatWeekDate(e.target.value))
          }} min="2023-W1" max={maxDate} defaultValue={getCurrentWeek(new Date())} />
        )}
        {loading ? <a className="ml-5">Loading data...</a> : null}
      </div>
      <div className="">{memoizedRenderTable}</div>
    </div>
  );
};

export function FilterScrollComponent({ filtersArray, handleFilerFunction }) {
  const [active, setActive] = React.useState(0);
  return (
    <div className='FilterScrollComponent flex-col'>
      {/* <div className='text-start w-full mt-4'>Filter</div> */}
      <div className='filter-container m-0'>
        {filtersArray?.map((filter, i) => (
          <button
            key={i}
            className={active === i ? "active text-sm text-white" : " text-sm"}
            onClick={() => {
              setActive(i);
              handleFilerFunction(i);
            }}
          >
            {filter}
          </button>
        ))}
      </div>
    </div>
  );
}

export default EmployeeAttendanceSection;
