import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Subscriptions.scss';
import { ProgressBarChart } from './../../components/ProgressBarComponent/ProgressBarComponent';
import { 
  Icon,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer } from '@chakra-ui/react';
import project_icon from '../../assets/icons/project-icon.svg';
import { BsThreeDots } from 'react-icons/bs';
import { AiOutlineCheck, AiOutlineMinus } from 'react-icons/ai';
import { BsArrowLeftCircle, BsTrash, BsCheck2Circle } from 'react-icons/bs';
import { ProjectsContext } from './../../context/ProjectsContext';
import { AuthContext } from '../../context/AuthContext';
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';
import { TostMessageContext } from '../../context/TostMessage';
import axios from 'axios';
import { get_enterprise_subscription_details, get_subscription_plans_endpoint } from '../../config/variables';
import { useCookies } from 'react-cookie';

function SubscriptionsPage() {
  const [loading, setLoading] = React.useState(true);
  const [subscription_plans,setSubscriptionPlans] = React.useState([])
  const { userProfile } = useContext(AuthContext);
  const [cookies] = useCookies();
  const [active_subscription,setActiveSubscription] = React.useState(null);
  const { projectDetailes, getProjectDetailes } = 
  React.useContext(ProjectsContext);
  const { setTostMessage } = React.useContext(TostMessageContext);
  const get_subscription_plans =  async () => {
    try {
      const headers = {
        access: cookies.urbexEnterpriseUserToken,
        "Content-Type": "application/json",
      };
     const res = await axios.get(get_subscription_plans_endpoint, { headers })
     const {data} = res
     if(data.status) {
      setSubscriptionPlans(data.data)
     }
    } catch (error) {
      
    }
  }
  async function getEnterpriseSubscription() {
    const data = {
      user: {
        account_id: userProfile?.account_id,
      },
    };
    try {
      const response = await axios.post(get_enterprise_subscription_details, data, {
        headers: {
          access: cookies.userToken,
        },
      });
      if (response?.data?.status) {
        setSubscription({isdata:true,data:response.data.data});
        setActiveSubscription(response.data.active_subscription)
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  const [subscription,setSubscription]  = React.useState({isdata:false,data:[]})

  React.useEffect(() => {
    getProjectDetailes();
    get_subscription_plans()
    getEnterpriseSubscription()
  },[]);

  return (
    <div className="OngoingProjectsPage xl:px-10 max-md:px-6 md:px-10">
      <div className="flex flex-wrap justify-between items-center">
        {
          subscription_plans.length > 0 ?
          <>
          {
            subscription_plans.map((sub)=>{
              return (
                <MainDrawerComponent key={sub.id}
                  projectDetailes={sub}
                  open={19}
                  className="max-md:w-[100%] md:w-[100%] items-center"
                  card={{ sub: `${sub.plan_name} Subscription`, plan: `${sub.plan_name}`, 'price': sub.price }}
                  >
                    <div className="h-auto py-3 px-4 my-4 w-[100%] border-3 hover:border-native-blue rounded-[10px] items-center justify-center bg-transparent flex">
                        <div className='flex flex-column pr-3'>
                            <a className='font-bold text-[12px]'>{sub.plan_name}</a>
                            <div>
                                <h2 className='text-native-blue text-[25px] font-bold mb-0'>${sub.price}</h2>
                                <span className='text-[12px]'>per year</span>
                            </div>
                        </div>
                        <div className='px-0'>
                            <a className='font-bold text-[12px]'>What you get</a>
                            <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>{sub.no_of_projects} projects</a></span>
                            <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>{sub.no_of_users} users </a></span>
                            <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>{sub.storage_limit} GB storage limit </a></span>
                            <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Training.</a></span>
                            <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>24/7 support</a></span>
                            <span className='flex items-center'><BsCheck2Circle color="#00b47a" className='mr-1' /><a className='text-[13px]'>Free updates</a></span>
                        </div>
                    </div>
                </MainDrawerComponent>
              )
            })
          }
          </>:
          <>
          <dv class='alert alert-info'>No subscription plan available</dv>
          </>
        }
    
      </div>
      <div className='flex flex-column px-10 justify-start bg-white rounded-[10px] pt-4'>
      <div className='flex flex-row w-100 py-2 items-center justify-end'>
        { !active_subscription ? (
          <button onClick={() => {
            console.log(active_subscription)
            setTostMessage({
              messageType: 'warning',
              message: 'No active subscription, please subscribe before buying add-ons.',
            });
          }} className='bg-[#000aff] text-white p-2 px-3 text-[16px] rounded-[5px] mx-10'>Buy Add-ons</button>
        ) : (
          <MainDrawerComponent open={19} active_subscription = {active_subscription} is_addon={true} card={{ sub: 'Add-ons', plan: 'Add-ons', 'price': 0 }}><button className='bg-[#000aff] text-white p-2 px-3 text-[16px] rounded-[5px] mx-10'>Buy Add-ons</button></MainDrawerComponent>
        )}
      </div>
      <TableContainer>
        <Table className='mb-4'  variant='striped' size="md">
          {/* <TableCaption>Subscriptions</TableCaption> */}
          <Thead>
            <Tr>
              <Th>Subscription</Th>
              <Th>Status</Th>
              <Th isNumeric>Duration</Th>
              <Th>Max. number of projects</Th>
              <Th>Max. number of users</Th>
              <Th>Storage Limit (GB)</Th>
              <Th>Started</Th>
              <Th>Expiry</Th>
            </Tr>
          </Thead>
          <Tbody>
            {subscription.isdata && subscription.data.length > 0 ? (
              <>
              {
                subscription.data.map(function(sub){
                  return (
                    <Tr key={sub.id}>
                      <Td>{sub.plan_name}</Td>
                      {sub.status == 1 ? <Td className="text-[#00b47a] font-bold text-[15px]">Active</Td> : <Td className="text-[#ff7300] font-bold text-[15px]">Expired</Td>}
                      <Td isNumeric>{sub.duration} Year(s)</Td>
                      <Td>{sub.no_of_projects} project(s)</Td>
                      <Td>{sub.no_of_users} user(s)</Td>
                      <Td>{sub.storage_limit} GB</Td>
                      <Td>{sub.started}</Td>
                      <Td>{sub.time_expire}</Td>
                    </Tr>
                  )
                })
              }
              </>
            ) : (
              <Tr>
                <Td>
                  No active subscription...
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      </div>
    </div>
  );
}

export default SubscriptionsPage;
