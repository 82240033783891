import React, { useContext, useEffect, useState } from "react";
import {
  create_department,
  create_department_level,
  edit_department,
  edit_level_department,
  get_permissions,
  staff_permissions,
  update_staff,
} from "../../config/variables";
import axios from "axios";
import { useCookies } from "react-cookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaChevronDown, FaUserEdit } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import { IoClose,IoPencilSharp } from "react-icons/io5";
import { TostMessageContext } from "../../context/TostMessage";

import { AiOutlineClose } from "react-icons/ai";
import { FALSE } from "sass";
import { useNavigate } from "react-router-dom";
import { FaRegPenToSquare } from "react-icons/fa6";

export default function Departments() {
  const [departmentItem, setDepartmentItem] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createDepartment, setCreateDepartment] = useState(false);
  const { userProfile } = React.useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);

  const [cookies] = useCookies();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const getDepartments = async () => {
    try {
      // const response = await axios.post(staff_permissions, {

      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     access: cookies.urbexEnterpriseUserToken,
      //   },
      // });

      const response = await axios.post(
        staff_permissions,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      //   console.log(response.data)
      const data = await response.data;
      //   const { data } = data;
      setDepartmentList(data.data);
      reset();
      setLoading(false);
    } catch (error) {
      //   console.log({ error });
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        create_department,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            title: data.title,
            details: data.description,
          }
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const resData = await response.data;
      // console.log(resData);
      if (resData.status) {
        getDepartments();
        setTostMessage({
          messageType: 'success',
          message: resData.response
        })
      } else {
        setTostMessage({
          messageType: 'error',
          message: resData.response
        })
      }
      setCreateDepartment(false);
      setLoading(false);
    } catch (error) {
      //   console.log(error);
      setTostMessage({
        messageType: 'error',
        message: 'Network error'
      })
      setLoading(false);
    }
  };

  const submitEditAccessLevel = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        edit_department,
        {
          ...currentAccessLevel,
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const resData = await response.data;
      // console.log(resData);
      getDepartments();
      setEditAccessLevel(false);
      setLoading(false);
    } catch (error) {
      //   console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getDepartments();
  }, []);
  const [currentAccessLevel,setCurrentAccessLevel] = useState(null)
  const [editAccessLevel,setEditAccessLevel] = useState(false)
  const onError = (data) => {
    // console.log({ data });
  };

  return (
    <div className='max-lg:px-5 px-10'>
      <>
        {departmentItem ? (
          <>
            <DepartmentDetail
              setDepartmentItem={setDepartmentItem}
              departmentItem={departmentItem}
              getDepartments={getDepartments}
            />
          </>
        ) : (
          <>
            <div className='flex items-center mt-7 justify-between'>
              <h4 className='mt-2 text-[1rem] sm:text-lg lg:text-2xl font-bold'>All Departments</h4>
              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "create_departments"
              ) ||
              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "full_access"
              ) ? (
                <button
                  onClick={() => setCreateDepartment(true)}
                  className='px-6 flex items-center hover:opacity-[0.9] gap-2 py-2 rounded-lg text-white bg-native-blue border'
                >
                  <FaRegPenToSquare /> Create Department
                </button>
              ) : null}
            </div>
            <div className='mt-3 w-full overflow-auto'>
              <>
                {loading ? (
                  <>
                    {Array.from({ length: 10 })?.map((item, index) => (
                      <div
                        className='bg-gray-200 animate-pulse my-4 rounded-2xl border py-4'
                        key={index}
                      ></div>
                    ))}
                  </>
                ) : (
                  <>
                    <table className='min-w-full border rounded-xl border-gray-300 bg-white'>
                      <thead className=''>
                        <tr className='bg-[#F9F9F9]'>
                          <th className='py-3 px-2 border-b font-semibold text-[14px]'>Name</th>
                          <th className='py-3 px-2 border-b font-semibold text-[14px] hidden sm:!block'>Description</th>
                          {/* <th className="py-2 px-2 border-b">Levels</th>
                        <th className="py-2 px-2 border-b">Members</th> */}
                          {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                            "create_departments"
                          ) ||
                          userProfile?.sub_account_overview?.allowed_permissions?.includes(
                            "full_access"
                          ) ? (
                            <th className='py-3 px-2 border-b font-semibold text-[14px]'></th>
                          ) : null}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {departmentList?.map((item, index) => (
                          <tr
                            key={index}
                            className='hover:bg-gray-50 cursor-pointer'
                          >
                            <td className='py-3 px-2 border-b'>
                              <div>
                                <div className='font-semibold'>
                                  {item.title}
                                </div>
                              </div>
                            </td>
                            <td className='py-3 px-2 border-b text-[13px] font-semibold hidden sm:!block'>
                              {item.short_description == "" ? "Not provided..." : item.short_description}
                            </td>
                            {/* <td className="py-2 px-2 border-b">{item.level_count}</td>
                            <td className="py-2 px-2 border-b">{item.members_count}</td> */}
                            {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                              "create_departments"
                            ) ||
                            userProfile?.sub_account_overview?.allowed_permissions?.includes(
                              "full_access"
                            ) ? (
                              <td className='py-3 px-2 border-b text-[13px] font-semibold'>
                                <button
                                  className='p-2 rounded-lg bg-native-blue text-white'
                                  onClick={() => setDepartmentItem(item)}
                                >
                                  Manage Access Levels
                                </button>
                                <button style={{background:"grey"}}
                                  className='p-2 rounded-lg mx-2 text-white'
                                  onClick={() => {
                                    setEditAccessLevel(true)
                                    setCurrentAccessLevel(item)
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                            ) : null}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            </div>
          </>
        )}
      </>
      <>
        {editAccessLevel && (
          <div className='fixed inset-0  flex items-center justify-center z-50 bg-black bg-opacity-75'>
            <form
              onSubmit={(e) =>{
                e.preventDefault()
                submitEditAccessLevel()
              }}
              className='rounded-xl pb-7 flex-col max-w-2xl w-full px-4 lg:px-10 flex mx-4 pt-4 bg-white shadow-lg'
            >
              <div className='flex items-center mb-4 justify-between'>
                <p className='text-lg m-0 font-bold'>Edit Department</p>
                <button
                  type='button'
                  className='text-black self-end text-xl rounded-xl py-2'
                  onClick={() => {
                    setEditAccessLevel(false)
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>

              <input
                className={`rounded-lg border mt-4 `}
                onChange={(e)=> {
                  setCurrentAccessLevel({...currentAccessLevel,title:e.target.value})
                }}
                type='text'
                placeholder='Department Name (e.g Site Engineers)'
                value={currentAccessLevel?.title}
                required
              />
              <input
                type='text'
                className={`rounded-lg border mt-4 `}
                placeholder='Department Description (Optional)'
                value={currentAccessLevel?.short_description}
                onChange={(e)=> {
                  setCurrentAccessLevel({...currentAccessLevel,short_description:e.target.value})
                }}
              />

              <button
                type='submit'
                className='text-white bg-native-blue mt-4 text-xs px-10 border active:scale-95 transition-all self-end rounded-xl py-2'
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </form>
          </div>
        )}
      </>
      <>
        {createDepartment && (
          <div className='fixed inset-0  flex items-center justify-center z-50 bg-black bg-opacity-75'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='rounded-xl pb-7 flex-col max-w-2xl w-full px-4 lg:px-10 flex mx-4 pt-4 bg-white shadow-lg'
            >
              <div className='flex items-center mb-4 justify-between'>
                <p className='text-lg m-0 font-bold'>Create New Department</p>
                <button
                  type='button'
                  className='text-black self-end text-xl rounded-xl py-2'
                  onClick={() => {
                    setCreateDepartment(false);
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>

              <input
                className={`rounded-lg border mt-4 ${
                  errors.title && "border-red-400"
                }`}
                type='text'
                placeholder='Department Name (e.g Site Engineers)'
                {...register("title")}
                required
              />
              {errors.title}

              <input
                className={`rounded-lg border mt-4 ${
                  errors.description && "border-red-400"
                }`}
                type='text'
                placeholder='Department Description (Optional)'
                {...register("description")}
              />

              <button
                type='submit'
                className='text-white bg-native-blue mt-4 text-xs px-10 border active:scale-95 transition-all self-end rounded-xl py-2'
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </form>
          </div>
        )}
      </>
    </div>
  );
}

export function DepartmentDetail({ departmentItem, getDepartments, setDepartmentItem }) {
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [createLevel, setCreateLevel] = useState(false);
  const {setTostMessage} = useContext(TostMessageContext);
  const [editStaffLoading, setEditStaffLoading] = useState(false);
  const [departmentDetail, setDepartmentDetail] = useState(null);
  const [permissionList, setPermissionList] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedPermissionDisplay, setSelectedPermissionDisplay] = useState(
    []
  );
  const [show, setShow] = useState(false);
  const [permissionErrState, setPermissionErrState] = useState(false);

  const getPermissions = async () => {
    const response = await axios.post(
      get_permissions,
      {
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
      },
      {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      }
    );
    const { data } = await response.data;
    setFetchLoading(false);
    setPermissionList(data);
  };

  useEffect(() => {
    setDepartmentDetail(departmentItem);
    getPermissions();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  function joinArrayElements(arr) {
    return arr.join(",");
  }
  const onCreateLevel = async (data) => {
    const permission = joinArrayElements(selectedPermissions);
    if (!permission) {
      setPermissionErrState(true);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        create_department_level,
        {
          name: data.name,
          permissions: permission,
          department_id: departmentDetail?.id,
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const resData = await response.data;
      //   console.log(resData);
      setCreateLevel(false);
      setTostMessage({
        messageType: 'success',
        message: resData.response,
      })
      setDepartmentItem(null);
      getDepartments();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const submitEditAccessLevel = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        edit_level_department,
        {
          ...accessLevel,permissions:accessLevel?.permissions.join(","),
          department_id: departmentDetail?.id,
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const resData = await response.data;
      // console.log(resData);
      setTostMessage({
        messageType: 'success',
        message: 'Data successfully changed!',
      });
      setDepartmentItem(null);
      getDepartments();
      setEditLevelPermissions(false)
      setLoading(false);
    } catch (error) {
      //   console.log(error);
      setTostMessage({
        messageType: 'error',
        message: error.message,
      });
      setLoading(false);
    }
  };
  function removeObjectById(id, arrayOfObjects) {
    const selectedArray = selectedPermissions.filter((obj) => obj !== id);
    const newArray = arrayOfObjects.filter((obj) => obj.id !== id);
    setSelectedPermissions(selectedArray);
    setSelectedPermissionDisplay(newArray);
    return;
  }
  const [editLevelPermission,setEditLevelPermissions] = useState(false)
  const [accessLevel,setAccessLevel] = useState(null)

  return (
    <div className=''>
      {fetchLoading ? (
        <div className='w-full'>
          <IoMdArrowRoundBack
            onClick={() => {
              setDepartmentItem(null);
              getDepartments();
            }}
            className='h-14 p-3 mt-0 bg-white rounded-full active:scale-95 transition-all hover:bg-gray-200 w-14'
          />
          <div
            className='h-20 w-20 rounded-full bg-gray-200 animate-pulse mt-5'
            alt=''
          />
          <div className='mt-3'>
            <p className='m-0 bg-gray-200 h-10 mb-3 w-2/3 font-medium'></p>
            <p className='m-0 bg-gray-200 h-10 mb-3 w-8/12 font-medium'></p>
            <hr className='mt-7 mb-4 border-gray-400' />

            <button className='px-10 py-3 h-14 w-36 rounded-xl bg-gray-200 text-white'></button>
          </div>
        </div>
      ) : (
        <div className='mt-3 w-full'>
          <div className='flex items-center justify-between w-full'>
            <IoMdArrowRoundBack
              onClick={() => {
                setDepartmentItem(null);
                getDepartments();
              }}
              className='h-14 p-3 mt-0 bg-white rounded-full active:scale-95 transition-all hover:bg-gray-200 w-14'
            />
            <p className='m-0 text-lg font-bold'>{departmentDetail?.title}</p>
            <button
              onClick={() => {
                setCreateLevel(true)
              }}
              className='px-6 flex items-center gap-2 py-2 rounded-lg text-black bg-white border'
            >
              <FaUserEdit /> Create New Level
            </button>
          </div>

          <div className='mt-3'>
            {
              <div className={`w-full`}>
                {/* <p className='m-0 text-lg text-gray-600 font-medium'>
                  {departmentDetail?.details}
                </p> */}
                <hr className='border-gray-300 border' />
                <div className='flex flex-column gap-2'>
                  {departmentDetail?.department_level.map((item, index) => (
                    <div className="flex flex-column mb-2 mt-2" key={index}>
                      <div className="flex flex-row gap-2" style={{alignItems:"center"}}>
                      <span>{item.name}</span>
                      <button onClick={()=>{
                        setAccessLevel({...item,permissions:item.permissions.split(",")})
                        setEditLevelPermissions(true)
                      }}  className='px-4 flex items-center gap-2 py-2 rounded-lg text-black bg-white border'><IoPencilSharp /> Edit</button>
                      </div>
                      <div className="flex flex-wrap justify-start gap-2 mt-2">
                        {item.perm_array?.map((item, index) => (
                        <div
                          className='px-3 py-2 border rounded-lg text-black text-sm bg-white'
                          key={index}
                        >
                          {item}
                        </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      )}
      {editLevelPermission && (
        <div className='fixed inset-0  flex items-center justify-center z-50 bg-black bg-opacity-75'>
          <form
            onSubmit={(e)=>{
              e.preventDefault()
              submitEditAccessLevel()
            }}
            className='rounded-xl pb-7 flex-col max-w-2xl w-full px-4 lg:px-10 flex mx-4 pt-4 bg-white shadow-lg'
          >
            <div className='flex items-center mb-4 justify-between'>
              <p className='text-lg m-0 font-bold'>Edit Access Level for {accessLevel?.name}</p>
              <button
                type='button'
                className='text-black self-end text-xl rounded-xl py-2'
                onClick={() => {
                  setEditLevelPermissions(false)
                }}
              >
                <AiOutlineClose />
              </button>
            </div>
            <button
              type='button'
              onClick={() => setShow(!show)}
              className='flex justify-between p-2 mt-4 rounded-lg border'
            >
              <p className='m-0'>Click to add Permission</p> <FaChevronDown />
            </button>
            {permissionList?.length !== 0 && show && (
              <div
                className='px-2 bg-white border transition-all rounded-lg mt-1'
                style={{ height: 250, overflowY: "scroll" }}
              >
                {permissionList.map((item, idx) => (
                  <div
                    key={idx}
                    className='my-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer p-2 flex items-center gap-x-3'
                    onClick={()=> {
                        if (!accessLevel?.permissions?.includes(item.id)) {
                           setAccessLevel({
                            ...accessLevel,
                            permissions:[...accessLevel.permissions, item.id],
                            perm_array:[...accessLevel?.perm_array,item?.description]
                           })
                        }
                      // setShow(false);
                      
                    }}
                  >
                    {/* <div>{item.name}</div> */}
                    <div>{item.description}</div>
                  </div>
                ))}
              </div>
            )}
            <b className="mt-3">Selected Permissions</b>
            <div className='max-h-[170px] overflow-y-auto px-2 bg-white mt-1 flex gap-x-3 flex-wrap transition-all rounded-lg'>
              {accessLevel?.perm_array?.map((item, idx) => (
                <div
                  key={idx}
                  onClick={() =>{
                    setAccessLevel({
                      ...accessLevel,
                      permissions:accessLevel.permissions?.filter((_,ind) => ind != idx),
                      perm_array:accessLevel?.perm_array?.filter((_,ind) => ind != idx)
                     })
                  }
                  }
                  className='mt-2 bg-gray-50 border rounded-lg hover:bg-gray-100 cursor-pointer p-2 flex items-center gap-x-3'
                >
                  <div>{item}</div>
                  <IoClose />
                </div>
              ))}
            </div>

            <button
              type='submit'
              className='text-white bg-native-blue mt-4 text-xs px-10 border active:scale-95 transition-all self-end rounded-xl py-2'
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </form>
        </div>
      )}
      {createLevel && (
        <div className='fixed inset-0  flex items-center justify-center z-50 bg-black bg-opacity-75'>
          <form
            onSubmit={handleSubmit(onCreateLevel)}
            className='rounded-xl pb-7 flex-col max-w-2xl w-full px-4 lg:px-10 flex mx-4 pt-4 bg-white shadow-lg'
          >
            <div className='flex items-center mb-4 justify-between'>
              <p className='text-lg m-0 font-bold'>Create Access Level for {departmentDetail?.title}</p>
              <button
                type='button'
                className='text-black self-end text-xl rounded-xl py-2'
                onClick={() => {
                  setCreateLevel(false);
                }}
              >
                <AiOutlineClose />
              </button>
            </div>
            <input
              className={`rounded-lg border mt-4 ${
                errors.name && "border border-red-400"
              }`}
              type='text'
              placeholder='Level Name'
              {...register("name", { required: true })}
            />
            <button
              type='button'
              onClick={() => setShow(!show)}
              className='flex justify-between p-2 mt-4 rounded-lg border'
            >
              <p className='m-0'>Click to add Permission</p> <FaChevronDown />
            </button>

            {permissionErrState && (
              <p className='text-red-400 text-xs mt-1'>
                Please select a permission
              </p>
            )}
            {permissionList?.length !== 0 && show && (
              <div
                className='px-2 bg-white border transition-all rounded-lg mt-1'
                style={{ height: 250, overflowY: "scroll" }}
              >
                {permissionList.map((item, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setSelectedPermissions((prevSelectedPermission) => {
                        if (!prevSelectedPermission.includes(item.id)) {
                          return [...prevSelectedPermission, item.id];
                        }
                        return prevSelectedPermission;
                      });
                      setSelectedPermissionDisplay((prevState) => {
                        const isItemAlreadySelected = prevState.find(
                          (selectedItem) => selectedItem.id === item.id
                        );
                        if (!isItemAlreadySelected) {
                          return [...prevState, item];
                        }
                        return prevState;
                      });
                      // setShow(false);
                      setPermissionErrState(false);
                    }}
                    className='my-3 bg-gray-50 rounded-lg hover:bg-gray-100 cursor-pointer p-2 flex items-center gap-x-3'
                  >
                    {/* <div>{item.name}</div> */}
                    <div>{item.description}</div>
                  </div>
                ))}
              </div>
            )}
            <b className="mt-3">Selected Permissions</b>
            <div className='max-h-[170px] overflow-y-auto px-2 bg-white mt-1 flex gap-x-3 flex-wrap transition-all rounded-lg'>
              {selectedPermissionDisplay?.map((item, idx) => (
                <div
                  key={idx}
                  onClick={() =>
                    removeObjectById(item.id, selectedPermissionDisplay)
                  }
                  className='mt-2 bg-gray-50 border rounded-lg hover:bg-gray-100 cursor-pointer p-2 flex items-center gap-x-3'
                >
                  <div>{item.name}</div>
                  <IoClose />
                </div>
              ))}
            </div>

            <button
              type='submit'
              className='text-white bg-native-blue mt-4 text-xs px-10 border active:scale-95 transition-all self-end rounded-xl py-2'
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
