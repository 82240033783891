import React, { useEffect, useState } from 'react';
import { Button, Icon } from '@chakra-ui/react';
import { BsArrow90DegDown, BsClock, BsCompass,BsArrowDownRight, BsArrowUpRight } from 'react-icons/bs';
import ProgressBarComponent from './../../components/ProgressBarComponent/ProgressBarComponent';
import Marquee from "react-fast-marquee";
import { MdArrowOutward } from 'react-icons/md';
import { Link, useNavigate } from "react-router-dom";

export default function OngoingProjectCardComponent({ profile }) {
  const [project, setProject] = useState([
    {
      project_milestones: 0,
      project_milestones_completed: 0,
    },
  ]);

  const [totalProjects, setTotalProjects] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile?.ongoing_project && profile?.ongoing_project.length >= 1) {
      setProject(profile?.ongoing_project);
    }
    setTotalProjects(project.length);
  }, [project]);
  const [currentProject, setCurrentProject] = useState(0);

  function showPreviousProject() {
    if (currentProject === 0) {
      return;
    }
    setCurrentProject((prev) => prev - 1);
  }
  function showNextProject() {
    if (currentProject + 1 === totalProjects) {
      return;
    }
    setCurrentProject((prev) => prev + 1);
  }

  function calculatePercentage(total, completed) {
    const percentage = (Number(completed) / Number(total)) * 100;

    if (isNaN(Number(percentage))) {
      return 0;
    }
    return Math.round(percentage);
  }
  const { project_milestones, project_milestones_completed } =
    project[currentProject];

  return (
    <div className="bg-native-blue m-0 p-3 rounded-lg mt-2">
      <div className="d-flex mb-4 justify-content-between align-items-center">
        <Button
          // rightIcon={<BsClock />}
          className="w-[120px] md:w-50 overflow-hidden"
          onClick={() => showPreviousProject()}
        >
          {/* Project {currentProject + 1} */}
          <Marquee className='text-xs md:text-sm lg:text-md text-[#000aff]' pauseOnHover={true}>
            <a className='px-1'>{`${profile?.ongoing_project[currentProject]?.project_title}`}</a>
          </Marquee>
        </Button>
        <div onClick={() => navigate(`/dashboard/ongoing-projects/${profile?.ongoing_project[currentProject]?.project_id}`) } className='flex flex-row items-center justify-center'>
          <a className='text-white underline underline-offset-4'>GO</a>
          <MdArrowOutward size={23} color="white" />
        </div>
        {currentProject + 1 === totalProjects || totalProjects === 0 ? (
          <div className="text-gray-400">No Projects</div>
        ) : (
          <Button className="w-[120px] md:w-50 overflow-hidden" onClick={() => showNextProject()}>
            {/* Project {currentProject + 2} */}
            <Marquee className='text-xs md:text-sm lg:text-md' pauseOnHover={true}>
              <a className='px-1'>{`${profile?.ongoing_project[currentProject + 1]?.project_title} `}</a>
            </Marquee>
          </Button>
        )}
      </div>
      <ProgressBarComponent
        percentage={calculatePercentage(
          project_milestones || 0,
          project_milestones_completed || 0
        )}
      />
      <div className="flex text-gray-300 justify-around">
        <dir>
          <h2>{project_milestones || 0}</h2>
          <span>Milestones</span>
        </dir>
        <hr className="vertical-line" />
        <dir>
          <h2>{project_milestones_completed || 0}</h2>
          <span>Accomplished</span>
        </dir>
      </div>
    </div>
  );
}
export function ViewOngoingProjectCardComponent({
  project_milestones,
  project_milestones_completed,
  calculatePercentage,
  status,
}) {
  // function calculatePercentage(total, completed) {
  //   const percentage = (Number(completed) / Number(total)) * 100;

  //   if (isNaN(Number(percentage))) {
  //     return 0;
  //   }
  //   return Math.round(percentage);
  // }
  return (
    <div className="bg-native-blue text-white p-4 rounded-xl lg:ml-3 h-full">
      <div className="d-flex justify-content-between align-items-center">
        <Icon as={BsCompass} w={6} h={6} color="gray.500" />
        <Button className="h-2" rightIcon={<BsClock />}>
          {status}
        </Button>
      </div>
      <ProgressBarComponent
        percentage={calculatePercentage(
          project_milestones,
          project_milestones_completed
        )}
      />
      <div className="flex justify-between">
        <dir>
          <h2>{project_milestones}</h2>
          <span>Milestones</span>
        </dir>
        <hr className="vertical-line border border-white" />
        <dir>
          <h2>{project_milestones_completed}</h2>
          <span>Accomplished </span>
        </dir>
      </div>
    </div>
  );
}
