export const base_url = "https://api.urbex.africa/mgt-enterprise";
export const messaging_token_endpoint = `${base_url}/messaging/notification/save_token.php`;
export const register_end_point = `${base_url}/auth/signup.php`;
export const weekly_report = `${base_url}/projects/add-weekly-report.php`;
export const Add_documents = `${base_url}/projects/add_documents.php`;
export const get_weekly_report = `${base_url}/projects/fetch_weekly_reports.php`;
export const login_end_point = `${base_url}/auth/login.php`;
export const verify_OTP_end_point = `${base_url}/auth/verify-otp.php`;
export const request_OTP_end_point = `${base_url}/auth/resetpassword.php`;
export const change_password_end_point = `${base_url}/auth/change_password.php`;
export const logged_in_password_change = `${base_url}/auth/logged_in_password_change.php`;
export const fetch_notifications_end_point = `${base_url}/notifications/fetch_notifications.php`;
export const verify_personal_information_end_point = `${base_url}/profile/personal_information.php`;
export const verify_contractor_information_end_point = `${base_url}/profile/contractor_information.php`;
export const persist_user_end_point = `${base_url}/auth/persist_user.php`;
export const search_projects_end_point = `${base_url}/projects/search_projects.php`;
export const select_project_by_id_end_point = `${base_url}/projects/select_my_project_by_id.php`;
export const client_profile_end_point = `${base_url}/profile/client_profile.php`;
export const fetch_user_profile_end_point = `${base_url}/profile/fetch_user_profile.php`;
export const save_user_profile_end_point = `${base_url}/profile/save_user_profile.php`;

export const delete_staff = `${base_url}/profile/delete_staff.php`;

export const fetch_my_projects_end_point = `${base_url}/projects/fetch_my_projects.php`;
export const bid_for_project_end_point = `${base_url}/projects/bid_for_project.php`;
export const project_applications_end_point = `${base_url}/projects/project_applications.php`;
export const project_bids_end_point = `${base_url}/projects/list_project_bids.php`;
export const view_user_financials_end_point = `${base_url}/financials/view_user_financials.php`;
export const add_to_portfolio_end_point = `${base_url}/profile/add_to_portfolio.php`;
export const file_upload_endpoint = `https://api.urbex.africa/messaging/upload_media.php`;
export const submit_withdraw_request = `${base_url}/financials/request_payout.php`;
export const fetch_bank_list_endpoint = `${base_url}/financials/fetch_bank_list.php`;
export const start_milestone_end_point = `${base_url}/projects/start_milestone.php`;
export const complete_milestone_end_point = `${base_url}/projects/complete_milestone.php`;
export const fetch_bid_details_end_point = `${base_url}/projects/fetch_bid_details.php`;
export const download_milestone_report_end_point = `${base_url}/reports/milestone.php`;
export const assign_contractor_end_point = `${base_url}/projects/assign_contractor.php`;
export const edit_project = `${base_url}/projects/edit_project.php`;
export const add_project = `${base_url}/projects/create_project.php`;
export const add_milestones_end_point = `${base_url}/projects/add_milestones.php`;
export const upload_milestone_csv_endpoint = `${base_url}/projects/add_milestones_csv.php`;
export const pay_with_wallet_endpoint = `${base_url}/wallet/in-app-purchases.php`;
export const start_project_end_point = `${base_url}/projects/start_project.php`;
export const pause_project_end_point = `${base_url}/projects/pause_project.php`;
export const upload_project_csv_endpoint = `${base_url}/projects/import_projects.php`;
export const get_all_attendance = `${base_url}/hr_functions/staff/fetch_attendance.php`;
export const get_procurement_requests = `${base_url}/projects/fetch_procurement_requests.php`;
export const cancel_procurement_requests = `${base_url}/projects/cancel_procurement_requests.php`;
export const change_procurement_requests_status = `${base_url}/projects/change_procurement_requests_status.php`;
export const create_procurement_request = `${base_url}/projects/add_procurement_request.php`;
export const get_all_schedule = `${base_url}/hr_functions/staff/fetch_scheduling_records.php`;
export const create_event = `${base_url}/profile/create_events.php`;
export const create_department = `${base_url}/profile/create_department.php`;
export const edit_department = `${base_url}/profile/edit_department.php`;
export const edit_level_department = `${base_url}/profile/edit_level_department.php`;
export const staff_permissions = `${base_url}/profile/fetch_permission_level.php`;
export const get_permissions = `${base_url}/profile/fetch_permissions.php`;
export const create_department_level = `${base_url}/profile/create_department_level.php`;
export const update_notifications_endpoint = `${base_url}/profile/update_notifications.php`;
export const fetch_notifications_endpoint = `${base_url}/profile/fetch_notifications.php`;
export const update_staff = `${base_url}/staff/update_staff.php`;
export const create_staff = `${base_url}/profile/create_staff.php`;
export const register_staff = `${base_url}/profile/register_staff.php`;
export const delete_project_end_point = `${base_url}/projects/delete_project.php`;
export const pin_project_end_point = `${base_url}/projects/pin_projects.php`;
export const delete_main_document = `${base_url}/projects/delete_main_document.php`;
export const delete_milestones = `${base_url}/projects/delete_milestones.php`;
export const delete_bulk_milestones = `${base_url}/projects/delete_bulk_milestones.php`;
export const fetch_messages_endpoint = `https://api.urbex.africa/messaging/fetch_messages.php`;
export const fetch_staffs = `${base_url}/profile/fetch_all_staffs.php`;
export const get_tasks = `${base_url}/profile/tasks.php`;
export const create_announcement = `${base_url}/profile/create_announcements.php`;
export const clockin_endpoint = `${base_url}/auth/clock_in.php`;
export const clockout_endpoint = `${base_url}/auth/clock_out.php`;
export const staff_analytics = `${base_url}/profile/get_staff_analytics.php`
// export const update_staff = `${base_url}/profile/update_staff.php`;
// export const create_staff = `${base_url}/profile/create_staff.php`;
export const enable_staff = `${base_url}/profile/enable_staff_account.php`;
export const disable_staff = `${base_url}/profile/disable_staff_account.php`;
export const update_staff_permission = `${base_url}/profile/update_staff_permission.php`;
export const get_staff = `${base_url}/profile/fetch_staff_by_id.php`;
export const update_staff_guarantor = `${base_url}/profile/update_staff_guarantor.php`;
export const get_subscription_plans_endpoint = `${base_url}/enterprise-subscriptions/subscription_plans.php`;
export const update_staff_profile = `${base_url}/profile/update_staff_profile.php`;
export const fetch_account_name_endpoint = `${base_url}/financials/resolve_account_name.php`;
export const pay_online_endpoint = `${base_url}/payments/initialize_payment.php`;
export const get_enterprise_subscription_details = `${base_url}/enterprise-subscriptions/subscription.php`;
export const approve_report = `${base_url}/projects/approve_report.php`;
export const survey_endpoint = `${base_url}/profile/answer_survey.php`
export const add_addons_endpoint = `${base_url}/payments/initialize_addon_payment.php`;
export const upload_weekly_report_file = `${base_url}/projects/add-weekly-report-files.php`;
export const login_to_facility_endpoint = "https://api.urbex.africa/enterprise_facilities/auth/login_from_enterprise.php"
export const fetch_user_projects_endpoint = `${base_url}/projects/fetch_user_projects.php`;
export const add_user_to_projects = `${base_url}/projects/add_user_to_projects.php`;
export const remove_user_from_projects_endpoint = `${base_url}/projects/remove_user_from_projects.php`;
export const fetch_remaining_permissions = `${base_url}/profile/fetch_remaining_permissions.php`;
export const add_user_permissions = `${base_url}/profile/add_user_permissions.php`;
export const revoke_user_permissions = `${base_url}/profile/revoke_user_permissions.php`;
export const get_budget_summary = `${base_url}/financials/budget_report.php`;
export const add_project_funds = `${base_url}/financials/add_project_funds.php`;
export const remove_project_funds = `${base_url}/financials/remove_project_funds.php`;
export const save_daily_checklist_endpoint = `${base_url}/projects/save_daily_checklist_endpoint.php`;
export const edit_milestone_cost = `${base_url}/projects/edit_milestone_cost.php`;