import React, { useContext, useEffect, useState } from "react";
import "./ProjectPage.scss";
import coverImag from "../../assets/images/profile-card-cover-image.png";
import greenTick from "../../assets/icons/green-tick.svg";
import galleryImage from "../../assets/images/gallery-image.png";
import { Avatar, Button, Divider, Icon } from "@chakra-ui/react";
import { BsHeart } from "react-icons/bs";
import person_icon from "../../assets/icons/person-icon.svg";
import time_icon from "../../assets/icons/time-icon.svg";
import review_icon from "../../assets/icons/review-icon.svg";
import { ProjectsContext } from "./../../context/ProjectsContext";
import { useNavigate, useParams } from "react-router-dom";
import { client_profile_end_point } from "./../../config/variables/index";
import axios from "axios";
import { useCookies } from "react-cookie";
import { MainDrawerComponent } from "../../components/AccountSetUpCard/DrawerComponent";

function ProjectPage() {
  const { id } = useParams();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  // {budget: "NGN8,000,000.00";
  // category: "Telecom";
  // client_id: "6";
  // country: "NIGERIA";
  // date_created: "1 month(s) ago ";
  // project_desc: "5G Mast for residents of Asokoro & Maitama";
  // project_id: "3";
  // project_milestones: [];
  // project_title: "Asokoro network mast";
  // proposed_duration: "1 Year";
  // site_address: "19 Nelson mandela, Asokoro";
  // state: "ABUJA";}
  const { getProject, getClentProfile, listProjects } =
    useContext(ProjectsContext);

  const [project, setProject] = useState({
    loading: true,
    data: {},
  });
  const [client_profile, setClient_profile] = useState({
    loading: true,
    data: {},
  });

  async function showProject() {
    // const project = await getProject(id);
    // console.log("one project", project);
    // if (project !== undefined ) {
    //   setProject({ loading: false, data: project });
    // }
    // console.log("function", listProjects);
    if (listProjects?.data?.length === 0 || listProjects.data === undefined) {
      // getProjects();
      navigate("/dashboard/search-project");
    }
    listProjects?.data?.map((project) => {
      if (project?.project_id === id) {
        // console.log("get project", project);
        setProject({ loading: false, data: project });
        // return project;
      } else {
        // return null;
      }
    });
  }
  useEffect(() => {
    if (id) {
      showProject();
    }
  }, []);
  // useEffect(() => {
  //   if (project.data?.client_id) {
  //     saveClient_profile();
  //   }
  // }, [project]);
  async function saveClient_profile() {
    // const profile = await getClentProfile(project.data.client_id);
    // // // console.log("profile", profile);
    // setClient_profile({ loading: false, data: profile });
  }

  // // console.log(project);

  return (
    <div className="ProjectPage">
      <div className="container">
        {project.loading ? (
          <div className="Loading_screen">Loading...</div>
        ) : (
          <div className="row">
            <div className="col-md-4">
              <div className="profile-card">
                <div className="hero-image-container">
                  <img src={coverImag} alt="" />
                </div>
                <div className="profile-image-container">
                  <Avatar src={project.data?.client_display_picture} />
                </div>
                <h3>About the client</h3>
                <p>Joined {project?.data?.about_client?.time_joined}</p>

                <Button> {project?.data?.about_client?.payment_status}</Button>

                <div className="more-info-container">
                  <div>
                    <div className="icon-container">
                      {/* <Icon> */}
                      <img src={time_icon} />
                      {/* </Icon> */}
                    </div>
                    {project?.data?.about_client?.project_count} Jobs Posted
                  </div>
                  <div>
                    <div className="icon-container">
                      <img src={review_icon} />
                    </div>
                    4.5 Reviews
                  </div>
                  <div>
                    <div className="icon-container">
                      {/* <Icon /> */}
                      <img src={person_icon} />
                    </div>
                    {project?.data?.about_client?.kyc_stage}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="project-info-card">
                <div className="top">
                  {" "}
                  <div className="d-flex justify-content-between">
                    <div>
                      <h3>{project.data?.project_title}</h3>
                      <p>
                        {project.data?.site_address}, {project.data?.state}
                      </p>
                    </div>
                    <div className="d-flex gap-2">
                      <MainDrawerComponent
                        open={6}
                        card={{ sub: "Details about the project" }}
                        project={project}
                      >
                        <Button>Apply now</Button>
                      </MainDrawerComponent>
                      <div className="icon-container">
                        <BsHeart />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 justify-content-start">
                    <span className="price"> N11,000,000 - N90,000,000</span>{" "}
                    <Divider orientation="vertical" />
                    <p className="date">{project.data?.date_created}</p>
                  </div>
                </div>
                <Divider className="mt-3" />
                <div className="d-flex align-items-center gap-2 justify-content-between info-grade">
                  <div className="info-card">
                    <div className="icon">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20,6h-3V4c0-1.103-0.897-2-2-2H9C7.897,2,7,2.897,7,4v2H4C2.897,6,2,6.897,2,8v11c0,1.103,0.897,2,2,2h16	c1.103,0,2-0.897,2-2V8C22,6.897,21.103,6,20,6z M15,4v2H9V4H15z M8,8h8h4v3H4V8H8z M4,19v-6h6v2h4v-2h6l0.001,6H4z"></path>
                      </svg>
                    </div>{" "}
                    <div>
                      {" "}
                      <span> Education</span> <br />
                      <b>Certified</b>
                    </div>
                  </div>
                  <div className="info-card">
                    <div className="icon">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="none"
                          stroke="#000"
                          strokeWidth="2"
                          d="M8,11 C10.7614237,11 13,8.76142375 13,6 C13,3.23857625 10.7614237,1 8,1 C5.23857625,1 3,3.23857625 3,6 C3,8.76142375 5.23857625,11 8,11 Z M14.6431888,15.6961461 C14.3091703,14.6675626 13.7524493,13.7598949 13.0233822,13.0234994 C11.7718684,11.7594056 10.0125018,11 8,11 C4,11 1,14 1,18 L1,23 L11,23 M12,18.8235294 L16.1904762,22 L23,13"
                        ></path>
                      </svg>
                    </div>{" "}
                    <div>
                      {" "}
                      <span> Min. Experience</span> <br />
                      <b>5 Years</b>
                    </div>
                  </div>
                  <div className="info-card">
                    <div className="icon">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12,22c3.976,0,8-1.374,8-4v-4v-4V6c0-2.626-4.024-4-8-4S4,3.374,4,6v4v4v4C4,20.626,8.024,22,12,22z M12,20 c-3.722,0-6-1.295-6-2v-1.268C7.541,17.57,9.777,18,12,18s4.459-0.43,6-1.268V18C18,18.705,15.722,20,12,20z M12,4 c3.722,0,6,1.295,6,2s-2.278,2-6,2S6,6.705,6,6S8.278,4,12,4z M6,8.732C7.541,9.57,9.777,10,12,10s4.459-0.43,6-1.268V10 c0,0.705-2.278,2-6,2s-6-1.295-6-2V8.732z M6,12.732C7.541,13.57,9.777,14,12,14s4.459-0.43,6-1.268V14c0,0.705-2.278,2-6,2 s-6-1.295-6-2V12.732z"></path>
                      </svg>
                    </div>{" "}
                    <div>
                      {" "}
                      <span> Bid Amount</span> <br />
                      <b className="bg">{project.data?.budget}</b>
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="project-info-card-body">
                  <b>Overview</b>
                  <p>{project.data?.project_desc}</p>
                  <b>Project Milestones</b>
                  <div className="project-milestones-container">
                    {/* {console.log(project?.data?.project_milestones)} */}
                    {project?.data?.project_milestones?.length <= 0 ? (
                      <>No project milestones available</>
                    ) : (
                      <>
                        {project?.data?.project_milestones?.map((milestone) => (
                          <div
                            className="milestone d-flex gap-3"
                            key={milestone?.milestone_id}
                          >
                            <div className="icon">
                              <img src={greenTick} alt="tick icon" />
                            </div>
                            <div className="text">
                              {milestone?.milestone_name}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <b>Gallery</b>
                  <div className="gallery-container container">
                    <div className="row">
                      {project?.data?.project_images?.length <= 0 ? (
                        <>No Image for this project</>
                      ) : (
                        <>
                          {project?.data?.project_images?.map((image, i) => (
                            <div className="col-4 col-md-3" key={i}>
                              <div className="image-container">
                                <img src={image.image} alt="" />
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectPage;
