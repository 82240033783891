import { Icon, FormLabel, Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState, Fragment } from "react";
import ReactLoading from "react-loading";
import "./add-staff.scss";
import { LuImagePlus } from "react-icons/lu";
import { AuthContext } from "../../context/AuthContext";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import {
  add_project,
  assign_contractor_end_point,
  create_staff,
  staff_permissions,
} from "../../config/variables";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { TostMessageContext } from "../../context/TostMessage";

function AddStaff() {
  const [image, setImage] = useState(null);
  const [departmentErrState, setDepartmentErrState] = useState(false);
  const [permissionErr, setPermissionErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [permissionItem, setPermissionItem] = useState(null);
  const [permission, setPermission] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentItem, setDepartmentItem] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);

  const handleOptionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setDepartment(selectedId);
    setDepartmentErrState(false);
    const selectedObject = departmentList?.find(
      (item) => item.id == selectedId
    );
    setDepartmentItem(selectedObject);

    const getDepartmentLevelId = () => {
      if (selectedObject && selectedObject.department_level && selectedObject.department_level.length > 0) {
        return selectedObject.department_level[0].id || '';
      }
      return '';
    };
    setPermissionItem(getDepartmentLevelId());
  };

  const handlePermissionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setPermission(selectedId);
    setPermissionErr(false);
    const selectedObject = departmentItem?.department_level?.find(
      (item) => item.id == selectedId
    );
    console.log({ selectedObject });
    setPermissionItem(selectedObject);
  };

  const getDepartments = async () => {
    try {
      const response = await axios.post(
        staff_permissions,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );

      const data = await response.data;
      setDepartmentList(data.data);
      console.log(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      email: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      staff_salary: "",
    },
  });

  const addProject = async (data) => {
    setLoading(true);
    if (!departmentItem) {
      setDepartmentErrState(true);
      setLoading(false);
      return;
    }
    if (!permissionItem) {
      setPermissionErr(true);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        create_staff,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            title: data.title,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            phone_number: data.phone_number,
            department_level: permissionItem?.id,
            company_id: userProfile?.account_id,
            account_id: userProfile?.account_id,
            salary_amount: data.staff_salary,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const resData = await response.data;
      // console.log({ response });
      if (resData.status) {
        setTostMessage({
          messageType: "success",
          message: resData.response,
        });
        setLoading(false);
        // closeTabFunction();
      } else {
        setTostMessage({
          messageType: "error",
          message: resData.response,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleErr = (err) => {
    if (!departmentItem) {
      setDepartmentErrState(true);
    }
    if (!permissionItem) {
      setPermissionErr(true);
      setLoading(false);
      return;
    }
  };

  return (
    <form
      onSubmit={handleSubmit(addProject, handleErr)}
      className='CreateProjectPage px-6 lg:px-20'
    >
      <div className='text-2xl mt-10 font-bold flex justify-between'>
        <div className='text-sm md:text-2xl'>
          Please endeavor to fill in the right details
        </div>
      </div>
      <div className='mt-7'>
        <div className='flex flex-col-reverse xl:grid xl:grid-cols-7 gap-4'>
          <div className='xl:col-span-4'>
            <div className='form-container bg-white px-4 rounded-xl py-3'>
              <h4 className='mb-3 text-xl'>User Personal Information</h4>
              <Box className='mt-4'>
                <FormLabel style={{ fontSize: 13 }}>Title</FormLabel>
                <input
                  {...register("title", { required: true })}
                  placeholder='Enter Title'
                  className={`bg-gray-100 w-full rounded-lg px-3 py-2 border-none focus:outline-none outline-none ${
                    errors?.title && "outline-red-300"
                  }`}
                />
              </Box>
              <Box className='mt-4'>
                <FormLabel style={{ fontSize: 13 }}>First Name</FormLabel>
                <input
                  {...register("first_name", { required: true })}
                  placeholder='Enter First Name'
                  className={`bg-gray-100 w-full rounded-lg px-3 py-2 border-none focus:outline-none outline-none ${
                    errors?.first_name && "outline-red-300"
                  }`}
                />
              </Box>
              <Box className='mt-4'>
                <FormLabel style={{ fontSize: 13 }}>Last Name</FormLabel>
                <input
                  {...register("last_name", { required: true })}
                  placeholder='Enter Last Name'
                  className={`bg-gray-100 w-full rounded-lg px-3 py-2 border-none focus:outline-none outline-none ${
                    errors?.last_name && "outline-red-300"
                  }`}
                />
              </Box>
              <Box className='mt-4'>
                <FormLabel style={{ fontSize: 13 }}>User Email</FormLabel>
                <input
                  {...register("email", { required: true })}
                  placeholder='Enter User Email'
                  className={`bg-gray-100 w-full rounded-lg px-3 py-2 border-none focus:outline-none outline-none ${
                    errors?.email && "outline-red-300"
                  }`}
                />
              </Box>
              <Box className='mt-4'>
                <FormLabel style={{ fontSize: 13 }}>Phone Number</FormLabel>
                <input
                  {...register("phone_number", { required: true })}
                  placeholder='Enter Phone Number'
                  className={`bg-gray-100 w-full rounded-lg px-3 py-2 border-none focus:outline-none outline-none ${
                    errors?.phone_number && "outline-red-300"
                  }`}
                  type='number'
                />
              </Box>
            </div>
          </div>
          <div className='xl:col-span-3'>
            <div className='bg-white p-4 rounded-xl'>
              <div className='relative'>
                <h4 className='mb-3 text-xl'>User Department & Access Level</h4>
                <select
                  value={department}
                  onChange={handleOptionChange}
                  className={`flex flex-col gap-6 w-full bg-white border rounded-md shadow-sm focus:ring-indigo-200 focus:ring-opacity-50 focus:ring focus:outline-none`}
                >
                  <option value='' disabled>
                    User Department
                  </option>
                  {departmentList?.map((item, id) => (
                    <option value={item.id} key={id} className=''>
                      {item.title}
                    </option>
                  ))}
                </select>
                {departmentErrState && (
                  <p className='text-red-400 text-xs mt-1'>
                    Please select a role
                  </p>
                )}
                <select
                  value={permission}
                  onChange={handlePermissionChange}
                  className='flex flex-col mt-3 gap-6 w-full bg-white border border-gray-300 rounded-md shadow-sm focus:ring-indigo-200 focus:ring-opacity-50 focus:ring focus:outline-none'
                >
                  <option value='' disabled>
                    User Permission Level
                  </option>
                  {departmentItem?.department_level?.map((item, id) => (
                    <option value={item.id} key={id} className=''>
                      {item.name}
                    </option>
                  ))}
                </select>
                {permissionErr && (
                  <p className='text-red-400 text-xs mt-1'>
                    Please select a level
                  </p>
                )}
              </div>
              {departmentItem?.details && (
                <>
                  <h4 className='mb-3 text-xl mt-4'>Role description</h4>
                  <p className='text-xs'>{departmentItem?.details}</p>
                </>
              )}

              {/* <h4 className="mb-3 text-xl mt-4">Staff Salary</h4>
							<Box className="mt-2">
								<input
									{...register("staff_salary", { required: true })}
									placeholder="Enter salary"
									className={`bg-gray-100 w-full rounded-lg px-3 py-2 border-none focus:outline-none outline-none ${
										errors?.staff_salary && "outline-red-400"
									}`}
								/>
							</Box> */}
              <div className='w-full mt-4'>
                {loading ? (
                  <ReactLoading className='' type={"cylon"} color='#172337' />
                ) : (
                  <button
                    type='submit'
                    className='bg-native-blue w-full px-2 py-3 text-white text-sm text-center rounded-3xl'
                  >
                    Add User
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddStaff;
