import React, { useContext, useState , useEffect} from 'react';
import { NavLink, useLocation, useParams, Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './DashboardSideNav.scss';
import { DashboardSideNavLinks } from './navLink';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { PiShootingStarBold } from 'react-icons/pi';
import Marquee from "react-fast-marquee";
import {
  BsArrowLeftShort,
  BsArrowRightShort,
  BsBellFill,
  BsFillArrowLeftSquareFill,
} from 'react-icons/bs';
import { useCookies } from "react-cookie";
import { Avatar, Icon } from '@chakra-ui/react';
import Logo from '../../assets/images/logo.png';
import { ProjectsContext } from '../../context/ProjectsContext';
import { MdArrowBackIosNew, MdOutlineImage } from 'react-icons/md';
import { TbActivityHeartbeat } from 'react-icons/tb';
import { RiUserLocationLine } from 'react-icons/ri';
import {clockin_endpoint, clockout_endpoint} from '../../config/variables/index';
import { TostMessageContext } from "../../context/TostMessage";
import axios from "axios";
import ReactLoading from "react-loading";
import { IoIosStar, IoIosStarOutline, IoMdArrowBack } from "react-icons/io";

export default function DashboardSideNav() {
  const { userProfile, logOutFunction } = useContext(AuthContext);
  const location = useLocation();
  const params = useParams();

  return (
    <div className="DashboardSideNav">
      <div className="">
        <img src={Logo} className="h-[3.5rem] mb-2 mt-[1.5rem] mx-auto" alt="logo" />
      </div>

      <div className="h-screen relative mt-0 flex flex-column justify-start overflow-auto">
        <ul className="p-0 h-[80%] mt-3 flex flex-column justify-start overflow-auto">
          {DashboardSideNavLinks?.map((link) => {
            const { name, icon, path, id, accountType } = link;
            // var isActive = false;
            // if (path == location.pathname || path == `${location.pathname}/${params.id}`) {
            //   isActive = true;
            // } else {
            //   isActive = false;
            // }
            var displayOption = false;
            if (name == "Roles & Levels") {
              if (userProfile?.sub_account_overview?.allowed_permissions?.includes("access_departments") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) {
                displayOption = true;
              } else {
                displayOption = false;
              }
            } else if (name == "Financials") {
              if (userProfile?.sub_account_overview?.allowed_permissions?.includes("access_financials") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) {
                displayOption = true;
              } else {
                displayOption = false;
              }
            } else if (name == "Subscriptions") {
              displayOption = true
            }
            else if (name == "Procurements") {
              if (userProfile?.sub_account_overview?.allowed_permissions?.includes("access_procurements") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) {
                displayOption = true;
              } else {
                displayOption = false;
              }
            } else if (name == "Attendance") {
              if (userProfile?.sub_account_overview?.allowed_permissions?.includes("access_attendance") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) {
                displayOption = true;
              } else {
                displayOption = false;
              }
            } else if (name == "Schedular") {
              if (userProfile?.sub_account_overview?.allowed_permissions?.includes("access_schedular") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) {
                displayOption = true;
              } else {
                displayOption = false;
              }
            } else if (name == "My Projects") {
              if (userProfile?.sub_account_overview?.allowed_permissions?.includes("access_projects_page") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) {
                displayOption = true;
              } else {
                displayOption = false;
              }
            } else if (name == "Add User") {
              if (userProfile?.sub_account_overview?.allowed_permissions?.includes("create_users") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) {
                displayOption = true;
              } else {
                displayOption = false;
              }
            } else if (name == "Add Project") {
              if (userProfile?.sub_account_overview?.allowed_permissions?.includes("create_project") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) {
                displayOption = true;
              } else {
                displayOption = false;
              }
            } else if (name == "Manage Users") {
              if (userProfile?.sub_account_overview?.allowed_permissions?.includes("create_users") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) {
                displayOption = true;
              } else {
                displayOption = false;
              }
            } else {
              displayOption = true;
            }
            
            function matchRuleShort(str, rule) {
              var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
              return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
            }
            
            // const isActive = path == location.pathname;
            const isActive = matchRuleShort(location.pathname, `${path}*`);
            return (
              <React.Fragment key={id}>
                {(userProfile?.account_type &&
                  userProfile?.account_type.toLowerCase() ===
                    accountType.toLowerCase()) ||
                accountType === 'both' ? (
                  displayOption && (
                    <NavLink to={path}>
                      <li
                        className={`flex justify-between text-lg h-[3.5rem] items-center pr-2 pl-4 ${
                          isActive
                            ? 'bg-white rounded-tr-full rounded-br-full text-native-blue'
                            : 'text-white'
                        }`}
                      >
                        <div className="flex items-center gap-2">
                          <div className="side-nav-icon text-whi">{icon}</div>
                          <span className="nav-link-name">{name}</span>
                        </div>
                        {isActive && (
                          <BsArrowRightShort
                            size={20}
                            className="rounded-full h-10 p-2 w-10 bg-stone-300"
                          />
                        )}
                        {/* {!isActive && name == "Schedular" ? (
                          <div className='h-5 px-2 flex items-center justify-center bg-white rounded-sm'>
                            <a className='text-native-blue'>1</a>
                          </div>
                        ) : (null)} */}
                      </li>
                    </NavLink>
                  )
                ) : null}
              </React.Fragment>
            );
          })}
        </ul>
        <div
          onClick={() => logOutFunction()}
          className={`flex mt-0 justify-between text-lg h-14 items-center  pl-4 hover:bg-red-500 rounded-tr-full rounded-br-full text-white `}
        >
          <div className="flex items-center gap-2">
            <BsFillArrowLeftSquareFill />
            <span className="nav-link-name text-[#ffffff]">LogOut</span>
          </div>
          <div className="arrow-container">
            <BsArrowLeftShort className="rounded-full h-10 p-2 w-10 bg-white text-black mr-4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export function DashboardTopNav() {
  const { userProfile, logOutFunction } = React.useContext(AuthContext);
  const [toggleNav, setToggleNav] = React.useState(false);
  const location = useLocation();
  const { projectDetailes, getProjectDetailes } = useContext(ProjectsContext);
  const params = useParams();
  const [clokingLoading, setLoadingClockin] = useState(false);
  const [cookies] = useCookies();
  const {setTostMessage} = useContext(TostMessageContext);
  const [userPosition, setUserPosition] = useState({latitude: 0, longitude: 0});
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { getUserProfileFunction } = useContext(AuthContext);
  

  React.useEffect(() => {
    getProjectDetailes();
    
  }, []);
  async function checkPermissionAndGetLocation() {
    try {
      const permission = await navigator.permissions.query({ name: 'geolocation' });
      if (permission.state === 'granted') {
        getLocation();
      } else if (permission.state === 'prompt') {
        getLocationWithPrompt();
      } else if (permission.state === 'denied') {
        // Try to request location again
        const locationOptions = {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 5000,
        }

        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserPosition({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              setTostMessage({
                messageType: 'error',
                message: 'Location access denied. Please enable location permissions in your browser settings.',
              });
            } else {
              setTostMessage({
                messageType: 'error',
                message: 'Location not allowed. Please use another device.',
              });
            }
          },
        );
      }
    } catch (error) {
      setTostMessage({
        messageType: 'error',
        message: 'An error occurred while checking location permissions.',
      });
    }
  }

  function getLocation() {
    const locationOptions = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 5000,
    }
    navigator.geolocation.getCurrentPosition((position) => {
      setUserPosition({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  }

  function getLocationWithPrompt() {
    const locationOptions = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 5000,
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        setTostMessage({
          messageType: 'error',
          message: 'Location not allowed. Please use another device.',
        });
      }
    );
  }

  useEffect(() => {
    checkPermissionAndGetLocation();
  }, []);


  function showPosition(position) {
    setUserPosition({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    })
  }
  
  async function clockIn() {
    setLoadingClockin(true);
    checkPermissionAndGetLocation();
    // if (navigator.geolocation) {
    //   await navigator.geolocation.getCurrentPosition(showPosition);
    // } else {
    //   setLoadingClockin(false);
    //   return setTostMessage({
    //     messageType: "warning",
    //     message: 'Location not allowed. Please use another device',
    //   })
    // }

    setTimeout(async () => {
      if ((userPosition?.latitude == 0 && userPosition?.longitude == 0) || (userPosition?.latitude == "" && userPosition?.longitude == "")) {
        setLoadingClockin(false);
        checkPermissionAndGetLocation();
        return;
      }

      const data = {
        user: {
          account_id: userProfile?.sub_account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          position: userPosition,
          device_phone: null,
          deviceId: 'WEB',
          uniqueId: null,
          carrier: null,
          androidID: null,
          ip: null,
          mac: null
        },
      };
      try {
        const response = await axios.post(clockin_endpoint, data, {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        });
        setLoadingClockin(false);
        if (response?.data?.status) {
          getUserProfileFunction();
          setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
          // closeTabFunction();
        } else {
          setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000)
  } 
  async function clockOut() {
    setLoadingClockin(true);
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      setLoadingClockin(false);
      return setTostMessage({
        messageType: "warning",
        message: 'Location not allowed. Please use another device',
      })
    }
    setTimeout(async () => {
      if ((userPosition?.latitude == 0 && userPosition?.longitude == 0) || (userPosition?.latitude == "" && userPosition?.longitude == "")) {
        setLoadingClockin(false);
        return setTostMessage({
          messageType: "warning",
          message: 'Still triangulating your location. Please try again in a few seconds.',
        })
      }

      const data = {
        user: {
          account_id: userProfile?.sub_account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          position: userPosition,
          device_phone: null,
          deviceId: 'WEB',
          uniqueId: null,
          carrier: null,
          androidID: null,
          ip: null,
          mac: null
        },
      };
      try {
        const response = await axios.post(clockout_endpoint, data, {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        });
        setLoadingClockin(false);
        if (response?.data?.status) {
          getUserProfileFunction();
          setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
          // closeTabFunction();
        } else {
          setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000)
  } 

  const handleIconClick = () => {
    if (!clokingLoading) {
      setDropdownVisible(!dropdownVisible);
    }
  };

  const handleClockIn = () => {
    setDropdownVisible(false);
    clockIn();
  };

  const handleClockOut = () => {
    setDropdownVisible(false);
    clockOut();
  };

  return (
    <>
      <div className="h-24 flex items-center justify-between border-b px-6 md:px-10">
        <div className="flex gap-4 items-center">
          <div
            onClick={() => setToggleNav(true)}
            className="flex cursor-pointer items-center justify-center lg:hidden"
          >
            <AiOutlineMenu size={20} />
          </div>
          {DashboardSideNavLinks.filter(
            (item) => item.path == location.pathname
          ).map((item, id) => (
            <div key={id}>
              {location.pathname == item.path && (
                <h2 className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold">{item.name}</h2>
              )}
            </div>
          ))}
          <div className="flex items-center gap-2">
            {location.pathname ==
              `/dashboard/ongoing-projects/${params.id}` && (
              <>
                {/* <img
                  src="/pajamas_project.svg"
                  className="h-6 w-6"
                  alt="icon"
                /> */}
                <div className='h-10 w-10 block cursor-pointer flex items-center justify-center bg-none md:bg-none rounded-full'>
                  <MdArrowBackIosNew className='h-6 w-6 block cursor-pointer' onClick={() => navigate(-1)} />
                </div>
                <div gradientColor='#f3f4f6' gradient={true} speed={100} pauseOnHover className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold">
                  {projectDetailes?.data?.project_title}
                </div>
                {/* <h2 className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold max-md:hidden">
                  {projectDetailes?.data?.project_title}
                </h2> */}
              </>
            )}
            {location.pathname ==
              `/dashboard/register-staff/staff-list/staff-profile` && (
              <>
                <div onClick={() => navigate(-1)} className='h-10 w-10 block cursor-pointer flex items-center justify-center bg-none md:bg-white rounded-full'>
                  <IoMdArrowBack className='h-6 w-6 block cursor-pointer' />
                </div>
                <h2 className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold">
                  User Profile
                </h2>
              </>
            )}
            {location.pathname ==
              `/dashboard/register-staff/staff-list` && (
              <>
                <div onClick={() => navigate(-1)} className='h-10 w-10 block cursor-pointer flex items-center justify-center bg-none md:bg-white rounded-full'>
                  <IoMdArrowBack className='h-6 w-6 block cursor-pointer' />
                </div>
                <h2 className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold">
                  User List
                </h2>
              </>
            )}
            {location.pathname ==
              `/dashboard/edit-project/${params.id}` && (
              <>
                <div className='h-10 w-10 block cursor-pointer flex items-center justify-center bg-none md:bg-white rounded-full'>
                  <MdArrowBackIosNew className='h-6 w-6 block cursor-pointer' onClick={() => navigate(-1)} />
                </div>
                <div gradientColor='#f3f4f6' gradient={true} speed={100} pauseOnHover className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold">
                  {projectDetailes?.data?.project_title}
                </div>
              </>
            )}
            {location.pathname == `/dashboard/activity-tracker` && (
              <>
                <h2 className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold md:hidden">Activity Tracker</h2>
                <h2 className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold max-md:hidden">Activity Tracker</h2>
              </>
            )}
            {location.pathname == `/dashboard/projects-gallery` && (
              <>
                <h2 className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold md:hidden">Media Library</h2>
                <h2 className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold max-md:hidden">Media Library</h2>
              </>
            )}
            {location.pathname == `/dashboard/audit-page` && (
              <>
                
              </>
            )}
            {location.pathname == `/dashboard/subscriptions` && (
              <>
                <div className='h-10 w-10 block cursor-pointer flex items-center justify-center bg-none md:bg-white rounded-full'>
                  <MdArrowBackIosNew className='h-6 w-6 block cursor-pointer' onClick={() => navigate(-1)} />
                </div>
                <h2 className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold ">
                  Subscriptions
                </h2>
              </>
            )}
            {location.pathname ==
              `/dashboard/audit-page` && (
              <>
                <div className='h-10 w-10 block cursor-pointer flex items-center justify-center bg-none md:bg-white rounded-full'>
                  <MdArrowBackIosNew className='h-6 w-6 block cursor-pointer' onClick={() => navigate(-1)} />
                </div>
                <div gradientColor='#f3f4f6' gradient={true} speed={100} pauseOnHover className="text-[1rem] sm:text-xl lg:text-2xl m-0 font-semibold">
                  User Analytics
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end gap-2 sm:gap-4">
          {location.pathname !== `/dashboard/ongoing-projects` && (
              <a className="hidden md:block text-sm md:text-md lg:text-lg subscription-text font-bold">{userProfile?.enterprise_account}</a>
          )}
          {location.pathname == `/dashboard/ongoing-projects` && (
            <>
              <Link
                to={`/dashboard/projects-gallery`}
                className="bg-native-blue rounded-full px-3 text-white py-2 flex justify-between items-center"
              >
                <MdOutlineImage />
                <a className="hidden md:block text-white ml-2">Media Library</a>
              </Link>
              <Link
                to={`/dashboard/activity-tracker`}
                className="bg-native-blue rounded-full px-3 text-white py-2 flex justify-between items-center"
              >
                <TbActivityHeartbeat />
                <a className="hidden md:block text-white ml-2">Activity Tracker</a>
              </Link>
            </>
          )}
          {userProfile?.sub_account_overview?.allowed_permissions?.includes("manage_subscriptions") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access") ? (
            <Link
              to={`/dashboard/subscriptions`}
              className='subscription-button rounded-full px-2  py-1 bg-transparent flex justify-between items-center'
            >
              {userProfile?.account_presets.subscription_plan == "" && <b className='hidden md:block subscription-text'>Subscribe</b>}
              {userProfile?.account_presets.subscription_plan != "" && <b className='hidden md:block subscription-text'>{userProfile?.account_presets.subscription_plan}</b>}
              <IoIosStar className="block md:hidden" color="gold" size={20} />
            </Link>
          ) : (
            <div
              className='subscription-button rounded-full px-2  py-1 bg-transparent flex justify-between items-center'
            >
              {userProfile?.account_presets.subscription_plan == "" && <b className='hidden md:block subscription-text'>Subscribe</b>}
              {userProfile?.account_presets.subscription_plan != "" && <b className='hidden md:block subscription-text'>{userProfile?.account_presets.subscription_plan}</b>}
              <IoIosStar className="block md:hidden" color="gold" size={20} />
            </div>
          )}
          {/* <div className="notification-icon-container">
            <Icon as={BsBellFill} w={6} h={6} />
          </div> */}
          {userProfile?.account_class == "sub" ? (
            // <div onClick={() => clokingLoading ? null : clockIn()} className='notification-icon-container relative bg-white p-2 rounded-full cursor-pointer'>
            //   {clokingLoading ? <ReactLoading type={"spokes"} color='#00b47a' height={25} width={25} /> : <RiUserLocationLine className='h-6 w-6' />}
            // </div>
            <div className='notification-icon-container relative bg-white p-2 rounded-full cursor-pointer'>
            <div onClick={handleIconClick}>
              {clokingLoading ? (
                <ReactLoading type={"spokes"} color='#00b47a' height={25} width={25} />
              ) : (
                <RiUserLocationLine className='h-6 w-6' />
              )}
            </div>
            {dropdownVisible && (
              <div className='absolute top-full mt-2 right-0 bg-white shadow-lg rounded-md' style={{width:150}}>
                <div onClick={userProfile?.user_attendance?.user_clocked_in ? null : handleClockIn} className={`p-2 text-sm font-bold ${userProfile?.user_attendance?.user_clocked_in ? `cursor-not-allowed` : `cursor-pointer`} hover:bg-gray-200`}>
                  Clock-In {userProfile?.user_attendance?.user_clocked_in && `(${userProfile?.user_attendance?.time_clocked_in})`}
                </div>
                <div onClick={userProfile?.user_attendance?.user_clocked_in ? handleClockOut : null} className={`p-2 text-sm font-bold text-[#ff0000] ${userProfile?.user_attendance?.user_clocked_in ? `cursor-pointer` : `cursor-not-allowed`} hover:bg-gray-200`}>
                  Clock-Out
                </div>
              </div>
            )}
          </div>
          ) : null}
          <div className='hidden md:block cursor-pointer' onClick={() => navigate('/dashboard/profile')}>
            <Avatar name={userProfile?.name} src={userProfile?.display_picture} />
          </div>
        </div>

        <div
          className={`transition-transform duration-500 lg:hidden fixed top-0 bottom-0 right-0 flex-col max-w-full bg-native-blue z-50 h-screen w-full ${
            toggleNav ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div
            onClick={() => setToggleNav(false)}
            tabIndex={0}
            className="btn btn-ghost btn-circle mb-4 mt-3 mr-5 self-end"
          >
            <AiOutlineClose className="h-7 w-7 text-white" />
          </div>
          <ul className="p-0">
            {DashboardSideNavLinks?.map((link) => {
              const { name, icon, path, id, accountType } = link;
              const isActive =
                path == location.pathname ||
                (`${path}/${params.id}` ==
                  `${location.pathname}/${params.id}` &&
                  true);

              return (
                <React.Fragment key={id}>
                  {(userProfile?.account_type &&
                    userProfile?.account_type.toLowerCase() ===
                      accountType.toLowerCase()) ||
                  accountType === 'both' ? (
                    <NavLink to={path} onClick={() => setToggleNav(false)}>
                      <li
                        className={`mr-4 flex justify-between text-lg h-16 items-center pr-2 pl-4 ${
                          isActive
                            ? 'bg-white rounded-tr-full rounded-br-full text-native-blue'
                            : 'text-white'
                        } `}
                      >
                        <div className="flex items-center gap-2">
                          <div className="side-nav-icon">{icon}</div>
                          <span className="nav-link-name">{name}</span>
                        </div>
                        {isActive && (
                          <BsArrowRightShort
                            size={20}
                            className="rounded-full h-10 p-2 w-10 bg-stone-300"
                          />
                        )}
                        {/* {!isActive && name == "Schedular" ? (
                          <div className='h-5 px-2 flex items-center justify-center bg-white rounded-sm'>
                            <a className='text-native-blue'>1</a>
                          </div>
                        ) : (null)} */}
                      </li>
                    </NavLink>
                  ) : null}
                </React.Fragment>
              );
            })}
          </ul>
          <div
            onClick={() => logOutFunction()}
            className={`flex mt-10 justify-between text-lg h-14 items-center  pl-4 hover:bg-red-500 rounded-tr-full rounded-br-full text-white `}
          >
            <div className="flex items-center gap-2">
              <BsFillArrowLeftSquareFill />
              <span className="nav-link-name">LogOut</span>
            </div>
            <div className="arrow-container">
              <BsArrowLeftShort className="rounded-full h-10 p-2 w-10 bg-white text-black mr-4" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
