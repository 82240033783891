import { FaRegTrashAlt } from "react-icons/fa";
import React, { useEffect, useState, useContext } from "react";
import { Tab } from "@headlessui/react";
import { classNames } from "../systemSettings/SystemSettings";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import image from "../../assets/images/profile-card-cover-image.jpg";
import { TostMessageContext } from "../../context/TostMessage";
import axios from "axios";
import {
  disable_staff,
  enable_staff,
  fetch_staffs,
  get_staff,
  update_staff_permission,
  staff_permissions,
  update_staff,
  update_staff_guarantor,
  update_staff_profile,
  delete_staff,
} from "../../config/variables";
import { AuthContext } from "../../context/AuthContext";
import { useCookies, Cookies } from "react-cookie";
// import avatar from "../../assets/images/avatar.png";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaUserEdit } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import ModalComponent from "../../components/Modal";

const InfoContext = React.createContext();
const StaffProfile = () => {
  const location = useLocation();
  const { staffDetails: staff } = location.state;

  const [userDetails, setUserDetails] = useState({
    personal_info: {
      name: {
        label: "Fullname",
        value: "",
        type: "text",
      },
      preffered_name: {
        label: "Preffered name",
        value: "",
        type: "text",
      },
      email: {
        label: "email",
        value: "",
        type: "email",
      },
      phone_number: {
        label: "Phone",
        value: "",
        type: "text",
      },
      sex: {
        label: "Gender",
        value: "",
        type: "select",
      },
      bvn: {
        label: "BVN",
        value: "", // No data provided in the JSON
        type: "text",
      },
      nin: {
        label: "NIN",
        value: "",
        type: "text",
      },
      date_of_birth: {
        label: "Date of Birth",
        value: "",
        type: "date",
      },
      work_email: {
        label: "work email",
        value: "",
        type: "email",
      },
      next_of_kin: {
        label: "next of kin",
        value: "",
        type: "text",
      },
      relationship_to_next_of_kin: {
        label: "relationship to next of kin",
        value: "",
        type: "text",
      },
      emergency_contact: {
        label: "emergency contact",
        value: "",
        type: "tel",
      },
      bank_name: {
        label: "Bank name",
        value: "",
        type: "text",
      },
      account_number: {
        label: "account number",
        value: "",
        type: "text",
      },
      correspondence_address: {
        label: "correspondence address",
        value: "",
        type: "textarea",
      },

      cug_number: {
        label: "CUG Number",
        value: "", // No data provided in the JSON
        type: "text",
      },
    },
    guarantors_info: {
      guarantor_name: {
        label: "name",
        value: "",
        type: "text",
      },
      guarantor_email_address: {
        label: "email address",
        value: "",
        type: "email",
      },
      guarantor_phone_number: {
        label: "phone number",
        value: "",
        type: "tel",
      },
      guarantor_occupation: {
        label: "occupation",
        value: "",
        type: "text",
      },
      guarantor_address: {
        label: "address",
        value: "",
        type: "textarea",
      },
      guarantor_occupation_address: {
        label: "occupation address",
        value: "",
        type: "textarea",
      },
    },
    staff_permission_info: {
      staff_salary: {
        value: "",
        label: "Staff salary",
        type: "tel",
      },
    },
  });

  function updateCurrentInput(section, field, updatedValue) {
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [section]: {
        ...prevUserDetails[section],
        [field]: {
          ...prevUserDetails[section][field],
          value: updatedValue,
        },
      },
    }));
  }
  let [cat] = useState([
    {
      id: 1,
      tabName: "Personal Info",
      tabSection: PersonalInfoTab,
    },
    {
      id: 2,
      tabName: "User Permission",
      tabSection: StaffPermissionTab,
    },
    {
      id: 3,
      tabName: "Guarantor Info",
      tabSection: GuarantorTab,
    },
    {
      id: 4,
      tabName: "User Documents",
      tabSection: StaffDocumentTab,
    },
    {
      id: 5,
      tabName: "Control",
      tabSection: StaffSystemDetailSetting,
    },
  ]);

  return (
    <InfoContext.Provider
      value={{ userDetails, staff, setUserDetails, updateCurrentInput }}
    >
      <div className='py-3 xl:px-10 max-md:px-6 md:px-10'>
        {/* <div>
					<h2>Profile</h2>
				</div> */}
        <div>
          <div className='mx-auto sm:px-0 flex flex-col gap-2 lg:flex-row'>
            <Tab.Group>
              <Tab.List className='flex min-w-[200px] gap-1 flex-wrap lg:flex-col rounded-lg bg-slate-300/40 p-1'>
                {cat.map((category) => (
                  <Tab
                    key={category.id}
                    className={({ selected }) =>
                      classNames(
                        "w-auto rounded-md py-3 px-3 flex-grow md:flex-grow-0 text-[.9rem] font-medium leading-5",
                        "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                        selected
                          ? "bg-white text-blue-700 shadow"
                          : "text-[#172337] hover:bg-white/[0.5]"
                      )
                    }
                  >
                    {category.tabName}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className='lg:flex-grow '>
                {cat.map((eachTab, idx) => (
                  <Tab.Panel
                    key={idx}
                    className={classNames(
                      "rounded-xl bg-white min-h-[500px] p-3 p-sm-4",
                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                    )}
                  >
                    <ul className='p-0'>{<eachTab.tabSection />}</ul>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </InfoContext.Provider>
  );
};

export default StaffProfile;

export function StaffSystemDetailSetting() {
  const { staff } = React.useContext(InfoContext);
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const navigate = useNavigate()
  const [currStaff, setCurrStaff] = useState(null);
  const [editStaff, setEditStaff] = useState(false);
  const [image, setImage] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);
  const [department, setDepartment] = useState("");
  const [departmentItem, setDepartmentItem] = useState(null);
  const [departmentErrState, setDepartmentErrState] = useState(false);
  const { setTostMessage } = useContext(TostMessageContext);

  const deleteUser = async (staffId) => {
    setDeleteLoading(true);
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        staffId
      },
    };
    try {
      const response = await axios.post(delete_staff, data, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      const resData = await response.data;
      if (resData.status) {
        setDeleteLoading(false)
        setTostMessage({
          messageType: "success",
          message: resData.message ?? resData.response,
        });
        navigate("/dashboard/register-staff/staff-list")
      } else {
        setTostMessage({
          messageType: "error",
          message: resData.response,
        });
      }
      setLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      setTostMessage({
        messageType: "error",
        message: error.message,
      });
    }
  }


  const handleOptionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setDepartment(selectedId);
    setDepartmentErrState(false);
    const selectedObject = departmentList?.find(
      (item) => item.id == selectedId
    );
    setDepartmentItem(selectedObject);

    // const getDepartmentLevelId = () => {
    //   if (selectedObject && selectedObject.department_level && selectedObject.department_level.length > 0) {
    //     return selectedObject.department_level[0].id || '';
    //   }
    //   return '';
    // };
    // setPermissionItem(getDepartmentLevelId());
  };

  const getDepartments = async () => {
    const response = await axios.post(
      staff_permissions,
      {
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
      },
      {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      }
    );
    const { data } = await response.data;
    setDepartmentList(data);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currStaff?.name,
      email: currStaff?.email,
      title: currStaff?.title,
      phone_number: currStaff?.phone_number,
      salary_amount: currStaff?.salary_amount,
    },
  });

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedImage = event.target.files[0];
      if (selectedImage.type.startsWith("image/")) {
        setImage(URL.createObjectURL(selectedImage));
      } else {
        alert("Please select an image file.");
      }
    }
  };
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      try {
        const response = await axios.post(
          get_staff,
          {
            user: {
              account_id: userProfile?.account_id,
              sub_account_id: userProfile?.sub_account_id,
            },
            data: {
              staff_id: staff.id,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              access: cookies.urbexEnterpriseUserToken,
            },
          }
        );

        const { data } = await response.data;
        console.log(response);
        setCurrStaff(data);
        setFetchLoading(false);
      } catch (error) {
        setFetchLoading(false);
      }
    })();
  }, []);

  const enableStaff = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        enable_staff,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
          data: {
            staff_email: staff.email,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );

      const { data } = await response.data;
      setCurrStaff(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const disableStaff = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        disable_staff,
        {
          user: {
            company_id: userProfile?.account_id,
            staff_email: staff.email,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );

      const { data } = await response.data;
      setCurrStaff(data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  function splitFullName(fullName) {
    const nameArray = fullName.split(" ");
    const firstName = nameArray[0];
    const lastName = nameArray.slice(1).join(" ");
    return { firstName, lastName };
  }
  //console.log(currStaff)

  return (
    <div className=''>
      {fetchLoading ? (
        <div className='w-full'>
          <div
            className='h-20 w-20 rounded-full bg-gray-200 animate-pulse mt-5'
            alt=''
          />
          <div className='mt-3'>
            <p className='m-0 bg-gray-200 h-10 mb-3 w-2/3 font-medium'></p>
            <p className='m-0 bg-gray-200 h-10 mb-3 w-8/12 font-medium'></p>
            <hr className='mt-7 mb-4 border-gray-400' />

            <button
              onClick={disableStaff}
              className='px-10 py-3 h-14 w-36 rounded-xl bg-gray-200 text-white'
            ></button>
          </div>
        </div>
      ) : (
        <div className='mt-5'>
          <div className='mt-3'>
            <div className='flex mt-3 justify-between gap-10 max-w-lg items-start md:flex-row md:items-start'>
              {currStaff?.personal_info?.status != 3 ? (
                <button
                  onClick={disableStaff}
                  className='px-6 py-2 rounded-lg text-white font-bold bg-[#ff0000]'
                >
                  {loading ? "Loading..." : "Deactivate User"}
                </button>
              ) : (
                <button
                  onClick={enableStaff}
                  className='px-6 py-2 rounded-lg bg-[#00b47a] text-white font-bold opacity-100'
                >
                  {loading ? "Loading..." : "Activate User"}
                </button>
              )}
              {/* <button
                 onClick={()=>{
                  deleteUser(staff.id)
                 }}
                disabled = {deleteLoading}
                className='px-6 py-2 rounded-lg text-red-500 bg-white border'
              >
                {deleteLoading ? "Loading..." : "Deactivate User"}
              </button> */}
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
}

function PersonalInfoTab() {
  const { userDetails, updateCurrentInput, staff, setUserDetails } =
    React.useContext(InfoContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);

  async function getStaffPersonalInfo() {
    const response = await axios.post(
      get_staff,
      {
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          staff_id: staff.id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          access: cookies.urbexEnterpriseUserToken,
        },
      }
    );

    const updatePersonalInfo = (data) => {
      const updatedPersonalInfo = { ...userDetails.personal_info };
      Object.entries(data).forEach(([eachKey, eachValue]) => {
        if (updatedPersonalInfo.hasOwnProperty(eachKey)) {
          updatedPersonalInfo[eachKey] = {
            ...updatedPersonalInfo[eachKey],
            value: eachValue,
            label: updatedPersonalInfo[eachKey].label,
            type: updatedPersonalInfo[eachKey].type,
          };
        }
      });

      setUserDetails((prevDetails) => ({
        ...prevDetails,
        personal_info: updatedPersonalInfo,
      }));
    };
    // console.log(response);
    if (response.data.data) {
      const fetchedPersonalInfoData = await response.data.data.personal_info;
      if (fetchedPersonalInfoData) {
        updatePersonalInfo(fetchedPersonalInfoData);
      }
    }
  }

  useEffect(() => {
    (async () => {
      staff && getStaffPersonalInfo();
    })();
  }, [staff]);

  async function saveStaffPersonalInfoUpdate() {
    setIsLoading(true);
    const personalInfo = userDetails.personal_info;
    const formattedData = Object.keys(personalInfo).reduce((acc, key) => {
      acc["account_id"] = staff.id;
      acc[key] = personalInfo[key].value;
      return acc;
    }, {});

    // Send POST request with Axios
    axios
      .post(update_staff_profile, formattedData, {
        headers: {
          "Content-Type": "application/json",
          access: cookies.urbexEnterpriseUserToken,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
        } else {
          setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
      })
      .catch((error) => {
        setTostMessage({
          messageType: "error",
          message: error.data.response,
        });
      })
      .finally(() => {
        setIsLoading(false); // Stop loading irrespective of the result
      });
  }

  return (
    <div>
      <header className='text-start'>
        <h4 className='font-semibold text-xl'>Profile Information</h4>
      </header>
      <div className='pt-4 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3.5'>
        {Object.entries(userDetails.personal_info).map(
          ([key, value], index) => {
            return (
              <div key={index} className='flex flex-col text-start gap-y-2'>
                <label htmlFor='' className='text-[.9rem] capitalize'>
                  {value.label}
                </label>
                <div>
                  <InputType
                    onChange={(updatedValue) =>
                      updateCurrentInput("personal_info", key, updatedValue)
                    }
                    type={value.type}
                    currentInputValue={value.value === null ? "" : value.value}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
      <div className='mt-4'>
        <button
          type='button'
          className='text-[.98rem] bg-[#172337] font-semibold text-white px-10 py-2.5 rounded-md'
          onClick={saveStaffPersonalInfoUpdate}
        >
          Save
        </button>
      </div>
    </div>
  );
}

function GuarantorTab() {
  const { userDetails, updateCurrentInput, staff, setUserDetails } =
    React.useContext(InfoContext);
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const { setTostMessage } = React.useContext(TostMessageContext);
  async function getStaffGuarantor() {
    const response = await axios.post(
      get_staff,
      {
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          staff_id: staff.id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          access: cookies.urbexEnterpriseUserToken,
        },
      }
    );

    const updateGuarantorInfo = (data) => {
      const updatedGuarantorsInfo = { ...userDetails.guarantors_info };
      Object.entries(data).forEach(([eachKey, eachValue]) => {
        if (updatedGuarantorsInfo.hasOwnProperty(eachKey)) {
          updatedGuarantorsInfo[eachKey] = {
            ...updatedGuarantorsInfo[eachKey],
            value: eachValue,
            label: updatedGuarantorsInfo[eachKey].label,
            type: updatedGuarantorsInfo[eachKey].type,
          };
        }
      });

      setUserDetails((prevDetails) => ({
        ...prevDetails,
        guarantors_info: updatedGuarantorsInfo,
      }));
    };

    if (response.data.data) {
      const fetchedGuarantorData = await response.data.data.guarantor_details;
      if (fetchedGuarantorData) {
        updateGuarantorInfo(fetchedGuarantorData);
      }
    }
  }

  useEffect(() => {
    (async () => {
      staff && getStaffGuarantor();
    })();
  }, [staff]);

  async function saveGuarantorUpdate() {
    const guarantorInfo = userDetails.guarantors_info;
    const formattedData = Object.keys(guarantorInfo).reduce((acc, key) => {
      acc["account_id"] = staff.id;
      acc[key] = guarantorInfo[key].value;
      return acc;
    }, {});

    axios
      .post(update_staff_guarantor, formattedData, {
        headers: {
          "Content-Type": "application/json",
          access: cookies.urbexEnterpriseUserToken,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
        } else {
          setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
      })
      .catch((error) => {
        setTostMessage({
          messageType: "error",
          message: "Network error",
        });
      });
  }
  return (
    <div>
      <header className='text-start'>
        <h4 className='font-semibold text-xl'>Guarantor's Information</h4>
      </header>
      <div className='pt-4 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3.5'>
        {Object.entries(userDetails.guarantors_info).map(
          ([key, value], index) => {
            return (
              <div key={index} className='flex flex-col text-start gap-y-2'>
                <label htmlFor='' className='text-[.9rem] capitalize'>
                  Guarantor's {value.label}
                </label>
                <div>
                  <InputType
                    onChange={(updatedValue) =>
                      updateCurrentInput("guarantors_info", key, updatedValue)
                    }
                    type={value.type}
                    currentInputValue={value.value === null ? "" : value.value}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
      <div className='mt-4'>
        <button
          type='button'
          className='text-[.98rem] bg-[#172337] font-semibold text-white px-10 py-2.5 rounded-md'
          onClick={saveGuarantorUpdate}
        >
          Save
        </button>
      </div>
    </div>
  );
}

function StaffDocumentTab() {
  const [isModalAddModalVisible, setIsAddModalVisibility] = useState(false);
  const { userDetails, staff, setUserDetails } = React.useContext(InfoContext);
  const [documents, setDocuments] = useState([]);
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);

  // function to handle new documents being recieved
  function receiveStaffNewDocuments(event) {
    const listOfFiles = [...event.target.files];
    if (!listOfFiles.length) return;
  }

  async function getStaffDocuments() {
    const response = await axios.post(
      get_staff,
      {
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          staff_id: staff.id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          access: cookies.urbexEnterpriseUserToken,
        },
      }
    );

    if (response.data.data) {
      const fetchedDocuments = await response.data.data.documents;
      setDocuments([...fetchedDocuments]);
    }
  }

  useEffect(() => {
    (async () => {
      staff && getStaffDocuments();
    })();
  }, [staff]);

  // function to save staff documents to db
  function saveStaffDocument() {}

  function handleDeleteDocument() {}

  return (
    <>
      <div>
        <div>
          <header className='flex items-center justify-between'>
            <h4 className='font-semibold text-xl'>User's Documents</h4>
            <button
              type='button'
              className='text-white/90 bg-[#172337] font-semibold rounded-md py-2 px-4 w-auto hover:text-white transition-all duration-300 text-sm ease-in-out'
              onClick={() => setIsAddModalVisibility(true)}
            >
              Add New Document
            </button>
          </header>
          <div className='pt-4'>
            <div className='shadow-sm p-2 rounded-md border border-slate-200/40 px-3'>
              <header className='border-bottom flex py-3 items-center justify-between'>
                <span className='text-sm uppercase font-semibold'>
                  Document name
                </span>
                <span className='text-sm uppercase font-semibold'>Delete</span>
              </header>
              <div className='min-h-[300px]'>
                {documents &&
                  documents.map((eachDoc, i) => (
                    <div
                      key={eachDoc.id || i}
                      className={`py-3 gap-x-2 flex justify-between items-center`}
                    >
                      <div>
                        <h3 className='text-sm font-medium m-0'>
                          {eachDoc.filename}
                        </h3>
                      </div>
                      <div className='relative'>
                        <button
                          type='button'
                          onClick={() => handleDeleteDocument(eachDoc.id)}
                          className='p-2 rounded-full hover:bg-primary hover:text-white duration-300 transition-all ease-in-out'
                        >
                          <FaRegTrashAlt className='w-4 h-4' />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className='mt-4'>
            <button
              type='button'
              className='text-[.98rem] bg-[#172337] font-semibold text-white px-10 py-2.5 rounded-md'
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {isModalAddModalVisible === true ? (
        <ModalComponent
          modalTitle="Add User Documents"
          isActiveModal={isModalAddModalVisible}
          closeModal={() => setIsAddModalVisibility(false)}
        >
          <div className='my-4 flex flex-col gap-y-4'>
            <div>
              <label htmlFor=''>
                Enter multiple staff's document(s), if there are more than one
              </label>
              <input
                type='file'
                onChange={receiveStaffNewDocuments}
                multiple
                className='mt-3 w-full'
                accept='application/pdf'
              />
            </div>
            <div className='mt-4 flex items-center justify-end'>
              <button
                type='button'
                className='text-white/90 bg-[#172337] rounded-md py-3 px-4 w-auto hover:text-white transition-all duration-300 text-sm ease-in-out'
                onClick={saveStaffDocument}
              >
                Upload
              </button>
            </div>
          </div>
        </ModalComponent>
      ) : null}
    </>
  );
}

const StaffPermissionTab = function () {
  const { userDetails, updateCurrentInput, staff, setUserDetails } =
    React.useContext(InfoContext);
  const { userProfile } = React.useContext(AuthContext);
  const [permissionItem, setPermissionItem] = useState(null);
  const [permission, setPermission] = useState("");
  const [department, setDepartment] = useState();
  const [departmentItem, setDepartmentItem] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);
  const { setTostMessage } = useContext(TostMessageContext);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies();

  const handleOptionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setDepartment(selectedId);
    const selectedObject = departmentList?.find(
      (item) => +item.id === +selectedId
    );
    setDepartmentItem(selectedObject);

    const getDepartmentLevelId = () => {
      if (selectedObject && selectedObject.department_level && selectedObject.department_level.length > 0) {
        return selectedObject.department_level[0].id || '';
      }
      return '';
    };
    setPermission(getDepartmentLevelId());
  };

  const handlePermissionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setPermission(selectedId);
    const selectedObject = departmentItem?.department_level?.find(
      (item) => +item.id === +selectedId
    );
    setPermissionItem(selectedObject);
  };

  const getDepartments = async () => {
    try {
      const response = await axios.post(
        staff_permissions,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const { data } = await response.data;
      setDepartmentList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getDepartments();

    return () => getDepartments();
  }, []);


const saveStaffPermissionsUpdate = async () => {
  setLoading(true);

  try {
    const response = await axios.post(
      update_staff_permission,
      {
        user: {
          account_id: userProfile?.account_id,
          sub_account_id: userProfile?.sub_account_id,
        },
        data: {
          staff_id: staff.id,
          permission_id: permission
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          access: cookies.urbexEnterpriseUserToken,
        },
      }
    );

    const resData = response.data;
    if (resData.status) {
      setTostMessage({
        messageType: "success",
        message: resData.response,
      });
    } else {
      setTostMessage({
        messageType: "error",
        message: resData.response,
      });
    }
  } catch (error) {
    console.log(error);
    setTostMessage({
      messageType: "error",
      message: "An error occurred. Please try again.",
    });
  } finally {
    setLoading(false);
  }
};


  return (
    <div className='flex flex-col gap-y-8'>
      <div>
        <header className='text-start'>
          <h4 className='font-semibold text-xl'>
            User Role And Access Level
          </h4>
        </header>
        <div className='flex flex-col gap-y-3'>
          <div>
            <label htmlFor=''>User Department</label>
            <select
              value={department}
              onChange={handleOptionChange}
              className={`flex flex-col gap-6 w-full cursor-pointer bg-white border rounded-md shadow-sm focus:ring-indigo-200 focus:ring-opacity-50 focus:ring focus:outline-none`}
            >
              <option value='' disabled>
                Select department
              </option>
              {departmentList?.map((item, id) => (
                <option value={item.id} key={id} className=''>
                  {item.title}
                </option>
              ))}
            </select>
            {departmentItem?.details && (
              <>
                <h4 className='mb-2 text-lg mt-2.5'>
                  Departmental description
                </h4>
                <p className='text-[.9rem] font-semibold px-2 py-2 rounded-sm bg-slate-200/50'>
                  {departmentItem?.details}
                </p>
              </>
            )}
          </div>
          <div>
            <label htmlFor=''>User Permission</label>
            <select
              value={permission}
              onChange={handlePermissionChange}
              className='flex flex-col gap-6 w-full cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm focus:ring-indigo-200 focus:ring-opacity-50 focus:ring focus:outline-none'
            >
              <option value='' disabled>
                Select permission
              </option>
              {departmentItem?.department_level?.map((item, id) => (
                <option value={item.id} key={id} className=''>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='mt-4'>
          <button
            type='button'
            className='text-[.98rem] bg-[#172337] font-semibold text-white px-10 py-2.5 rounded-md'
            onClick={saveStaffPermissionsUpdate}
          >
            Save
          </button>
        </div>
      </div>
      <div>
        <header className='text-start'>
          <h4 className='font-semibold text-xl'>Staff's Salary (Optional)</h4>
        </header>
        <div className='pt-2.5 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-3.5'>
          <InputType
            type='tel'
            currentInputValue={
              userDetails.staff_permission_info.staff_salary.value
            }
            onChange={(updatedValue) =>
              updateCurrentInput(
                "staff_permission_info",
                "staff_salary",
                updatedValue
              )
            }
          />
        </div>
        <div className='mt-4'>
          <button
            type='button'
            className='text-[.98rem] bg-[#172337] font-semibold text-white px-10 py-2.5 rounded-md'
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

// form component
function InputType({ type, currentInputValue, onChange }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  const otherGender =
    currentInputValue.toLowerCase() === "male" ? "Female" : "Male";
  const shortForm = currentInputValue.toLowerCase() === "male" ? "F" : "M";
  switch (type) {
    case "select": {
      return (
        <select
          className='style-form-input'
          onChange={handleChange}
          value={currentInputValue}
        >
          <option>{currentInputValue}</option>
          <option value={`${shortForm}`}>{otherGender}</option>
        </select>
      );
    }
    case "textarea": {
      return (
        <textarea
          onChange={handleChange}
          value={currentInputValue}
          className='style-form-input'
          rows={2}
        ></textarea>
      );
    }
    default: {
      return (
        <input
          type={type || "text"}
          inputMode={type.toLowerCase() === "tel" ? "numeric" : "text"}
          onChange={handleChange}
          value={currentInputValue}
          className='style-form-input'
        />
      );
    }
  }
}
