import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import { AuthContext } from "./AuthContext";
import { useCookies } from "react-cookie";
// import log from '../pages/CallPage/debug/log';
// import logError from '../pages/CallPage/debug/logError';

const VideoCallContext = createContext();

// const socket = io('http://localhost:5000');
// const socket = io("https://warm-wildwood-81069.herokuapp.com");
// const socket = io("https://3533-105-112-190-154.eu.ngrok.io");
const url = "https://mgtplatform-call.herokuapp.com";
// const url = "https://3533-105-112-190-154.eu.ngrok.io";

const VideoCallProvider = ({ children }) => {
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [cookies] = useCookies();
  const [stream, setStream] = useState();
  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");
  const [socket, setSocket] = useState(null);
  const [info, setInfo] = useState("Initializing");
  const [status, setStatus] = useState("init");
  const { userProfile } = useContext(AuthContext);
  const [roomID, setRoomID] = useState("ROOM1");
  const [isFront, setIsFront] = useState(true);
  const [chatObject, setChatObject] = useState(null);
  const [streamURL, setStreamURL] = useState(null);
  // const [remoteList, setRemoteList] = useState();
  // ----------------------- new code here --------------------
  const [callButtonClicked, setCallButtonClicked] = useState(false);
  const [incomingCall, setIncomingCall] = useState(false);
  const [dialing, setDialing] = useState(false);

  // auth context
  const { isLoggedIn } = useContext(AuthContext);
  // use to show video call popup
  // useEffect(() => {
  //   let timer;
  //   // check login before accessing camera
  //   if (!isLoggedIn) return;

  //   if (!incomingCall) {
  //     timer = setTimeout(() => {
  //       setIncomingCall(true);
  //     }, 10000);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [incomingCall, isLoggedIn]);
  useEffect(() => {
    let PMPChatObject = JSON.parse(sessionStorage.getItem("PMPChatObject"));
    setChatObject(PMPChatObject);
  }, []);

  useEffect(() => {
    // check login before accessing camera
    if (!isLoggedIn) return;

    let timer;

    if (dialing) {
      timer = setTimeout(() => {
        setDialing(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [dialing, isLoggedIn]);

  // -------------------- end --------------------
  const myVideo = useRef();
  const remoteList = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  let newCallSocket;

  const configuration = {
    iceServers: [
      {
        urls: ["stun:jb-turn1.xirsys.com"],
      },
      {
        username:
          "w9kgZV-HPfOqnAkPWuEOopqyHKEk-hbLNEEXyH9krklCZTcZ9_LgidOCzcAsN0KdAAAAAGK-49BlbW1h",
        credential: "9041065a-f936-11ec-b379-0242ac120004",
        credentialType: "password",
        urls: [
          "turn:jb-turn1.xirsys.com:80?transport=udp",
          "turn:jb-turn1.xirsys.com:3478?transport=udp",
          "turn:jb-turn1.xirsys.com:80?transport=tcp",
          "turn:jb-turn1.xirsys.com:3478?transport=tcp",
          "turns:jb-turn1.xirsys.com:443?transport=tcp",
          "turns:jb-turn1.xirsys.com:5349?transport=tcp",
        ],
      },
    ],
    iceTransportPolicy: "relay",
  };

  let pcPeers = {};
  let appClass;
  let localStream;

  useEffect(() => {
    // check login before accessing camera
    if (!isLoggedIn) return;
    // must be on call before accessing camera
    if (!callButtonClicked) return;

    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((currentStream) => {
        console.log(currentStream);
        setStream(currentStream);
        // setLocalStream(currentStream);
        myVideo.current.srcObject = currentStream;
      });
    // console.log("in UseEffect");s
    newCallSocket = io(url, {
      forceNew: true,
      reconnectionAttempts: 15, //Nombre de fois qu'il doit réessayer de se connecter
      //transports: ['websocket']
    });
    setSocket(newCallSocket);
    newCallSocket.on("connect", () => {
      console.log("connected");
    });
    newCallSocket.on("exchange", (data) => {
      exchange(data);
    });
    newCallSocket.on("leave", (socketId) => {
      // socket?.emit('end-call');
      console.log("Video Call ended");
      leave(socketId);
    });

    join(roomID);

    newCallSocket.on("me", (id) => setMe(id));
  }, [isLoggedIn, callButtonClicked]);
  useEffect(() => {
    // check login before accessing camera
    if (!isLoggedIn) return;
    // must be on call before accessing camera
    if (callButtonClicked) return;
    // To cancel the access state change and stop accessing the media devices
    cancelMediaDevicesVideoAudioAccess();
  }, [callButtonClicked]);

  // To cancel the access state change and stop accessing the media devices
  function cancelMediaDevicesVideoAudioAccess() {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop()); // Stop all tracks in the media stream
      setStream(null); // Reset the stream state variable if needed
      // check before uncommenting check video stream element
      // myVideo.current.srcObject = null; // Remove the media stream from the video element
    }
  }
  function endVideoCallFunction() {
    // console.log("endVideoCallFunction called");
    cancelMediaDevicesVideoAudioAccess();
    setIncomingCall(false);
    setDialing(false);
    setCallButtonClicked(false);
    window.history.go(-1); // Navigates back to the previous page
  }

  const join = (roomID) => {
    // check login before accessing camera
    if (!isLoggedIn) return;

    console.log("joining room");
    let onJoin = (socketIds) => {
      for (const i in socketIds) {
        if (socketIds.hasOwnProperty(i)) {
          const socketId = socketIds[i];
          console.log("join function", socketId);
          createPC(socketId, true);
        }
      }
    };

    const token = cookies.urbexEnterpriseUserToken || null;

    // HERE
    console.log("chatObject", chatObject);
    newCallSocket?.emit(
      "join",
      {
        access: token,
        account_id: userProfile?.account_id,
        client_id: chatObject?.client_id,
        project_manager_id: chatObject?.project_manager_id,
        project_id: chatObject?.project_id,
        contractor_id: chatObject?.contractor_id,
      },
      onJoin
    );
  };

  const createPC = (socketId, isOffer) => {
    // check login before accessing camera
    if (!isLoggedIn) return;
    /**
     * Create the Peer Connection
     */
    const peer = new RTCPeerConnection(configuration);
    pcPeers = {
      ...pcPeers,
      [socketId]: peer,
    };
    peer.onnegotiationneeded = async () => {
      console.log("onnegotiationneeded");
      if (isOffer) {
        try {
          await peer.setLocalDescription(await peer.createOffer());
          // send the offer to the other peer
          newCallSocket?.emit("exchange", {
            to: socketId,
            sdp: peer.localDescription,
            type: "offer",
          });
        } catch (err) {
          console.error("error here", err);
        }
      }
    };
    console.log("streamURL", streamURL);
    console.log("myVideo", myVideo);

    peer.addStream(myVideo.current.srcObject);

    /**
     * On Add Stream (Deprecated)
     */
    peer.onaddstream = (event) => {
      //console.log('onaddstream', event.stream);
      // const remoteList = remoteList;

      console.log("event", event);
      remoteList.current.srcObject = event.stream;
      // appClass.setState({
      //   info: 'Connected',
      //   remoteList: remoteList,
      // });
      setInfo("Connected");
      // setRemoteList(remoteList);
    };

    /**
     * On Ice Candidate
     */
    peer.onicecandidate = (event) => {
      //console.log('onicecandidate', event.candidate);
      if (event.candidate) {
        newCallSocket?.emit("exchange", {
          to: socketId,
          candidate: event.candidate,
        });
      }
    };

    /**
     * On Ice Connection State Change
     */
    peer.oniceconnectionstatechange = (event) => {
      //console.log('oniceconnectionstatechange', event.target.iceConnectionState);
      if (event.target.iceConnectionState === "completed") {
        //console.log('event.target.iceConnectionState === 'completed'');
        setTimeout(() => {
          getStats();
        }, 1000);
      }
      if (event.target.iceConnectionState === "connected") {
        //console.log('event.target.iceConnectionState === 'connected'');
      }
    };

    /**
     * On Signaling State Change
     */
    peer.onsignalingstatechange = (event) => {
      //console.log('on signaling state change', event.target.signalingState);
    };

    /**
     * On Remove Stream
     */
    peer.onremovestream = (event) => {
      //console.log('on remove stream', event.stream);
    };

    return peer;
  };

  const getStats = () => {
    // check login before accessing camera
    if (!isLoggedIn) return;
    const pc = pcPeers[Object.keys(pcPeers)[0]];
    if (
      pc.getRemoteStreams()[0] &&
      pc.getRemoteStreams()[0].getAudioTracks()[0]
    ) {
      const track = pc.getRemoteStreams()[0].getAudioTracks()[0];
      let callback = (report) => console.log("getStats report", report);

      //console.log('track', track);

      // pc.getStats(track, callback, logError);
    }
  };

  const exchange = async (data) => {
    // check login before accessing camera
    if (!isLoggedIn) return;

    // InCallManager.stopRingback();
    let fromId = data.from;

    if (data.sdp) {
      //  log('Exchange', data);
    }

    let peer;
    if (fromId in pcPeers) {
      peer = pcPeers[fromId];
    } else {
      peer = createPC(fromId, false);
    }

    if (data.sdp) {
      //console.log('exchange sdp', data);
      let sdp = new RTCSessionDescription(data.sdp);

      if (data.type == "offer") {
        await peer.setRemoteDescription(sdp);
        await peer.setLocalDescription(await peer.createAnswer());
        newCallSocket?.emit("exchange", {
          to: fromId,
          sdp: peer.localDescription,
          type: "answer",
        });
      } else if (data.type == "answer") {
        await peer.setRemoteDescription(sdp);
      }
    } else {
      peer.addIceCandidate(new RTCIceCandidate(data.candidate));
    }
  };

  const leave = (socketId) => {
    // check login before accessing camera
    if (!isLoggedIn) return;

    console.log("leave", socketId);

    const peer = pcPeers[socketId];

    console.log(pcPeers);
    // peerServer.disconnect();
    // RTCPeerConnection.close();
    peer.close();

    setCallEnded(true);

    connectionRef.current.destroy();

    window.location.reload();
  };

  const mapHash = (hash, func) => {
    // check login before accessing camera
    if (!isLoggedIn) return;

    //console.log(hash);
    const array = [];
    for (const key in hash) {
      if (hash.hasOwnProperty(key)) {
        const obj = hash[key];
        array.push(func(obj, key));
      }
    }
    return array;
  };

  const answerCall = () => {
    // check login before accessing camera
    if (!isLoggedIn) return;

    setCallAccepted(true);

    const peer = new Peer({
      initiator: false,
      config: {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          { urls: "stun:global.stun.twilio.com:3478?transport=udp" },
        ],
      },
      trickle: false,
      stream,
    });

    peer.on("signal", (data) => {
      socket?.emit("answerCall", { signal: data, to: call.from });
    });

    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const callUser = (id) => {
    // check login before accessing camera
    if (!isLoggedIn) return;

    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on("signal", (data) => {
      socket?.emit("callUser", {
        userToCall: roomID,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket?.on("callAccepted", (signal) => {
      setCallAccepted(true);

      peer.signal(signal);
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    // check login before accessing camera
    if (!isLoggedIn) return;

    setCallEnded(true);

    connectionRef.current.destroy();

    window.location.reload();
  };

  return (
    <VideoCallContext.Provider
      value={{
        call,
        callAccepted,
        myVideo,
        userVideo,
        remoteList,
        stream,
        mapHash,
        name,
        setName,
        callEnded,
        me,
        callUser,
        leaveCall,
        answerCall,
        callButtonClicked,
        setCallButtonClicked,
        incomingCall,
        setIncomingCall,
        dialing,
        setDialing,
        endVideoCallFunction,
      }}
    >
      {children}
    </VideoCallContext.Provider>
  );
};
export { VideoCallProvider, VideoCallContext };
// export { VideoCallProvider, VideoCallContext };
