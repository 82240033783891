import { FormLabel, Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState, Fragment } from "react";
import ReactLoading from "react-loading";
import "./add-staff.scss";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import {
  create_staff,
  staff_permissions,
  register_staff,
} from "../../config/variables";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { TostMessageContext } from "../../context/TostMessage";
import { useNavigate } from "react-router-dom";
import { PiUserListDuotone } from "react-icons/pi";

function RegisterStaff() {
  const [formHeightIndex, setFormHeightIndex] = useState(0);
  const [departmentErrState, setDepartmentErrState] = useState(false);
  const [permissionErr, setPermissionErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const { setTostMessage } = useContext(TostMessageContext);
  const [permissionItem, setPermissionItem] = useState(null);
  const [permission, setPermission] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentItem, setDepartmentItem] = useState(null);
  const [departmentList, setDepartmentList] = useState(null);
  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setDepartment(selectedId);
    const selectedObject = departmentList?.find(
      (item) => +item.id === +selectedId
    );
    setDepartmentItem(selectedObject);

    const getDepartmentLevelId = () => {
      if (selectedObject && selectedObject.department_level && selectedObject.department_level.length > 0) {
        return selectedObject.department_level[0].id || '';
      }
      return '';
    };
    setPermission(getDepartmentLevelId());
    setDepartmentErrState(false)
  };

  const handlePermissionChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setPermission(selectedId);
    const selectedObject = departmentItem?.department_level?.find(
      (item) => +item.id === +selectedId
    );
    setPermissionItem(selectedObject);
    setPermissionErr(false)
    
  };

  const getDepartments = async () => {
    try {
      const response = await axios.post(
        staff_permissions,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const { data } = await response.data;
      setDepartmentList(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      email: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      staff_salary: "",
    },
  });

  const addProject = async (data) => {
    setLoading(true);
    if (!departmentItem) {
      setDepartmentErrState(true);
      setLoading(false);
      return;
    }
    if (!permissionItem) {
      setPermissionErr(true);
      setLoading(false);
      return;
    }

    // Create a FormData object
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("email", data.email);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("phone_number", data.phone_number);
    formData.append("department_level", permissionItem?.id);
    formData.append("company_id", userProfile?.account_id);
    formData.append("account_id", userProfile?.account_id);
    formData.append("sub_account_id", userProfile?.sub_account_id);
    formData.append("salary_amount", data.staff_salary);
    // Append Guarantor's Information
    formData.append("guarantors_name", data.guarantors_name);
    formData.append("guarantors_occupation", data.guarantors_occupation);
    formData.append("guarantors_email_address", data.guarantors_email_address);
    formData.append("guarantors_phone_number", data.guarantors_phone_number);
    formData.append("guarantors_address", data.guarantors_address);
    formData.append(
      "guarantors_occupation_address",
      data.guarantors_occupation_address
    );

    // Append other fields like preferred name, date of birth, etc.
    formData.append("preferred_name", data.preferred_name);
    formData.append("date_of_birth", data.date_of_birth);
    formData.append("gender", data.gender);
    formData.append("home_address", data.home_address);
    formData.append("correspondence_address", data.correspondence_address);
    formData.append("work_email", data.work_email);
    formData.append("next_of_kin", data.next_of_kin);
    formData.append(
      "relationship_to_next_of_kin",
      data.relationship_to_next_of_kin
    );
    formData.append("emergency_contact", data.emergency_contact);
    formData.append("bank_name", data.bank_name);
    formData.append("account_number", data.account_number);
    formData.append("bvn", data.bvn);

    // For files (documents), iterate through them if they're multiple
    if (data.documents && data.documents.length > 0) {
      Array.from(data.documents).forEach((file, index) => {
        formData.append(`documents[${index}]`, file);
      });
    }

    try {
      const response = await axios.post(register_staff, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for sending files
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      const resData = await response.data;
      if (resData.status) {
        setTostMessage({
          messageType: "success",
          message: resData.response,
        });
      } else {
        setTostMessage({
          messageType: "error",
          message: resData.response,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleErr = (err) => {
    if (!departmentItem) {
      setDepartmentErrState(true);
    }
    if (!permissionItem) {
      setPermissionErr(true);
      setLoading(false);
      return;
    }
  };

  function handleFormHeight() {
    if (formHeightIndex < 2) {
      setFormHeightIndex((prev_value) => prev_value + 1);
    }
    if (formHeightIndex === 2) {
      setFormHeightIndex(0);
    }
  }

  function handleFormReduction() {
    if (formHeightIndex === 2 || formHeightIndex === 1) {
      setFormHeightIndex((prev_value) => prev_value - 1);
    }
  }

  const newLocal = "mb-3 text-xl";
  return (
    <form
      onSubmit={handleSubmit(addProject, handleErr)}
      className='CreateProjectPage xl:px-10 max-md:px-6 md:px-10'
    >
      <div className='mt-3 font-bold flex justify-end items-start'>
        {/* <div className='text-sm md:text-lg'>
          Please endeavor to fill in the right details
        </div> */}
        <button
          onClick={() => navigate("/dashboard/register-staff/staff-list")}
          className='px-6 flex items-center hover:opacity-[0.9] gap-2 py-2 rounded-lg text-white bg-native-blue border'
        >
          <PiUserListDuotone /> View User List
        </button>
      </div>
      <div className=''>
        <div className='flex flex-col items-start xl:grid xl:grid-cols-7 gap-4 pt-7'>
          <div className='w-full xl:col-span-4 flex flex-col gap-y-4'>
            <div
              className={`form-container bg-white px-4 rounded-xl pt-3 pb-6`}
            >
              <h4 className='mb-1 text-xl'>User Personal Information</h4>
              <div className='w-full h-auto p-3 bg-[#c4f1ff] rounded-[10px] flex items-center justify-center'>
                <a className='text-sm text-center'>
                  <b>{userProfile?.account_presets?.total_users_created}</b> of <b>{userProfile?.account_presets?.total_user_limit} </b>users created
                </a>
              </div>
              <div
                className='mt-1 flex flex-col gap-y-4 overflow-y-hidden px-1 pb-3'
                style={{
                  maxHeight: `${
                    formHeightIndex === 0
                      ? "500px"
                      : formHeightIndex === 1
                      ? "800px"
                      : "100%"
                  }`,
                }}
              >
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Title <a className="text-[#ff0000]">*</a></FormLabel>
                  <input
                    {...register("title", { required: true })}
                    placeholder='Enter Title'
                    className={`style-form-input ${
                      errors?.title && "outline-red-300"
                    }`}
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>First name <a className="text-[#ff0000]">*</a></FormLabel>
                  <input
                    autoComplete={'off'}
                    {...register("first_name", { required: true })}
                    placeholder="Enter first name"
                    className={`style-form-input ${
                      errors?.first_name && "outline-red-300"
                    }`}
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Last name <a className="text-[#ff0000]">*</a></FormLabel>
                  <input
                    autoComplete={'off'}
                    {...register("last_name", { required: true })}
                    placeholder="Enter last name"
                    className={`style-form-input ${
                      errors?.last_name && "outline-red-300"
                    }`}
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Gender <a className="text-[#ff0000]">*</a></FormLabel>
                  <select
                    {...register("gender", { required: true })}
                    className={`style-form-input ${
                      errors?.sex && "outline-red-300"
                    }`}
                  >
                    <option value=''>Select sex</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                  </select>
                </Box>
                {/* <Box>
                  <FormLabel style={{ fontSize: 13 }}>Preferred Name</FormLabel>
                  <input
                    autoComplete={'off'}
                    {...register("preferred_name", { required: false })}
                    placeholder='Enter preferred name'
                    className={`style-form-input ${
                      errors?.preferred_name && "outline-red-300"
                    }`}
                    type='text'
                  />
                </Box> */}
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Email <a className="text-[#ff0000]">*</a></FormLabel>
                  <input
                    type="email"
                    autoComplete={'off'}
                    {...register("email", { required: true })}
                    placeholder="Enter work email"
                    className={`style-form-input ${
                      errors?.email && "outline-red-300"
                    }`}
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Phone Number <a className="text-[#ff0000]">*</a></FormLabel>
                  <input
                    autoComplete={'off'}
                    {...register("phone_number", { required: true })}
                    placeholder='Enter phone number'
                    className={`style-form-input ${
                      errors?.phone_number && "outline-red-300"
                    }`}
                    type='number'
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Date of Birth</FormLabel>
                  <input
                    {...register("date_of_birth", { required: false })}
                    placeholder='Enter date of birth'
                    className={`style-form-input ${
                      errors?.date_of_birth && "outline-red-300"
                    }`}
                    type='date'
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Home Address</FormLabel>
                  <textarea
                    autoComplete={'off'}
                    {...register("home_address")}
                    placeholder='Enter home address'
                    className={`style-form-input ${
                      errors?.home_address && "outline-red-300"
                    }`}
                    rows={2}
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>
                    Correspondence Address
                  </FormLabel>
                  <textarea
                    {...register("correspondence_address")}
                    placeholder='Enter correspondence address'
                    className={`style-form-input ${
                      errors?.correspondence_address && "outline-red-300"
                    }`}
                    rows={2}
                  />
                </Box>
                {/* <Box>
                  <FormLabel style={{ fontSize: 13 }}>Work Email</FormLabel>
                  <input
                    {...register("work_email")}
                    placeholder='Enter work email'
                    className={`style-form-input ${
                      errors?.work_email && "outline-red-300"
                    }`}
                    type='email'
                  />
                </Box> */}
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Next Of Kin</FormLabel>
                  <input
                    {...register("next_of_kin")}
                    placeholder='Enter next of kin'
                    className={`style-form-input ${
                      errors?.next_of_kin && "outline-red-300"
                    }`}
                    type='text'
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>
                    Relationship to Next of Kin
                  </FormLabel>
                  <input
                    {...register("relationship_to_next_of_kin")}
                    placeholder='Enter relationship to next of kin'
                    className={`style-form-input ${
                      errors?.relationship_to_next_of_kin && "outline-red-300"
                    }`}
                    type='text'
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>
                    Emergency Contact
                  </FormLabel>
                  <input
                    autoComplete={'off'}
                    {...register("emergency_contact")}
                    placeholder='Enter emergency contact'
                    className={`style-form-input ${
                      errors?.emergency_contact && "outline-red-300"
                    }`}
                    type='text'
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Bank Name</FormLabel>
                  <input
                    {...register("bank_name")}
                    placeholder='Enter bank name'
                    className={`style-form-input ${
                      errors?.bank_name && "outline-red-300"
                    }`}
                    type='text'
                  />
                </Box>
                <Box>
                  <FormLabel style={{ fontSize: 13 }}>Account Number</FormLabel>
                  <input
                    autoComplete={'off'}
                    type="number"
                    {...register("account_number")}
                    placeholder='Enter account number'
                    className={`style-form-input ${
                      errors?.account_number && "outline-red-300"
                    }`}
                  />
                </Box>
                {/* <Box>
                  <FormLabel style={{ fontSize: 13 }}>
                    BVN (Bank Verification Number)
                  </FormLabel>
                  <input
                    {...register("bvn", { pattern: /^[0-9]{11}$/ })}
                    placeholder='Enter BVN'
                    className={`style-form-input ${
                      errors?.bvn && "outline-red-300"
                    }`}
                    type='text'
                  />
                </Box> */}
              </div>
              <div className='pt-4 flex items-center gap-x-4'>
                {formHeightIndex < 2 && (
                  <button
                    type='button'
                    className='hover:underline text-[.94rem] bg-[#172337] text-white px-3 py-2.5 rounded-md'
                    onClick={handleFormHeight}
                  >
                    See more
                  </button>
                )}
                {formHeightIndex > 0 && (
                  <button
                    type='button'
                    className='hover:underline text-[.94rem] bg-[#172337] text-white px-3 py-2.5 rounded-md'
                    onClick={handleFormReduction}
                  >
                    See less
                  </button>
                )}
              </div>
            </div>
            <div
              className={`form-container bg-white px-4 rounded-xl pt-3 pb-6`}
            >
              <h4 className='mb-3 text-xl'>Profile Documents</h4>
              <div className='flex flex-col gap-y-4'>
                <span className='text-[.9rem] text-[#000aff]'>
                  ** Ensure to upload all documents such as Certificates, Offer
                  letter, ID information e.t.c.
                </span>
                <Box>
                  <FormLabel style={{ fontSize: 15 }}>
                    Upload documents
                  </FormLabel>
                  <input
                    {...register("documents")}
                    type='file'
                    accept='application/*'
                    multiple
                  />
                </Box>
              </div>
            </div>
          </div>
          <div className='w-full xl:col-span-3 xl:sticky xl:top-6'>
            <section className='flex flex-col gap-y-4'>
              <div className='bg-white p-4 rounded-xl'>
                <div className='relative'>
                  <h4 className={newLocal}>Additional information (Optional)</h4>
                  <div className='flex flex-col gap-y-3'>
                    <Box>
                      <FormLabel style={{ fontSize: 13 }}>
                        Guarantor's Name
                      </FormLabel>
                      <input
                        autoComplete={'off'}
                        {...register("guarantors_name")}
                        placeholder="Enter guarantor's name"
                        className={`style-form-input ${
                          errors?.guarantors_name && "outline-red-300"
                        }`}
                        type='text'
                      />
                    </Box>

                    <Box>
                      <FormLabel style={{ fontSize: 13 }}>
                        Guarantor's Occupation
                      </FormLabel>
                      <input
                        autoComplete={'off'}
                        {...register("guarantors_occupation")}
                        placeholder="Enter guarantor's occupation"
                        className={`style-form-input ${
                          errors?.guarantors_occupation && "outline-red-300"
                        }`}
                        type='text'
                      />
                    </Box>

                    <Box>
                      <FormLabel style={{ fontSize: 13 }}>
                        Guarantor's Email Address
                      </FormLabel>
                      <input
                        {...register("guarantors_email_address", {
                          pattern: /^\S+@\S+$/i,
                        })}
                        type="email"
                        autoComplete={'off'}
                        placeholder="Enter guarantor's email address"
                        className={`style-form-input ${
                          errors?.guarantors_email_address && "outline-red-300"
                        }`}
                        type='email'
                      />
                    </Box>

                    <Box>
                      <FormLabel style={{ fontSize: 13 }}>
                        Guarantor's Phone Number
                      </FormLabel>
                      <input
                        autoComplete={'off'}
                        {...register("guarantors_phone_number", {
                          pattern: /^[0-9]{10}$/,
                        })}
                        placeholder="Enter guarantor's phone number"
                        className={`style-form-input ${
                          errors?.guarantors_phone_number && "outline-red-300"
                        }`}
                        type='tel'
                      />
                    </Box>

                    <Box>
                      <FormLabel style={{ fontSize: 13 }}>
                        Guarantor's Address
                      </FormLabel>
                      <textarea
                        autoComplete={'off'}
                        {...register("guarantors_address")}
                        placeholder="Enter guarantor's address"
                        className={`style-form-input ${
                          errors?.guarantors_address && "outline-red-300"
                        }`}
                        rows={2}
                      />
                    </Box>

                    <Box>
                      <FormLabel style={{ fontSize: 13 }}>
                        Guarantor's Occupation Address
                      </FormLabel>
                      <textarea
                        autoComplete={'off'}
                        {...register("guarantors_occupation_address")}
                        placeholder="Enter guarantor's occupation address"
                        className={`style-form-input ${
                          errors?.guarantors_occupation_address &&
                          "outline-red-300"
                        }`}
                        rows={2}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <div className='bg-white p-4 rounded-xl'>
                <div className='relative'>
                  <h4 className={newLocal}>User Department & Access Level</h4>
                  <div className='flex flex-col gap-y-3'>
                    <div>
                      <label htmlFor=''>User Department <a className="text-[#ff0000]">*</a></label>
                      <select
                        onChange={handleOptionChange}
                        className={`flex flex-col gap-6 w-full cursor-pointer bg-white border rounded-md shadow-sm focus:ring-indigo-200 focus:ring-opacity-50 focus:ring focus:outline-none`}
                      >
                        <option value=''>
                          Select department
                        </option>
                        {departmentList?.map((item, id) => (
                          <option value={item.id} key={id} className=''>
                            {item.title}
                          </option>
                        ))}
                      </select>
                      {departmentErrState && (
                        <p className='text-red-400 text-xs mt-1'>
                          Please select a department
                        </p>
                      )}
                      {departmentItem?.details && (
                        <>
                          <h4 className='mb-2 text-lg mt-2.5'>
                            Departmental description
                          </h4>
                          <p className='text-[.9rem] font-semibold px-2 py-2 rounded-sm bg-slate-200/50'>
                            {departmentItem?.details}
                          </p>
                        </>
                      )}
                    </div>
                    <div>
                      <label htmlFor=''>User Access Level <a className="text-[#ff0000]">*</a></label>
                      <select
                        onChange={handlePermissionChange}
                        className='flex flex-col gap-6 w-full cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm focus:ring-indigo-200 focus:ring-opacity-50 focus:ring focus:outline-none'
                      >
                        <option value=''>
                          Select permission
                        </option>
                        {departmentItem?.department_level?.map((item, id) => (
                          <option value={item.id} key={id} className=''>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {permissionErr && (
                        <p className='text-red-400 text-xs mt-1'>
                          Please select a permission
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <h4 className='mb-3 text-xl mt-4'>Monthly Wage (Optional)</h4>
                <Box className='mt-2'>
                  <input
                    {...register("staff_salary", { required: false })}
                    placeholder='Enter monthly wage'
                    type={'number'}
                    autoComplete={'off'}
                    className={`style-form-input ${
                      errors?.staff_salary && "outline-red-400"
                    }`}
                  />
                </Box>
                <div className='w-full mt-4'>
                  {loading ? (
                    <ReactLoading className='' type={"cylon"} color='#172337' />
                  ) : (
                    <button
                      type='submit'
                      className='bg-native-blue w-full px-2 py-3 text-white text-sm text-center rounded-3xl'
                    >
                      Add User
                    </button>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </form>
  );
}

export default RegisterStaff;
