import { Icon } from "@chakra-ui/react";
import { LiaDownloadSolid } from "react-icons/lia";
import { LuGalleryThumbnails } from "react-icons/lu";
import React, { useCallback, useState } from "react";
import { MainDrawerComponent } from "../../components/AccountSetUpCard/DrawerComponent";
import { ProjectOverviewComponent } from "../../components/OverviewComponent/OverviewComponent";
import { NotificationCol } from "../HomePage/HomePage";
import "./ViewOngoingProjectsPage.scss";
import legal_icon from "../../assets/icons/legal-icon.svg";
import notification_icon from "../../assets/icons/notification-ball.svg";
import accomplished_icon from "../../assets/icons/accomplished-icon.svg";
import transer_icon from "../../assets/icons/transfer-icon.svg";
import note_in_icon from "../../assets/icons/note_in.svg";
import note_out_icon from "../../assets/icons/note_out.svg";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "./../../context/AuthContext";
import { TostMessageContext } from "../../context/TostMessage";
import { GoDotFill } from "react-icons/go";
import {
  start_project_end_point,
  pause_project_end_point,
  select_project_by_id_end_point,
} from "../../config/variables";
import { useCookies } from "react-cookie";
import axios from "axios";
import { RiLuggageDepositLine, RiUserReceived2Line } from "react-icons/ri";
import { ProjectsContext } from "../../context/ProjectsContext";
import { GrDocumentCsv } from "react-icons/gr";
import { CgMediaLive } from "react-icons/cg";

import {
  TbCash,
  TbBuildingBank,
  TbUsersGroup,
  TbListCheck,
  TbCameraBolt,
  TbDownload,
  TbReport,
} from "react-icons/tb";
import { BsPlay, BsChatDots, BsPause } from "react-icons/bs";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";

function ViewOngoingProjectsPage() {
  const { id } = useParams();
  const { projectDetailes, setProjectDetailes } =
    React.useContext(ProjectsContext);
  const { userProfile } = React.useContext(AuthContext);
  const [cardSelected, setCardSelected] = React.useState(null);
  const [cardHeaders, setCardHeaders] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies();
  const { setTostMessage } = React.useContext(TostMessageContext);
  const [startingProject, setStartingProject] = React.useState(false);
  const [pausingProject, setPausingProject] = React.useState(false);

  const getProjectDetailes = useCallback(async () => {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
      },
    };
    try {
      const response = await axios.post(select_project_by_id_end_point, data, {
        headers: {
          access: cookies.urbexEnterpriseUserToken,
        },
      });
      // console.log("Project", id, response.data.projects);
      // return response.data;
      setProjectDetailes({
        loading: false,
        data: response.data.projects,
      });
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  React.useEffect(() => {
    getProjectDetailes();
    return () => {
      if (projectDetailes) {
        setProjectDetailes({
          loading: false,
          data: null,
        });
      }
    };
  }, [id]);

  function setChatObject() {
    // console.log(projectDetailes.data?.project_members);
    let chatObject = {
      contractor_id: projectDetailes.data?.contractor_id,
      account_id: userProfile?.account_id,
      client_id: projectDetailes.data?.client_id,
      project_id: id,
      project_members: projectDetailes.data?.project_members,
      // project_manager_id: projectDetailes.data?.project_manager_id,
      project_title: projectDetailes.data?.project_title,
      // pm_to: "contractor_chat",
    };
    sessionStorage.setItem("PMPChatObject", JSON.stringify(chatObject));
    // localStorage.setItem("PMPChatObject", JSON.stringify(chatObject));
  }

  React.useEffect(() => {
    if (projectDetailes?.data) {
      setCardHeaders([
        {
          sub: projectDetailes?.data?.project_stage == "PAUSED" ? "Milestones (Paused " + projectDetailes?.data?.project_paused_days_ago + " day(s) ago)" : "Milestones",
          color: "#000AFF",
          name: "Milestones",
          figure: `${projectDetailes?.data?.project_milestones.length}`,
          icon: accomplished_icon,
        },
        {
          sub: "Transactions",
          color: "#00CC99",
          name: "Transactions",
          figure: `${projectDetailes?.data?.project_credits?.length} (${projectDetailes?.data?.project_withdrawals?.length})`,
          icon: transer_icon,
        },
        {
          sub: "Notifications",
          color: "#EC8D36",
          name: "Notifications",
          figure: `${projectDetailes?.data?.project_notifications?.length}`,
          icon: notification_icon,
        },
        {
          sub: "Documents Control",
          color: "#9848FF",
          name: "Doc. Control",
          figure: `${projectDetailes?.data?.project_documents.documents?.length}`,
          icon: legal_icon,
        },
      ]);
      setLoading(false);
    }
  }, [projectDetailes]);

  function startProjectFunction() {
    setStartingProject(true);
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
      },
    });

    const headers = {
      access: cookies.urbexEnterpriseUserToken,
      "Content-Type": "application/json",
    };

    axios
      .post(start_project_end_point, data, { headers })
      .then((response) => {

        if (!response.data.status) {
          setStartingProject(false);
          return setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
        if (response.data.status) {
          setStartingProject(false);
          getProjectDetailes();
          return setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
        }
        // Handle the response data here
      })
      .catch((error) => {
        setStartingProject(false);
        console.error("Error:", error);
        // Handle the error here
      });
  }

  function pauseProjectFunction(action) {
    setPausingProject(true);
    let data = JSON.stringify({
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        project_id: id,
        action: action
      },
    });

    const headers = {
      access: cookies.urbexEnterpriseUserToken,
      "Content-Type": "application/json",
    };

    axios
      .post(pause_project_end_point, data, { headers })
      .then((response) => {
        if (!response.data.status) {
          setPausingProject(false);
          return setTostMessage({
            messageType: "error",
            message: response.data.response,
          });
        }
        if (response.data.status) {
          setPausingProject(false);
          getProjectDetailes();
          return setTostMessage({
            messageType: "success",
            message: response.data.response,
          });
        }
        // Handle the response data here
      }).catch((error) => {
        setStartingProject(false);
        console.error("Error:", error);
        // Handle the error here
      });
  }

  return (
    <div className='ViewOngoingProjectsPage px-4 pt-10 lg:pt-10 lg:px-10'>
      {loading ? (
        <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full'>
          {[1, 2, 3, 4].map((i, index) => (
            <div
              key={index}
              className='h-40 w-full bg-white rounded-xl animate-pulse'
            ></div>
          ))}
        </div>
      ) : (
        <>
          <div className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full'>
            {cardHeaders?.map((card, i) => (
              <div
                className='h-40 cursor-pointer w-full flex items-center justify-center bg-white transition-all ease-in-out hover:bg-[#cdcadc] rounded-xl'
                onClick={() => setCardSelected(i)}
                key={i}
              >
                <ProjectsSummaryCard
                  cardSelected={cardSelected}
                  card={card}
                  projectDetails={projectDetailes}
                />
              </div>
            ))}
          </div>

          <div className='xl:grid xl:grid-cols-7 gap-4 px-4 md:px-10 lg:px-20'>
            <div className='md:col-span-5'>
              {/* Overview section */}
              <ProjectOverviewComponent project={projectDetailes.data} />
            </div>
            <div className='col-span-2 mt-7'>
              <h3 className='text-black text-2xl font-bold'>Actions</h3>
              {projectDetailes?.data?.project_stage !== "ACTIVE" && projectDetailes?.data?.project_stage !== "PAUSED" && (userProfile?.sub_account_overview?.allowed_permissions?.includes("create_project") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) ? (
              <div
                  onClick={() => startProjectFunction()}
                  className='withdraw-container'
                >
                  <span className='uppercase text-sm text-[#000aff] font-bold'>
                    {startingProject ? "Starting project..." : "Start Project"}
                  </span>
                  <button>
                    <BsPlay size={24} color='#000aff' />
                  </button>
                </div>
              ) : null}
              {projectDetailes?.data?.project_stage == "ACTIVE" && (userProfile?.sub_account_overview?.allowed_permissions?.includes("create_project") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) ? (
              <div
                  onClick={() => pauseProjectFunction('pause')}
                  className='withdraw-container'
                >
                  <span className='uppercase text-sm text-[#000aff] font-bold'>
                    {pausingProject ? "Pausing project..." : "Pause Project"}
                  </span>
                  <button>
                    <BsPause size={24} color='#000aff' />
                  </button>
                </div>
              ) : null}
              {projectDetailes?.data?.project_stage == "PAUSED" && (userProfile?.sub_account_overview?.allowed_permissions?.includes("create_project") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access")) ? (
                <div
                  onClick={() => pauseProjectFunction('resume')}
                  className='withdraw-container'
                >
                  <span className='uppercase text-sm text-[#000aff] font-bold'>
                    {pausingProject ? "Resuming project..." : "Resume Project"}
                  </span>
                  <button>
                    <BsPlay size={24} color='#000aff' />
                  </button>
                </div>
              ) : null}
              {/* <Link to="./chat" onClick={() => setChatObject()}>
                <div className="withdraw-container">
                  Chat Room
                  <button>
                    <BsChatDots
                      style={{ fontSize: "20px", color: "#000AFF" }}
                    />
                  </button>
                </div>
              </Link> */}
              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "create_project"
              ) ||
              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "full_access"
              ) ? (
                  <Link
                    to={`/dashboard/edit-project/${id}`}
                    className='withdraw-container'
                  >
                    <span className='uppercase text-sm'>Edit Project</span>
                    <button>
                      <FaRegEdit size={24} />
                    </button>
                  </Link>
              ) : null}
              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "project_chat_room"
              ) ||
              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "full_access"
              ) ? (
                <MainDrawerComponent
                  projectDetailes={
                    projectDetailes?.data?.project_account_details
                  }
                  open={27}
                  size={"xl"}
                  card={{ sub: projectDetailes?.data?.project_title }}
                >
                  <div
                    onClick={() => setChatObject()}
                    className='withdraw-container'
                  >
                    <span className='uppercase text-sm'>Project Chat Room</span>
                    <button>
                      <HiOutlineChatAlt2 size={24} />
                    </button>
                  </div>
                </MainDrawerComponent>
              ) : null}
              <MainDrawerComponent
                projectDetailes={projectDetailes?.data?.project_account_details}
                open={25}
                card={{ sub: "Live" }}
              >
                <div className='withdraw-container'>
                  <span className='uppercase text-sm'>Live Eye</span>
                  <button>
                    <CgMediaLive size={24} color='#ff0000' />
                  </button>
                </div>
              </MainDrawerComponent>
              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "create_project"
              ) ||
              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "full_access"
              ) ? (
                <MainDrawerComponent
                  open={18}
                  project={projectDetailes?.data?.project_milestones}
                  card={{ sub: "Add Milestones" }}
                  // getProjectDetailes={getProjectDetailes}
                >
                  <div className='withdraw-container'>
                    <span className='uppercase text-sm'>Set/Edit Milestones</span>
                    <button>
                      <TbListCheck size={24} color='#172337' />
                    </button>
                  </div>
                </MainDrawerComponent>
              ) : null}
              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "site_activity_report"
              ) ||
              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "full_access"
              ) ? (
                <MainDrawerComponent
                  open={16}
                  project={projectDetailes.data}
                  card={{ sub: "Activity report" }}
                >
                  <div className='withdraw-container'>
                    <span className='uppercase text-sm'>
                      Daily Site Activity Report
                    </span>
                    <button>
                      <TbDownload size={24} color='#172337' />
                    </button>
                  </div>
                </MainDrawerComponent>
              ) : null}
              {/* <MainDrawerComponent
                open={17}
                project={projectDetailes.data}
                card={{ sub: "View Bids" }}
              >
                <div className='withdraw-container'>
                  <span className='uppercase text-sm'>View Bids</span>
                  <button>
                    <TbUsersGroup size={24} color='#172337' />
                  </button>
                </div>
              </MainDrawerComponent> */}
              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "project_weekly_reports"
              ) ||
              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "full_access"
              ) ? (
                <>
                <MainDrawerComponent
                  open={26}
                  project={projectDetailes.data}
                  card={{ sub: "Project Report" }}
                  size={"xl"}
                  // getProjectDetailes={getProjectDetailes}
                >
                  <div className='withdraw-container'>
                    <span className='uppercase text-sm flex flex-row items-center'>
                      Weekly Report {projectDetailes?.data?.unapproved_reports > 0 && <b className="text-[#ff8a30] ml-2 font-bold text-sm">({projectDetailes?.data?.unapproved_reports})</b>}
                      {projectDetailes?.data?.unapproved_reports == 0 && <GoDotFill className="ml-2" color="#00b47a" size={17} />}
                    </span>
                    <button>
                      <TbReport size={24} color='#172337' />
                    </button>
                  </div>
                </MainDrawerComponent>
                </>
              ) : null}
              <MainDrawerComponent
                projectDetailes={projectDetailes?.data?.project_account_details}
                open={14}
                card={{ sub: "Budget Planning" }}
                project_id={id}
                size={'xl'}
              >
                <div className='withdraw-container'>
                  <span className='uppercase text-sm flex flex-row items-center'>Budgeting</span>
                  <button>
                    <TbBuildingBank size={24} color='#172337' />
                  </button>
                </div>
              </MainDrawerComponent>
              {userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "request_withdrawal"
              ) ||
              userProfile?.sub_account_overview?.allowed_permissions?.includes(
                "full_access"
              ) ? (
                <MainDrawerComponent open={5} card={{ sub: "Withdraw" }}>
                   <div className='withdraw-container'>
                    <span className='uppercase text-sm'>Withdraw</span>
                    <button>
                      <TbCash size={24} color='#172337' />
                    </button>
                  </div>
                </MainDrawerComponent>
              ) : null}
              <MainDrawerComponent
                open={15}
                project={projectDetailes.data}
                card={{ sub: "Progress Gallery" }}
              >
                <div className='withdraw-container'>
                  <span className='uppercase text-sm'>View Media</span>
                  <button>
                    <TbCameraBolt size={24} color='#172337' />
                  </button>
                </div>
              </MainDrawerComponent>
              {/* <NotificationCol /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ViewOngoingProjectsPage;

function ProjectsSummaryCard({ color, cardSelected, card, projectDetails }) {
  return (
    <MainDrawerComponent
      open={cardSelected}
      card={card}
      projectDetailes={projectDetails.data}
      size={cardSelected == 0 ? "xl" : "lg"}
    >
      <div className='p-4 flex flex-column sm:!flex-row items-center gap-0 sm:!gap-10 justify-center'>
        <div className='icon-container'>
          <img className="h-[35px] w-[35px] sm:h-[50px] sm:w-[50px]" alt='icon' src={card.icon} />
        </div>
        <div className="text-center sm:!text-start"> 
          <h2 className='text-2xl sm:text-3xl font-bold'> {card.figure}</h2>
          <span>{card.name}</span>
        </div>
      </div>
    </MainDrawerComponent>
  );
}

function NoteCard({ note }) {
  return (
    <div className='NoteCard'>
      <div className='icon-container'>
        {note.type === "WARNING" ? (
          <img src={note_out_icon} alt='' />
        ) : (
          <img src={note_in_icon} alt='' />
        )}
      </div>
      <div>
        <h2>{note.title}</h2>
        <p>{note.message}</p>
        <span>{note.time}</span>
      </div>
    </div>
  );
}
