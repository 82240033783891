import React, { useEffect, useState } from "react";
import axios from "axios";
import { get_all_schedule } from "../../config/variables";
import { AuthContext } from "../../context/AuthContext";

import FullCalendar from "@fullcalendar/react";
import _default from "@fullcalendar/daygrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FaLocationDot } from "react-icons/fa6";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';

function getMonthNameFromDate(dateString) {
	const [day, month, year] = dateString.split("-");
	const dateObject = new Date(`${year}-${month}-${day}`);
	const monthName = new Intl.DateTimeFormat("en-US", { month: "long" }).format(dateObject);
	return monthName;
}
function formatDate(dateString) {
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	const date = new Date(dateString);
	return new Intl.DateTimeFormat('en-US', options).formatToParts(date);
}
function generateMonthObjects() {
	const currentYear = new Date().getFullYear();
	const monthObjects = [];

	for (let month = 0; month < 12; month++) {
		const monthIndex = month + 1;
		const monthString = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;

		const dateString = `01-${monthString}-${currentYear}`;

		const monthObject = {
			label: new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date(`${currentYear}-${monthString}-01`)),
			value: dateString,
		};

		monthObjects.push(monthObject);
	}

	return monthObjects;
}
function getCurrentMonthDate() {
	const currentDate = new Date();

	const day = currentDate.getDate();
	const month = currentDate.getMonth() + 1;
	const year = currentDate.getFullYear();

	const dayString = day < 10 ? `0${day}` : `${day}`;
	const monthString = month < 10 ? `0${month}` : `${month}`;

	return `${dayString}-${monthString}-${year}`;
}
const Index = () => {
	const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthDate());
	const [OfficeSchedules, setOfficeSchedules] = useState(null);
	const [monthObject, setMonthObject] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(5);
	const { userProfile } = React.useContext(AuthContext);


	// Pagination logic
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = OfficeSchedules?.slice(indexOfFirstItem, indexOfLastItem);
  
	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(OfficeSchedules?.length / itemsPerPage); i++) {
	  pageNumbers.push(i);
	}
	useEffect(() => {
		setMonthObject(generateMonthObjects());
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const response = await axios.post(get_all_schedule, {
					month_of_attendance: selectedMonth,
					account_id: userProfile?.account_id,
					sub_account_id: userProfile?.sub_account_id,
				});
				const data = await response.data;
				setOfficeSchedules([...data]);
			} catch (error) {
				console.log(error);
			}
		})();
	}, [selectedMonth]);

    function recall () {
        (async () => {
			try {
				const response = await axios.post(get_all_schedule, {
					month_of_attendance: selectedMonth,
					account_id: userProfile?.account_id,
					sub_account_id: userProfile?.sub_account_id,
				});
				const data = await response.data;
				setOfficeSchedules([...data]);
			} catch (error) {
				console.log(error);
			}
		})();
    }

	function handleDatesSet(dateInfo) {
		// Load or update events based on dateInfo.start and dateInfo.end
		// This is where you fetch data for the new month displayed
	}

	return (
		<div className="p-3">
            <div className="flex flex-row w-full justify-end">
				{userProfile?.sub_account_overview?.allowed_permissions?.includes("create_event") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access") ? (
					<MainDrawerComponent
						open={32}
						size={'sm'}
						card={{ sub: 'Create Announcement' }}
						refresh={recall}
					>
						<button
						className="px-3 py-2 sm:px-6 sm:py-2 rounded-full border border-gray-400 bg-native-blue text-white active:scale-95 transition-all mr-2"
						>
						Create Announcement
						</button>
					</MainDrawerComponent>
				):null}
				{userProfile?.sub_account_overview?.allowed_permissions?.includes("create_event") || userProfile?.sub_account_overview?.allowed_permissions?.includes("full_access") ? (
					<MainDrawerComponent
						open={31}
						size={'sm'}
						card={{ sub: 'Create New Event' }}
						refresh={recall}
					>
						<button
						className="px-3 py-2 sm:px-6 sm:py-2 rounded-full border border-gray-400 bg-native-blue text-white active:scale-95 transition-all"
						>
						Create New Event
						</button>
					</MainDrawerComponent>
				):null}
            </div>
			<div className="mt-2 md:mt-0 grid grid-cols-12 gap-x-5">
				<div className="col-span-full md:col-span-8">
					<FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" events={OfficeSchedules} datesSet={handleDatesSet} />
				</div>
				<div className="col-span-full md:col-span-4 flex flex-col items-start gap-y-5">
					<div className="pt-4 pt-md-0">
						<h3 className="font-semibold">Upcoming Events</h3>
					</div>
					<ul className="p-0 flex-grow w-full">
						{currentItems?.map((holiday) => (
							
							<li key={holiday.id} className="flex w-full items-center justify-start gap-x-4 border-bottom border-gray-900 pb-3 pt-2">
								<span className="">
									<span className="flex-col flex items-center justify-center">
										<span>{formatDate(holiday.date).find(part => part.type === 'day')?.value}</span>
										<span>{formatDate(holiday.date).find(part => part.type === 'month')?.value}</span>
										<span>{formatDate(holiday.date).find(part => part.type === 'year')?.value}</span>
									</span>
								</span>
								<span className="flex flex-col items-start">
									<b>{holiday.category}</b>
									<span className="text-[.9rem]">{holiday.title}</span>
									<span className="flex items-center gap-x-2">
										<FaLocationDot className="w-4 h-4 opacity-60" />
										<span className="text-[.89rem] opacity-80">{holiday.location}</span>
									</span>
								</span>
							</li>
						))}
					</ul>
					

					{/* Pagination container */}
					<div className="w-full">
						<div className="flex items-stretch justify-end gap-x-2">
						{/* Previous Page Button */}
						<button
							type="button"
							disabled={currentPage === 1}
							onClick={() => paginate(currentPage - 1)}
							className={`rounded-[4px] aspect-square flex items-center justify-center p-[10px] leading-[0] text-white text-[1.2rem] border transition duration-200 ease-in-out ${
							currentPage === 1 ? 'bg-gray-300' : 'bg-[#172337]'
							}`}>
							<FaChevronLeft className="h-4 w-4" />
						</button>

						{/* Page Number Buttons */}
						{pageNumbers.map(number => (
							<button
							key={number}
							onClick={() => paginate(number)}
							className={`rounded-[4px] aspect-square flex items-center justify-center p-[11px] leading-[0] text-[1.2rem] border transition duration-200 ease-in-out ${
								number === currentPage ? 'border-stone-400 bg-[#172337] text-white' : 'border-stone-400 bg-white text-[#172337] hover:bg-slate-200'
							}`}>
							<span>{number}</span>
							</button>
						))}

						{/* Next Page Button */}
						<button
							type="button"
							disabled={currentPage === pageNumbers.length}
							onClick={() => paginate(currentPage + 1)}
							className={`rounded-[4px] aspect-square flex items-center justify-center p-[10px] leading-[0] text-white text-[1.2rem] border transition duration-200 ease-in-out ${
							currentPage === pageNumbers.length ? 'bg-gray-300' : 'bg-[#172337]'
							}`}>
							<FaChevronRight className="h-4 w-4" />
						</button>
						</div>
					</div>
					{/* pagination container @::end */}
				</div>
			</div>
		</div>
	);
};

export default Index;
