import React from 'react';

function SuccessStep() {
  return (
    <div className="SuccessStep">
      <h2>🎉 Welcome to Urbex Enterprise,</h2>

      <p>
      Your All-in-One Solution for Project Management, Reporting, document control and Staff Management!</p>
      <p>We are thrilled to have you on board with our cutting-edge software designed to streamline your project workflows, enhance reporting capabilities, and simplify staff management.</p><p> Whether you're a seasoned project manager or just starting your journey, Urbex is here to empower you every step of the way.
      </p>
    </div>
  );
}

export default SuccessStep;
