import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MyStaffs.scss";
import { ProgressBarChart } from "../../components/ProgressBarComponent/ProgressBarComponent";
import project_icon from "../../assets/icons/project-icon.svg";
import { BsThreeDots } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import image from "../../assets/images/profile-card-cover-image.jpg";
import axios from "axios";
import { get_tasks } from "../../config/variables";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';
import { MdKeyboardArrowDown, MdOutlineManageSearch } from "react-icons/md";
import { FaRegPenToSquare } from "react-icons/fa6";
import ReactSelect from "react-select";
import { TostMessageContext } from "../../context/TostMessage";
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Button,
    Icon,
    Box,
    FormLabel,
    Input,
    Select,
    Switch,
    InputGroup,
    InputRightElement,
    Avatar,
    Divider,
    useBoolean,
    Textarea,
  } from "@chakra-ui/react";

export const Staffs = [
  {
    name: "John Smith",
    image: image,
    department: "Engineering",
    checkedin: true,
    phone: "0903902980383",
    date: "today",
  },
];

function Tasks() {
  const [loading, setLoading] = React.useState(true);
  const [input, setInput] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [createTask, setCreateTask] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const toggleDropdown = (employeeId) => {
    if (openDropdownId === employeeId) {
      setOpenDropdownId(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdownId(employeeId); // Open the dropdown for the clicked employee
    }
  };

  const navigate = useNavigate();

  const fetchStaffs = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        get_tasks,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const { data } = await response.data;
      setStaffs(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handlePermissionsClick = () => {
    // Handle the permissions click
   // console.log("Permissions clicked for:", employee.id);
  };

 
  useEffect(() => {
    (async () => {
      fetchStaffs();
    })();
  }, [selectedOption]);

  return (
    <div className='MyStaffs xl:px-10 max-md:px-6 md:px-10'>
        <>
        {createTask && (
            <CreateTaskForm setCreateTask={setCreateTask} />
        )}
         <div className="w-full flex flex-row justify-end mt-3">
            <button
                onClick={() => setCreateTask(true)}
                className='px-6 flex items-center hover:opacity-[0.9] gap-2 py-2 rounded-lg text-white bg-native-blue border'
              >
                <FaRegPenToSquare /> Create New Task
            </button>
         </div>
          {loading ? (
            <>
              <div className="search-filter-container mt-3">
                <div className="search-box rounded-[5px] border-none">
                  {/* <span className="search-icon">🔍</span> */}
                  <MdOutlineManageSearch size={20} />
                  <input type="text" placeholder="Search" className="search-input" />
                </div>
              </div>
              <div>
              <div className='mt-3 mx-auto w-full overflow-auto'>
                <table className='min-w-full rounded-xl border-none bg-white'>
                  <thead className=''>
                    <tr className='bg-[#F9F9F9]'>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Task Title</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Status</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Priority</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Created</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Due Date</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>More</th>
                    </tr>
                  </thead>
                </table>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((i, index) => (
                  <div
                    key={index}
                    className='h-16 w-full bg-gray-200 animate-pulse py-3 mb-2'
                  ></div>
                ))}
                </div>
              </div>
            </>
          ) : (
            <>
            <div className="search-filter-container mt-3">
              <div className="search-box rounded-[5px] border-none">
                {/* <span className="search-icon">🔍</span> */}
                <MdOutlineManageSearch size={20} />
                <input type="text" placeholder="Search" className="search-input" />
              </div>
            </div>
            <div className='mt-3 mx-auto w-full overflow-auto'>
              <table className='min-w-full rounded-xl border-none bg-white'>
                <thead className=''>
                  <tr className='bg-[#F9F9F9]'>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Task Title</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Status</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Priority</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Created</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Due Date</th>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>More</th>
                  </tr>
                </thead>
                <tbody>
                  {staffs?.map((task, id) => (
                      <tr
                        key={task.id}
                        className='hover:bg-gray-50 border-b'
                      >
                        <td className='py-3 px-2 flex'>
                            <span className="text-[13px] font-semibold">{task.task_title}</span>
                        </td>
                        <td className='py-3 px-2'>
                            {task.status == "OPEN" && <span className="text-[#AE7E04] font-semibold text-[13px]">{task.status}</span>}
                            {task.status == "COMPLETE" && <span className="text-[#00b47a] font-semibold text-[13px]">{task.status}</span>}
                            {task.status == "DUE" && <span className="text-[#EA4335] font-semibold text-[13px]">{task.status}</span>}
                        </td>
                        <td className='py-3 px-2'>
                            {task.priority == "LOW" && <span className="text-[13px] text-white p-2 px-3 rounded-lg bg-[#48412F] font-semibold">{task.priority}</span>}
                            {task.priority == "MEDIUM" && <span className="text-[13px] text-white p-2 px-3 rounded-lg bg-[#AE7E04] font-semibold">{task.priority}</span>}
                            {task.priority == "HIGH" && <span className="text-[13px] text-white p-2 px-3 rounded-lg bg-[#EA4335] font-semibold">{task.priority}</span>}
                        </td>
                        <td className='py-3 px-2'>
                            <span className="text-[13px] font-semibold">{task.date_created}</span>
                        </td>
                        <td className='py-3 px-2'>
                            <span className="text-[13px] font-semibold">{task.due_date}</span>
                        </td>
                        <td className='py-3 px-2'>
                            <span className="text-[14px] cursor-pointer font-bold flex flex-row text-underline">View <MdKeyboardArrowDown size={20} /></span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <div className="pagination-container">
                <div className="pagination-info">
                  Showing 11 from 250 data
                </div>
                <div className="pagination-buttons">
                  <button className="pagination-button pagination-prev">
                    &laquo; Previous
                  </button>
                  <button className="pagination-button">1</button>
                  <button className="pagination-button">2</button>
                  <button className="pagination-button active">3</button>
                  <button className="pagination-button">4</button>
                  <button className="pagination-button pagination-next">
                    Next &raquo;
                  </button>
                </div>
              </div>
              }
              </div> */}
            </div>
            </>
          )}
        </>
    </div>
  );
}

export default Tasks;

export function OngoingProjectCard({ project }) {
  // const { project_milestones, project_milestones_completed } = project;
  function calculatePercentage(total, completed) {
    const percentage = (Number(completed) / Number(total)) * 100;

    if (isNaN(Number(percentage))) {
      return 0;
    }
    return Math.round(percentage);
  }
  return (
    <Link
      to={`./${project?.project_id}`}
      className='projectCard flex p-4 lg:p-8 justify-between items-center h-28 bg-white rounded-2xl my-3'
    >
      <div>
        <img src={project_icon} />
      </div>
      <div className='w-[35%]'>
        <h2 className='text-sm xl:text-xl leading-tight'>
          {project?.project_title}
        </h2>
        <span>{project?.site_address}</span>
      </div>
      <div>
        <ProgressBarChart
          percentage={calculatePercentage(
            project?.project_status?.project_milestones || 0,
            project?.project_status?.project_milestones_completed || 0
          )}
        />
      </div>
      <div className='d-flex flex-column'>
        <span>Milestones</span>{" "}
        <h1 className='text-[20px]'>
          {project?.project_status.project_milestones}
        </h1>{" "}
        <span>
          {project?.project_status.project_milestones_completed +
            " of " +
            project?.project_status.project_milestones}{" "}
          milestones completed
        </span>
      </div>
      <div>
        <Icon as={BsThreeDots} />
      </div>
    </Link>
  );
}

const CreateTaskForm = ({ setCreateTask }) => {
    const [loading, setLoading] = useState(false);
    const { userProfile } = useContext(AuthContext);
    const { setTostMessage } = useContext(TostMessageContext);
    const [cookies] = useCookies();
    const [selectedPriority, setSelectedPriority] = useState('');
  
    const handleSubmit = async () => {
    //   setLoading(true);
    //   let data = JSON.stringify({
    //     user: {
    //       account_id: userProfile?.account_id,
    //       sub_account_id: userProfile?.sub_account_id,
    //     },
    //     data: {
    //       sub_account_id: employee.id,
    //       selectedPermissions: selectedPermissions,
    //       selectedDuration: selectedDuration
    //     },
    //   });
  
    //   const headers = {
    //     access: cookies.urbexEnterpriseUserToken,
    //     "Content-Type": "application/json",
    //   };
  
    //   axios
    //     .post(add_user_permissions, data, { headers })
    //     .then((response) => {
    //       if (!response.data.status) {
    //         setLoading(false);
    //         return setTostMessage({
    //           messageType: 'error',
    //           message: response.data.response
    //         })
    //       }
    //       if (response.data.status) {
    //         setAddNewPermission(false)
    //         setSelectedPermissions([]);
    //         setLoading(false);
    //         for (var i=0;i<selectedPermissions.length;i++) {
    //           permissionRef[`extra${selectedPermissions[i]}`].style.backgroundColor = '#defff7'
    //           permissionRef[`extra_button${selectedPermissions[i]}`].style.display = "none";
    //         }
    //         return setTostMessage({
    //           messageType: 'success',
    //           message: response.data.response
    //         })
    //       }
    //     }).catch((error) => {
    //       setLoading(false);
    //       return setTostMessage({
    //         messageType: 'error',
    //         message: 'Network error, please check your network and try again.'
    //       })
    //     });
    }
    return(
      <div className="backdropFile w-[100%] h-[100%] z-[999] position-fixed top-[0] flex flex-column items-end p-2">
        <button onClick={() => setCreateTask(false) } className="p-3 py-2 rounded-md bg-[#ffffff] text-[#ff0000] font-bold">Close</button>
        <div className={`w-full h-full mt-2 flex items-center justify-center`}>
          <div className="h-auto w-[40%] bg-white min-h-[30%] rounded-lg p-4 flex flex-column justify-center">
            <div className="flex flex-col items-start mb-2">
              <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
                Task Title
              </FormLabel>
              <input className="bg-slate-100 h-10 px-3 border-none rounded-md text-[.9rem] w-full" 
                placeholder="Enter task title"
              />
            </div>
            <div className="flex flex-col items-start mb-2">
              <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
                Task Description
              </FormLabel>
              <textarea className="bg-slate-100 min-h-[5rem] px-3 border-none rounded-md text-[.9rem] w-full" 
                placeholder="Enter task description"
              ></textarea>
            </div>
            <div className="flex flex-row items-start justify-evenly mb-2">
                <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
                        Set Due Date
                    </FormLabel>
                    <input className="bg-slate-100 h-10 px-3 border-none rounded-md text-[.9rem] w-full" 
                        type="datetime-local"
                    />
                </Box>
                <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
                        Select Status
                    </FormLabel>
                    <select
                    defaultValue={'Open'}
                    className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                    onChange={(e) => {
                        setSelectedPriority(e.target.value)
                    }}
                    >
                        <option value="">Select an option</option>
                        <option value="Open">Open</option>
                        <option value="Complete">Complete</option>
                        <option value="Due">Due</option>
                    </select>
                </Box>
                <Box>
                    <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
                        Set Priority
                    </FormLabel>
                    <select
                    defaultValue={'Open'}
                    className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                    onChange={(e) => {
                        setSelectedPriority(e.target.value)
                    }}
                    >
                        <option value="">Select an option</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                    </select>
                </Box>
            </div>
            <Box>
              <FormLabel style={{ fontSize: 13 }} htmlFor='request'>
                Assign Users
              </FormLabel>
                <select
                name="team_size"
                id="team_size"
                className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                onChange={(e) => {
                    setSelectedPriority(e.target.value)
                }}
                >
                    <option value="">Select an option</option>
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                </select>
            </Box>
            {(selectedPriority == "") ? 
              (<button className="continue w-50 mt-3 mx-auto !rounded-full disabled" type="button" role="disabled">
                  Assign Task
              </button>):
              (<button
                  className={`continue w-50 mx-auto mt-3 bg-[#000aff] !rounded-full ${loading ? 'loading' : ''}`}
                  onClick={handleSubmit}
                  type="button"
                  disabled={loading}
              >
                  {loading ? 'Please Wait...' : `Assign Task`}
              </button>) }
          </div>
        </div>
      </div>
    )
  } 

export function FilterScrollComponent({ filtersArray, handleFilerFunction }) {
  const [active, setActive] = React.useState(0);
  return (
    <div className='FilterScrollComponent flex-col'>
      <div className='text-start w-full mt-4'>Filter</div>
      <div className='filter-container'>
        {filtersArray?.map((filter, i) => (
          <button
            key={i}
            className={active === i ? "active text-sm" : " text-sm"}
            onClick={() => {
              setActive(i);
              handleFilerFunction(i);
            }}
          >
            {filter}
          </button>
        ))}
      </div>
    </div>
  );
}
