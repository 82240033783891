import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MyStaffs.scss";
import { ProgressBarChart } from "../../components/ProgressBarComponent/ProgressBarComponent";
import { Icon } from "@chakra-ui/react";
import project_icon from "../../assets/icons/project-icon.svg";
import { BsThreeDots } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import image from "../../assets/images/profile-card-cover-image.jpg";
import axios from "axios";
import { fetch_staffs } from "../../config/variables";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import { MainDrawerComponent } from '../../components/AccountSetUpCard/DrawerComponent';
import { MdOutlineManageSearch } from "react-icons/md";

export const Staffs = [
  {
    name: "John Smith",
    image: image,
    department: "Engineering",
    checkedin: true,
    phone: "0903902980383",
    date: "today",
  },
];

function MyStaffs() {
  const [loading, setLoading] = React.useState(true);
  const [input, setInput] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [staffDetails, setStaffDetails] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");

  const [cookies] = useCookies();
  const { userProfile } = React.useContext(AuthContext);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const toggleDropdown = (employeeId) => {
    if (openDropdownId === employeeId) {
      setOpenDropdownId(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdownId(employeeId); // Open the dropdown for the clicked employee
    }
  };

  const navigate = useNavigate();

  const fetchStaffs = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        fetch_staffs,
        {
          user: {
            account_id: userProfile?.account_id,
            sub_account_id: userProfile?.sub_account_id,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            access: cookies.urbexEnterpriseUserToken,
          },
        }
      );
      const { data } = await response.data;
      setStaffs(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handlePermissionsClick = () => {
    // Handle the permissions click
   // console.log("Permissions clicked for:", employee.id);
  };

 
  useEffect(() => {
    (async () => {
      fetchStaffs();
    })();
  }, [selectedOption]);

  return (
    <div className='MyStaffs xl:px-10 max-md:px-6 md:px-10'>
      {staffDetails ? (
        navigate("/dashboard/register-staff/staff-list/staff-profile", {
          state: { staffDetails: staffDetails },
          replace: false,
        })
      ) : (
        <>
         
          {loading ? (
            <>
              <div className="search-filter-container mt-3">
                <div className="search-box rounded-[5px] border-none">
                  {/* <span className="search-icon">🔍</span> */}
                  <MdOutlineManageSearch size={20} />
                  <input type="text" placeholder="Search" className="search-input" />
                </div>
              </div>
              <div>
              <div className='mt-3 mx-auto w-full overflow-auto'>
                <table className='min-w-full rounded-xl border-none bg-white'>
                  <thead className=''>
                    <tr className='bg-[#F9F9F9]'>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Name/Email</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Status</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Access</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Phone</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Permissions</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Profile</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Analytics</th>
                    </tr>
                  </thead>
                </table>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((i, index) => (
                  <div
                    key={index}
                    className='h-16 w-full bg-gray-200 animate-pulse py-3 mb-2'
                  ></div>
                ))}
                </div>
              </div>
            </>
          ) : (
            <>
            <div className="search-filter-container mt-3">
              <div className="search-box rounded-[5px] border-none">
                {/* <span className="search-icon">🔍</span> */}
                <MdOutlineManageSearch size={20} />
                <input type="text" placeholder="Search" className="search-input" />
              </div>
            </div>
            <div className='mt-3 mx-auto w-full overflow-auto'>
              <table className='min-w-full rounded-xl border-none bg-white'>
                <thead className=''>
                  <tr className='bg-[#F9F9F9]'>
                    <th className='py-3 px-2 border-b font-semibold text-[14px]'>Name/Email</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Status</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Access</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Phone</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Permissions</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Profile</th>
                    <th className='py-3 border-b font-semibold text-[14px]'>Analytics</th>
                  </tr>
                </thead>
                <tbody>
                  {staffs
                    ?.filter((item) =>
                      item?.name.toLowerCase().includes(input.toLowerCase())
                    )
                    .map((employee, id) => (
                      <tr
                        
                        key={employee.id}
                        className='hover:bg-gray-50 cursor-pointer border-b'
                      >
                        <td className='py-2 px-2 flex'>
                          <div className="user-avatar bg-native-blue">
                            <span>{employee.name[0]}{employee.name.split(' ')[1][0]}</span>
                          </div>
                          <div>
                            <div className="text-[13px] font-semibold">{employee.title} {employee.name}</div>
                            <div className='text-gray-500 text-sm'>
                              {employee.email}
                            </div>
                          </div>
                        </td>
                        <td className='py-3'>
                            {employee.status == 3 && <span className="text-[13px] text-white p-2 px-3 rounded-lg bg-[#48412F] font-semibold">{'Disabled'}</span>}
                            {employee.status != 3 && <span className="text-[13px] text-white p-2 px-3 rounded-lg bg-[#00b47a] font-semibold">{'Active'}</span>}
                        </td>
                        <td className='py-2'>
                        <div>
                            <div className="text-[13px] font-semibold" style={{whiteSpace:'nowrap'}}> {employee.department}</div>
                            <div className='text-gray-500 text-sm'>
                              {employee.department_level}
                            </div>
                          </div>
                          
                        </td>
                        <td className='py-2'>
                          {employee.phone_number}
                        </td>
                        <td>
                        <MainDrawerComponent
                            open={33}
                            card={{ sub: `Permissions: ${employee.name}` }}
                            employee={employee}
                            fetchStaffs={fetchStaffs}
                          >
                          <button 
                            // onClick={() => setStaffDetails(employee)}
                            className='bg-native-blue text-white font-bold text-sm px-2 py-1 rounded'>
                            View
                          </button>
                        </MainDrawerComponent>
                        </td>
                        <td>
                        <button 
                          onClick={() => setStaffDetails(employee)} style={{borderWidth:1}}
                          className='bg-white outline-native-blue border-native-blue text-native-blue font-bold text-sm px-2 py-1 rounded'>
                          Edit
                        </button>
                        </td>
                        <td>
                        <button 
                          onClick={() => navigate("/dashboard/audit-page", {
                            state: { staffDetails: staffDetails , staff_id:employee?.id }
                          })} 
                          className='bg-[#000] text-white font-bold text-sm px-2 py-1 rounded'>
                          View
                        </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <div className="pagination-container">
                <div className="pagination-info">
                  Showing 11 from 250 data
                </div>
                <div className="pagination-buttons">
                  <button className="pagination-button pagination-prev">
                    &laquo; Previous
                  </button>
                  <button className="pagination-button">1</button>
                  <button className="pagination-button">2</button>
                  <button className="pagination-button active">3</button>
                  <button className="pagination-button">4</button>
                  <button className="pagination-button pagination-next">
                    Next &raquo;
                  </button>
                </div>
              </div>
              }
              </div> */}
            </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MyStaffs;

export function OngoingProjectCard({ project }) {
  // const { project_milestones, project_milestones_completed } = project;
  function calculatePercentage(total, completed) {
    const percentage = (Number(completed) / Number(total)) * 100;

    if (isNaN(Number(percentage))) {
      return 0;
    }
    return Math.round(percentage);
  }
  return (
    <Link
      to={`./${project?.project_id}`}
      className='projectCard flex p-4 lg:p-8 justify-between items-center h-28 bg-white rounded-2xl my-3'
    >
      <div>
        <img src={project_icon} />
      </div>
      <div className='w-[35%]'>
        <h2 className='text-sm xl:text-xl leading-tight'>
          {project?.project_title}
        </h2>
        <span>{project?.site_address}</span>
      </div>
      <div>
        <ProgressBarChart
          percentage={calculatePercentage(
            project?.project_status?.project_milestones || 0,
            project?.project_status?.project_milestones_completed || 0
          )}
        />
      </div>
      <div className='d-flex flex-column'>
        <span>Milestones</span>{" "}
        <h1 className='text-[20px]'>
          {project?.project_status.project_milestones}
        </h1>{" "}
        <span>
          {project?.project_status.project_milestones_completed +
            " of " +
            project?.project_status.project_milestones}{" "}
          milestones completed
        </span>
      </div>
      <div>
        <Icon as={BsThreeDots} />
      </div>
    </Link>
  );
}

export function FilterScrollComponent({ filtersArray, handleFilerFunction }) {
  const [active, setActive] = React.useState(0);
  return (
    <div className='FilterScrollComponent flex-col'>
      <div className='text-start w-full mt-4'>Filter</div>
      <div className='filter-container'>
        {filtersArray?.map((filter, i) => (
          <button
            key={i}
            className={active === i ? "active text-sm" : " text-sm"}
            onClick={() => {
              setActive(i);
              handleFilerFunction(i);
            }}
          >
            {filter}
          </button>
        ))}
      </div>
    </div>
  );
}
